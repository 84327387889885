import React, { FC } from "react";
import { IFormTemplate } from "@/model/IForm";
import { useAppSelector } from "@/app/hooks";
import { selectValueByPropertyFromCurrentFormData } from "@/app/store/formSlice";
import BhWysiwygEditor from "@components/wysiwyg/BhWysiwygEditor";
import { formTextEditorTools } from "@components/form/helper/FormTextEditorTools";

interface Props {
  formTemplate: IFormTemplate;
  saveCallback: Function;
  disabled: boolean;
}

const FormTextEditorField: FC<Props> = ({ formTemplate, saveCallback, disabled }) => {
  const fieldValue = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, formTemplate.property));
  const fieldCss = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, formTemplate.property + "Css"));
  const toolbarTools = formTextEditorTools(formTemplate);

  return (
    <div className="p-4">
      <BhWysiwygEditor initialValue={fieldValue} property={formTemplate.property} saveCallback={saveCallback} contentStyle={fieldCss} toolbarTools={toolbarTools} />
    </div>
  );
};

export default FormTextEditorField;
