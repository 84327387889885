import React, { FC, useCallback } from "react";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { useTranslation } from "react-i18next";
import { IFormDataSaveRequest } from "@/model/IForm";
import { useAppSelector } from "@/app/hooks";
import { selectBwdRowIdsByPropertyFromCurrentFormData, selectValueByPropertyFromCurrentFormData } from "@/app/store/formSlice";
import FormBwdWorkTableRow from "@components/form/fields/customFields/bwd/FormBwdWorkTableRow";
import { shallowEqual } from "react-redux";
import { createObjectId } from "@/utilities/formUtilities";
import { useModal } from "react-modal-hook";
import BhModal from "@components/modal/BhModal";
import BhModalFooter from "@components/modal/BhModalFooter";

interface Props {
  saveRowCallback: Function;
  addRowCallback: Function;
  removeRowCallback: Function;
  saveCallback: Function;
  disabled: boolean;
  property: string;
}

const FormBwdWorkersTable: FC<Props> = ({ property, saveRowCallback, addRowCallback, removeRowCallback, saveCallback, disabled }) => {
  const { t } = useTranslation();
  const rowIds = useAppSelector((state) => selectBwdRowIdsByPropertyFromCurrentFormData(state, property), shallowEqual);
  const workers = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, "workers"));

  const addRow = useCallback((index?: number) => {
    if (index || index === 0) {
      addRowCallback({}, "works", index + 1);
    } else {
      addRowCallback({}, "works");
    }
  }, []);

  const removeRow = useCallback((row: any) => {
    removeRowCallback(row, "works");
  }, []);

  const saveRow = useCallback((changedObject: any, changedProperty: any, row: any) => {
    const saveRequest = {
      path: "works",
      changedProperty: changedProperty,
      changedValue: changedObject[changedProperty],
      changedObjectId: row._id
    } as IFormDataSaveRequest;
    saveRowCallback(saveRequest);
  }, []);

  const copyFromWorkers = () => {
    if (workers.length > 0) {
      const worksFromWorkers = workers.map((worker: any) => {
        return { company: worker.name, _id: createObjectId() };
      });
      saveCallback({ works: worksFromWorkers });
    }
  };

  const [showCopyFromWorkersConfirmationModal, hideCopyFromWorkersConfirmationModal] = useModal(
    () => (
      <BhModal
        isShown={true}
        setIsShown={hideCopyFromWorkersConfirmationModal}
        header={<h2>{t("FORMS.BWD.COPY_WORKERSS")}</h2>}
        children={<div className="flex flex-col gap-y-8 p-8">{t("FORMS.BWD.COPY_WORKERS_MODAL_BODY")}</div>}
        footer={
          <BhModalFooter
            onCancel={() => {
              hideCopyFromWorkersConfirmationModal();
            }}
            onConfirm={() => {
              copyFromWorkers();
              hideCopyFromWorkersConfirmationModal();
            }}
            confirmButtonText={t("GLOBAL.COPY") as string}
          />
        }
      />
    ),
    [workers]
  );

  return (
    <div className="px-28">
      <h3>{t("FORMS.BWD.WORKS")}</h3>
      <div className="mt-4 flex flex-row items-center">
        <div className="w-1/2 pr-8">
          <div className="mb-1.5">{t("FORMS.BWD.WORKS.COMPANY")}</div>
        </div>
        <div className="w-1/2 pr-8">
          <div className="mb-1.5">{t("FORMS.BWD.WORKS.WORKS")}</div>
        </div>
      </div>
      {rowIds &&
        rowIds.map((id: any) => {
          return <FormBwdWorkTableRow rowId={id} saveCallback={saveRow} addCallback={addRow} removeCallback={removeRow} disabled={disabled} key={id} />;
        })}
      <div className=" flex w-full flex-row items-center justify-between pr-7">
        <BhSecondaryButton icon={faPlus} buttonProps={{ onClick: () => addRow(), classes: "ml-0", disabled: disabled }}>
          {t("FORMS.SCM.ADD_WORK")}
        </BhSecondaryButton>
        <BhTextOnlyButton buttonProps={{ onClick: showCopyFromWorkersConfirmationModal }}>{t("FORMS.BWD.COPY_WORKERSS")}</BhTextOnlyButton>
      </div>
    </div>
  );
};

export default FormBwdWorkersTable;
