import { IFormTemplate } from "@/model/IForm";

export function hideEditableObjectNameField(formTemplate: IFormTemplate) {
  const propertyMap: Record<string, boolean> = {
    BWD:
      formTemplate.property === "projectName" ||
      formTemplate.property === "workStartDate" ||
      formTemplate.property === "workStartTime" ||
      formTemplate.property === "workEndDate" ||
      formTemplate.property === "workEndTime",
    PROOVIVOTU_PROTOKOLL: formTemplate.property === "projectName",
    VCW: formTemplate.property === "projectName"
  };

  return Object.keys(propertyMap).includes(formTemplate.formType) && propertyMap[formTemplate.formType];
}
