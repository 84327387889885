import { IFormTemplate } from "@/model/IForm";

export function getFormSuffix(formTemplate: IFormTemplate) {
  const suffixPropertyMap: Record<string, boolean> = {
    MNT_KTA: formTemplate.property === "nextWorks",
    KATSETUSE_PROTOKOLL: formTemplate.property === "nextWorks"
  };

  if (!suffixPropertyMap[formTemplate.formType]) return;

  const formToTooltipMap: Record<string, Record<string, string>> = {
    MNT_KTA: { nextWorks: ", tegemine lubatud." },
    KATSETUSE_PROTOKOLL: { nextWorks: ", lubatud." }
  };

  return formToTooltipMap[formTemplate.formType][formTemplate.property];
}
