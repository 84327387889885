import React, { FC } from "react";
import BhSideBySideInput from "@components/input/BhSideBySideInput";
import { IFormTemplate } from "@/model/IForm";
import FormLabelWithImport from "@components/form/fields/FormLabelWithImport";
import BhTextareaSimple from "@components/input/BhTextareaSimple";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/app/hooks";
import { selectValueByPropertyFromCurrentFormData } from "@/app/store/formSlice";

interface Props {
  formTemplate: IFormTemplate;
  saveCallback?: Function;
  disabled: boolean;
}

const FormFieldImportField: FC<Props> = ({ formTemplate, saveCallback, disabled }) => {
  const { t } = useTranslation();
  const fieldValue = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, formTemplate.property));

  return (
    <BhSideBySideInput label={<FormLabelWithImport label={t(formTemplate.value)} property={formTemplate.property} disabled={disabled} />}>
      <BhTextareaSimple initialValue={fieldValue} property={formTemplate.property} onBlurCallback={saveCallback} disabled={disabled} />
    </BhSideBySideInput>
  );
};

export default FormFieldImportField;
