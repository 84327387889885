import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import BhDropdown from "@components/dropdown/BhDropdown";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import BhIconButton from "@components/buttons/BhIconButton";
import { faArrowDownToLine } from "@fortawesome/pro-regular-svg-icons/faArrowDownToLine";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectFilteredAndSortedOpenedTaskBoardTasks, selectIsAnyFilterApplied, selectOpenedTaskBoardTasks, selectProjectTaskCategories } from "@/app/store/tasksSlice";
import { selectCurrentProject } from "@/app/store/project/projectSlice";
import { selectIsAllTasksTaskBoardOpen, selectIsPersonalTaskBoardOpen, selectOpenedTaskBoardName } from "@/app/store/taskBoardsSlice";
import { fetchDownloadUrl } from "@/api/contactAPI";
import { imitatePdfFromFormDownload } from "@/utilities/downloadUtilities";
import { ITask } from "@/model/taskBoard/ITask";
import { CSVLink } from "react-csv";
import BhTooltip from "@components/BhTooltip";
import { useTranslation } from "react-i18next";
import { formatDate, formatDateTime, getCurrentDateString } from "@/utilities/dateUtility";
import { fullPageLoadingSet } from "@/app/store/globalSlice";

const TaskBoardExport: FC = () => {
  const { t } = useTranslation();
  const filterApplied = useAppSelector(selectIsAnyFilterApplied);
  const project = useAppSelector(selectCurrentProject);
  const openedTaskBoardName = useAppSelector(selectOpenedTaskBoardName);
  const isPersonal = useAppSelector(selectIsPersonalTaskBoardOpen);
  const isAllTasks = useAppSelector(selectIsAllTasksTaskBoardOpen);
  const taskBoardName = isPersonal || isAllTasks ? t(openedTaskBoardName) : openedTaskBoardName;
  const projectTaskCategories = useAppSelector(selectProjectTaskCategories);
  const tasksToExport = useAppSelector(selectOpenedTaskBoardTasks);
  const filteredTasksToExport = useAppSelector(selectFilteredAndSortedOpenedTaskBoardTasks);
  const dispatch = useAppDispatch();
  const csvLinkRef = useRef(null);
  const [pdfExportData, setPdfExportData] = useState<any>();
  const [csvExportData, setCsvExportData] = useState<Array<any>>();
  const translations = {
    task: t("TASK.TASK"),
    status: t("TASK.STATUS"),
    name: t("GLOBAL.ITEM_NAME"),
    description: t("TASK.DESCRIPTION"),
    created: t("TASK.CREATED"),
    deadline: t("TASK.DEADLINE"),
    reporter: t("TASK.REPORTER"),
    assignee: t("TASK.ASSIGNEE"),
    attachments: t("TASK.ATTACHMENTS"),
    info: t("GLOBAL.INFO"),
    comments: t("TASK.COMMENTS"),
    author: t("TASK.COMMENT.AUTHOR"),
    content: t("CONTAINER.FILES.HEADER"),
    changeLog: t("TASK.CHANGE_LOG"),
    date: t("TASK.CHANGE.DATE"),
    priority: t("TASK.PRIORITY"),
    participants: t("TASK.PARTICIPANTS"),
    workgroup: t("TASK.TASK_BOARD"),
    taskBoard: t("TASK.TASK_BOARD"),
    locked: t("TASK.HISTORY.LOCKED.TRUE"),
    unlocked: t("TASK.HISTORY.LOCKED.FALSE"),
    projectCategoryId: t("TASK.CATEGORY"),
    category: t("TASK.CATEGORY"),
    tags: t("TASK.TAGS"),
    IN_PROGRESS: t("TASK.IN_PROGRESS"),
    TODO: t("TASK.TODO"),
    NEEDS_APPROVAL: t("TASK.NEEDS_APPROVAL"),
    ARCHIVED: t("TASK.ARCHIVED"),
    DONE: t("TASK.DONE"),
    ADDED: t("TASK.ADDED"),
    REMOVED: t("TASK.REMOVED"),
    5: t("PRIORITY.CRITICAL"),
    4: t("PRIORITY.HIGH"),
    3: t("PRIORITY.MEDIUM"),
    2: t("PRIORITY.LOW"),
    1: t("PRIORITY.LOWEST"),
    RFI: t("TASK.CATEGORY.RFI"),
    Task: t("TASK.CATEGORY.TASK"),
    Issue: t("TASK.CATEGORY.ISSUE"),
    Safety: t("TASK.CATEGORY.SAFETY"),
    Guarantee: t("TASK.CATEGORY.GUARANTEE")
  };

  const downloadTaskPdf = (tasks: Array<ITask>) => {
    const pdfData = { ...pdfExportData, ...{ data: { ...pdfExportData.data, ...{ tasks: tasks } } } };
    dispatch(fullPageLoadingSet(true));
    fetchDownloadUrl(project.id, pdfData)
      .then((response) => imitatePdfFromFormDownload(response))
      .finally(() => {
        dispatch(fullPageLoadingSet(false));
      });
  };

  const exportAllTasksPdf = useCallback(() => {
    downloadTaskPdf(tasksToExport);
  }, [tasksToExport, pdfExportData]);

  const exportFilteredTasksPdf = useCallback(() => {
    downloadTaskPdf(filteredTasksToExport);
  }, [filteredTasksToExport, pdfExportData]);

  const generateCsvExportFileName = () => {
    return "Task_export_" + taskBoardName.replaceAll(" ", "_").toLowerCase() + "_" + getCurrentDateString().replaceAll("-", "_");
  };

  const generateCsvExportData = (tasks: Array<ITask>) => {
    let data = [] as Array<any>;
    tasks.forEach((task) => {
      data.push({
        assignee: task.assigneeFullName,
        created: formatDateTime(task.created),
        deadline: formatDate(new Date(task.deadline)),
        category: task.category ? task.category.name : "",
        description: task.description,
        identifier: task.taskIdentifier,
        name: task.name,
        priority: task.priority,
        reporter: task.reporterFullName + "(" + task.createdBy + ")",
        status: task.status,
        tags:
          !!task.tags && task.tags.length > 0
            ? "[" +
              task.tags
                .map(function (tag) {
                  return tag.name;
                })
                .join(",") +
              "]"
            : "[]",
        attachments:
          !!task.attachments && task.attachments.length > 0
            ? "[" +
              task.attachments
                .map(function (attachment) {
                  return attachment.name;
                })
                .join(",") +
              "]"
            : "[]",
        comments:
          !!task.comments && task.comments.length > 0
            ? "[" +
              task.comments
                .map(function (comm) {
                  var outputString =
                    comm.attachments && comm.attachments.length > 0
                      ? "[" + comm.authorFullName + ":" + comm.text + "(" + comm.attachments.map((att) => att.name).join(",") + ")]"
                      : "[" + comm.authorFullName + ":" + comm.text + "]";
                  return outputString;
                })
                .join(",") +
              "]"
            : "[]",
        changeLog:
          !!task.changeLog && task.changeLog.length > 0
            ? "[" +
              task.changeLog
                .map(function (log) {
                  return "[" + log.creatorFullName + ":" + log.field + "(" + log.oldValue + "->" + log.newValue + "): " + formatDateTime(log.created) + "]";
                })
                .join(",") +
              "]"
            : "[]"
      });
    });
    return data;
  };

  const exportAllTasksCsv = () => {
    setCsvExportData(generateCsvExportData(tasksToExport));
  };

  const exportFilteredTasksCsv = () => {
    setCsvExportData(generateCsvExportData(filteredTasksToExport));
  };

  useEffect(() => {
    if (csvExportData && csvExportData.length > 0) {
      // @ts-ignore
      csvLinkRef?.current?.link.click();
      setCsvExportData([] as Array<any>);
    }
  }, [csvExportData]);

  useEffect(() => {
    setPdfExportData({
      data: {
        printAddons: true,
        recursivelySearchForAttachments: true,
        translations: translations,
        categories: projectTaskCategories,
        tasks: [],
        title: project.name + " - " + taskBoardName,
        currentDate: getCurrentDateString(),
        fileName: "Task_export_" + taskBoardName.replaceAll(" ", "_").toLowerCase() + "_" + getCurrentDateString().replaceAll("-", "_")
      },
      formType: "TASKS"
    });
  }, [project, taskBoardName, projectTaskCategories]);

  const exportDropdownValues = [
    {
      text: t("TASKBOARD.EXPORT.ALL.PDF"),
      function: exportAllTasksPdf
    },
    {
      text: t("TASKBOARD.EXPORT.ALL.CSV"),
      function: exportAllTasksCsv
    },
    ...(filterApplied
      ? [
          {
            text: t("TASKBOARD.EXPORT.FILTERED.PDF"),
            function: exportFilteredTasksPdf
          },
          {
            text: t("TASKBOARD.EXPORT.FILTERED.CSV"),
            function: exportFilteredTasksCsv
          }
        ]
      : [])
  ];

  return (
    <div className="ml-5">
      <BhDropdown
        button={
          <BhTooltip body={t("TASKBOARD.EXPORT")}>
            <BhIconButton icon={faArrowDownToLine} />
          </BhTooltip>
        }
        disabled={tasksToExport.length === 0}
        menu={<BhDropdownMenu values={exportDropdownValues} type={BhDropdownTypeEnum.STRING} textProperty="text" widthClass={filterApplied ? "w-60" : "w-56"} closeOnItemClick={true} />}
        position={BhDropdownPositionEnum.BOTTOM_LEFT}
      />
      {csvExportData && <CSVLink data={csvExportData} ref={csvLinkRef} filename={generateCsvExportFileName()} separator={";"} className={"hidden"} />}
    </div>
  );
};

export default TaskBoardExport;
