import React, { FC } from "react";
import BhSideBySideInput from "@components/input/BhSideBySideInput";
import BhFormInput from "@components/input/BhFormInput";
import { useAppSelector } from "@/app/hooks";
import { selectValueByPropertyFromCurrentFormData } from "@/app/store/formSlice";

interface Props {
  saveCallback: Function;
  disabled: boolean;
}

const FormBetoneerimiseProtokollTableFieldKakskeelne: FC<Props> = ({ saveCallback, disabled }) => {
  const materialClass = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, "class"));
  const thicknessRequired = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, "thicknessRequired"));
  const thicknessReal = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, "thicknessReal"));
  const strengthClassProject = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, "strengthClassProject"));
  const strengthClassChosen = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, "strengthClassChosen"));
  const specialProject = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, "specialProject"));
  const specialChosen = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, "specialChosen"));
  return (
    <BhSideBySideInput>
      <table className="form-table">
        <tbody>
          <tr>
            <td>Betoonipinna klass/Concrete Surface Class</td>
            <td colSpan={4}>
              <BhFormInput initialValue={materialClass} property={"class"} saveCallback={saveCallback} disabled={disabled} />
            </td>
          </tr>
          <tr>
            <td>Betoonkaitsekihi paksus/Concrete Protective Layer Thickness</td>
            <td>nõuetekohane (mm)/adequate (mm):</td>
            <td>
              <BhFormInput initialValue={thicknessRequired} property={"thicknessRequired"} saveCallback={saveCallback} disabled={disabled} />
            </td>
            <td>Kontrollitud tegelik (mm)/Controlled Actual (mm):</td>
            <td>
              <BhFormInput initialValue={thicknessReal} property={"thicknessReal"} saveCallback={saveCallback} disabled={disabled} />
            </td>
          </tr>
          <tr>
            <td>Betooni tugevusklass/Concrete Strength Class</td>
            <td>projektikohane/in accordance with the project:</td>
            <td>
              <BhFormInput initialValue={strengthClassProject} property={"strengthClassProject"} saveCallback={saveCallback} disabled={disabled} />
            </td>
            <td>valitud/selected:</td>
            <td>
              <BhFormInput initialValue={strengthClassChosen} property={"strengthClassChosen"} saveCallback={saveCallback} disabled={disabled} />
            </td>
          </tr>
          <tr>
            <td>Erinõuded/Special Requirements</td>
            <td>projektikohane/in accordance with the project:</td>
            <td>
              <BhFormInput initialValue={specialProject} property={"specialProject"} saveCallback={saveCallback} disabled={disabled} />
            </td>
            <td>valitud/selected:</td>
            <td>
              <BhFormInput initialValue={specialChosen} property={"specialChosen"} saveCallback={saveCallback} disabled={disabled} />
            </td>
          </tr>
        </tbody>
      </table>
    </BhSideBySideInput>
  );
};

export default FormBetoneerimiseProtokollTableFieldKakskeelne;
