import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { selectCurrentProjectId, toggleProjectModalsOpen } from "@/app/store/project/projectSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentUser } from "@/app/store/userSlice";
import { createNewTaskObject, ITask, TaskStatus } from "@/model/taskBoard/ITask";
import { selectCurrentFormFileEntityId, selectCurrentFormId, selectCurrentFormType, selectValueByPropertyFromCurrentFormData } from "@/app/store/formSlice";
import { selectDefaultProjectTaskCategory, setNewTask, setOpenedTaskInfo } from "@/app/store/tasksSlice";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import BhDropdown from "@components/dropdown/BhDropdown";
import { IDropdownItem } from "@/model/IDropdownItem";
import { faFile } from "@fortawesome/pro-regular-svg-icons/faFile";
import { faSquareKanban } from "@fortawesome/pro-regular-svg-icons/faSquareKanban";
import { addDaysToDate } from "@/utilities/dateUtility";
import FormTaskModal from "@/views/home/project/detail/form/modals/FormTaskModal";
import { useTranslation } from "react-i18next";
import { IFormDataSaveRequest } from "@/model/IForm";

interface Props {
  addRow: Function;
  saveRow: Function;
  disabled: boolean;
  tasksLength: number;
  openedFormTask?: any;
  setOpenedFormTask: Dispatch<SetStateAction<any>>;
}

const ScmTaskDropdown: FC<Props> = ({ addRow, saveRow, disabled, tasksLength, openedFormTask, setOpenedFormTask }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const projectId = useAppSelector(selectCurrentProjectId);
  const defaultTaskCategory = useAppSelector(selectDefaultProjectTaskCategory);
  const docNumber = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, "number"));
  const formId = useAppSelector(selectCurrentFormId);
  const formFileEntityId = useAppSelector(selectCurrentFormFileEntityId);
  const formType = useAppSelector(selectCurrentFormType);
  const [formTaskModalOpen, setFormTaskModalOpen] = useState(false);
  const newFormTask = {
    status: TaskStatus.TODO,
    deadline: addDaysToDate(new Date(), 1),
    name: "KA nr. " + docNumber + " Korrastamisülesanne " + (tasksLength + 1),
    category: defaultTaskCategory
  } as ITask;

  useEffect(() => {
    if (openedFormTask && openedFormTask._id) {
      setFormTaskModalOpen(true);
    }
  }, [openedFormTask]);

  useEffect(() => {
    if (!formTaskModalOpen) {
      setOpenedFormTask({});
    }
  }, [formTaskModalOpen]);

  const saveTaskToTaskboard = () => {
    const taskName = "KA nr. " + docNumber + " Korrastamisülesanne " + (tasksLength + 1);
    const task = createNewTaskObject({
      currentUser: currentUser,
      predefinedName: taskName,
      category: defaultTaskCategory,
      formTaskInfo: { formId: formId, fileEntityId: formFileEntityId, projectId: projectId, formType: formType }
    });
    dispatch(setNewTask(task));
    dispatch(setOpenedTaskInfo({ closeModalOnNewTaskSave: true }));
    dispatch(toggleProjectModalsOpen({ modal: "projectTaskModal" }));
  };

  const saveTaskToFormOnly = (taskToSave: any) => {
    if (taskToSave._id) {
      const saveRequest = {
        path: "tasks",
        changes: taskToSave,
        changedObjectId: taskToSave._id
      } as IFormDataSaveRequest;
      saveRow(saveRequest);
    } else {
      addRow(taskToSave, "tasks", 0);
    }
  };

  const dropdownValues: Array<IDropdownItem> = [
    {
      text: "Salvesta vormi",
      function: () => setFormTaskModalOpen(true),
      icon: faFile
    },
    {
      text: "Salvesta töölauale",
      function: saveTaskToTaskboard,
      icon: faSquareKanban
    }
  ];

  return (
    <>
      <BhDropdown
        position={BhDropdownPositionEnum.BOTTOM_LEFT}
        button={
          <BhSecondaryButton icon={faPlus} buttonProps={{ disabled }}>
            {t("FORMS.SCM.ADD_TASK")}
          </BhSecondaryButton>
        }
        menu={<BhDropdownMenu values={dropdownValues} type={BhDropdownTypeEnum.STRING} textProperty="text" />}
        disabled={disabled}
      />
      {formTaskModalOpen && <FormTaskModal task={openedFormTask && openedFormTask._id ? openedFormTask : newFormTask} setIsShown={setFormTaskModalOpen} saveCallback={saveTaskToFormOnly} />}
    </>
  );
};

export default ScmTaskDropdown;
