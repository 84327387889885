import React, { FC } from "react";
import { ICompanyBilling } from "@/model/billing/ICompanyBilling";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { classNames } from "@/utilities/jsUtilities";
import { EntityId } from "@reduxjs/toolkit";
import PlanFeaturesList from "@/views/home/company/detail/companySettingsSubscription/PlanFeaturesList";
import { BauhubPlan } from "@/model/IProject";

interface Props {
  companyBilling: ICompanyBilling;
  openModal: Function;
  plan: BauhubPlan;
}

interface ITierInfoTableData {
  id: EntityId;
  tierNameKey: string;
  monthlyCostProperty: keyof ICompanyBilling;
  yearlyCostProperty: keyof ICompanyBilling;
}

const PlanOverviewTable: FC<Props> = ({ companyBilling, openModal, plan }) => {
  const { t } = useTranslation();
  const isProPlan = plan === BauhubPlan.PRO;
  const isLightPlan = plan === BauhubPlan.LIGHT;
  const disablePlanChange = companyBilling && companyBilling.plan === BauhubPlan.PRO && plan === BauhubPlan.LIGHT && companyBilling.disablePlanChange;
  const proPlanClasses = "bh-bg-deep-ocean bh-text-white";
  const lightPlanClasses = disablePlanChange ? "bh-bg-smoke-50 bh-border-pigeon-20 border" : "bh-bg-white bh-border-pigeon-50 border";

  const isActivePlan = companyBilling && companyBilling.plan === plan;
  const isNotActivePlan = !isActivePlan;

  const tierInfoTableData: Array<ITierInfoTableData> = [
    {
      id: 0,
      tierNameKey: "PLANS.PRICING.TABLE.TIER_ONE",
      monthlyCostProperty: isProPlan ? "tier1ProMonthlyCost" : "tier1MonthlyCost",
      yearlyCostProperty: isProPlan ? "tier1ProYearlyCost" : "tier1YearlyCost"
    },
    {
      id: 1,
      tierNameKey: "PLANS.PRICING.TABLE.TIER_TWO",
      monthlyCostProperty: isProPlan ? "tier2ProMonthlyCost" : "tier2MonthlyCost",
      yearlyCostProperty: isProPlan ? "tier2ProYearlyCost" : "tier2YearlyCost"
    },
    {
      id: 2,
      tierNameKey: "PLANS.PRICING.TABLE.TIER_THREE",
      monthlyCostProperty: isProPlan ? "tier3ProMonthlyCost" : "tier3MonthlyCost",
      yearlyCostProperty: isProPlan ? "tier3ProYearlyCost" : "tier3YearlyCost"
    },
    {
      id: 3,
      tierNameKey: "PLANS.PRICING.TABLE.TIER_FOUR",
      monthlyCostProperty: isProPlan ? "tier4ProMonthlyCost" : "tier4MonthlyCost",
      yearlyCostProperty: isProPlan ? "tier4ProYearlyCost" : "tier4YearlyCost"
    }
  ];

  const euroSign = <span className="text-sm font-bold">€</span>;

  return (
    <div className={classNames(isProPlan && proPlanClasses, isLightPlan && lightPlanClasses, "text-18px l-h-24px relative flex w-1/2 max-w-xl flex-col rounded-xl p-8 md:w-full")}>
      {isActivePlan && (
        <div className="h-8.5 bh-text-deep-ocean bh-shadow-t-r-white bh-bg-white absolute right-0 top-0 w-40 rounded-bl text-center lg:w-1/2">
          <div className={classNames(isLightPlan && "bh-border-pigeon-50 rounded-bl border-b border-l", "flex h-full w-full flex-row items-center justify-center gap-2 whitespace-nowrap font-bold")}>
            <FontAwesomeIcon icon={faCheck} className="bh-text-bauhub-green-120" />
            {t("TIER.SELECTION.ACTIVE_PLAN")}
          </div>
        </div>
      )}
      <div className="flex flex-row items-center justify-between">
        <span className={classNames(isLightPlan && "bh-text-bauhub-green-120", isProPlan && "font-extrabold", disablePlanChange && "bh-text-deep-ocean-40", "mr-4 text-6xl")}>
          {t("PLANS." + plan)}
        </span>
        {!disablePlanChange && isNotActivePlan && (
          <BhSecondaryButton buttonProps={{ onClick: openModal, classes: "h-12 min-w-0 w-2/5 whitespace-nowrap" }}>{t("TIER.SELECTION.SELECT_BUTTON")}</BhSecondaryButton>
        )}
      </div>
      <table className="mt-12">
        <thead>
          <tr>
            <th colSpan={2} />
            <th className={classNames(disablePlanChange && "bh-text-deep-ocean-40", "text-sm font-normal")}>{t("TIER.SELECTION.MONTH_YEAR")}</th>
          </tr>
        </thead>
        <tbody className={classNames(isProPlan && "divide-y-bh-deep-ocean-80", isLightPlan && "divide-y-bh-pigeon-50", disablePlanChange && "bh-text-deep-ocean-40")}>
          {tierInfoTableData.map((td) => (
            <tr key={td.id} className="h-13">
              <td className="pr-3" dangerouslySetInnerHTML={{ __html: t(td.tierNameKey) as any }} />
              <td className="pr-2 text-right text-sm">{t("PLANS.PRICING.TABLE.COST_PER_PROJECT")}</td>
              <td className="font-bold">
                <>
                  {companyBilling[td.monthlyCostProperty]}
                  {euroSign} / {companyBilling[td.yearlyCostProperty]}
                  {euroSign}
                </>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-4">
        <PlanFeaturesList isProPlan={isProPlan} disablePlanChange={disablePlanChange} />
      </div>
    </div>
  );
};

export default PlanOverviewTable;
