import React, { FC, useState } from "react";
import { IFormTaskInfo, ITask } from "@/model/taskBoard/ITask";
import FormTaskModal from "@/views/home/project/detail/form/modals/FormTaskModal";
import FormTaskContainer from "@components/task/container/FormTaskContainer";
import BhIconButton from "@components/buttons/BhIconButton";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentFormFileEntityId, selectCurrentFormId, selectCurrentFormType } from "@/app/store/formSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";

interface Props {
  saveRow: Function;
  row: any;
  disabled: boolean;
}

const RowTaskContainer: FC<Props> = ({ saveRow, row, disabled }) => {
  const [formTaskModalOpen, setFormTaskModalOpen] = useState(false);
  const formId = useAppSelector(selectCurrentFormId);
  const formFileEntityId = useAppSelector(selectCurrentFormFileEntityId);
  const formType = useAppSelector(selectCurrentFormType);
  const projectId = useAppSelector(selectCurrentProjectId);
  const formTaskInfo = { formId: formId, fileEntityId: formFileEntityId, formType: formType, projectId: projectId, formRow: row } as IFormTaskInfo;

  const saveTaskToFormOnly = (task: ITask) => {
    const taskToSave = { task: task };
    saveRow(taskToSave, "task", row._id);
  };

  const deleteFormTask = () => {
    const changedObject = { task: undefined };
    saveRow(changedObject, "task", row._id);
  };

  return (
    <div className="flex flex-row items-center">
      <FormTaskContainer task={row.task} formTaskOpenCallback={() => setFormTaskModalOpen(true)} clickDisabled={disabled} formTaskInfo={formTaskInfo} />
      {!row.task.id && !disabled && <BhIconButton icon={faTrash} buttonProps={{ onClick: deleteFormTask }} />}
      {formTaskModalOpen && <FormTaskModal task={row.task} setIsShown={setFormTaskModalOpen} saveCallback={saveTaskToFormOnly} />}
    </div>
  );
};

export default RowTaskContainer;
