import { fetchCompanyBilling } from "@/api/billingAPI";
import { ICompanyBilling } from "@/model/billing/ICompanyBilling";
import { EntityId } from "@reduxjs/toolkit";
import { FC, useEffect, useState } from "react";
import PlanOverviewTable from "@/views/home/company/detail/companySettingsSubscription/PlanOverviewTable";
import BhScrollableBody from "@/components/detailContainer/BhScrollableBody";
import CompanySettingsDowngradeModal from "@/views/home/company/detail/companySettingsSubscription/CompanySettingsDowngradeModal";
import CompanySettingsUpgradeModal from "@/views/home/company/detail/companySettingsSubscription/CompanySettingsUpgradeModal";
import { BauhubPlan } from "@/model/IProject";
import { BhSectionMessageInfo, BhSectionMessageSomethingElse } from "@/components/sectionMessage/BhSectionMessages";
import { t } from "i18next";

interface Props {
  companyId: EntityId;
}

const CompanySettingsPlanChoiceTab: FC<Props> = ({ companyId }) => {
  const [companyBilling, setCompanyBilling] = useState({} as ICompanyBilling);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [testDate, setTestDate] = useState(new Date());
  const [downgradeModalOpen, setDowngradeModalOpen] = useState(false);
  const disablePlanChange = companyBilling && companyBilling.plan === BauhubPlan.PRO && companyBilling.disablePlanChange;

  useEffect(() => {
    if (companyId) {
      fetchCompanyBilling(companyId).then((res) => {
        setCompanyBilling(res);
      });
    }
  }, []);

  return (
    <BhScrollableBody>
      <div className="px-44 pt-6 lg:px-24 md:px-8">
       {disablePlanChange &&  <div className="flex flex-col w-full">
          <BhSectionMessageInfo>
            <div className="text-18px l-h-24px font-bold">{t("BILLING.PLAN_CHANGE_DISABLED")}</div>
            <div>{t("BILLING.PLAN_CHANGE_DISABLED_TEXT")}</div>
          </BhSectionMessageInfo>
        </div>}
        <div className="flex flex-row">
          {downgradeModalOpen && <CompanySettingsDowngradeModal setDowngradeModalOpen={setDowngradeModalOpen} companyId={companyId} setCompanyBilling={setCompanyBilling} testDate={testDate} />}
          {confirmModalOpen && <CompanySettingsUpgradeModal setConfirmModalOpen={setConfirmModalOpen} companyId={companyId} setCompanyBilling={setCompanyBilling} testDate={testDate} />}

          <div className="flex w-full flex-row justify-start gap-8 md:flex-col">
            <PlanOverviewTable companyBilling={companyBilling} openModal={() => setConfirmModalOpen(true)} plan={BauhubPlan.PRO} />
            <PlanOverviewTable companyBilling={companyBilling} openModal={() => setDowngradeModalOpen(true)} plan={BauhubPlan.LIGHT} />
          </div>
        </div>
      </div>
    </BhScrollableBody>
  );
};

export default CompanySettingsPlanChoiceTab;
