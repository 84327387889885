import React, { Dispatch, FC, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import BhModal from "@components/modal/BhModal";
import BhModalFooter from "@components/modal/BhModalFooter";
import { useAppSelector } from "@/app/hooks";
import { IChecklistBase } from "@/model/checklist/IChecklistBase";
import { selectAllCompanyActiveProjects } from "@/app/store/companiesSlice";
import BhToggleRow from "@components/toggle/BhToggleRow";
import { classNames } from "@/utilities/jsUtilities";
import { selectActivatedBasesInCompanyProjects } from "@/app/store/checklist/checklistsSlice";
import { EntityId } from "@reduxjs/toolkit";
import { naturalSortString } from "@/utilities/sortUtilities";

interface Props {
  setIsShown: Dispatch<SetStateAction<boolean>>;
  checklistBase: IChecklistBase;
  companyId: EntityId;
  addChecklistBaseToProjects: Function;
  removeChecklistBaseFromProjects: Function;
}

const ActivateBaseInProjectsModal: FC<Props> = ({ setIsShown, checklistBase, companyId, addChecklistBaseToProjects, removeChecklistBaseFromProjects }) => {
  const { t } = useTranslation();
  const companyProjects = useAppSelector((state) => selectAllCompanyActiveProjects(state, companyId)).sort((a, b) => naturalSortString(a.name, b.name));
  const activatedBasesInCompanyProjects = useAppSelector(selectActivatedBasesInCompanyProjects);
  const companyProjectsWithSelected = companyProjects.map((project) => {
    const isChecklistBaseActivatedInProject = activatedBasesInCompanyProjects.some((pcb) => pcb.checklistBaseId === checklistBase.id && pcb.projectId === project.id);
    return { ...project, selected: isChecklistBaseActivatedInProject };
  });
  const baseInAllProjects =
    companyProjectsWithSelected.filter((project) => {
      return project.selected;
    }).length === companyProjects.length;

  if (!companyProjects || companyProjects.length === 0) return null;

  const toggleBaseInProject = (project: any) => {
    if (project.selected) {
      removeChecklistBaseFromProjects([project.id]);
    } else {
      addChecklistBaseToProjects([project.id]);
    }
  };

  const toggleBaseInAllProjects = () => {
    if (baseInAllProjects) {
      const projectsSelectedIds = companyProjectsWithSelected.filter((project) => project.selected).map((p) => p.id);
      removeChecklistBaseFromProjects(projectsSelectedIds);
    } else {
      const projectsNotSelectedIds = companyProjectsWithSelected.filter((project) => !project.selected).map((p) => p.id);
      addChecklistBaseToProjects(projectsNotSelectedIds);
    }
  };

  return (
    <BhModal
      isShown={true}
      size={"5xl"}
      setIsShown={setIsShown}
      header={
        <div className="h-7">
          <h2 className="mt-1 leading-7">
            <span className="bh-text-deep-ocean-60">{t("CHECKLIST.TEMPLATE.MANAGE.HEADER")} – </span>
            <span className="bh-text-deep-ocean">{checklistBase.type}</span>
          </h2>
        </div>
      }
      children={
        <div className="h-full w-full overflow-auto px-20 pb-16">
          <div className="my-8">{t("CHECKLIST.TEMPLATE.MANAGE.BODY")}</div>
          <BhToggleRow
            toggleObject={{ id: 0, selected: baseInAllProjects } as any}
            toggleProperty={"selected"}
            onToggle={toggleBaseInAllProjects}
            label={<div className="bh-text-deep-ocean text-14px font-bold leading-5">Kõik projektid</div>}
            classes={"pb-5"}
          />
          {companyProjectsWithSelected &&
            companyProjectsWithSelected.map((project, index) => {
              return (
                <BhToggleRow
                  key={project.id}
                  toggleObject={project}
                  toggleProperty={"selected"}
                  onToggle={() => toggleBaseInProject(project)}
                  label={<span className="bh-text-deep-ocean text-14px leading-5">{project.name}</span>}
                  classes={classNames("pt-5 bh-border-pigeon-20 border-t", index !== companyProjects.length - 1 && "pb-5")}
                />
              );
            })}
        </div>
      }
      footer={<BhModalFooter onCancel={() => setIsShown(false)} cancelButtonText={t("GLOBAL.CLOSE") as string} />}
    />
  );
};

export default ActivateBaseInProjectsModal;
