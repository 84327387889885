import React, { FC, useEffect } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectImportFieldInfoModalOpen } from "@/app/store/formSlice";
import ImportFieldInfoModal from "@/views/home/project/detail/form/modals/ImportFieldInfoModal";
import { EntityId } from "@reduxjs/toolkit";

interface Props {
  dirId: EntityId;
  saveCallback?: Function;
}

const FormModals: FC<Props> = ({ dirId, saveCallback }) => {
  const importFieldInfoModal = useAppSelector(selectImportFieldInfoModalOpen);

  return <>{importFieldInfoModal && <ImportFieldInfoModal dirId={dirId} saveCallback={saveCallback} />}</>;
};

export default FormModals;
