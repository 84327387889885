import React, { FC } from "react";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhIconButton from "@components/buttons/BhIconButton";
import { faFilePlus } from "@fortawesome/pro-regular-svg-icons/faFilePlus";
import { BauhubFormTypes, BauhubMntFormTypes, ClientFormTypes, IForm } from "@/model/IForm";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { saveNewFormAsync } from "@/app/store/formSlice";
import { EntityId } from "@reduxjs/toolkit";
import { selectCurrentProject } from "@/app/store/project/projectSlice";
import { selectCompanyById } from "@/app/store/companiesSlice";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import { Module } from "@/model/IProject";
import { chunkArray, classNames } from "@/utilities/jsUtilities";
import { Menu } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { IEnabledForm } from "@/model/ICompany";
import BhTooltip from "@components/BhTooltip";

interface Props {
  currentDirId: EntityId;
}

const DirectoryFileListHeaderMenuNewFormButton: FC<Props> = ({ currentDirId }) => {
  const { t } = useTranslation();
  const project = useAppSelector(selectCurrentProject);
  const company = useAppSelector((state) => selectCompanyById(state, project.companyId));
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const enabledCompanyFormsWithoutType = company?.enabledForms?.filter((enabledForm) => !enabledForm.type);

  // SET AVAILABLE FORMS
  const forms = enabledCompanyFormsWithoutType?.filter((enabledForm) => !BauhubMntFormTypes.includes(enabledForm.code));
  const mntForms = enabledCompanyFormsWithoutType?.filter((enabledForm) => BauhubMntFormTypes.includes(enabledForm.code));

  const nameTitleSuffixModuleExists = company?.modules?.find((mod: any) => mod.module === Module.CUSTOM_FORM_NAMES && mod.adminSettings && mod.adminSettings.nameTitleSuffix);
  const nameFromProjectModule = nameTitleSuffixModuleExists ? nameTitleSuffixModuleExists.adminSettings.nameTitleSuffix + "." : "";

  // PROJECT BASED CUSTOM ENABLED FORMS
  const addSCM_NBtoEMBACH_NOBE_COOP = project?.modules?.some((mod) => mod.module === Module.EMBACH_NOBE_COOP);
  const addKOOSOLEK_NORDECONtoNOBE_NORDECON_COOP = project?.modules?.some((mod) => mod.module === Module.NOBE_NORDECON_COOP);
  const enabledForms =
    ((forms &&
      [
        ...forms,
        addSCM_NBtoEMBACH_NOBE_COOP && ({ id: -1, code: "SCM_NB" } as IEnabledForm),
        addKOOSOLEK_NORDECONtoNOBE_NORDECON_COOP && ({ id: -2, code: "KOOSOLEK_NORDECON" } as IEnabledForm)
      ].filter(Boolean)) as Array<IEnabledForm>) || [];

  const formsArraySplitInChunks = enabledForms && chunkArray(enabledForms, 14);

  const createNewForm = (enabledForm: IEnabledForm) => {
    if (currentDirId) {
      const form = {
        formType: enabledForm.code,
        locked: false
      } as IForm;
      if ([...BauhubFormTypes, ...BauhubMntFormTypes, ...ClientFormTypes].includes(form.formType)) {
        dispatch(saveNewFormAsync({ projectId: project.id, dirId: currentDirId, formToSave: form })).then((response: any) => {
          const navigateOptions = { state: { previousState: "directoryView" } };
          navigate(`/project/${project.id}/dir/${currentDirId}/form/${response.payload.fileEntityId}`, navigateOptions);
        });
      } else {
        navigate(`/project/${project.id}/dir/${currentDirId}/form/new`, { state: enabledForm.code });
      }
    }
  };

  return (
    <>
      <BhDropdown
        button={
          <BhTooltip body={t("GLOBAL.CREATE_FORM")}>
            <BhIconButton icon={faFilePlus} />
          </BhTooltip>
        }
        menu={
          <div className={classNames("bh-shadow bh-bg-raised-white max-h-minus-200px flex max-w-[70vw] flex-row overflow-auto rounded p-8")}>
            <div className="flex flex-col">
              <h3 className="px-2">{t("FORMS.TEMPLATES")}</h3>
              <div className="flex flex-row gap-x-4">
                {formsArraySplitInChunks?.map((formsChunk, index) => {
                  return (
                    <div key={index} className="w-80">
                      <ul>
                        {formsChunk?.map((enabledForm) => {
                          return (
                            <Menu.Item as={"div"} key={enabledForm.id} onClick={() => createNewForm(enabledForm)}>
                              <li className="hover:bh-bg-smoke my-0.5 cursor-pointer rounded px-2 py-1">{t("FORMS." + nameFromProjectModule + enabledForm.code)}</li>
                            </Menu.Item>
                          );
                        })}
                      </ul>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="flex flex-col">
              <h3 className="px-2">{t("FORMS.MNT")}</h3>
              <div className="w-80">
                <ul>
                  {mntForms?.map((enabledForm) => {
                    return (
                      <Menu.Item as={"div"} key={enabledForm.id} onClick={() => createNewForm(enabledForm)}>
                        <li className="hover:bh-bg-smoke my-0.5 cursor-pointer rounded px-2 py-1">{t("FORMS." + nameFromProjectModule + enabledForm.code)}</li>
                      </Menu.Item>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        }
        position={BhDropdownPositionEnum.BOTTOM_RIGHT}
      />
    </>
  );
};

export default DirectoryFileListHeaderMenuNewFormButton;
