import React, { FC, useEffect } from "react";
import { FileEntityType, IFileEntity } from "@/model/files/IFileEntity";
import BhFileCardLarge from "@components/cards/BhFileCardLarge";
import BhAttachFileButton from "@components/buttons/BhAttachFileButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import BhAttachmentsUploadContainer from "@components/upload/BhAttachmentsUploadContainer";
import { useAppDispatch } from "@/app/hooks";
import { filesUpdatedAndAdded } from "@/app/store/filesSlice";

interface Props {
  attachments: Array<IFileEntity>;
  addingDisabled?: boolean;
  saveCallback?: Function;
  property?: string;
  parentFileEntityId?: number;
  removeCallback?: Function;
  openInNewTab?: boolean;
  fileTypesAllowedForUpload?: string;
  fileUploadAllowed?: boolean;
  chooseFromProjectAllowed?: boolean;
  renameCallback?: Function;
  multiple?: boolean;
  excludeFileTypes?: Array<FileEntityType>;
  uploadCallback?: Function;
}

const AttachmentsContainerLarge: FC<Props> = ({
  attachments,
  addingDisabled,
  saveCallback,
  property,
  parentFileEntityId,
  removeCallback,
  openInNewTab,
  fileTypesAllowedForUpload,
  fileUploadAllowed,
  chooseFromProjectAllowed,
  renameCallback,
  multiple,
  excludeFileTypes,
  uploadCallback
}) => {
  const allowFileUpload = fileUploadAllowed !== undefined ? fileUploadAllowed : true;
  const allowFileChoosingFromProject = chooseFromProjectAllowed !== undefined ? chooseFromProjectAllowed : true;
  const dispatch = useAppDispatch();

  const saveUploadedFiles = (files: any) => {
    saveCallback && saveCallback(files);
  };

  useEffect(() => {
    dispatch(filesUpdatedAndAdded(attachments));
  }, [attachments]);

  const attachmentFileIds = attachments.map((attachment) => attachment.id);

  return (
    <div>
      {attachments && attachments.length > 0 && (
        <div className="flex flex-row flex-wrap items-center">
          {attachments.map((attachment: IFileEntity) => {
            return (
              <div className="bh-bg-white mr-2 mb-2 rounded" key={attachment.id}>
                <BhFileCardLarge
                  fileEntity={attachment}
                  removeCallback={removeCallback}
                  openInNewTab={openInNewTab}
                  renameCallback={renameCallback}
                  downloadContainers={true}
                  attachmentFileIds={attachmentFileIds}
                />
              </div>
            );
          })}
          {!addingDisabled && (
            <div className="mb-2">
              <BhAttachmentsUploadContainer
                saveCallback={saveUploadedFiles}
                existingAttachments={attachments}
                button={<BhAttachFileButton icon={faPlus} />}
                property={property}
                parentFileEntityId={parentFileEntityId}
                fileUploadAllowed={allowFileUpload}
                chooseFromProjectAllowed={allowFileChoosingFromProject}
                fileTypesAllowedForUpload={fileTypesAllowedForUpload}
                multiple={multiple}
                excludeFileTypes={excludeFileTypes}
                uploadCallback={uploadCallback}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AttachmentsContainerLarge;
