import React, { FC } from "react";
import { IFormTemplate } from "@/model/IForm";
import { useTranslation } from "react-i18next";
import BhTextareaSimple from "@components/input/BhTextareaSimple";
import { getFormSuffix } from "@components/form/helper/FormSuffixMap";
import { useAppSelector } from "@/app/hooks";
import { selectValueByPropertyFromCurrentFormData } from "@/app/store/formSlice";

interface Props {
  formTemplate: IFormTemplate;
  saveCallback?: Function;
  disabled: boolean;
}

const FormTextareaMiddleField: FC<Props> = ({ formTemplate, saveCallback, disabled }) => {
  const { t } = useTranslation();
  const fieldValue = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, formTemplate.property));
  const suffixLabel = getFormSuffix(formTemplate);

  return (
    <div className="flex flex-row items-center py-1.5">
      <div className="flex inline-flex w-2/6 justify-end px-3 text-right font-semibold">
        <div>{t(formTemplate.value)}</div>
      </div>
      <div className="flex inline-flex w-3/6 px-3">
        <BhTextareaSimple initialValue={fieldValue} property={formTemplate.property} onBlurCallback={saveCallback} disabled={disabled} />
      </div>
      <div className="flex inline-flex w-1/6 px-3 align-top font-semibold">{suffixLabel}</div>
    </div>
  );
};

export default FormTextareaMiddleField;
