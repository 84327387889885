import { getUserFullName, IUser } from "@/model/IUser";
import { IFileEntity } from "@/model/files/IFileEntity";
import { ITaskBoard } from "@/model/taskBoard/ITaskBoard";
import { ITaskComment } from "@/model/taskBoard/ITaskComment";
import { IObjectChange } from "@/model/IObjectChange";
import { ITaskTag } from "@/model/taskBoard/ITaskTag";
import { IProjectTaskCategory } from "@/model/taskBoard/IProjectTaskCategory";
import { EntityId } from "@reduxjs/toolkit";
import { ITaskToFileAnnotation } from "@/model/files/IFileAnnotation";
import { FormType } from "@/model/IForm";

export interface ITask {
  id: EntityId;
  taskBoardId: EntityId;
  name: string;
  reporter: EntityId;
  assignee: EntityId;
  description: string;
  priority: number;
  deadline: Date | string;
  projectId: number;
  deleted: boolean;
  locked: boolean;
  copyOfTaskId: EntityId;
  projectCategoryId: EntityId;
  taskBoardIdentifier: number;
  participants: Array<IUser>;
  reporterFullName: string;
  assigneeFullName: string;
  status: TaskStatus;
  attachments: Array<IFileEntity>;
  comments: Array<ITaskComment>;
  changeLog: Array<IObjectChange>;
  taskIdentifier: string;
  isRelatedTo: boolean;
  tags: Array<ITaskTag>;
  fileAnnotationRelations: Array<ITaskToFileAnnotation>;
  category: IProjectTaskCategory;
  checklistRowId: EntityId;
  baseFileUrl: string;
  taskBoard: ITaskBoard;
  created: Date;
  updated: Date;
  createdBy: string;
  updatedBy: string;
  formTaskInfo: IFormTaskInfo;
  checklistBaseFileId?: EntityId;
  nameDropdownValues?: Array<any>;
}

export interface IFormTaskInfo {
  formId: EntityId;
  fileEntityId: EntityId;
  projectId: EntityId;
  formType: FormType;
  formRow?: any;
}

export enum TaskStatus {
  TODO = "TODO",
  IN_PROGRESS = "IN_PROGRESS",
  NEEDS_APPROVAL = "NEEDS_APPROVAL",
  DONE = "DONE",
  ARCHIVED = "ARCHIVED"
}

export interface ITaskStatusChangeDTO {
  taskId: EntityId;
  status: TaskStatus;
  checklistTask?: boolean;
}

export interface IOpenedTaskInfo {
  id?: EntityId;
  disabled?: boolean;
  closeModalOnNewTaskSave?: boolean;
  checklistTask?: ITask;
  formTask?: ITask;
}

export function createNewTaskObject(values: {
  currentUser: IUser;
  category?: IProjectTaskCategory;
  taskBoardId?: EntityId;
  isPersonalTask?: boolean;
  predefinedName?: string;
  predefinedDescription?: string;
  checklistRowId?: EntityId;
  deadline?: Date;
  formTaskInfo?: IFormTaskInfo;
  attachments?: Array<IFileEntity>;
  checklistBaseFileId?: EntityId;
  tags?: Array<ITaskTag>;
  nameDropdownValues?: Array<any>;
}) {
  return {
    reporter: values.currentUser.id,
    reporterFullName: getUserFullName(values.currentUser),
    status: TaskStatus.TODO,
    priority: 3,
    name: values.predefinedName ? values.predefinedName : "",
    category: values.category,
    projectCategoryId: values.category ? values.category.id : null,
    taskBoardId: values.taskBoardId ? values.taskBoardId : null,
    assignee: values.isPersonalTask ? values.currentUser.id : null,
    assigneeFullName: values.isPersonalTask ? getUserFullName(values.currentUser) : null,
    description: values.predefinedDescription ? values.predefinedDescription : "",
    checklistRowId: values.checklistRowId ? values.checklistRowId : null,
    deleted: values.checklistRowId ? true : false,
    deadline: values.deadline ? values.deadline.toISOString() : null,
    formTaskInfo: values.formTaskInfo ? values.formTaskInfo : null,
    attachments: values.attachments ? values.attachments : [],
    tags: values.tags ? values.tags : [],
    checklistBaseFileId: values.checklistBaseFileId || undefined,
    nameDropdownValues: values.nameDropdownValues || []
  } as ITask;
}
