import React, { FC } from "react";
import FormParticipantTable from "@components/form/fields/FormParticipantTable";
import { FormType, IFormTemplate } from "@/model/IForm";
import { getFormParticipantDataMap } from "@components/form/helper/FormParticipantMap";

interface Props {
  saveCallback: Function;
  addCallback: Function;
  removeCallback: Function;
  labelSaveCallback: Function;
  formTemplate: IFormTemplate;
  disabled: boolean;
}

const FormParticipantsField: FC<Props> = ({ saveCallback, addCallback, removeCallback, labelSaveCallback, formTemplate, disabled }) => {
  const formsWithSigners = [FormType.KOOSOLEK, FormType.KOOSOLEK_LV, FormType.KTA_UUS, FormType.EHITUSPAEVIK_UUS];
  const participantTableData = getFormParticipantDataMap(formTemplate.property);
  return (
    <div className="my-2.5">
      <FormParticipantTable
        tableData={participantTableData}
        saveCallback={saveCallback}
        withSigners={formsWithSigners.includes(formTemplate.formType)}
        addCallback={addCallback}
        removeCallback={removeCallback}
        labelSaveCallback={labelSaveCallback}
        disabled={disabled}
        formType={formTemplate.formType}
      />
    </div>
  );
};

export default FormParticipantsField;
