import React, { FC, useEffect, useRef } from "react";
import BhModal from "@components/modal/BhModal";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import { IFileEntity } from "@/model/files/IFileEntity";
import BhModalFooter from "@components/modal/BhModalFooter";
import { useTranslation } from "react-i18next";
import { setSelectionRange } from "@/utilities/jsUtilities";

interface Props {
  fileEntity: IFileEntity;
  closeModal: Function;
  renameCallback: Function;
}

const AttachmentRenameModal: FC<Props> = ({ fileEntity, closeModal, renameCallback }) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (fileEntity && fileEntity.name) {
      setSelectionRange(inputRef, 0, fileEntity.name.lastIndexOf("."));
    }
  }, []);

  const renameFile = () => {
    if (inputRef.current && inputRef.current.value) {
      renameCallback(fileEntity.id, inputRef.current.value);
    }
  };

  return (
    <BhModal
      isShown={true}
      setIsShown={() => closeModal()}
      header={<h2>{t("GLOBAL.RENAME")}</h2>}
      children={
        <div className="w-full p-4">
          <BhInputStackedLabel initialValue={fileEntity.name} property={"name"} label={t("FILE.NAME")} inputRef={inputRef} onEnterCallback={renameFile} />
        </div>
      }
      footer={<BhModalFooter confirmButtonText={t("GLOBAL.SAVE") as string} onCancel={closeModal} cancelButtonText={t("GLOBAL.CLOSE") as string} onConfirm={renameFile} />}
    />
  );
};

export default AttachmentRenameModal;
