import React, { FC } from "react";
import MntMpTruupTableCell from "@components/form/fields/customFields/mntTables/MntMpTruupTableCell";
import { ReactComponent as TruupImage } from "@svg/truup.svg";

interface Props {
  dataTable: any;
  saveCallback: Function;
  disabled: boolean;
}

const MntMpTruupTables: FC<Props> = ({ dataTable, saveCallback, disabled }) => {
  return (
    <div>
      <table className="form-table">
        <thead>
          <tr>
            <th>Truubi asend ja kaevik</th>
            <th>Truubi materjal</th>
            <th>Muud tööd</th>
            <th>Märkused</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <MntMpTruupTableCell initialValue={dataTable.a} saveCallback={saveCallback} field={"a"} label={"Projektne truubi pikkus L (m)"} textAlignClass={"text-center"} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.b} saveCallback={saveCallback} field={"b"} label={"Truubi materjal"} textAlignClass={"text-center"} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.c} saveCallback={saveCallback} field={"c"} label={"Täide truubi peal H (m)"} textAlignClass={"text-center"} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.d} saveCallback={saveCallback} field={"d"} inputOnly={true} textAlignClass={"text-center"} disabled={disabled} />
          </tr>
          <tr>
            <MntMpTruupTableCell initialValue={dataTable.e} saveCallback={saveCallback} field={"e"} label={"Tegelik truubi pikkus L (m)"} textAlignClass={"text-center"} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.f} saveCallback={saveCallback} field={"f"} label={"Projektne kalle (%)"} textAlignClass={"text-center"} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.g} saveCallback={saveCallback} field={"g"} label={"Otsakindlustused (m2)"} textAlignClass={"text-center"} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.h} saveCallback={saveCallback} field={"h"} inputOnly={true} textAlignClass={"text-center"} disabled={disabled} />
          </tr>
          <tr>
            <MntMpTruupTableCell initialValue={dataTable.i} saveCallback={saveCallback} field={"i"} label={"Truubi läbimõõt d (m)"} textAlignClass={"text-center"} disabled={disabled} />
            <MntMpTruupTableCell
              initialValue={dataTable.j}
              saveCallback={saveCallback}
              field={"j"}
              label={
                <>
                  Tegelik kalle, <em>i</em> (%)
                </>
              }
              textAlignClass={"text-center"}
              disabled={disabled}
            />
            <MntMpTruupTableCell labelOnly={true} label={"Sissevoolu ava ees kraavi kaevatud"} textAlignClass={"text-center"} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.k} saveCallback={saveCallback} field={"k"} inputOnly={true} textAlignClass={"text-center"} disabled={disabled} />
          </tr>
          <tr>
            <MntMpTruupTableCell initialValue={dataTable.l} saveCallback={saveCallback} field={"l"} label={"Kaevise kõrgus projekti järgi (m)"} textAlignClass={"text-center"} disabled={disabled} />
            <MntMpTruupTableCell labelOnly={true} label={"Suuete kõrgused:"} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.m} saveCallback={saveCallback} field={"m"} label={"(jm/m3)"} textAlignClass={"text-center"} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.n} saveCallback={saveCallback} field={"n"} inputOnly={true} textAlignClass={"text-center"} disabled={disabled} />
          </tr>
          <tr>
            <MntMpTruupTableCell initialValue={dataTable.o} saveCallback={saveCallback} field={"o"} label={"Kaevatud kõrgusmärgini (m)"} textAlignClass={"text-center"} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.p} saveCallback={saveCallback} field={"p"} label={"a) tegelik sissevool Hs (m)"} textAlignClass={"text-center"} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.q} saveCallback={saveCallback} field={"q"} label={"ja kindlustatud (jm/m2)"} textAlignClass={"text-center"} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.r} saveCallback={saveCallback} field={"r"} inputOnly={true} textAlignClass={"text-center"} disabled={disabled} />
          </tr>
          <tr>
            <MntMpTruupTableCell initialValue={dataTable.s} saveCallback={saveCallback} field={"s"} label={"Projektne aluspadja paksus h (m)"} textAlignClass={"text-center"} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.t} saveCallback={saveCallback} field={"t"} label={"projektne sissevool Hs (m)"} textAlignClass={"text-center"} disabled={disabled} />
            <MntMpTruupTableCell labelOnly={true} label={"Väljavoolu ava ees kraavi kaevatud"} textAlignClass={"text-center"} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.u} saveCallback={saveCallback} field={"u"} inputOnly={true} textAlignClass={"text-center"} disabled={disabled} />
          </tr>
          <tr>
            <MntMpTruupTableCell initialValue={dataTable.v} saveCallback={saveCallback} field={"v"} label={"Tegelik aluspadi paksusega h (m)"} textAlignClass={"text-center"} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.w} saveCallback={saveCallback} field={"w"} label={"b) tegelik väljavool Hv (m)"} textAlignClass={"text-center"} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.aa} saveCallback={saveCallback} field={"aa"} label={"(jm/m3)"} textAlignClass={"text-center"} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.ab} saveCallback={saveCallback} field={"ab"} inputOnly={true} textAlignClass={"text-center"} disabled={disabled} />
          </tr>
          <tr>
            <td></td>
            <MntMpTruupTableCell initialValue={dataTable.ac} saveCallback={saveCallback} field={"ac"} label={"projekne väljavool Hv (m)"} textAlignClass={"text-center"} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.ad} saveCallback={saveCallback} field={"ad"} label={"ja kindlustatud (jm/m2)"} textAlignClass={"text-center"} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.ae} saveCallback={saveCallback} field={"ae"} inputOnly={true} textAlignClass={"text-center"} disabled={disabled} />
          </tr>
        </tbody>
      </table>

      <div className="w-full">
        <TruupImage />
      </div>

      <table className="form-table">
        <thead>
          <tr>
            <th colSpan={4} className="text-left">
              Tööetappide vastuvõtmine - üleandmine:
            </th>
            <th colSpan={4}></th>
          </tr>
          <tr>
            <th colSpan={2}></th>
            <th colSpan={3}>Andis üle: Töövõtja projektijuht või tema volitatud isik</th>
            <th colSpan={3}>Võttis vastu: Inseneri projektijuht või tema volitatud isik</th>
          </tr>
          <tr>
            <th colSpan={2} className="text-left">
              Tööetapid
            </th>
            <th colSpan={2} className="text-left">
              Nimi
            </th>
            <th className="w-[140px] text-left">Kuupäev</th>
            <th colSpan={2} className="text-left">
              Nimi
            </th>
            <th className="w-[140px] text-left">Kuupäev</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <MntMpTruupTableCell label={"Kaevik:"} labelOnly={true} colSpan={2} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.af} saveCallback={saveCallback} field={"af"} inputOnly={true} colSpan={2} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.ag} saveCallback={saveCallback} field={"ag"} datePickerOnly={true} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.ah} saveCallback={saveCallback} field={"ah"} inputOnly={true} colSpan={2} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.ai} saveCallback={saveCallback} field={"ai"} datePickerOnly={true} disabled={disabled} />
          </tr>
          <tr>
            <MntMpTruupTableCell label={"Alus:"} labelOnly={true} colSpan={2} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.aj} saveCallback={saveCallback} field={"aj"} inputOnly={true} colSpan={2} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.ak} saveCallback={saveCallback} field={"ak"} datePickerOnly={true} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.al} saveCallback={saveCallback} field={"al"} inputOnly={true} colSpan={2} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.am} saveCallback={saveCallback} field={"am"} datePickerOnly={true} disabled={disabled} />
          </tr>
          <tr>
            <MntMpTruupTableCell label={"Truubi keha:"} labelOnly={true} colSpan={2} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.an} saveCallback={saveCallback} field={"an"} inputOnly={true} colSpan={2} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.ao} saveCallback={saveCallback} field={"ao"} datePickerOnly={true} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.ap} saveCallback={saveCallback} field={"ap"} inputOnly={true} colSpan={2} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.aq} saveCallback={saveCallback} field={"aq"} datePickerOnly={true} disabled={disabled} />
          </tr>
          <tr>
            <MntMpTruupTableCell label={"Tagasitäide:"} labelOnly={true} colSpan={2} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.ar} saveCallback={saveCallback} field={"ar"} inputOnly={true} colSpan={2} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.as} saveCallback={saveCallback} field={"as"} datePickerOnly={true} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.at} saveCallback={saveCallback} field={"at"} inputOnly={true} colSpan={2} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.au} saveCallback={saveCallback} field={"au"} datePickerOnly={true} disabled={disabled} />
          </tr>
          <tr>
            <MntMpTruupTableCell label={"Otsakindlustus:"} labelOnly={true} colSpan={2} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.av} saveCallback={saveCallback} field={"av"} inputOnly={true} colSpan={2} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.aw} saveCallback={saveCallback} field={"aw"} datePickerOnly={true} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.ay} saveCallback={saveCallback} field={"ay"} inputOnly={true} colSpan={2} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.ba} saveCallback={saveCallback} field={"ba"} datePickerOnly={true} disabled={disabled} />
          </tr>
          <tr>
            <MntMpTruupTableCell label={"Kraavid:"} labelOnly={true} colSpan={2} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.bb} saveCallback={saveCallback} field={"bb"} inputOnly={true} colSpan={2} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.bc} saveCallback={saveCallback} field={"bc"} datePickerOnly={true} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.bd} saveCallback={saveCallback} field={"bd"} inputOnly={true} colSpan={2} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.be} saveCallback={saveCallback} field={"be"} datePickerOnly={true} disabled={disabled} />
          </tr>
          <tr>
            <MntMpTruupTableCell initialValue={dataTable.bf} saveCallback={saveCallback} field={"bf"} inputOnly={true} colSpan={2} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.bg} saveCallback={saveCallback} field={"bg"} inputOnly={true} colSpan={2} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.bh} saveCallback={saveCallback} field={"bh"} datePickerOnly={true} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.bi} saveCallback={saveCallback} field={"bi"} inputOnly={true} colSpan={2} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.bj} saveCallback={saveCallback} field={"bj"} datePickerOnly={true} disabled={disabled} />
          </tr>
          <tr>
            <MntMpTruupTableCell initialValue={dataTable.bk} saveCallback={saveCallback} field={"bk"} inputOnly={true} colSpan={2} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.bl} saveCallback={saveCallback} field={"bl"} inputOnly={true} colSpan={2} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.bm} saveCallback={saveCallback} field={"bm"} datePickerOnly={true} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.bn} saveCallback={saveCallback} field={"bn"} inputOnly={true} colSpan={2} disabled={disabled} />
            <MntMpTruupTableCell initialValue={dataTable.bo} saveCallback={saveCallback} field={"bo"} datePickerOnly={true} disabled={disabled} />
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default MntMpTruupTables;
