import React, { FC, useState } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { Trans, useTranslation } from "react-i18next";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { fetchSimpleFormsInSameDir } from "@/api/formAPI";
import { ISimpleForm } from "@/model/IForm";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import { useAppDispatch } from "@/app/hooks";
import { prefillFormFromPreviousFormAsync } from "@/app/store/formSlice";
import { useModal } from "react-modal-hook";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";

interface Props {
  formId: EntityId;
  dirId: EntityId;
}

const FormContainerNewFillFormFromPrevious: FC<Props> = ({ formId, dirId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [previousForms, setPreviousForms] = useState<Array<ISimpleForm>>();
  const [formToUseForPrefill, setFormToUseForPrefill] = useState({} as ISimpleForm);

  const fetchPreviousForms = () => {
    fetchSimpleFormsInSameDir(dirId, formId).then((result) => {
      setPreviousForms(result);
    });
  };

  const selectPreviousForm = (selectedForm: ISimpleForm) => {
    setFormToUseForPrefill(selectedForm);
    showPrefillConfirmationModal();
  };

  const sortedPreviousForms = previousForms?.sort((a, b) => (new Date(a.created) < new Date(b.created) ? 1 : -1));

  const [showPrefillConfirmationModal, hidePrefillConfirmationModal] = useModal(
    () => (
      <BhDeleteConfirmationModal
        setIsOpen={hidePrefillConfirmationModal}
        header={<h2>{t("FORM.PREFILL_MODAL.HEADER")}</h2>}
        body={<div>{t("FORM.PREFILL_MODAL.BODY")}</div>}
        confirmationButtonText={t("CONTRACT.COPY_MODAL.REPLACE")}
        handleDelete={() => {
          dispatch(prefillFormFromPreviousFormAsync({ formId: formId, selectedForm: formToUseForPrefill }));
          hidePrefillConfirmationModal();
        }}
      />
    ),
    [formToUseForPrefill]
  );

  return (
    <div>
      <BhDropdown
        button={
          <BhTextOnlyButton buttonProps={{ onClick: fetchPreviousForms }}>
            <Trans>FORM.HISTORY_SELECT.PLACEHOLDER</Trans>
          </BhTextOnlyButton>
        }
        menu={<BhDropdownMenu values={sortedPreviousForms} textProperty="name" type={BhDropdownTypeEnum.STRING} onSelect={selectPreviousForm} widthClass={"w-fit whitespace-nowrap"} />}
        position={BhDropdownPositionEnum.BOTTOM_LEFT}
      />
    </div>
  );
};

export default FormContainerNewFillFormFromPrevious;
