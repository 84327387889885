import React, { FC } from "react";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faCircleMinus } from "@fortawesome/pro-regular-svg-icons/faCircleMinus";
import { useTranslation } from "react-i18next";
import BhInputSimple from "@components/input/BhInputSimple";
import { useAppSelector } from "@/app/hooks";
import { selectBwdWorkerRowByIdFromCurrentFormData } from "@/app/store/formSlice";
import FormBwdWorkerRowAddButton from "@components/form/fields/customFields/bwd/FormBwdWorkerRowAddButton";

interface Props {
  rowId: string;
  saveCallback: Function;
  addCallback: Function;
  removeCallback: Function;
  disabled: boolean;
}

const FormBwdWorkerTableRow: FC<Props> = ({ rowId, saveCallback, addCallback, removeCallback, disabled }) => {
  const { t } = useTranslation();
  const row = useAppSelector((state) => selectBwdWorkerRowByIdFromCurrentFormData(state, rowId));

  return (
    <div className="relative flex flex-row pb-3">
      <FormBwdWorkerRowAddButton rowId={rowId} addCallback={addCallback} disabled={disabled} />
      <div className="w-1/2 pr-8">
        <BhInputSimple initialValue={row.name} property={"name"} saveCallback={(value: any) => saveCallback(value, "name", row)} disabled={disabled} />
      </div>
      <div className="w-1/2 pr-8">
        <BhInputSimple initialValue={row.count} property={"count"} saveCallback={(value: any) => saveCallback(value, "count", row)} disabled={disabled} />
      </div>
      <div className="absolute -right-20">
        <BhTextOnlyButton icon={faCircleMinus} buttonProps={{ onClick: () => removeCallback(row), disabled: disabled }}>
          {t("GLOBAL.REMOVE")}
        </BhTextOnlyButton>
      </div>
    </div>
  );
};

export default React.memo(FormBwdWorkerTableRow);
