import { IFormTemplate } from "@/model/IForm";

export function getFormFieldTooltip(formTemplate: IFormTemplate) {
  const tooltipVisible: Record<string, boolean> = {
    EHITUSPAEVIK_UUS: formTemplate.property === "otherFactors",
    BWD:
      formTemplate.property === "otherFactors" ||
      formTemplate.property === "materials" ||
      formTemplate.property === "dangerFactors" ||
      formTemplate.property === "waste" ||
      formTemplate.property === "quality" ||
      formTemplate.property === "supervisionInstructions" ||
      formTemplate.property === "documents"
  };

  if (!tooltipVisible[formTemplate.formType]) return;

  const formToTooltipMap: Record<string, Record<string, string>> = {
    EHITUSPAEVIK_UUS: { otherFactors: "nt ruumi temperatuur, niiskus, keskkonnaolud" },
    BWD: {
      otherFactors: "FORMS.BDW.OTHER_FACTORS_TOOLTIP",
      materials: "FORMS.BDW.MATERIALS_TOOLTIP",
      dangerFactors: "FORMS.BDW.DANGER_FACTORS_TOOLTIP",
      waste: "FORMS.BDW.WASTE_TOOLTIP",
      quality: "FORMS.BDW.QUALITY_TOOLTIP",
      supervisionInstructions: "FORMS.BDW.SUPERVISION_INSTRUCTIONS_TOOLTIP",
      documents: "FORMS.BDW.DOCUMENTS_TOOLTIP"
    }
  };

  return formToTooltipMap[formTemplate.formType][formTemplate.property];
}
