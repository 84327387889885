import React, { FC } from "react";
import { IFileEntity } from "@/model/files/IFileEntity";
import BhFileCardMedium from "@components/cards/BhFileCardMedium";

interface Props {
  hideDropdowns?: boolean;
  attachments: Array<IFileEntity>;
  parentFileEntityId?: number;
  saveCallback?: Function;
  removeCallback?: Function;
}

const AttachmentsContainerMedium: FC<Props> = ({ attachments, saveCallback, removeCallback }) => {
  return (
    <div>
      {attachments && attachments.length > 0 && (
        <div className="flex flex-row flex-wrap items-center">
          {attachments.map((attachment: IFileEntity) => {
            return (
              <div className="mr-2 mb-2" key={attachment.id}>
                <BhFileCardMedium fileEntity={attachment} deleteCallback={removeCallback} maxWidthClass={"max-w-224px"} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AttachmentsContainerMedium;
