import React, { FC, useCallback, useEffect, useState } from "react";
import BhModal from "@components/modal/BhModal";
import BhToggleSwitch from "@components/toggle/BhToggleSwitch";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import { EntityId } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import BhModalFooter from "@components/modal/BhModalFooter";
import { ICompanyInvitedUser } from "@/model/companyUsers/ICompanyInvitedUser";
import { ICompanyUser } from "@/model/companyUsers/ICompanyUser";
import { isValidEmail } from "@/utilities/jsUtilities";
import { store } from "@/app/store";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";

export interface Props {
  companyId: EntityId;
  onSave: Function;
  onCancel: Function;
}

const AddCompanyAdminModal: FC<Props> = ({ companyId, onSave, onCancel }) => {
  const { t } = useTranslation();
  const [request, setRequest] = useState({ username: "", accountManager: false, newProjectDefaultAdmin: false, checklistsManager: false });

  useEffect(() => {
    if (request.username.length > 3 && !isValidEmail(request.username)) {
      store.dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.ERROR,
          disappear: true,
          translateCode: "GLOBAL.EMAIL_INVALID"
        })
      );
    }
  }, [request]);

  const updateRequest = useCallback(
    (changedValue: ICompanyInvitedUser | ICompanyUser) => {
      setRequest((previousRequest) => {
        const accountManagerToggledOn = !request.accountManager && changedValue.accountManager;
        if (accountManagerToggledOn) {
          changedValue.checklistsManager = true;
        }
        return { ...previousRequest, ...changedValue };
      });
    },
    [request]
  );

  const handleAdd = () => {
    onSave(request);
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <div>
      <BhModal
        isShown={true}
        setIsShown={() => handleCancel()}
        header={
          <div className="inline-flex items-center">
            <h2>{t("COMPANY.SETTINGS.ADD_ADMINISTRATOR")}</h2>
          </div>
        }
        children={
          <div className="px-8 py-6">
            <BhInputStackedLabel
              initialValue={request.username}
              property={"username"}
              placeholder={t("SHARE.EMAIL_PLACEHOLDER") as string}
              onChangeCallback={updateRequest}
              onBlurAction={updateRequest}
              debounceLength={900}
            />
            <div className="mt-6 mb-4 flex flex-row items-center">
              <h3 className="flex-grow">{t("COMPANY.SETTINGS.TABLE.ACCOUNT_MANAGER")}</h3>
              <BhToggleSwitch value={request.accountManager} property={"accountManager"} onClickAction={updateRequest} />
            </div>
            <div className="mt-6 mb-4 flex flex-row items-center">
              <h3 className="flex-grow">{t("COMPANY.SETTINGS.TABLE.CHECKLISTS_MANAGER")}</h3>
              <BhToggleSwitch value={request.checklistsManager} property={"checklistsManager"} onClickAction={updateRequest} />
            </div>
            <div className="my-4 flex flex-row items-center">
              <h3 className="flex-grow">{t("COMPANY.SETTINGS.TABLE.ADD_TO_NEW_PROJECT")}</h3>
              <BhToggleSwitch value={request.newProjectDefaultAdmin} property={"newProjectDefaultAdmin"} onClickAction={updateRequest} />
            </div>
          </div>
        }
        footer={<BhModalFooter onConfirm={handleAdd} onCancel={handleCancel} confirmDisabled={!isValidEmail(request.username)} />}
      />
    </div>
  );
};

export default AddCompanyAdminModal;
