import React, { FC, useState } from "react";
import BhInputSimple from "@components/input/BhInputSimple";
import BhSideBySideInput from "@components/input/BhSideBySideInput";
import { IFormTemplate } from "@/model/IForm";
import { useAppSelector } from "@/app/hooks";
import { selectIsEditableObjectNameInForms } from "@/app/store/project/projectSlice";
import { hideEditableObjectNameField } from "@components/form/helper/FormEditableObjectNameMap";
import { selectValueByPropertyFromCurrentFormData } from "@/app/store/formSlice";

interface Props {
  formTemplate: IFormTemplate;
  saveCallback?: Function;
  disabled: boolean;
}

const FormInputWithEditableTitleField: FC<Props> = ({ formTemplate, saveCallback, disabled }) => {
  const titleProperty = formTemplate.property + "Title";
  const fieldTitle = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, titleProperty));
  const fieldTitleValue = fieldTitle !== undefined ? fieldTitle : formTemplate.value;
  const fieldValue = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, formTemplate.property));
  const editableObjectNameInForms = useAppSelector(selectIsEditableObjectNameInForms);
  const shouldHideField = hideEditableObjectNameField(formTemplate);

  if (!editableObjectNameInForms && shouldHideField) {
    return null;
  }

  return (
    <BhSideBySideInput
      label={
        <div className="flex w-full flex-row items-center">
          <BhInputSimple initialValue={fieldTitleValue} property={titleProperty} saveCallback={saveCallback} disabled={disabled} inputClasses="text-right font-bold" />
        </div>
      }
    >
      <BhInputSimple initialValue={fieldValue} property={formTemplate.property} saveCallback={saveCallback} disabled={disabled} />
    </BhSideBySideInput>
  );
};

export default FormInputWithEditableTitleField;
