import React, { FC } from "react";
import FormBwdWorkersTable from "@components/form/fields/customFields/bwd/FormBwdWorkersTable";

interface Props {
  saveRowCallback: Function;
  addRowCallback: Function;
  removeRowCallback: Function;
  disabled: boolean;
}

const FormBwdWorkersField: FC<Props> = ({ saveRowCallback, addRowCallback, removeRowCallback, disabled }) => {
  return (
    <div className="my-2.5">
      <FormBwdWorkersTable property={"workers"} saveRowCallback={saveRowCallback} addRowCallback={addRowCallback} removeRowCallback={removeRowCallback} disabled={disabled} />
    </div>
  );
};

export default FormBwdWorkersField;
