import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import BhTableHeader from "@components/table/BhTableHeader";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/app/hooks";
import { selectCompanyChecklistBases, selectCompanyChecklistBasesActivatedInProject } from "@/app/store/checklist/checklistsSlice";
import { naturalSortByField, naturalSortString } from "@/utilities/sortUtilities";
import { IChecklistBase } from "@/model/checklist/IChecklistBase";
import ProjectChecklistSettingListRowContainer from "@components/checklists/settings/projectSettings/ProjectChecklistSettingListRowContainer";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";

interface Props {
  filter: any;
  setFilter: Dispatch<SetStateAction<any>>;
}

const ProjectChecklistSettingsTable: FC<Props> = ({ filter, setFilter }) => {
  const { t } = useTranslation();
  const projectId = useAppSelector(selectCurrentProjectId);
  const companyChecklistBases = useAppSelector(selectCompanyChecklistBases);
  const basesActivatedInProject = useAppSelector((state) => selectCompanyChecklistBasesActivatedInProject(state, projectId));
  const [filteredAndSortedChecklistBases, setFilteredAndSortedChecklistBases] = useState([] as Array<any>);

  useEffect(() => {
    filterAndSortChecklistBases();
  }, [filter, companyChecklistBases, basesActivatedInProject]);

  const filterAndSortChecklistBases = () => {
    const filteredChecklistBases = filterChecklistBases(companyChecklistBases);
    const sortedAndFilteredChecklistBases = sortChecklistBases(filteredChecklistBases);
    setFilteredAndSortedChecklistBases(sortedAndFilteredChecklistBases);
  };

  const filterChecklistBases = (checklistBases: Array<IChecklistBase>) => {
    if (filter.searchString && filter.searchString.length > 0) {
      checklistBases = checklistBases.filter((base) => base.type.toLowerCase().includes(filter.searchString.toLowerCase()));
    }
    if (filter.username && filter.username.length > 0) {
      checklistBases = checklistBases.filter((base) => base.createdBy.toLowerCase() === filter.username.toLowerCase());
    }
    if (filter.category) {
      checklistBases = checklistBases.filter((base) => base.category.toUpperCase() === filter.category.categoryName.toUpperCase());
    }
    if (filter.showProjectTemplatesOnly) {
      checklistBases = checklistBases.filter((base) => base.createdInProjectId);
    }
    if (filter.showCompanyTemplatesOnly) {
      checklistBases = checklistBases.filter((base) => !base.createdInProjectId);
    }
    return checklistBases;
  };

  const sortChecklistBases = (checklistBases: Array<IChecklistBase>) => {
    if (filter.orderBy === "type") {
      checklistBases = checklistBases.slice().sort((a, b) => naturalSortByField(a, b, filter.orderBy, filter.reversed));
    }
    if (filter.orderBy === "category") {
      checklistBases = checklistBases
        .slice()
        .sort((a, b) => naturalSortString(t("CHECKLIST.CATEGORY." + a.category.toUpperCase()), t("CHECKLIST.CATEGORY." + b.category.toUpperCase()), filter.reversed));
    }
    if (filter.orderBy === "created") {
      checklistBases = checklistBases.slice().sort((a, b) => (a.created > b.created ? (filter.reversed ? -1 : 1) : filter.reversed ? 1 : -1));
    }
    if (filter.orderBy === "active") {
      const activeBases = basesActivatedInProject.slice().sort((a, b) => naturalSortByField(a, b, "type", false));
      const inactiveBases = checklistBases
        .slice()
        .filter((base) => !activeBases.some((pcb) => pcb.id === base.id))
        .sort((a, b) => naturalSortByField(a, b, "type", false));
      checklistBases = filter.reversed ? [...inactiveBases, ...activeBases] : [...activeBases, ...inactiveBases];
    }
    return checklistBases;
  };

  const tableColumnHeaders: Array<any> = [
    { id: 0, content: t("CHECKLIST.BASE.NAME"), field: "type", sortable: true },
    { id: 1, content: t("CHECKLIST_BASE.CATEGORY"), field: "category", sortable: true, classes: "w-32" },
    { id: 2, content: t("CHECKLIST.CHECKLIST_VERSION"), classes: "w-24 text-center" },
    { id: 3, content: t("CHECKLIST.CREATED"), field: "created", sortable: true, classes: "w-44" },
    { id: 4, content: t("CHECKLIST.ACTIVE_IN_PROJECT"), classes: "w-28", sortable: true, field: "active" },
    { id: 5, content: t("CHECKLIST.ADDED_TO_USER_GROUPS"), classes: "w-28" },
    { id: 6, content: "", classes: "w-10" }
  ];

  const onTableHeaderClickCallback = (column: IBhTableHeader<IChecklistBase>) => {
    if (column.field) {
      setFilter({ orderBy: column.field, reversed: column.field === filter.orderBy ? !filter.reversed : filter.reversed, searchString: filter.searchString, username: filter.username });
    }
  };

  return (
    <div>
      <table className="h-full w-full">
        <BhTableHeader columns={tableColumnHeaders} sortedBy={filter.orderBy} reversed={filter.reversed} onClickCallback={onTableHeaderClickCallback} />
        <tbody>
          {filteredAndSortedChecklistBases.length > 0 &&
            filteredAndSortedChecklistBases.map((checklistBase) => {
              return <ProjectChecklistSettingListRowContainer checklistBase={checklistBase} key={checklistBase.id} filter={filter} setFilter={setFilter} />;
            })}
        </tbody>
      </table>
    </div>
  );
};

export default ProjectChecklistSettingsTable;
