import React, { FC, useEffect } from "react";
import BhInputSimple from "@components/input/BhInputSimple";
import BhSideBySideInput from "@components/input/BhSideBySideInput";
import { IFormTemplate } from "@/model/IForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons/faCircleInfo";
import { getFormFieldTooltip } from "@components/form/helper/FormTooltipMap";
import { useTranslation } from "react-i18next";
import BhTooltip from "@components/BhTooltip";
import { useAppSelector } from "@/app/hooks";
import { selectIsEditableObjectNameInForms } from "@/app/store/project/projectSlice";
import { hideEditableObjectNameField } from "@components/form/helper/FormEditableObjectNameMap";
import { selectValueByPropertyFromCurrentFormData } from "@/app/store/formSlice";

interface Props {
  formTemplate: IFormTemplate;
  saveCallback?: Function;
  disabled: boolean;
  shortInput?: boolean;
}

const FormInputField: FC<Props> = ({ formTemplate, saveCallback, disabled, shortInput }) => {
  const { t } = useTranslation();
  const fieldValue = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, formTemplate.property));
  const tooltip = getFormFieldTooltip(formTemplate);
  const editableObjectNameInForms = useAppSelector(selectIsEditableObjectNameInForms);
  const shouldHideField = hideEditableObjectNameField(formTemplate);

  if (!editableObjectNameInForms && shouldHideField) {
    return null;
  }

  return (
    <BhSideBySideInput
      label={
        <div className="flex flex-row items-center">
          {tooltip && (
            <BhTooltip body={t(tooltip)}>
              <FontAwesomeIcon icon={faCircleInfo} className="bh-text-deep-ocean mr-3" />
            </BhTooltip>
          )}
          <div>{t(formTemplate.value)}</div>
        </div>
      }
    >
      <BhInputSimple initialValue={fieldValue} property={formTemplate.property} saveCallback={saveCallback} disabled={disabled} inputClasses={shortInput ? "max-w-[140px]" : ""} />
    </BhSideBySideInput>
  );
};

export default FormInputField;
