import { IFormTemplate } from "@/model/IForm";

export function uploadFileTypesAllowed(formTemplate: IFormTemplate) {
  const propertyMap: Record<string, boolean> = {
    PROOVIVOTU_PROTOKOLL: formTemplate.property === "addons"
  };

  if (!propertyMap[formTemplate.formType]) return undefined;

  const formToFileTypeMap: Record<string, Record<string, string>> = {
    PROOVIVOTU_PROTOKOLL: { addons: "image/*" }
  };

  return formToFileTypeMap[formTemplate.formType][formTemplate.property];
}
