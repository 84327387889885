import React, { FC } from "react";
import BhSideBySideInput from "@components/input/BhSideBySideInput";
import { FormType, IFormTemplate } from "@/model/IForm";
import BhToggleSwitch from "@components/toggle/BhToggleSwitch";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/app/hooks";
import { selectValueByPropertyFromCurrentFormData } from "@/app/store/formSlice";

interface Props {
  formTemplate: IFormTemplate;
  saveCallback?: Function;
  disabled: boolean;
}

const FormToggleField: FC<Props> = ({ formTemplate, saveCallback, disabled }) => {
  const { t } = useTranslation();
  const fieldValue = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, formTemplate.property));

  return (
    <BhSideBySideInput
      label={
        <div className="flex flex-row items-center">
          <div className="mr-4 font-semibold">{t(formTemplate.value)}</div>
          <BhToggleSwitch value={fieldValue} onClickAction={saveCallback} small={true} property={formTemplate.property} disabled={disabled} />
        </div>
      }
    >
      {formTemplate.formType === FormType.KOOSOLEK && <div>{t("FORMS.GLOBAL.SIGNED_DIGITALLY")}</div>}
      {formTemplate.formType === FormType.KOOSOLEK_LV && <div>{t("FORMS.GLOBAL.SIGNED_DIGITALLY")}</div>}
      {formTemplate.formType === FormType.SCM && formTemplate.property === "isSignedDigitally" && <div>{t("FORMS.GLOBAL.SIGNED_DIGITALLY")}</div>}
      {formTemplate.formType === FormType.PROOVIVOTU_PROTOKOLL && formTemplate.property === "isSignedDigitally" && <div>{t("FORMS.GLOBAL.SIGNED_DIGITALLY")}</div>}
      {formTemplate.formType === FormType.PROOVIVOTU_PROTOKOLL && formTemplate.property === "koostati" && <div>koostati</div>}
      {formTemplate.formType === FormType.BETONEERIMISE_PROTOKOLL && formTemplate.property === "isSignedDigitally" && <div>{t("FORMS.GLOBAL.SIGNATURES_DIGITALLY")}</div>}
      {formTemplate.formType === FormType.BETONEERIMISE_PROTOKOLL_KAKSKEELNE && formTemplate.property === "isSignedDigitally" && <div>{t("FORMS.GLOBAL.SIGNATURES_DIGITALLY")}</div>}
      {formTemplate.formType === FormType.TOOPLAAN && formTemplate.property === "isSignedDigitally" && <div>{t("FORMS.TOOPLAAN.SIGNED_DIGITALLY")}</div>}
      {formTemplate.formType === FormType.MNT_MP_ALUSED && formTemplate.property === "isSignedDigitally" && <div>{t("FORMS.GLOBAL.SIGNED_DIGITALLY")}</div>}
      {formTemplate.formType === FormType.MNT_MP_DREEN && formTemplate.property === "isSignedDigitally" && <div>{t("FORMS.GLOBAL.SIGNED_DIGITALLY")}</div>}
    </BhSideBySideInput>
  );
};

export default FormToggleField;
