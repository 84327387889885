import React, { FC, useCallback, useEffect } from "react";
import FormKoosolekTableRow from "@components/form/fields/customFields/koosolek/FormKoosolekTableRow";
import { fetchProjectTaskCategoriesAsync } from "@/app/store/tasksSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { useTranslation } from "react-i18next";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { selectKoosolekAgendaRowIdsFromCurrentFormData } from "@/app/store/formSlice";
import { IFormDataSaveRequest } from "@/model/IForm";
import { shallowEqual } from "react-redux";

interface Props {
  saveCallback: Function;
  addCallback: Function;
  removeCallback: Function;
  disabled: boolean;
}

const FormKoosolekTableField: FC<Props> = ({ saveCallback, addCallback, removeCallback, disabled }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectCurrentProjectId);
  const agendaRowIds = useAppSelector(selectKoosolekAgendaRowIdsFromCurrentFormData, shallowEqual);

  useEffect(() => {
    dispatch(fetchProjectTaskCategoriesAsync(projectId));
  }, []);

  const addRowBelow = useCallback((index: number, level: number) => {
    addCallback({ level: level }, "agenda", index);
  }, []);

  const removeRow = useCallback((row: any) => {
    removeCallback(row, "agenda");
  }, []);

  const saveRow = useCallback((changedObject: any, changedProperty: any, rowId: string) => {
    const saveRequest = {
      path: "agenda",
      changedProperty: changedProperty,
      changedValue: changedObject[changedProperty],
      changedObjectId: rowId
    } as IFormDataSaveRequest;
    saveCallback && saveCallback(saveRequest);
  }, []);

  return (
    <div>
      <table className="bh-table bh-border-pigeon-20 w-full">
        <thead>
          <tr>
            <th className="w-24"></th>
            <th className="w-24">{t("FORMS.KOOSOLEK.TABLE.HEADER.NUMBER")}</th>
            <th>{t("FORMS.KOOSOLEK.TABLE.HEADER.TOPIC")}</th>
            <th className="w-60"></th>
            <th className="w-24"></th>
          </tr>
        </thead>
        <tbody>
          {agendaRowIds &&
            agendaRowIds.length > 0 &&
            agendaRowIds.map((rowId: any) => {
              return <FormKoosolekTableRow rowId={rowId} addRowCallback={addRowBelow} removeRowCallback={removeRow} key={rowId} disabled={disabled} saveRow={saveRow} />;
            })}
          {(!agendaRowIds || agendaRowIds.length === 0) && !disabled && (
            <tr>
              <td colSpan={5}>
                <div className="w-full text-center">
                  <BhTextOnlyButton icon={faPlus} buttonProps={{ onClick: () => addRowBelow(0, 0) }}>
                    {t("FORMS.KOOSOLEK.TABLE.ADD_ROW")}
                  </BhTextOnlyButton>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default FormKoosolekTableField;
