import React, { FC, startTransition, useCallback, useEffect, useRef } from "react";

interface Props {
  initialValue: any;
  property: string;
  onBlurCallback?: Function;
  onChangeCallback?: Function;
  onFocus?: Function;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
  saveOnEnter?: boolean;
}

const BhInlineInputXL: FC<Props> = ({ property, initialValue, required, onBlurCallback, onChangeCallback, onFocus, placeholder, disabled, inputRef, saveOnEnter }) => {
  let containedInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let inputRefPropOrContained = inputRef || containedInputRef;
    if (inputRefPropOrContained.current && initialValue !== undefined) inputRefPropOrContained.current.value = initialValue;
  }, []);

  const onChange = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
    startTransition(() => {
      const inputValue = e.target.value;
      // if (inputValue === initialValue) return;
      const changedValue = { [property]: inputValue };
      onChangeCallback && onChangeCallback(changedValue);
    });
  }, []);

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      e.preventDefault();
      const inputRefPropOrContained = inputRef ? inputRef : containedInputRef;
      const changedValue = { [property]: inputRefPropOrContained.current?.value };
      onBlurCallback && onBlurCallback(changedValue);
    },
    [onBlurCallback]
  );

  const onKeyDown = (event: any) => {
    if (event.key === "Enter" && saveOnEnter) {
      event.preventDefault();
      const inputRefPropOrContained = inputRef ? inputRef : containedInputRef;
      inputRefPropOrContained.current?.blur();
    }
  };

  const handleOnFocus = () => {
    onFocus && onFocus();
  };

  return (
    <input
      ref={inputRef || containedInputRef}
      name={property}
      onChange={onChange}
      className="bh-text-deep-ocean hover:bh-border-pigeon-70 focus:bh-border-pigeon-70 bh-border-white w-full rounded border border p-2 text-xl font-bold leading-7 hover:border focus:outline-0"
      placeholder={placeholder || ""}
      disabled={disabled}
      autoComplete="off"
      onBlur={onBlur}
      onFocus={handleOnFocus}
      onKeyDown={(e) => onKeyDown(e)}
    />
  );
};

export default React.memo(BhInlineInputXL);
