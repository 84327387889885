import { IFormTemplate } from "@/model/IForm";

export function setDatesForAddedFiles(formTemplate: IFormTemplate) {
  const propertyMap: Record<string, boolean> = {
    VCW: formTemplate.property === "drawings",
    MNT_TEOSTATUD_TOODE_AKT: formTemplate.property === "addons",
    MNT_KTA: formTemplate.property === "addons"
  };

  return Object.keys(propertyMap).includes(formTemplate.formType) && propertyMap[formTemplate.formType];
}
