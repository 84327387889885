import React, { ChangeEvent, FC, useId } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentDirId } from "@/app/store/filesSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { uploadAndImportZipFileAsync } from "@/app/store/uploadSlice";
import { selectCurrentSidebarMenuItem } from "@/app/store/sidebarSlice";
import { SidebarItemType } from "@/model/ISidebarItem";

interface Props {
  fileInputRef: React.LegacyRef<HTMLInputElement>;
}

const DirectoryZipFileUploadAndImport: FC<Props> = ({ fileInputRef }) => {
  const id = useId();
  const currentDirId = useAppSelector(selectCurrentDirId);
  const projectId = useAppSelector(selectCurrentProjectId);
  const currentItem = useAppSelector(selectCurrentSidebarMenuItem);
  const dispatch = useAppDispatch();

  const isDocument = currentItem === SidebarItemType.DOCUMENTS;

  const onFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const file = files && files[0];
    if (!file || !currentDirId) return;
    dispatch(uploadAndImportZipFileAsync({ file: file, projectId: projectId, isDocument, dirId: currentDirId }));
    event.target.value = "";
  };

  return <input type="file" id={id} ref={fileInputRef} style={{ display: "none" }} multiple={false} accept={"application/zip"} onChange={onFileSelect} />;
};

export default DirectoryZipFileUploadAndImport;
