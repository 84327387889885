import { IFormTemplate } from "@/model/IForm";

export function getVisibleFieldProperty(formTemplate: IFormTemplate) {
  const propertyMap: Record<string, boolean> = {
    BETONEERIMISE_PROTOKOLL: formTemplate.property === "jarelhooldus",
    BETONEERIMISE_PROTOKOLL_KAKSKEELNE: formTemplate.property === "jarelhooldus"
  };

  if (!propertyMap[formTemplate.formType]) return;

  const formToTooltipMap: Record<string, Record<any, any>> = {
    BETONEERIMISE_PROTOKOLL: { jarelhooldus: "jarelhooldusMore" },
    BETONEERIMISE_PROTOKOLL_KAKSKEELNE: { jarelhooldus: "jarelhooldusMore" }
  };

  return formToTooltipMap[formTemplate.formType][formTemplate.property];
}

export function getVisibleDatepickerProperties(formTemplate: IFormTemplate) {
  const propertyMap: Record<string, boolean> = {
    BETONEERIMISE_PROTOKOLL: formTemplate.property === "kastmine" || formTemplate.property === "katmine",
    BETONEERIMISE_PROTOKOLL_KAKSKEELNE: formTemplate.property === "kastmine" || formTemplate.property === "katmine"
  };

  if (!propertyMap[formTemplate.formType]) return;

  const formToTooltipMap: Record<string, Record<any, any>> = {
    BETONEERIMISE_PROTOKOLL: { kastmine: { startDate: "kastmineStart", endDate: "kastmineEnd" }, katmine: { startDate: "katmineStart", endDate: "katmineEnd" } },
    BETONEERIMISE_PROTOKOLL_KAKSKEELNE: { kastmine: { startDate: "kastmineStart", endDate: "kastmineEnd" }, katmine: { startDate: "katmineStart", endDate: "katmineEnd" } }
  };

  return formToTooltipMap[formTemplate.formType][formTemplate.property];
}
