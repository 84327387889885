import React, { FC, useCallback } from "react";
import BhSearchInputWBG from "@components/input/BhSearchInputWBG";
import BhFilter from "@components/filters/BhFilter";
import BhFilterWithToggle from "@components/filters/BhFilterWithToggle";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  categoryTaskBoardFilterCleared,
  categoryToggledInTaskBoardFilter,
  selectFilteredTaskTags,
  selectProjectTaskCategories,
  selectShowArchivedFilter,
  selectTasksFilter,
  setTasksFilter,
  tagTaskBoardFilterCleared,
  tagToggledInTaskBoardFilter,
  userRoleTaskBoardFilterCleared,
  userRoleToggledInTaskBoardFilter,
  userTaskBoardFilterCleared,
  userToggledInTaskBoardFilter
} from "@/app/store/tasksSlice";
import { ITasksFilter, ITaskUserRoleFilter, TaskUserRoleFilterEnum } from "@/model/taskBoard/ITasksFilter";
import { ITaskTag } from "@/model/taskBoard/ITaskTag";
import { IProjectTaskCategory } from "@/model/taskBoard/IProjectTaskCategory";
import { selectIsPersonalTaskBoardOpen, selectOpenedTaskBoardUsers } from "@/app/store/taskBoardsSlice";
import { BhFilterTypeEnum } from "@components/filters/BhFilterTypeEnum";
import { IUserAuthority } from "@/model/IUserAuthority";
import BhFilterContainer from "@components/filters/BhFilterContainer";
import { useTranslation } from "react-i18next";
import {
  selectFilteredTaskTagsInSearch,
  selectTaskCategoriesInSearch,
  selectTasksFilterInSearch,
  selectTaskUsersInSearch,
  taskCategoryFilterClearedInSearch,
  taskCategoryFilterToggledInSearch,
  taskFilterInSearchSet,
  taskTagFilterClearedInSearch,
  taskTagFilterToggledInSearch,
  taskUserFilterClearedInSearch,
  taskUserFilterToggledInSearch,
  taskUserRoleFilterClearedInSearch,
  taskUserRoleFilterToggledInSearch
} from "@/app/store/searchSlice";

export interface Props {
  isSearchView?: boolean;
}

const TaskBoardFilter: FC<Props> = ({ isSearchView }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const filter = useAppSelector(isSearchView ? selectTasksFilterInSearch : selectTasksFilter);
  const projectTaskCategories = useAppSelector(isSearchView ? selectTaskCategoriesInSearch : selectProjectTaskCategories);
  const filteredTaskTags = useAppSelector(isSearchView ? selectFilteredTaskTagsInSearch : selectFilteredTaskTags);
  const showArchived = useAppSelector(selectShowArchivedFilter);
  const isPersonal = useAppSelector(selectIsPersonalTaskBoardOpen);
  const taskBoardUsers = useAppSelector(isSearchView ? selectTaskUsersInSearch : selectOpenedTaskBoardUsers);

  const userRoleFilterValues = [
    { name: TaskUserRoleFilterEnum.REPORTER, translation: "TASK.REPORTER" },
    { name: TaskUserRoleFilterEnum.ASSIGNEE, translation: "TASK.ASSIGNEE" },
    { name: TaskUserRoleFilterEnum.PARTICIPANT, translation: "TASK.PARTICIPANT" }
  ];
  const isDefaultView = !isSearchView;

  const onNameFilterChange = useCallback(
    (changedValue: any) => {
      const changedObject = { ...filter, ...changedValue };
      dispatch(setTasksFilter(changedObject));
    },
    [filter]
  );

  const onCategoryFilterSelect = useCallback(
    (category: IProjectTaskCategory) => {
      dispatch(isDefaultView ? categoryToggledInTaskBoardFilter(category) : taskCategoryFilterToggledInSearch(category));
    },
    [filter]
  );

  const onCategoryFilterReset = useCallback(() => {
    dispatch(isDefaultView ? categoryTaskBoardFilterCleared() : taskCategoryFilterClearedInSearch());
  }, []);

  const isCategorySelected = useCallback(
    (category: IProjectTaskCategory) => {
      return (filter.categories || []).flatMap((c) => c.name.toLowerCase()).includes(category.name.toLowerCase());
    },
    [filter]
  );

  const onTagFilterSelect = useCallback(
    (tag: ITaskTag) => {
      dispatch(isDefaultView ? tagToggledInTaskBoardFilter(tag) : taskTagFilterToggledInSearch(tag));
    },
    [filter]
  );

  const onTagFilterReset = useCallback(() => {
    dispatch(isDefaultView ? tagTaskBoardFilterCleared() : taskTagFilterClearedInSearch());
  }, []);

  const isTagSelected = useCallback(
    (tag: ITaskTag) => {
      return (filter.tags || []).flatMap((t) => t.name.toLowerCase()).includes(tag.name.toLowerCase());
    },
    [filter]
  );

  const onTagSearchChange = useCallback(
    (changedValue: ITasksFilter) => {
      const changedObject = { ...filter, ...changedValue };
      dispatch(isDefaultView ? setTasksFilter(changedObject) : taskFilterInSearchSet(changedValue));
    },
    [filter]
  );

  const onUserRoleFilterSelect = useCallback(
    (role: ITaskUserRoleFilter) => {
      dispatch(isDefaultView ? userRoleToggledInTaskBoardFilter(role) : taskUserRoleFilterToggledInSearch(role));
    },
    [filter]
  );

  const onUserRoleFilterReset = useCallback(() => {
    dispatch(isDefaultView ? userRoleTaskBoardFilterCleared() : taskUserRoleFilterClearedInSearch());
  }, []);

  const isUserRoleSelected = useCallback(
    (role: ITaskUserRoleFilter) => {
      return (filter.userRoles || []).flatMap((r) => r.name.toLowerCase()).includes(role.name.toLowerCase());
    },
    [filter]
  );

  const onUserFilterSelect = useCallback(
    (user: IUserAuthority) => {
      dispatch(isDefaultView ? userToggledInTaskBoardFilter(user) : taskUserFilterToggledInSearch(user));
    },
    [filter]
  );

  const onUserFilterReset = useCallback(() => {
    dispatch(isDefaultView ? userTaskBoardFilterCleared() : taskUserFilterClearedInSearch());
  }, []);

  const isUserSelected = useCallback(
    (user: IUserAuthority) => {
      return filter.users && filter.users.some((u) => u.userEntityId === user.userEntityId);
    },
    [filter]
  );

  const toggleArchived = useCallback(() => {
    const changedValue = { showArchived: !showArchived };
    const changedObject = { ...filter, ...changedValue };
    dispatch(setTasksFilter(changedObject));
  }, [filter]);

  return (
    <BhFilterContainer>
      {isDefaultView && (
        <div>
          <BhSearchInputWBG initialValue={filter.name} property="name" onChangeCallback={onNameFilterChange} placeholder={t("TASKBOARD.FILTER.NAME.PLACEHOLDER") as string} />
        </div>
      )}
      <div className="ml-2">
        <div className="mr-2 inline-block">
          <BhFilter
            title={t("TASKBOARD.FILTER.CATEGORIES")}
            values={projectTaskCategories}
            textProperty="name"
            onSelect={onCategoryFilterSelect}
            onReset={onCategoryFilterReset}
            isSelected={isCategorySelected}
            selectedValues={filter.categories && filter.categories.length}
            numberOfItemsDisplayed={10}
            closeOnItemClick={false}
          />
        </div>
        <div className="mr-2 inline-block">
          <BhFilter
            title={t("TASKBOARD.FILTER.TAG")}
            values={filteredTaskTags}
            textProperty="name"
            onSelect={onTagFilterSelect}
            onReset={onTagFilterReset}
            isSelected={isTagSelected}
            selectedValues={filter.tags && filter.tags.length}
            numberOfItemsDisplayed={10}
            search={true}
            searchInputProperty={"tagSearch"}
            searchInputSaveCallback={onTagSearchChange}
            closeOnItemClick={false}
          />
        </div>
        {!isPersonal && (
          <div className="mr-2 inline-block">
            <BhFilter
              filterType={BhFilterTypeEnum.USER}
              title={t("TASKBOARD.FILTER.USER")}
              values={taskBoardUsers}
              onSelect={onUserFilterSelect}
              onReset={onUserFilterReset}
              isSelected={isUserSelected}
              selectedValues={filter.users && filter.users.length}
            />
          </div>
        )}
        {!isPersonal && filter.users && filter.users.length > 0 && (
          <div className="mr-2 inline-block">
            <BhFilter
              title={t("TASKBOARD.FILTER.ROLE")}
              values={userRoleFilterValues}
              textProperty="translation"
              onSelect={onUserRoleFilterSelect}
              onReset={onUserRoleFilterReset}
              isSelected={isUserRoleSelected}
              selectedValues={filter.userRoles && filter.userRoles.length}
              translateValues={true}
            />
          </div>
        )}
        {isDefaultView && (
          <div className="inline-block">
            <BhFilterWithToggle text={t("TASKBOARD.FILTER.ARCHIVED")} value={showArchived} onClickAction={toggleArchived} />
          </div>
        )}
      </div>
    </BhFilterContainer>
  );
};

export default TaskBoardFilter;
