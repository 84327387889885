import React, { FC, useCallback } from "react";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { IFormParticipantTableData } from "@/model/form/IFormParticipantTableData";
import { classNames } from "@/utilities/jsUtilities";
import BhInlineInput from "@components/input/BhInlineInput";
import { useTranslation } from "react-i18next";
import { FormType, IFormDataSaveRequest } from "@/model/IForm";
import { useAppSelector } from "@/app/hooks";
import { selectParticipantIdsFromCurrentFormData, selectValueByPropertyFromCurrentFormData } from "@/app/store/formSlice";
import FormParticipantRow from "@components/form/fields/FormParticipantRow";
import { shallowEqual } from "react-redux";

interface Props {
  tableData: IFormParticipantTableData;
  saveCallback: Function;
  addCallback: Function;
  removeCallback: Function;
  withSigners?: boolean;
  labelSaveCallback?: Function;
  disabled: boolean;
  formType: FormType;
}

const FormParticipantTable: FC<Props> = ({ tableData, saveCallback, withSigners, addCallback, removeCallback, labelSaveCallback, disabled, formType }) => {
  const { t } = useTranslation();
  const participantIdsFromData = useAppSelector((state) => selectParticipantIdsFromCurrentFormData(state, tableData.columnData.participantsProperty), shallowEqual);
  const participantIds = participantIdsFromData || [];
  const tableEditableValue = useAppSelector((state) => tableData.tableHeaderProperty && selectValueByPropertyFromCurrentFormData(state, tableData.tableHeaderProperty));
  const tableHeader = tableData.tableHeaderEditable ? tableEditableValue : tableData.tableHeader;
  const displayTableHeaderInput = tableHeader || (!tableHeader && tableData.tableHeaderEditable);

  const addParticipant = useCallback(() => {
    const newParticipant = [FormType.KOOSOLEK, FormType.KOOSOLEK_LV].includes(formType) ? { isSigner: true, status: "ATTENDED" } : { isSigner: true };
    addCallback(newParticipant, tableData.columnData.participantsProperty);
  }, []);

  const removeParticipant = useCallback((participant: any) => {
    removeCallback(participant, tableData.columnData.participantsProperty);
  }, []);

  const saveParticipant = useCallback((value: any, changedProperty: any, participant: any) => {
    let changedValue = value;
    if (!value.name.username) {
      saveParticipantField(value, changedProperty, participant);
      return;
    }
    const isValueObjectIncludingCompanyName = value.name && value.name.simpleUserInfo && Object.values(tableData.columnData.columnHeaderToPropertyMap).includes("company");
    const isValueObjectIncludingTitle = value.name && value.name.simpleUserInfo && Object.values(tableData.columnData.columnHeaderToPropertyMap).includes("title");
    const isValueObjectIncludingFirm = value.name && value.name.simpleUserInfo && Object.values(tableData.columnData.columnHeaderToPropertyMap).includes("firm");
    const isValueObjectIncludingEmail = value.name && value.name.username && Object.values(tableData.columnData.columnHeaderToPropertyMap).includes("email");
    const isValueObjectIncludingPhone = value.name && value.name.simpleUserInfo && Object.values(tableData.columnData.columnHeaderToPropertyMap).includes("num");
    if (isValueObjectIncludingCompanyName) {
      changedValue = { ...changedValue, ...{ company: value.name.simpleUserInfo.companyName } };
    }
    if (isValueObjectIncludingTitle) {
      changedValue = { ...changedValue, ...{ title: value.name.simpleUserInfo.title } };
    }
    if (isValueObjectIncludingFirm) {
      changedValue = { ...changedValue, ...{ firm: value.name.simpleUserInfo.companyName } };
    }
    if (isValueObjectIncludingEmail) {
      changedValue = { ...changedValue, ...{ email: value.name.username } };
    }
    if (isValueObjectIncludingPhone) {
      changedValue = { ...changedValue, ...{ num: value.name.simpleUserInfo.contactNumber } };
    }
    const saveRequest = {
      path: tableData.columnData.participantsProperty,
      changes: { ...participant, ...changedValue },
      changedObjectId: participant._id
    } as IFormDataSaveRequest;
    saveCallback(saveRequest);
  }, []);

  const saveParticipantField = useCallback((changedObject: any, changedProperty: any, participant: any) => {
    const saveRequest = {
      path: tableData.columnData.participantsProperty,
      changedProperty: changedProperty,
      changedValue: changedObject[changedProperty],
      changedObjectId: participant._id
    } as IFormDataSaveRequest;
    saveCallback(saveRequest);
  }, []);

  const saveParticipantStatus = useCallback((value: any, participant: any) => {
    const saveRequest = {
      path: tableData.columnData.participantsProperty,
      changedProperty: "status",
      changedValue: value.status ? "ATTENDED" : "MISSED",
      changedObjectId: participant._id
    } as IFormDataSaveRequest;
    saveCallback(saveRequest);
  }, []);

  return (
    <div className="px-28">
      {displayTableHeaderInput && (
        <BhInlineInput
          initialValue={tableData.tableHeaderEditable ? tableHeader : t(tableHeader)}
          property={tableData.tableHeaderProperty || ""}
          saveCallback={labelSaveCallback}
          disabled={!tableData.tableHeaderEditable || disabled}
          initialRows={1}
          inputClasses={"font-bold -ml-2 text-16px bh-text-deep-ocean disabled:bh-text-deep-ocean disabled:bh-bg-white disabled:bh-border-white overflow-hidden"}
        />
      )}
      {participantIds.length > 0 && (
        <div className="mt-4 flex flex-row items-center">
          {Object.keys(tableData.columnData.columnHeaderToPropertyMap).map((header, index) => {
            return (
              <div className={classNames("pr-8", tableData.columnData.columnWidthClass)} key={index}>
                <div className="mb-1.5">{t(header)}</div>
              </div>
            );
          })}
        </div>
      )}
      {participantIds &&
        participantIds.map((id: any) => {
          return (
            <FormParticipantRow
              participantId={id}
              saveParticipantField={saveParticipantField}
              disabled={disabled}
              saveParticipant={saveParticipant}
              removeParticipant={removeParticipant}
              withSigners={withSigners}
              saveParticipantStatus={saveParticipantStatus}
              columnData={tableData.columnData}
              key={id}
            />
          );
        })}
      <div className="mt-3.5">
        <BhSecondaryButton icon={faPlus} buttonProps={{ onClick: addParticipant, classes: "ml-0", disabled: disabled }}>
          {t(tableData.addParticipantButtonText)}
        </BhSecondaryButton>
      </div>
    </div>
  );
};

export default FormParticipantTable;
