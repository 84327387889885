import React, { FC, useCallback } from "react";
import BhIconButton from "@components/buttons/BhIconButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { classNames } from "@/utilities/jsUtilities";
import BhColorPicker from "@components/colorPicker/BhColorPicker";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { useAppSelector } from "@/app/hooks";
import BhAttachmentsUploadContainer from "@components/upload/BhAttachmentsUploadContainer";
import { useTranslation } from "react-i18next";
import AttachmentsContainerMedium from "@components/attachments/AttachmentsContainerMedium";
import { useModal } from "react-modal-hook";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import { IFileEntity } from "@/model/files/IFileEntity";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/pro-regular-svg-icons/faCircleExclamation";
import { selectKoosolekAgendaRowByIdFromCurrentFormData } from "@/app/store/formSlice";
import RowTaskDropdown from "@components/form/fields/customFields/koosolek/RowTaskDropdown";
import AddRowButtons from "@components/form/fields/customFields/koosolek/AddRowButtons";
import RowTaskContainer from "@components/form/fields/customFields/koosolek/RowTaskContainer";
import BhInputSimple from "@components/input/BhInputSimple";
import BhTextareaSimple from "@components/input/BhTextareaSimple";
import BhFakeInput from "@components/input/BhFakeInput";

interface Props {
  rowId: any;
  saveRow: Function;
  addRowCallback: Function;
  removeRowCallback: Function;
  disabled: boolean;
}

const FormKoosolekTableRow: FC<Props> = ({ rowId, saveRow, addRowCallback, removeRowCallback, disabled }) => {
  const { t } = useTranslation();
  const row = useAppSelector((state) => selectKoosolekAgendaRowByIdFromCurrentFormData(state, rowId));
  const enableRowDeleteConfirmationModal = row.attachments?.length > 0 || row.task || row.content || row.nr || row.level === 0;

  const rowColorMap: Record<string, string> = {
    red: "koosolek-table-red",
    blue: "koosolek-table-blue",
    green: "koosolek-table-green",
    black: "koosolek-table-black"
  };

  const removeFile = (file: IFileEntity) => {
    const attachmentsToSave = row.attachments.filter((att: any) => file.id !== att.id);
    const objToSave = { attachments: attachmentsToSave };
    saveRow(objToSave, "attachments", row._id);
  };

  const [showRowDeleteConfirmationModal, hideRowDeleteConfirmationModal] = useModal(
    () => (
      <BhDeleteConfirmationModal
        setIsOpen={hideRowDeleteConfirmationModal}
        header={<h2>{t("FORMS.CONFIRMATION.DELETE")}</h2>}
        body={
          <div>
            <div className="mb-4 font-bold">
              {row.nr && <i>{row.content ? "Nr. " + row.nr + " - " : "Nr. " + row.nr}</i>}
              {row.content && <i>{row.content}</i>}
            </div>
            <div>{t("FORMS.CONFIRMATION.DELETE_BODY")}</div>
          </div>
        }
        confirmationButtonText={t("GLOBAL.DELETE")}
        handleDelete={() => {
          removeRowCallback(row);
          hideRowDeleteConfirmationModal();
        }}
      />
    ),
    [removeRowCallback, row]
  );

  const nrSaveCallback = useCallback((value: any) => saveRow(value, "nr", row._id), [saveRow, row._id]);
  const contentOnBlurCallback = useCallback((value: any) => saveRow(value, "content", row._id), [saveRow, row._id]);

  return (
    <tr key={row.id} className={classNames("bh-border-pigeon-20", row.level === 0 && "font-bold")}>
      <td className="align-top">
        <AddRowButtons row={row} disabled={disabled} addRowCallback={addRowCallback} />
      </td>
      <td className="align-top">
        <BhFakeInput value={row.nr} className={classNames("text-right", row.level === 0 && "font-bold", rowColorMap[row.color] || "koosolek-table-black")}>
          <BhInputSimple
            initialValue={row.nr}
            property={"nr"}
            inputClasses={classNames("text-right", row.level === 0 && "font-bold", rowColorMap[row.color] || "koosolek-table-black")}
            saveCallback={nrSaveCallback}
            disabled={disabled}
            autoFocus={true}
          />
        </BhFakeInput>
      </td>
      <td className="align-top">
        <div className="flex flex-col">
          <BhFakeInput value={row.content} textarea={true} className={classNames(row.level === 0 && "font-bold", rowColorMap[row.color] || "koosolek-table-black")}>
            <BhTextareaSimple
              initialValue={row.content}
              property={"content"}
              inputClasses={classNames(row.level === 0 && "font-bold", rowColorMap[row.color] || "koosolek-table-black")}
              onBlurCallback={contentOnBlurCallback}
              disabled={disabled}
              autoFocus={true}
            />
          </BhFakeInput>
          {row.task && <RowTaskContainer row={row} saveRow={saveRow} disabled={disabled} />}
          {row.task && !row.task.id && (
            <div className="bh-text-deep-ocean-80 ml-2 flex flex-row items-center gap-x-2">
              <FontAwesomeIcon icon={faCircleExclamation} />
              {t("FORMS.TASK.SAVED_TO_FORM")}
            </div>
          )}
          {row.attachments && row.attachments.length > 0 && (
            <div className="pt-2">
              <AttachmentsContainerMedium attachments={row.attachments} removeCallback={!disabled ? (file: IFileEntity) => removeFile(file) : undefined} />
            </div>
          )}
        </div>
      </td>
      <td className="align-top">
        <div className="flex w-full flex-row">
          <BhAttachmentsUploadContainer
            saveCallback={(value: any) => saveRow(value, "attachments", row._id)}
            existingAttachments={row.attachments}
            button={
              <BhSecondaryButton icon={faPlus} buttonProps={{ disabled: disabled }}>
                {t("FORMS.KOOSOLEK.TABLE.EXTRAS")}
              </BhSecondaryButton>
            }
            property={"attachments"}
            multiple={true}
            disabled={disabled}
            fileUploadAllowed={true}
            chooseFromProjectAllowed={true}
          />
          {!row.task && <RowTaskDropdown saveRow={saveRow} row={row} disabled={disabled} />}
        </div>
      </td>
      <td className="align-top">
        <div className="flex flex-row items-center justify-end">
          <BhColorPicker currentColor={row.color || "black"} dropDownValues={Object.keys(rowColorMap)} onSelect={(value: any) => saveRow(value, "color", row._id)} diameter={6} disabled={disabled} />
          <BhIconButton icon={faTrash} buttonProps={{ onClick: enableRowDeleteConfirmationModal ? showRowDeleteConfirmationModal : () => removeRowCallback(row), disabled: disabled }} />
        </div>
      </td>
    </tr>
  );
};

export default React.memo(FormKoosolekTableRow);
