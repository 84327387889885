import React, { FC } from "react";
import BhSideBySideInput from "@components/input/BhSideBySideInput";
import { IFormTemplate } from "@/model/IForm";
import BhDatePicker from "@components/input/BhDatePicker";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/app/hooks";
import { selectIsEditableObjectNameInForms } from "@/app/store/project/projectSlice";
import { hideEditableObjectNameField } from "@components/form/helper/FormEditableObjectNameMap";
import { enableDateRemove } from "@components/form/helper/FormEnableDateRemove";
import { initializeWithNullValues } from "@components/form/helper/FormInitializeWithNullValues";
import { selectValueByPropertyFromCurrentFormData } from "@/app/store/formSlice";

interface Props {
  formTemplate: IFormTemplate;
  saveCallback?: Function;
  disabled: boolean;
}

const FormDatepickerField: FC<Props> = ({ formTemplate, saveCallback, disabled }) => {
  const { t } = useTranslation();
  const fieldValue = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, formTemplate.property));
  const editableObjectNameInForms = useAppSelector(selectIsEditableObjectNameInForms);
  const shouldHideField = hideEditableObjectNameField(formTemplate);
  const enableDateRemoveOnDatepicker = enableDateRemove(formTemplate);
  const initializeWithNull = initializeWithNullValues(formTemplate) || fieldValue === "";
  const initialValue = fieldValue ? new Date(fieldValue) : initializeWithNull ? undefined : new Date();

  if (!editableObjectNameInForms && shouldHideField) {
    return null;
  }

  const resetDatefield = () => {
    const dateToSave = { [formTemplate.property]: "" };
    saveCallback && saveCallback(dateToSave);
  };

  return (
    <BhSideBySideInput label={t(formTemplate.value)}>
      <BhDatePicker
        property={formTemplate.property}
        initialValue={initialValue}
        onChangeCallback={saveCallback}
        returnISOString={true}
        disabled={disabled}
        onResetCallback={enableDateRemoveOnDatepicker ? resetDatefield : undefined}
      />
    </BhSideBySideInput>
  );
};

export default FormDatepickerField;
