import React, { FC, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  initialValue: string;
  property: string;
  saveCallback: Function;
  toolbarTools?: string;
  contentStyle?: string;
  disabled?: boolean;
}

const BhWysiwygEditor: FC<Props> = ({ initialValue, property, saveCallback, toolbarTools, contentStyle, disabled }) => {
  const editorRef = useRef(null) as any;

  const defaultToolbar =
    "undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | fontsize |" +
    " table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol";

  const saveEditorContent = () => {
    if (editorRef.current) {
      saveCallback({ [property]: editorRef.current.getContent() });
    }
  };

  const additionalCss =
    ".bauhub-field {background-color: #fbeabb;}" +
    ".mce-content-body [contentEditable=false][data-mce-selected], .mce-content-body [contentEditable=false] [contentEditable=true]:hover, .mce-content-body [contentEditable=false] [contentEditable=true]:focus {outline: none !important;}" +
    ".mce-content-body [contentEditable=false] [contentEditable=true]:hover, .mce-content-body [contentEditable=false] [contentEditable=true]:focus {background-color: #fdf7e6;}";

  return (
    <Editor
      tinymceScriptSrc={ConfigSingleton.getInstance().getConfig().PUBLIC_URL + "/tinymce4.3.0/tinymce.min.js"}
      onInit={(evt, editor) => (editorRef.current = editor)}
      disabled={disabled}
      initialValue={initialValue}
      init={{
        setup: (editor) => {
          editor.on("blur", () => {
            saveEditorContent();
          });
          // TODO "remove" event should fire save also, but should not fire two events with blur
        },
        statusbar: false,
        resize: false,
        menubar: false,
        plugins: "link image lists table autoresize",
        autoresize_bottom_margin: 20,
        toolbar: toolbarTools || defaultToolbar,
        themes: "modern",
        paste_auto_cleanup_on_paste: true,
        paste_remove_styles: true,
        paste_remove_styles_if_webkit: true,
        elementpath: false,
        branding: false,
        invalid_styles: {
          th: "height",
          td: "height"
        },
        content_style: contentStyle + additionalCss,
        width: "100%",
        editable_class: "bauhub-field",
        font_size_formats: "8pt 10pt 11pt 12pt 14pt 16pt 18pt 24pt 36pt"
      }}
    />
  );
};

export default BhWysiwygEditor;
