import React, { FC } from "react";
import FormBwdWorksTable from "@components/form/fields/customFields/bwd/FormBwdWorksTable";

interface Props {
  saveRowCallback: Function;
  addRowCallback: Function;
  removeRowCallback: Function;
  saveCallback: Function;
  disabled: boolean;
}

const FormBwdWorksField: FC<Props> = ({ saveRowCallback, addRowCallback, removeRowCallback, saveCallback, disabled }) => {
  return (
    <div className="my-2.5">
      <FormBwdWorksTable property={"works"} saveRowCallback={saveRowCallback} addRowCallback={addRowCallback} removeRowCallback={removeRowCallback} saveCallback={saveCallback} disabled={disabled} />
    </div>
  );
};

export default FormBwdWorksField;
