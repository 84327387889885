import React, { FC } from "react";

import { faCog } from "@fortawesome/pro-regular-svg-icons/faCog";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import BhBadge from "@components/badge/BhBadge";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import { EntityId } from "@reduxjs/toolkit";
import { useAppSelector } from "@/app/hooks";
import { selectAllCompanyProjectIds, selectCompanyById } from "@/app/store/companiesSlice";
import { useNavigate } from "react-router-dom";
import { isCurrentUserCompanyAdminWithAccountManagerPrivilege, isCurrentUserCompanyChecklistsManager } from "@/app/store/userSlice";
import { Trans } from "react-i18next";
import BhCompanyLogo from "@/views/home/project/sidebar/BhCompanyLogo";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  companyId: EntityId;
}

const CompanyHeaderRow: FC<Props> = ({ companyId }) => {
  const navigate = useNavigate();
  const company = useAppSelector((state) => selectCompanyById(state, companyId));
  const isCompanyAdminWithAccountManagerPrivilege = useAppSelector((state) => isCurrentUserCompanyAdminWithAccountManagerPrivilege(state, companyId));
  const isChecklistsManager = useAppSelector((state) => isCurrentUserCompanyChecklistsManager(state, companyId));
  const numOfCompanyProjects = useAppSelector((state) => selectAllCompanyProjectIds(state, companyId)).length;

  const openCompany = () => {
    if (company) {
      if (isCompanyAdminWithAccountManagerPrivilege) {
        navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/company/${company.id}/general`);
        return;
      }
      if (isChecklistsManager) {
        navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/company/${company.id}/checklists`);
        return;
      }
    }
  };

  const anyProjectSuspended = company?.projects?.some((project) => project.suspended);
  const showAdminButtons = isCompanyAdminWithAccountManagerPrivilege || isChecklistsManager;

  return (
    <>
      {company && (
        <div className="mt-10 mb-4 flex flex-row flex-wrap items-center">
          <div className="mr-4 flex flex-row items-center overflow-hidden align-middle">
            <BhCompanyLogo companyId={companyId} />
          </div>
          <div className="inline-flex flex-1 items-center">
            <h1 className="bh-text-deep-ocean my-2 mr-3 text-2xl">{company.name}</h1>
            {isCompanyAdminWithAccountManagerPrivilege && <BhBadge children={numOfCompanyProjects} />}
          </div>
          {showAdminButtons && (
            <div className="ml-6 flex items-center gap-2">
              <BhSecondaryButton icon={faCog} buttonProps={{ onClick: () => openCompany() }}>
                <Trans>GLOBAL.SETTINGS</Trans>
              </BhSecondaryButton>
              {isCompanyAdminWithAccountManagerPrivilege && (
                <BhPrimaryButton
                  icon={faPlus}
                  buttonProps={{ onClick: () => navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/company/${companyId}/new`), disabled: anyProjectSuspended }}
                >
                  <Trans>PROJECT.ADD_NEW_PROJECT</Trans>
                </BhPrimaryButton>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CompanyHeaderRow;
