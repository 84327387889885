import React, { FC } from "react";
import BhSideBySideInput from "@components/input/BhSideBySideInput";
import { IFormTemplate } from "@/model/IForm";
import BhTimePicker from "@components/input/BhTimePicker";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/app/hooks";
import { selectIsEditableObjectNameInForms } from "@/app/store/project/projectSlice";
import { hideEditableObjectNameField } from "@components/form/helper/FormEditableObjectNameMap";
import { selectValueByPropertyFromCurrentFormData } from "@/app/store/formSlice";

interface Props {
  formTemplate: IFormTemplate;
  saveCallback?: Function;
  disabled: boolean;
}

const FormTimepickerField: FC<Props> = ({ formTemplate, saveCallback, disabled }) => {
  const { t } = useTranslation();
  const fieldValue = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, formTemplate.property));
  const editableObjectNameInForms = useAppSelector(selectIsEditableObjectNameInForms);
  const shouldHideField = hideEditableObjectNameField(formTemplate);

  if (!editableObjectNameInForms && shouldHideField) {
    return null;
  }

  return (
    <BhSideBySideInput label={t(formTemplate.value)}>
      <BhTimePicker property={formTemplate.property} initialValue={fieldValue} saveCallback={saveCallback} disabled={disabled} />
    </BhSideBySideInput>
  );
};

export default FormTimepickerField;
