import React, { FC } from "react";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentFormTemplatesSorted } from "@/app/store/formSlice";
import FormField from "@components/form/FormField";

interface Props {
  dataSaveCallback: Function;
  nestedDataSaveCallback: Function;
  nestedDataAddCallback: Function;
  nestedDataRemoveCallback: Function;
  disabled: boolean;
}

const FormContent: FC<Props> = ({ dataSaveCallback, nestedDataSaveCallback, nestedDataAddCallback, nestedDataRemoveCallback, disabled }) => {
  const formTemplates = useAppSelector(selectCurrentFormTemplatesSorted);

  if (!formTemplates) return null;

  return (
    <div>
      {formTemplates.map((template) => {
        return (
          <FormField
            formTemplate={template}
            dataSaveCallback={dataSaveCallback}
            nestedDataRemoveCallback={nestedDataRemoveCallback}
            nestedDataAddCallback={nestedDataAddCallback}
            nestedDataSaveCallback={nestedDataSaveCallback}
            disabled={disabled}
            key={template.id}
          />
        );
      })}
    </div>
  );
};

export default FormContent;
