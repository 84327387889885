import React, { FC } from "react";
import BhLightSeparator from "@components/separator/BhLightSeparator";
import { IFormTemplate } from "@/model/IForm";
import { useTranslation } from "react-i18next";

interface Props {
  formTemplate: IFormTemplate;
}

const FormHeaderSeparatorField: FC<Props> = ({ formTemplate }) => {
  const { t } = useTranslation();

  return (
    <div className="py-4">
      <BhLightSeparator />
      <div className="py-4 font-bold">{t(formTemplate.value)}</div>
      <BhLightSeparator />
    </div>
  );
};

export default FormHeaderSeparatorField;
