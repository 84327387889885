import React, { FC, startTransition, useCallback, useEffect, useMemo, useRef } from "react";
import { classNames } from "@/utilities/jsUtilities";
import { debounce } from "lodash";
import useResizeObserver from "use-resize-observer";

interface Props {
  initialValue: any;
  property: string;
  onBlurCallback?: Function;
  onChangeCallback?: Function;
  required?: boolean;
  placeholder?: string;
  inputClasses?: string;
  disabled?: boolean;
  inputRef?: React.RefObject<HTMLTextAreaElement>;
  triggerSaveWithInitialValue?: boolean;
  rows?: number;
  autoFocus?: boolean;
}

const BhTextarea: FC<Props> = ({ property, initialValue, required, onBlurCallback, onChangeCallback, placeholder, inputClasses, disabled, inputRef, triggerSaveWithInitialValue, rows, autoFocus }) => {
  let containedInputRef = useRef<HTMLTextAreaElement>(null);

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLTextAreaElement>) => {
      e.preventDefault();
      startTransition(() => {
        if (!triggerSaveWithInitialValue) {
          const inputValue = e.target.value;
          const initialValueString = initialValue || "";
          if (inputValue === initialValueString) return;
        }
        const changedValue = { [property]: e.target.value };
        onBlurCallback && onBlurCallback(changedValue);
      });
    },
    [onBlurCallback, initialValue]
  );

  const onChange = useCallback(
    (e: React.FocusEvent<HTMLTextAreaElement>) => {
      e.preventDefault();
      startTransition(() => {
        calculateHeight();
        if (!triggerSaveWithInitialValue) {
          const inputValue = e.target.value;
          const initialValueString = initialValue || "";
          if (inputValue === initialValueString) return;
        }
        const changedValue = { [property]: e.target.value };
        onChangeCallback && onChangeCallback(changedValue);
      });
    },
    [onChangeCallback]
  );

  const debouncedOnChangeHandler = useMemo(() => debounce(onChange, 300), [initialValue, onChangeCallback]);

  useEffect(() => {
    return () => {
      debouncedOnChangeHandler.cancel();
      onResize.cancel();
    };
  }, []);

  useEffect(() => {
    let inputRefPropOrContained = inputRef || containedInputRef;
    if (inputRefPropOrContained.current && initialValue !== undefined) inputRefPropOrContained.current.value = initialValue;
    calculateHeight();
  });

  const calculateHeight = () => {
    let inputRefPropOrContained = inputRef || containedInputRef;
    if (inputRefPropOrContained && inputRefPropOrContained.current) {
      inputRefPropOrContained.current.style.height = "inherit";
      inputRefPropOrContained.current.style.height = `${inputRefPropOrContained.current.scrollHeight + 2}px`;
    }
  };

  const onResize = useMemo(() => debounce(calculateHeight, 500, { leading: true }), []);
  const {} = useResizeObserver({ onResize, ref: inputRef || containedInputRef });

  return (
    <textarea
      ref={inputRef || containedInputRef}
      name={property}
      onChange={debouncedOnChangeHandler}
      className={classNames(
        "l-h-20 bh-border-pigeon-40 placeholder:bh-text-deep-ocean-40 hover:bh-border-pigeon-70 focus:bh-border-pigeon-70 py-7px w-full rounded " +
          "disabled:bh-text-deep-ocean-40 disabled:bh-bg-smoke disabled:bh-border-smoke scrollbar-hide resize-none",
        inputClasses
      )}
      rows={rows || 1}
      placeholder={placeholder || ""}
      disabled={disabled}
      autoComplete="off"
      onBlur={onBlur}
      autoFocus={autoFocus}
    />
  );
};

export default React.memo(BhTextarea);
