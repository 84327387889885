import React, { FC } from "react";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { useTranslation } from "react-i18next";
import BhIconButton from "@components/buttons/BhIconButton";
import BhTooltip from "@components/BhTooltip";
import { useAppSelector } from "@/app/hooks";
import { selectBwdWorkerRowIndexByIdFromCurrentFormData } from "@/app/store/formSlice";

interface Props {
  rowId: string;
  addCallback: Function;
  disabled: boolean;
}

const FormBwdWorkerRowAddButton: FC<Props> = ({ rowId, addCallback, disabled }) => {
  const { t } = useTranslation();
  const rowIndex = useAppSelector((state) => selectBwdWorkerRowIndexByIdFromCurrentFormData(state, rowId));

  const addRow = () => {
    addCallback(rowIndex);
  };

  return (
    <div className="absolute -left-12">
      <BhTooltip body={t("FORMS.SCM.ADD_WORKER")}>
        <BhIconButton icon={faPlus} buttonProps={{ onClick: addRow, disabled: disabled }} />
      </BhTooltip>
    </div>
  );
};

export default FormBwdWorkerRowAddButton;
