import { IFormParticipantTableData } from "@/model/form/IFormParticipantTableData";

export function getFormParticipantDataMap(tableDataName: string) {
  const participantDataMap: Record<string, IFormParticipantTableData> = {
    contractorsTableData: {
      tableHeaderEditable: true,
      tableHeaderProperty: "contractorTitle",
      columnData: {
        columnWidthClass: "w-3/12",
        columnHeaderToPropertyMap: { "GLOBAL.NAME": "name", Ettevõte: "company", Kutsetunnistus: "qualification" },
        participantsProperty: "contractors"
      },
      addParticipantButtonText: "Lisa ehitustöö tegija"
    },
    supervisorsTableData: {
      tableHeaderEditable: true,
      tableHeaderProperty: "supervisorTitle",
      columnData: {
        columnWidthClass: "w-3/12",
        columnHeaderToPropertyMap: { Nimi: "name", Ettevõte: "company", Kutsetunnistus: "qualification" },
        participantsProperty: "supervisors"
      },
      addParticipantButtonText: "Lisa omanikujärelevalve tegija"
    },
    ktaContractorsTableData: {
      tableHeaderEditable: true,
      tableHeaderProperty: "contractorTitle",
      columnData: {
        columnWidthClass: "w-3/12",
        columnHeaderToPropertyMap: { Nimi: "name", Ettevõte: "company", Kutsetunnistus: "qualification" },
        participantsProperty: "contractors"
      },
      addParticipantButtonText: "Lisa ehitustöö tegija"
    },
    ktaSupervisorsTableData: {
      tableHeaderEditable: true,
      tableHeaderProperty: "supervisorTitle",
      columnData: {
        columnWidthClass: "w-3/12",
        columnHeaderToPropertyMap: { Nimi: "name", Ettevõte: "company", Kutsetunnistus: "qualification" },
        participantsProperty: "supervisors"
      },
      addParticipantButtonText: "Lisa omanikujärelevalve tegija"
    },
    koosolekParticipantsTableData: {
      tableHeader: "FORMS.KOOSOLEK.PARTICIPANTS.HEADER",
      tableHeaderEditable: false,
      columnData: {
        columnWidthClass: "w-1/5",
        columnHeaderToPropertyMap: {
          "FORMS.KOOSOLEK.PARTICIPANTS.NAME": "name",
          "FORMS.KOOSOLEK.PARTICIPANTS.COMPANY": "firm",
          "FORMS.KOOSOLEK.PARTICIPANTS.EMAIL": "email",
          "FORMS.KOOSOLEK.PARTICIPANTS.PHONE": "num",
          "FORMS.KOOSOLEK.PARTICIPANTS.STATUS": "status"
        },
        participantsProperty: "participants"
      },
      addParticipantButtonText: "FORMS.KOOSOLEK.PARTICIPANTS.ADD"
    },
    vcwParticipantsTableData: {
      tableHeader: "",
      tableHeaderEditable: false,
      columnData: {
        columnWidthClass: "w-1/3",
        columnHeaderToPropertyMap: { Tiitel: "title", Nimi: "name", Ettevõte: "company" },
        participantsProperty: "participants"
      },
      addParticipantButtonText: "Lisa osaleja"
    },
    katsetuseProtokollParticipantsTableData: {
      tableHeader: "",
      tableHeaderEditable: false,
      columnData: {
        columnWidthClass: "w-1/3",
        columnHeaderToPropertyMap: { Tiitel: "title", Nimi: "name", Ettevõte: "company" },
        participantsProperty: "participants"
      },
      addParticipantButtonText: "Lisa osaleja"
    },
    katsetuseProtokollStagesTableData: {
      tableHeader: "Katsetuse etapid",
      tableHeaderEditable: false,
      columnData: {
        columnWidthClass: "w-1/3",
        columnHeaderToPropertyMap: { Nimetus: "name", Kirjeldus: "description", Otsus: "decision" },
        participantsProperty: "stages"
      },
      addParticipantButtonText: "Lisa etapp"
    },
    proovivotuProtokollParticipantsTableData: {
      tableHeader: "",
      tableHeaderEditable: false,
      columnData: {
        columnWidthClass: "w-1/3",
        columnHeaderToPropertyMap: { Tiitel: "title", Nimi: "name" },
        participantsProperty: "participants"
      },
      addParticipantButtonText: "Lisa osaleja"
    },
    betoneerimiseProtokollParticipantsTableData: {
      tableHeader: "",
      tableHeaderEditable: false,
      columnData: {
        columnWidthClass: "w-1/3",
        columnHeaderToPropertyMap: { Tiitel: "title", Nimi: "name" },
        participantsProperty: "participants"
      },
      addParticipantButtonText: "Lisa osaleja"
    },
    mntKtaParticipantsTableData: {
      tableHeader: "",
      tableHeaderEditable: false,
      columnData: {
        columnWidthClass: "w-1/3",
        columnHeaderToPropertyMap: { Tiitel: "title", Nimi: "name", Ettevõte: "company" },
        participantsProperty: "participants"
      },
      addParticipantButtonText: "Lisa osaleja"
    },
    mntTeostatudToodeAktParticipantsTableData: {
      tableHeader: "",
      tableHeaderEditable: false,
      columnData: {
        columnWidthClass: "w-1/3",
        columnHeaderToPropertyMap: { Tiitel: "title", Nimi: "name", Ettevõte: "company" },
        participantsProperty: "participants"
      },
      addParticipantButtonText: "Lisa osaleja"
    },
    bwdParticipantTableData: {
      tableHeader: "",
      tableHeaderEditable: false,
      columnData: {
        columnWidthClass: "w-1/2",
        columnHeaderToPropertyMap: { "FORMS.GLOBAL.TITLE": "title", "FORMS.GLOBAL.EMPLOYEE": "name" },
        participantsProperty: "participants"
      },
      addParticipantButtonText: "FORMS.SCM.ADD_PARTICIPANT"
    },
    tooohutusPlaanParticipantTableData: {
      tableHeaderProperty: "tooohutusPlaanParticipantTableHeader",
      tableHeaderEditable: true,
      columnData: {
        columnWidthClass: "w-1/2",
        columnHeaderToPropertyMap: { Tiitel: "titleWithoutPrefill", Nimi: "name" },
        participantsProperty: "participants"
      },
      addParticipantButtonText: "FORMS.SCM.ADD_PARTICIPANT"
    }
  };
  return participantDataMap[tableDataName];
}
