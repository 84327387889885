import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { useTranslation } from "react-i18next";
import { classNames } from "@/utilities/jsUtilities";
import { FormType, IFormDataSaveRequest } from "@/model/IForm";
import BhFormInput from "@components/input/BhFormInput";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import { useModal } from "react-modal-hook";

interface Props {
  row: any;
  columns: Array<string>;
  saveRowCallback: Function;
  onPasteCallback: Function;
  addRowCallback: Function;
  removeRowCallback: Function;
  formType?: string;
  disabled: boolean;
  rowIndex: number;
}

const MntMpTableRow: FC<Props> = ({ row, columns, saveRowCallback, onPasteCallback, removeRowCallback, addRowCallback, formType, disabled, rowIndex }) => {
  const { t } = useTranslation();

  const saveRow = (changedObject: any, changedProperty: any) => {
    const saveRequest = {
      path: "dataTable",
      changedProperty: changedProperty,
      changedValue: changedObject[changedProperty],
      changedObjectId: row._id
    } as IFormDataSaveRequest;
    saveRowCallback(saveRequest);
  };

  const onPaste = (event: any, column: string) => {
    const pastedContent = event.clipboardData.getData("Text");
    const pastedRows = pastedContent.split(/\r\n|\n|\r/g);
    onPasteCallback(pastedRows, row, column);
  };

  const [showRemoveRowConfirmationModal, hideRemoveRowConfirmationModal] = useModal(
    () => (
      <BhDeleteConfirmationModal
        setIsOpen={hideRemoveRowConfirmationModal}
        header={<h2>{t("FORMS.CONFIRMATION.DELETE")}</h2>}
        body={<div>{t("FORMS.CONFIRMATION.DELETE_BODY")}</div>}
        confirmationButtonText={t("GLOBAL.DELETE")}
        handleDelete={() => {
          removeRowCallback(row);
          hideRemoveRowConfirmationModal();
        }}
      />
    ),
    [removeRowCallback, row]
  );

  const removeRow = () => {
    const rowHasAnyValuesBesideId = Object.values(row).length > 1;
    if (rowHasAnyValuesBesideId) {
      showRemoveRowConfirmationModal();
    } else {
      removeRowCallback(row);
    }
  };

  return (
    <tr className="group">
      {columns.map((column, index) => {
        return (
          <td className={classNames(formType && formType === FormType.MNT_MP_KANDEVOIME && (column === "b" || column === "c") && "bh-bg-warning-yellow-20")} key={index}>
            <BhFormInput
              initialValue={row[column]}
              property={column}
              saveCallback={(value: any) => saveRow(value, column)}
              onPasteCallback={(event: any) => onPaste(event, column)}
              disabled={disabled}
            />
          </td>
        );
      })}
      <td>
        {!disabled && (
          <div className="flex flex-row items-center justify-center opacity-0 group-hover:opacity-100">
            <div className="hover:bh-bg-pigeon-20 flex h-5 w-5 cursor-pointer flex-row items-center justify-center rounded p-1" onClick={() => addRowCallback(rowIndex)}>
              <FontAwesomeIcon icon={faPlus} size={"xs"} />
            </div>
            <div className="hover:bh-bg-pigeon-20 flex h-5 w-5 cursor-pointer flex-row items-center justify-center rounded p-1" onClick={removeRow}>
              <FontAwesomeIcon icon={faTrash} size={"xs"} />
            </div>
          </div>
        )}
      </td>
    </tr>
  );
};

export default MntMpTableRow;
