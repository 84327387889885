import React, { FC, ReactElement } from "react";
import { FormFieldType, IFormTemplate } from "@/model/IForm";
import FormInputField from "@components/form/fields/FormInputField";
import FormSeparatorField from "@components/form/fields/FormSeparatorField";
import FormHeaderSeparatorField from "@components/form/fields/FormHeaderSeparatorField";
import FormSubheaderField from "@components/form/fields/FormSubheaderField";
import FormAttachmentsField from "@components/form/fields/FormAttachmentsField";
import FormUploadField from "@components/form/fields/FormUploadField";
import FormAttachmentsWithDatesField from "@components/form/fields/FormAttachmentsWithDatesField";
import FormToggleField from "@components/form/fields/FormToggleField";
import FormDatepickerField from "@components/form/fields/FormDatepickerField";
import FormTimepickerField from "@components/form/fields/FormTimepickerField";
import FormFieldImportField from "@components/form/fields/FormFieldImportField";
import FormInputUserField from "@components/form/fields/FormInputUserField";
import FormTextareaField from "@components/form/fields/FormTextareaField";
import FormTextareaMiddleField from "@components/form/fields/FormTextareaMiddleField";
import FormWeatherField from "@components/form/fields/FormWeatherField";
import FormToggleVisibilityDatepickerField from "@components/form/fields/FormToggleVisibilityDatepickerField";
import FormToggleVisibilityInputField from "@components/form/fields/FormToggleVisibilityInputField";
import FormParticipantsField from "@components/form/fields/FormParticipantsField";
import FormKoosolekTableField from "@components/form/fields/customFields/koosolek/FormKoosolekTableField";
import FormScmTableField from "@components/form/fields/customFields/scm/FormScmTableField";
import FormScmTasksField from "@components/form/fields/customFields/scm/FormScmTasksField";
import FormBetoneerimiseProtokollTableField from "@components/form/fields/customFields/FormBetoneerimiseProtokollTableField";
import FormBetoneerimiseProtokollWeatherTableField from "@components/form/fields/customFields/FormBetoneerimiseProtokollWeatherTableField";
import FormTooplaanTableField from "@components/form/fields/customFields/FormTooplaanTableField";
import FormMntHeaderField from "@components/form/fields/customFields/FormMntHeaderField";
import FormMntFooterField from "@components/form/fields/customFields/FormMntFooterField";
import FormMntTableField from "@components/form/fields/customFields/FormMntTableField";
import FormBwdWorksField from "@components/form/fields/customFields/bwd/FormBwdWorksField";
import FormBwdWorkersField from "@components/form/fields/customFields/bwd/FormBwdWorkersField";
import FormTextEditorField from "@components/form/fields/FormTextEditorField";
import FormBetoneerimiseProtokollTableFieldKakskeelne from "@components/form/fields/customFields/FormBetoneerimiseProtokollTableFieldKakskeelne";
import FormBetoneerimiseProtokollWeatherTableFieldKakskeelne from "@components/form/fields/customFields/FormBetoneerimiseProtokollWeatherTableFieldKakskeelne";
import FormInputWithEditableTitleField from "@components/form/fields/customFields/FormInputWithEditableTitleField";

interface Props {
  formTemplate: IFormTemplate;
  dataSaveCallback: Function;
  nestedDataSaveCallback: Function;
  nestedDataAddCallback: Function;
  nestedDataRemoveCallback: Function;
  disabled: boolean;
}

const FormField: FC<Props> = ({ formTemplate, dataSaveCallback, nestedDataSaveCallback, nestedDataAddCallback, nestedDataRemoveCallback, disabled }) => {
  const formFieldElementMap: Record<string, ReactElement> = {
    // Default components
    ATTACHMENTS: <FormAttachmentsField formTemplate={formTemplate} saveCallback={dataSaveCallback} disabled={disabled} />,
    ATTACHMENTS_WITH_DATES: <FormAttachmentsWithDatesField formTemplate={formTemplate} saveCallback={dataSaveCallback} disabled={disabled} />,
    CHECKBOX: <FormToggleField formTemplate={formTemplate} saveCallback={dataSaveCallback} disabled={disabled} />,
    DATEPICKER: <FormDatepickerField formTemplate={formTemplate} saveCallback={dataSaveCallback} disabled={disabled} />,
    TIMEPICKER: <FormTimepickerField formTemplate={formTemplate} saveCallback={dataSaveCallback} disabled={disabled} />,
    FIELD_IMPORT: <FormFieldImportField formTemplate={formTemplate} saveCallback={dataSaveCallback} disabled={disabled} />,
    HEADER_SEPARATOR: <FormHeaderSeparatorField formTemplate={formTemplate} />,
    INPUT: <FormInputField formTemplate={formTemplate} saveCallback={dataSaveCallback} disabled={disabled} />,
    INPUT_SHORT: <FormInputField formTemplate={formTemplate} saveCallback={dataSaveCallback} shortInput={true} disabled={disabled} />,
    INPUT_DISABLED: <FormInputField formTemplate={formTemplate} saveCallback={dataSaveCallback} disabled={true} />,
    INPUT_WITH_EDITABLE_TITLE: <FormInputWithEditableTitleField formTemplate={formTemplate} saveCallback={dataSaveCallback} disabled={disabled} />,
    INPUT_USER: <FormInputUserField formTemplate={formTemplate} saveCallback={dataSaveCallback} disabled={disabled} />,
    SEPARATOR: <FormSeparatorField />,
    SUBHEADER: <FormSubheaderField formTemplate={formTemplate} />,
    TEXTAREA: <FormTextareaField formTemplate={formTemplate} saveCallback={dataSaveCallback} disabled={disabled} />,
    TEXTAREA_MIDDLE: <FormTextareaMiddleField formTemplate={formTemplate} saveCallback={dataSaveCallback} disabled={disabled} />,
    UPLOAD: <FormUploadField formTemplate={formTemplate} saveCallback={dataSaveCallback} disabled={disabled} />,
    WEATHER: <FormWeatherField saveCallback={dataSaveCallback} disabled={disabled} />,
    TOGGLE_VISIBILITY_DATE: <FormToggleVisibilityDatepickerField formTemplate={formTemplate} saveCallback={dataSaveCallback} disabled={disabled} />,
    TOGGLE_VISIBILITY_INPUT: <FormToggleVisibilityInputField formTemplate={formTemplate} saveCallback={dataSaveCallback} disabled={disabled} />,
    TEXT_EDITOR: <FormTextEditorField formTemplate={formTemplate} saveCallback={dataSaveCallback} disabled={disabled} />,
    PARTICIPANTS: (
      <FormParticipantsField
        labelSaveCallback={dataSaveCallback}
        saveCallback={nestedDataSaveCallback}
        addCallback={nestedDataAddCallback}
        removeCallback={nestedDataRemoveCallback}
        formTemplate={formTemplate}
        disabled={disabled}
      />
    ),

    // // Custom form components
    KOOSOLEK_TABLE: <FormKoosolekTableField saveCallback={nestedDataSaveCallback} addCallback={nestedDataAddCallback} removeCallback={nestedDataRemoveCallback} disabled={disabled} />,
    SCM_TABLE: <FormScmTableField saveCallback={dataSaveCallback} disabled={disabled} />,
    SCM_TASKS: <FormScmTasksField disabled={disabled} removeCallback={nestedDataRemoveCallback} addCallback={nestedDataAddCallback} saveCallback={nestedDataSaveCallback} />,
    BETONEERIMISE_PROTOKOLL_TABLE: <FormBetoneerimiseProtokollTableField saveCallback={dataSaveCallback} disabled={disabled} />,
    BETONEERIMISE_PROTOKOLL_TABLE_KAKSKEELNE: <FormBetoneerimiseProtokollTableFieldKakskeelne saveCallback={dataSaveCallback} disabled={disabled} />,
    BETONEERIMISE_PROTOKOLL_WEATHER: <FormBetoneerimiseProtokollWeatherTableField saveCallback={nestedDataSaveCallback} disabled={disabled} />,
    BETONEERIMISE_PROTOKOLL_WEATHER_KAKSKEELNE: <FormBetoneerimiseProtokollWeatherTableFieldKakskeelne saveCallback={nestedDataSaveCallback} disabled={disabled} />,
    TOOPLAAN_TABLE: (
      <FormTooplaanTableField
        saveCallback={dataSaveCallback}
        saveWeekCallback={nestedDataSaveCallback}
        addWeekCallback={nestedDataAddCallback}
        removeWeekCallback={nestedDataRemoveCallback}
        disabled={disabled}
      />
    ),
    MNT_MP_HEADER_FIELD: <FormMntHeaderField saveCallback={dataSaveCallback} disabled={disabled} />,
    MNT_MP_FOOTER_FIELD: <FormMntFooterField saveCallback={dataSaveCallback} disabled={disabled} />,
    MNT_MP_TABLE_FIELD: (
      <FormMntTableField
        saveCallback={dataSaveCallback}
        addRowCallback={nestedDataAddCallback}
        removeRowCallback={nestedDataRemoveCallback}
        saveRowCallback={nestedDataSaveCallback}
        disabled={disabled}
      />
    ),
    BWD_WORKS_TABLE: (
      <FormBwdWorksField
        saveRowCallback={nestedDataSaveCallback}
        disabled={disabled}
        removeRowCallback={nestedDataRemoveCallback}
        addRowCallback={nestedDataAddCallback}
        saveCallback={dataSaveCallback}
      />
    ),
    BWD_WORKERS_TABLE: <FormBwdWorkersField saveRowCallback={nestedDataSaveCallback} disabled={disabled} removeRowCallback={nestedDataRemoveCallback} addRowCallback={nestedDataAddCallback} />
  };

  return formFieldElementMap[formTemplate.rowType as FormFieldType];
};

export default FormField;
