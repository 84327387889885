import React, { FC, startTransition, useCallback, useEffect, useState } from "react";
import DatePicker, { CalendarContainer, getDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { classNames } from "@/utilities/jsUtilities";
import BhIconButton from "@components/buttons/BhIconButton";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons/faChevronRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons/faXmark";
import { faCalendarDay } from "@fortawesome/pro-regular-svg-icons/faCalendarDay";

interface Props {
  initialValue?: any;
  property: string;
  placeholder?: string;
  onChangeCallback?: Function;
  disabled?: boolean;
  classes?: string;
  returnISOString?: boolean;
  disablePastDates?: boolean;
  onResetCallback?: Function;
  inline?: boolean;
  autoFocus?: boolean;
}

const BhDatePicker: FC<Props> = ({ initialValue, property, placeholder, onChangeCallback, disabled, classes, returnISOString, disablePastDates, onResetCallback, inline, autoFocus }) => {
  function isValidDate(d: any) {
    return d instanceof Date && !isNaN(d.getTime());
  }

  const [startDate, setStartDate] = useState(isValidDate(initialValue) ? initialValue : null);

  const currentDate = new Date();
  const minDate = new Date("1900-01-01T00:00:00");
  const displayCalendarIcon = onResetCallback && !startDate;
  const displayResetIcon = onResetCallback && startDate;

  useEffect(() => {
    setStartDate(isValidDate(initialValue) ? initialValue : null);
  }, [initialValue]);

  // @ts-ignore
  const BhDatePickerContainer = ({ className, children }) => {
    return (
      <CalendarContainer className={classNames(className, "bh-shadow -mt-1.5")}>
        <div style={{ position: "relative" }}>{children}</div>
      </CalendarContainer>
    );
  };

  const onChange = useCallback(
    (inputDate: Date) => {
      startTransition(() => {
        if (inputDate === initialValue) return;
        inputDate.setHours(12);
        const changedValue = { [property]: returnISOString ? inputDate.toISOString() : inputDate };
        onChangeCallback && onChangeCallback(changedValue);
        setStartDate(inputDate);
      });
    },
    [onChangeCallback]
  );

  const resetDate = () => {
    setStartDate(null);
    onResetCallback && onResetCallback();
  };

  return (
    <div className="relative max-w-[140px]">
      <DatePicker
        name={property}
        placeholderText={placeholder}
        renderCustomHeader={({ date, changeYear, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
          <div className="flex items-center justify-between">
            <BhIconButton icon={faChevronLeft} buttonProps={{ onClick: decreaseMonth, disabled: prevMonthButtonDisabled }} />
            <h3>
              {date.toLocaleString([getDefaultLocale()], { month: "long" })} {date.getFullYear()}
            </h3>
            <BhIconButton icon={faChevronRight} buttonProps={{ onClick: increaseMonth, disabled: nextMonthButtonDisabled }} />
          </div>
        )}
        selected={startDate}
        onChange={onChange}
        calendarContainer={BhDatePickerContainer}
        dateFormat={"dd.MM.yyyy"}
        className={classNames("disabled:bh-bg-smoke disabled:bh-border-smoke disabled:bh-text-deep-ocean-40", classes)}
        calendarClassName="!bh-border-pigeon-40"
        autoComplete="off"
        disabled={disabled}
        minDate={disablePastDates ? currentDate : minDate}
        calendarStartDay={1}
        inline={inline}
        autoFocus={autoFocus}
      />
      {displayCalendarIcon && <FontAwesomeIcon icon={faCalendarDay} className="bh-text-deep-ocean pointer-events-none absolute top-2.5 right-2.5" aria-hidden="true" />}
      {displayResetIcon && !disabled && (
        <div className="absolute top-1.5 right-2.5 h-6 cursor-pointer">
          <FontAwesomeIcon
            icon={faXmark}
            className="hover:bh-bg-deep-ocean hover:bh-text-white bh-text-deep-ocean h-4 w-4 rounded-full p-1"
            aria-hidden="true"
            onClick={resetDate}
            onMouseDown={(e) => e.preventDefault()}
          />
        </div>
      )}
    </div>
  );
};

export default BhDatePicker;
