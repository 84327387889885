import React, { FC } from "react";
import { BauhubBannerType } from "@/model/IProject";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faOctagonExclamation } from "@fortawesome/pro-solid-svg-icons/faOctagonExclamation";
import { faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons/faTriangleExclamation";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons/faCircleCheck";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface Props {
  type: BauhubBannerType;
  customIcon?: IconProp;
}

const BhBannerLeftSide: FC<Props> = ({ type, customIcon }) => {
  let icon;
  let classes = "flex rounded-l items-center flex-col justify-center w-14 -ml-5 mr-6 self-stretch ";

  if (type === BauhubBannerType.SUCCESS) {
    icon = faCircleCheck;
    classes += " bh-bg-success-green w-14 ";
  }
  if (type === BauhubBannerType.ERROR) {
    icon = faOctagonExclamation;
    classes += " bh-bg-error-red w-14 ";
  }
  if (type === BauhubBannerType.WARNING) {
    icon = faTriangleExclamation;
    classes += " bh-bg-warning-yellow w-14 ";
  }
  if (type === BauhubBannerType.ANNOUNCEMENT) {
    //TODO NONE
    classes += " bh-bg-deep-ocean ";
  }

  return <div className={classes}>{icon && <FontAwesomeIcon icon={customIcon || icon} size="lg" className="bh-text-white" />}</div>;
};

export default BhBannerLeftSide;
