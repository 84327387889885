import React, { FC, useCallback } from "react";
import ProfileUserInfoForm from "@/views/home/profile/ProfileTabs/ProfileUserInfoForm";
import ProfileUserInfoNameForm from "@/views/home/profile/ProfileTabs/ProfileUserInfoNameForm";
import { saveCurrentUserLanguageAsync, selectCurrentUser, selectCurrentUserInfo, setUserAvatarUuid } from "@/app/store/userSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { IUserAvatarDTO } from "@/model/IUserAvatarDTO";
import { fetchPresignedUploadUrlForAvatar } from "@/api/fileAPI";
import BhPigeon20Separator from "@components/separator/BhPigeon20Separator";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BhInputLabel from "@components/input/BhInputLabel";
import { Trans, useTranslation } from "react-i18next";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";

const ProfileUserInfo: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(selectCurrentUserInfo);
  const currentUser = useAppSelector(selectCurrentUser);

  // TODO: päringud peaksid olema API failides
  function FileUpload(file: any, dto: IUserAvatarDTO) {
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", dto.avatarUrl, true);
    xhr.setRequestHeader("Content-Type", file.type + ";charset=UTF-8");
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        dispatch(setUserAvatarUuid(dto.avatarUuid));
      }
    };
    xhr.send(file);
  }

  const onFileSelected = (file: any) => {
    fetchPresignedUploadUrlForAvatar(currentUserInfo.userEntityId, file.type).then((response) => {
      FileUpload(file, response);
    });
  };

  const changeUserLanguage = useCallback(
    (changedValue: any) => {
      const changedObject = { ...currentUser, ...{ language: changedValue.value } };
      dispatch(saveCurrentUserLanguageAsync(changedObject));
    },
    [currentUser]
  );

  const languageMap: Record<string, any> = {
    et_EE: { label: "ET", value: "et_EE" },
    en_EN: { label: "EN", value: "en_EN" },
    ru_RU: { label: "RU", value: "ru_RU" },
    lt_LT: { label: "LT", value: "lt_LT" },
    lv_LV: { label: "LV", value: "lv_LV" }
  };

  return (
    <BhScrollableBody>
      <div className="flex w-full flex-row justify-center px-8 pt-10">
        <div className="flex w-full max-w-[800px] flex-row justify-center">
          {/*<div className="w-1/3 p-1 text-center">
            <BhUserIcon user={currentUser} size={120} />
            <h2 className="mt-0 text-center">
              <Trans>USER.PROFILE.PHOTO</Trans>
            </h2>
            <div className="mb-4">
              <div className="flex justify-center pt-1">
                {currentUserInfo.avatarUuid && <img className="h-32 w-32 rounded-full" crossOrigin="" src={"https://s3.eu-central-1.amazonaws.com/www.bauhub.ee/public/avatar/" + currentUserInfo.avatarUuid} alt="" />}
                {!currentUserInfo.avatarUuid && <BhUserIcon user={currentUser} size={120} />}
              </div>
            </div>
            <BhUploadButton onFileSelected={onFileSelected} buttonProps={{ classes: ButtonStyle.SECONDARY }}>
              {t("USER.PROFILE.PHOTO.UPLOAD")}
            </BhUploadButton>
          </div>*/}
          <div className="w-2/3">
            <h2 className="mt-0">
              <Trans>USER.PROFILE.DETAILS</Trans>
            </h2>
            <p>
              <Trans>USER.PROFILE.DETAILS.SHARING_INFO</Trans>
            </p>
            <ProfileUserInfoNameForm />
            <ProfileUserInfoForm />
            <BhPigeon20Separator />
            <div className="mt-11">
              <BhInputLabel>{t("USER.INFO.LANGUAGE")}</BhInputLabel>
              <BhDropdown
                position={BhDropdownPositionEnum.TOP_RIGHT}
                buttonClasses={"w-28"}
                button={
                  <button className="min-w-70px bh-border-smoke-40 hover:bh-border-pigeon-70 flex h-9 w-full items-center justify-between rounded border p-2">
                    {languageMap[currentUser.language].label}
                    <FontAwesomeIcon icon={faCaretDown} />
                  </button>
                }
                menu={
                  <BhDropdownMenu
                    values={Object.values(languageMap)}
                    closeOnItemClick={true}
                    type={BhDropdownTypeEnum.STRING}
                    onSelect={changeUserLanguage}
                    textProperty={"label"}
                    widthClass={"w-28"}
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>
    </BhScrollableBody>
  );
};

export default ProfileUserInfo;
