import React, { FC } from "react";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import BhIconButton from "@components/buttons/BhIconButton";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { useAppSelector } from "@/app/hooks";
import { selectIndexForNextKoosolekMainRowByCurrentRowId, selectIndexForNextKoosolekSubRowByCurrentRowId } from "@/app/store/formSlice";

interface Props {
  addRowCallback: Function;
  row: any;
  disabled: boolean;
}

const AddRowButtons: FC<Props> = ({ addRowCallback, row, disabled }) => {
  const nextMainRowIndex = useAppSelector((state) => selectIndexForNextKoosolekMainRowByCurrentRowId(state, row._id));
  const nextSubRowIndex = useAppSelector((state) => selectIndexForNextKoosolekSubRowByCurrentRowId(state, row._id));

  const addRowBelow = (level: number) => {
    if (level === 0) {
      addRowCallback(nextMainRowIndex, level);
    } else {
      addRowCallback(nextSubRowIndex, level);
    }
  };

  return (
    <div className="flex flex-row items-center justify-end">
      {row.level === 0 && <BhIconButton icon={faPlus} buttonProps={{ onClick: () => addRowBelow(0), disabled: disabled }} />}
      <BhIconButton icon={faCaretDown} buttonProps={{ onClick: () => addRowBelow(1), disabled: disabled }} />
    </div>
  );
};

export default AddRowButtons;
