import React, { FC, useState } from "react";
import { selectCurrentProjectId, toggleProjectModalsOpen } from "@/app/store/project/projectSlice";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectCurrentUser } from "@/app/store/userSlice";
import { createNewTaskObject, ITask, TaskStatus } from "@/model/taskBoard/ITask";
import { selectCurrentFormFileEntityId, selectCurrentFormId, selectCurrentFormType, selectValueByPropertyFromCurrentFormData } from "@/app/store/formSlice";
import { selectDefaultProjectTaskCategory, setNewTask, setOpenedTaskInfo } from "@/app/store/tasksSlice";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import BhDropdown from "@components/dropdown/BhDropdown";
import { IDropdownItem } from "@/model/IDropdownItem";
import { faFile } from "@fortawesome/pro-regular-svg-icons/faFile";
import { faSquareKanban } from "@fortawesome/pro-regular-svg-icons/faSquareKanban";
import { addDaysToDate } from "@/utilities/dateUtility";
import FormTaskModal from "@/views/home/project/detail/form/modals/FormTaskModal";
import { useTranslation } from "react-i18next";
import { FormType } from "@/model/IForm";

interface Props {
  saveRow: Function;
  row: any;
  disabled: boolean;
}

const RowTaskDropdown: FC<Props> = ({ saveRow, row, disabled }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const projectId = useAppSelector(selectCurrentProjectId);
  const defaultTaskCategory = useAppSelector(selectDefaultProjectTaskCategory);
  const docName = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, "docName"));
  const docNumber = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, "number"));
  const formId = useAppSelector(selectCurrentFormId);
  const formFileEntityId = useAppSelector(selectCurrentFormFileEntityId);
  const formType = useAppSelector(selectCurrentFormType);
  const [formTaskModalOpen, setFormTaskModalOpen] = useState(false);
  const newFormTask = {
    status: TaskStatus.TODO,
    deadline: addDaysToDate(new Date(), 1),
    name: docName + " nr. " + docNumber + ", " + (formType === FormType.KOOSOLEK_LV ? "punktu" : "punkt") + " nr. " + (row.nr || ""),
    category: defaultTaskCategory,
    description: row.content
  };

  const saveTaskToTaskboard = () => {
    const taskName = docName + " nr. " + docNumber + ", " + (formType === FormType.KOOSOLEK_LV ? "punktu" : "punkt") + " nr. " + (row.nr || "");
    const deadline = new Date();
    deadline.setDate(deadline.getDate() + 1);
    deadline.setHours(12);
    deadline.setMinutes(0);
    const task = createNewTaskObject({
      currentUser: currentUser,
      predefinedName: taskName,
      predefinedDescription: row.content,
      formTaskInfo: { formId: formId, fileEntityId: formFileEntityId, projectId: projectId, formType: formType, formRow: row },
      attachments: row.attachments,
      deadline: deadline,
      category: defaultTaskCategory
    });
    dispatch(setNewTask(task));
    dispatch(setOpenedTaskInfo({ closeModalOnNewTaskSave: true }));
    dispatch(toggleProjectModalsOpen({ modal: "projectTaskModal" }));
  };

  const saveTaskToFormOnly = (task: ITask) => {
    const taskToSave = { task: task };
    saveRow(taskToSave, "task", row._id);
  };

  const dropdownValues: Array<IDropdownItem> = [
    {
      text: t("FORMS.KOOSOLEK.TABLE.TASK.SAVE_TO_FORM"),
      function: () => setFormTaskModalOpen(true),
      icon: faFile
    },
    {
      text: t("FORMS.KOOSOLEK.TABLE.TASK.SAVE_TO_TASKBOARD"),
      function: saveTaskToTaskboard,
      icon: faSquareKanban
    }
  ];

  return (
    <>
      <BhDropdown
        position={BhDropdownPositionEnum.BOTTOM_LEFT}
        button={
          <BhSecondaryButton icon={faPlus} buttonProps={{ disabled }}>
            {t("FORMS.KOOSOLEK.TABLE.TASK")}
          </BhSecondaryButton>
        }
        menu={<BhDropdownMenu values={dropdownValues} type={BhDropdownTypeEnum.STRING} textProperty="text" />}
        disabled={disabled}
      />
      {formTaskModalOpen && <FormTaskModal task={row.task ? row.task : newFormTask} setIsShown={setFormTaskModalOpen} saveCallback={saveTaskToFormOnly} />}
    </>
  );
};

export default RowTaskDropdown;
