import BauhubBOHome from "@/views/home/backoffice/BauhubBOHome";
import { Route, Routes, useParams } from "react-router-dom";
import React, { FC } from "react";
import BOCompanies from "@/views/home/backoffice/views/companies/BOCompanies";
import BOInvoices from "@/views/home/backoffice/views/invoices/BOInvoices";
import BOMonthlyInvoiceOverview from "@/views/home/backoffice/views/invoices/BOMonthlyInvoiceOverview";
import NotFound from "@/views/global/NotFound";
import BOCompany from "@/views/home/backoffice/views/company/BOCompany";
import BOTrials from "@/views/home/backoffice/views/trials/BOTrials";
import BOMonthlyTrialOverview from "./views/trials/BOMonthlyTrialOverview";

const BauhubBORouter: FC = () => {
  function BOCompanyWithParams() {
    let { companyId } = useParams();
    return companyId ? <BOCompany companyId={parseInt(companyId)} /> : <NotFound />;
  }

  return (
    <div className="w-full overflow-y-auto">
      <Routes>
        <Route index element={<BauhubBOHome />} />
        <Route path="company/:companyId/*" element={<BOCompanyWithParams />} />
        <Route path="companies" element={<BOCompanies />} />
        <Route path="users" element={<></>} />
        <Route path="invoices" element={<BOInvoices />} />
        <Route path="invoices/:month" element={<BOMonthlyInvoiceOverview />} />
        <Route path="trials" element={<BOTrials />} />
        <Route path="trials/:month" element={<BOMonthlyTrialOverview />} />
        <Route path="backend" element={<></>} />
        <Route path="template-developer" element={<></>} />
        <Route path="form-developer" element={<></>} />
        <Route path="events" element={<></>} />
        <Route path="baudrive-logs" element={<></>} />
        <Route path="killswitch" element={<></>} />
        <Route path="asyncjobs" element={<></>} />
      </Routes>
    </div>
  );
};

export default BauhubBORouter;
