import { IFormTemplate } from "@/model/IForm";

export function enableFileRename(formTemplate: IFormTemplate) {
  const propertyMap: Record<string, boolean> = {
    BETONEERIMISE_PROTOKOLL: formTemplate.property === "drawingsFileArray" || formTemplate.property === "documentsFileArray" || formTemplate.property === "ktaFileArray",
    BETONEERIMISE_PROTOKOLL_KAKSKEELNE: formTemplate.property === "drawingsFileArray" || formTemplate.property === "documentsFileArray" || formTemplate.property === "ktaFileArray",
    BWD: formTemplate.property === "documentsFileArray",
    KATSETUSE_PROTOKOLL: formTemplate.property === "addons" || formTemplate.property === "documentsFileArray",
    KTA_UUS: formTemplate.property === "documentsFileArray",
    PROOVIVOTU_PROTOKOLL: formTemplate.property === "documentsFileArray" || formTemplate.property === "addons",
    VCW: formTemplate.property === "addons",
    MNT_KTA: formTemplate.property === "documentsFileArray",
    MNT_TEOSTATUD_TOODE_AKT: formTemplate.property === "documentsFileArray",
    EHITUSPAEVIK_UUS: formTemplate.property === "documentsFileArray",
    EHITUSOBJEKTI_TOOOHUTUSE_PLAAN_MARU: formTemplate.property === "documentsFileArray"
  };

  return Object.keys(propertyMap).includes(formTemplate.formType) && propertyMap[formTemplate.formType];
}
