import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/app/hooks";
import { selectParticipantByIdFromCurrentFormData } from "@/app/store/formSlice";
import BhTooltip from "@components/BhTooltip";
import BhInputSimple from "@components/input/BhInputSimple";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhToggleSwitch from "@components/toggle/BhToggleSwitch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { classNames } from "@/utilities/jsUtilities";
import BhInputWithFetchedUsers from "@components/input/BhInputWithFetchedUsers";
import { faSignature } from "@fortawesome/pro-regular-svg-icons/faSignature";
import { IFormParticipantTableColumnData } from "@/model/form/IFormParticipantTableData";
import { faCircleMinus } from "@fortawesome/pro-regular-svg-icons/faCircleMinus";

interface Props {
  participantId: string;
  saveParticipantField: Function;
  saveParticipant: Function;
  removeParticipant: Function;
  saveParticipantStatus: Function;
  withSigners?: boolean;
  disabled: boolean;
  columnData: IFormParticipantTableColumnData;
}

const FormParticipantRow: FC<Props> = ({ participantId, saveParticipantField, removeParticipant, saveParticipant, saveParticipantStatus, withSigners, columnData, disabled }) => {
  const { t } = useTranslation();
  const participant = useAppSelector((state) => selectParticipantByIdFromCurrentFormData(state, columnData.participantsProperty, participantId));

  return (
    <div className="relative flex flex-row items-center pb-3">
      {withSigners && (
        <div className="absolute top-2 -left-20 flex flex-row items-center">
          <BhTooltip body={t("SIGNING.SIGNER")}>
            <FontAwesomeIcon icon={faSignature} className={"bh-text-deep-ocean mr-2"} />
          </BhTooltip>
          <BhToggleSwitch value={participant.isSigner} onClickAction={(value: any) => saveParticipantField(value, "isSigner", participant)} small={true} property={"isSigner"} disabled={disabled} />
        </div>
      )}
      {Object.keys(columnData.columnHeaderToPropertyMap).map((header, index) => {
        return (
          <div className={classNames("relative pr-8", columnData.columnWidthClass)} key={index}>
            {columnData.columnHeaderToPropertyMap[header] === "name" && columnData.participantsProperty !== "stages" && (
              <BhInputWithFetchedUsers
                initialValue={participant.name}
                property={columnData.columnHeaderToPropertyMap[header]}
                saveCallback={(value: any) => saveParticipant(value, columnData.columnHeaderToPropertyMap[header], participant)}
                disabled={disabled}
              />
            )}
            {columnData.columnHeaderToPropertyMap[header] === "status" && (
              <div className="flex flex-row">
                <BhToggleSwitch
                  value={participant.status === "ATTENDED"}
                  onClickAction={(value: any) => saveParticipantStatus(value, participant)}
                  small={true}
                  property={"status"}
                  disabled={disabled}
                />
                <div className="ml-2 font-semibold">{participant.status === "ATTENDED" ? t("FORMS.KOOSOLEK.PARTICIPANTS.STATUS.ATTEND") : t("FORMS.KOOSOLEK.PARTICIPANTS.STATUS.MISSED")}</div>
              </div>
            )}
            {(columnData.columnHeaderToPropertyMap[header] !== "name" || (columnData.columnHeaderToPropertyMap[header] === "name" && columnData.participantsProperty === "stages")) &&
              columnData.columnHeaderToPropertyMap[header] !== "status" && (
                <BhInputSimple
                  initialValue={participant[columnData.columnHeaderToPropertyMap[header]]}
                  property={columnData.columnHeaderToPropertyMap[header]}
                  saveCallback={(value: any) => saveParticipantField(value, columnData.columnHeaderToPropertyMap[header], participant)}
                  disabled={disabled}
                />
              )}
          </div>
        );
      })}
      <div className="absolute top-0 -right-20">
        <BhTextOnlyButton icon={faCircleMinus} buttonProps={{ onClick: () => removeParticipant(participant), disabled: disabled }}>
          {t("GLOBAL.REMOVE")}
        </BhTextOnlyButton>
      </div>
    </div>
  );
};

export default React.memo(FormParticipantRow);
