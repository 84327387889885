import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityId, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/app/store";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import { IOpenedTaskInfo, ITask, ITaskStatusChangeDTO, TaskStatus } from "@/model/taskBoard/ITask";
import {
  copyTask,
  deleteTask,
  deleteTaskComment,
  deleteTaskTag,
  fetchAllTasks,
  fetchChecklistPersonalTask,
  fetchPersonalTask,
  fetchPersonalTaskBoardTasks,
  fetchProjectTaskCategories,
  fetchProjectTaskTags,
  fetchTaskBoardTasksWithExtraFields,
  fetchTaskByIdentifier,
  fetchTasksByIdsWithExtraFields,
  moveTask,
  savePersonalTask,
  savePersonalTaskComment,
  saveProjectTaskCategory,
  saveTaskBoardTask,
  saveTaskBoardTaskComment,
  saveTaskStatus,
  saveTaskTag
} from "@/api/taskAPI";
import { initialTasksFilter, ITasksFilter, ITaskUserRoleFilter, TaskUserRoleFilterEnum } from "@/model/taskBoard/ITasksFilter";
import { initialTasksSort, ITasksSort } from "@/model/taskBoard/ITasksSort";
import { CategoryDefaultCode, IProjectTaskCategory } from "@/model/taskBoard/IProjectTaskCategory";
import { ITaskTag } from "@/model/taskBoard/ITaskTag";
import { IUserAuthority } from "@/model/IUserAuthority";
import { ITaskComment, ITaskCommentDTO } from "@/model/taskBoard/ITaskComment";
import { getUniqueValues } from "@/utilities/jsUtilities";
import { selectIsAllTasksTaskBoardOpen, selectIsPersonalTaskBoardOpen, selectOpenedTaskBoardId } from "@/app/store/taskBoardsSlice";
import { isTaskPersonal } from "@/utilities/taskBoardUtil";
import { selectCurrentUser } from "@/app/store/userSlice";
import { IUser } from "@/model/IUser";
import { ITaskModals, ITaskModalsOpen, TaskModalsInitialState } from "@/model/taskBoard/ITaskModals";
import { setChecklistRowTask } from "@/app/store/checklist/checklistRowsSlice";
import { FormType, IFormDataSaveRequest } from "@/model/IForm";
import { addNestedFormDataAsync, saveNestedFormDataAsync } from "@/app/store/formSlice";
import { ITaskToFileAnnotation } from "@/model/files/IFileAnnotation";
import { fileAnnotationTaskAnnotationRemoved, fileAnnotationUpdated } from "@/app/store/fileAnnotationsSlice";
import { naturalSortByField } from "@/utilities/sortUtilities";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";

export const tasksAdapter = createEntityAdapter<ITask>();

export interface ITaskState {
  status: BhStateStatusType;
  sort: ITasksSort;
  filter: ITasksFilter;
  projectTaskCategories: Array<IProjectTaskCategory>;
  projectTaskTags: Array<ITaskTag>;
  openedTaskInfo?: IOpenedTaskInfo;
  newTask?: ITask;
  taskModals: ITaskModals;
}

export const tasksInitialState = tasksAdapter.getInitialState<ITaskState>({
  status: BhStateStatusType.INITIAL,
  sort: initialTasksSort,
  filter: initialTasksFilter,
  projectTaskCategories: [] as Array<IProjectTaskCategory>,
  projectTaskTags: [] as Array<ITaskTag>,
  taskModals: TaskModalsInitialState
});

export const fetchTaskAsync = createAsyncThunk("task/fetchTask", async (dto: { taskIdentifier: string; projectId: EntityId }) => {
  return fetchTaskByIdentifier(dto.projectId, dto.taskIdentifier);
});

export const fetchPersonalTaskAsync = createAsyncThunk("task/personal/fetchTask", async (dto: { taskId: EntityId; projectId: EntityId }) => {
  return fetchPersonalTask(dto.projectId, dto.taskId);
});

export const fetchChecklistPersonalTaskAsync = createAsyncThunk("task/checklist/personal/fetchTask", async (dto: { taskId: EntityId; projectId: EntityId }) => {
  return fetchChecklistPersonalTask(dto.projectId, dto.taskId);
});

export const fetchPersonalTaskBoardTasksAsync = createAsyncThunk("task/fetchPersonalTaskBoardTasks", async (projectId: EntityId) => {
  return fetchPersonalTaskBoardTasks(projectId);
});

export const fetchAllTasksAsync = createAsyncThunk("task/fetchAllTasks", async (dto: { includeExtraFields: boolean; projectId: EntityId }) => {
  return fetchAllTasks(dto.projectId, dto.includeExtraFields);
});

export const fetchProjectTaskCategoriesAsync = createAsyncThunk("task/fetchProjectTaskCategories", async (projectId: EntityId) => {
  return fetchProjectTaskCategories(projectId);
});

export const fetchTasksByIdsWithExtraFieldsAsync = createAsyncThunk("task/fetchTasksByIdsWithExtraFields", async (taskIds: Array<EntityId>) => {
  return fetchTasksByIdsWithExtraFields(taskIds);
});

export const fetchProjectTaskTagsAsync = createAsyncThunk("task/fetchProjectTaskTags", async (projectId: EntityId) => {
  return fetchProjectTaskTags(projectId);
});

const checkAndSaveFormTask = (oldTask: any, newTask: any, dispatch: any) => {
  const formTaskInfo = oldTask.formTaskInfo;
  const isNewTask = !oldTask.id;

  if (formTaskInfo) {
    if ([FormType.KOOSOLEK, FormType.KOOSOLEK_LV].includes(formTaskInfo.formType)) {
      const dataSaveRequest = {
        formId: formTaskInfo.formId,
        fileEntityId: formTaskInfo.fileEntityId,
        projectId: formTaskInfo.projectId,
        changedProperty: "task",
        changedValue: newTask,
        path: "agenda",
        changedObjectId: formTaskInfo.formRow._id
      } as IFormDataSaveRequest;
      dispatch(saveNestedFormDataAsync(dataSaveRequest));
    }
    if (formTaskInfo.formType === FormType.SCM) {
      if (isNewTask) {
        const dataSaveRequest = {
          formId: formTaskInfo.formId,
          fileEntityId: formTaskInfo.fileEntityId,
          projectId: formTaskInfo.projectId,
          changes: newTask,
          path: "tasks",
          index: 0
        } as IFormDataSaveRequest;
        dispatch(addNestedFormDataAsync(dataSaveRequest));
      } else {
        const dataSaveRequest = {
          formId: formTaskInfo.formId,
          fileEntityId: formTaskInfo.fileEntityId,
          projectId: formTaskInfo.projectId,
          changes: { ...newTask, _id: oldTask._id },
          path: "tasks",
          changedObjectId: oldTask._id
        } as IFormDataSaveRequest;
        dispatch(saveNestedFormDataAsync(dataSaveRequest));
      }
    }
    dispatch(setOpenedTaskInfo({ id: newTask.id, formTask: { ...newTask, ...{ formTaskInfo: formTaskInfo, _id: oldTask._id } } }));
  }
};

export const saveFormTaskAsync = createAsyncThunk("form/task/new/save", async (dto: { task: ITask; projectId: EntityId }, { dispatch }) => {
  const response = await saveTaskBoardTask(dto.projectId, dto.task);
  checkAndSaveFormTask(dto.task, response, dispatch);
  return response;
});

export const savePersonalFormTaskAsync = createAsyncThunk("form/personal/task/new/save", async (dto: { task: ITask; projectId: EntityId }, { dispatch }) => {
  const response = await savePersonalTask(dto.projectId, dto.task);
  checkAndSaveFormTask(dto.task, response, dispatch);
  return response;
});

export const saveTaskBoardTaskAsync = createAsyncThunk("task/new/save", async (dto: { task: ITask; projectId: EntityId }, { getState, dispatch }) => {
  const state: RootState = getState() as RootState;
  const response = await saveTaskBoardTask(dto.projectId, dto.task);
  const isChecklistTask = response.checklistRowId;
  if (isChecklistTask) {
    dispatch(setChecklistRowTask({ task: response }));

    if (response.fileAnnotationRelations.length > 0 && response.fileAnnotationRelations[0].fileAnnotation && response.fileAnnotationRelations[0].fileAnnotation.xfdf) {
      dispatch(fileAnnotationUpdated(response.fileAnnotationRelations[0].fileAnnotation));
    }
  }
  const isProjectTaskModalOpen = state.project.projectModals.open.projectTaskModal;
  if (isProjectTaskModalOpen) {
    dispatch(setOpenedTaskInfo({ id: response.id, checklistTask: isChecklistTask ? response : undefined }));
  }
  return response;
});

export const savePersonalTaskAsync = createAsyncThunk("task/personal/new/save", async (dto: { task: ITask; projectId: EntityId }, { getState, dispatch }) => {
  const state: RootState = getState() as RootState;
  const response = await savePersonalTask(dto.projectId, dto.task);
  const isChecklistTask = response.checklistRowId;
  if (isChecklistTask) {
    dispatch(setChecklistRowTask({ task: response }));

    if (response.fileAnnotationRelations.length > 0 && response.fileAnnotationRelations[0].fileAnnotation && response.fileAnnotationRelations[0].fileAnnotation.xfdf) {
      dispatch(fileAnnotationUpdated(response.fileAnnotationRelations[0].fileAnnotation));
    }
  }
  const isProjectTaskModalOpen = state.project.projectModals.open.projectTaskModal;
  if (isProjectTaskModalOpen) {
    dispatch(setOpenedTaskInfo({ id: response.id, checklistTask: isChecklistTask ? response : undefined }));
  }
  return response;
});

export const saveTaskStatusAsync = createAsyncThunk("task/status/save", async (dto: { taskStatusChangeDTO: ITaskStatusChangeDTO; projectId: EntityId }, { getState, dispatch }) => {
  const state: RootState = getState() as RootState;
  const response = await saveTaskStatus(dto.projectId, dto.taskStatusChangeDTO);
  const formTask = state.tasks.openedTaskInfo && state.tasks.openedTaskInfo.formTask;
  if (formTask) {
    const taskWithNewStatus = { ...formTask, status: response.status };
    checkAndSaveFormTask(formTask, taskWithNewStatus, dispatch);
  }
  const checklistTask = state.tasks.openedTaskInfo && state.tasks.openedTaskInfo.checklistTask;
  if (checklistTask) {
    const taskWithNewStatus = { ...checklistTask, status: response.status };
    dispatch(setOpenedTaskInfo({ id: checklistTask.id, checklistTask: taskWithNewStatus }));
    dispatch(setChecklistRowTask({ task: taskWithNewStatus }));
  }
  return response;
});

export const deleteTaskAsync = createAsyncThunk("task/delete", async (task: ITask, { dispatch }) => {
  const result = await deleteTask(task.id);
  dispatch(fileAnnotationTaskAnnotationRemoved(task));
  return result;
});

const updateFormAndChecklistTaskComments = (openedTaskInfo: IOpenedTaskInfo, newComment: ITaskComment, dispatch: any) => {
  const formTask = openedTaskInfo.formTask;
  if (formTask) {
    const formTaskWithNewComment = { ...formTask, comments: [...formTask.comments, newComment] };
    checkAndSaveFormTask(formTask, formTaskWithNewComment, dispatch);
  }
  const checklistTask = openedTaskInfo.checklistTask;
  if (checklistTask) {
    const checklistTaskWithNewComment = { ...checklistTask, comments: [...checklistTask.comments, newComment] };
    dispatch(setOpenedTaskInfo({ id: checklistTask.id, checklistTask: checklistTaskWithNewComment }));
    dispatch(setChecklistRowTask({ task: checklistTaskWithNewComment }));
  }
};

export const saveTaskBoardTaskCommentAsync = createAsyncThunk("task/comment/save", async (dto: { commentDTO: ITaskCommentDTO; projectId: EntityId }, { getState, dispatch }) => {
  const state: RootState = getState() as RootState;
  const response = await saveTaskBoardTaskComment(dto.projectId, dto.commentDTO);
  if (state.tasks.openedTaskInfo) {
    updateFormAndChecklistTaskComments(state.tasks.openedTaskInfo, response, dispatch);
  }

  return response;
});

export const savePersonalTaskCommentAsync = createAsyncThunk("task/personal/comment/save", async (dto: { commentDTO: ITaskCommentDTO; projectId: EntityId }, { getState, dispatch }) => {
  const state: RootState = getState() as RootState;
  const response = await savePersonalTaskComment(dto.projectId, dto.commentDTO);
  if (state.tasks.openedTaskInfo) {
    updateFormAndChecklistTaskComments(state.tasks.openedTaskInfo, response, dispatch);
  }

  return response;
});

export const deleteTaskCommentAsync = createAsyncThunk("task/comment/delete", async (comment: ITaskComment, { getState, dispatch }) => {
  const state: RootState = getState() as RootState;
  const response = await deleteTaskComment(comment);
  const formTask = state.tasks.openedTaskInfo && state.tasks.openedTaskInfo.formTask;
  if (formTask) {
    const formTaskWithoutRemovedComment = { ...formTask, comments: formTask.comments.filter((c) => c.id !== response.id) };
    checkAndSaveFormTask(formTask, formTaskWithoutRemovedComment, dispatch);
  }
  const checklistTask = state.tasks.openedTaskInfo && state.tasks.openedTaskInfo.checklistTask;
  if (checklistTask) {
    const checklistTaskWithoutRemovedComment = { ...checklistTask, comments: checklistTask.comments.filter((c) => c.id !== response.id) };
    dispatch(setOpenedTaskInfo({ id: checklistTask.id, checklistTask: checklistTaskWithoutRemovedComment }));
    dispatch(setChecklistRowTask({ task: checklistTaskWithoutRemovedComment }));
  }
  return response;
});

export const saveProjectTaskCategoryAsync = createAsyncThunk("task/category/save", async (category: IProjectTaskCategory) => {
  return saveProjectTaskCategory(category);
});

export const fetchTaskBoardTasksWithExtraFieldsAsync = createAsyncThunk("task/fetchTaskBoardTasks/extra", async (dto: { taskBoardId: EntityId; projectId: EntityId }) => {
  return fetchTaskBoardTasksWithExtraFields(dto.projectId, dto.taskBoardId);
});

export const copyTaskAsync = createAsyncThunk("task/copy", async (task: ITask) => {
  return copyTask(task);
});

export const moveTaskAsync = createAsyncThunk("task/move", async (task: ITask) => {
  return moveTask(task);
});

export const saveTaskTagAsync = createAsyncThunk("task/tag/save", async (tag: ITaskTag, { getState, dispatch }) => {
  const state: RootState = getState() as RootState;
  const response = await saveTaskTag(tag);
  const formTask = state.tasks.openedTaskInfo && state.tasks.openedTaskInfo.formTask;
  if (formTask) {
    const formTaskWithSavedTag = { ...formTask, tags: [...formTask.tags, response] };
    checkAndSaveFormTask(formTask, formTaskWithSavedTag, dispatch);
  }
  const checklistTask = state.tasks.openedTaskInfo && state.tasks.openedTaskInfo.checklistTask;
  if (checklistTask) {
    const checklistTaskWithSavedTag = { ...checklistTask, tags: [...checklistTask.tags, response] };
    dispatch(setOpenedTaskInfo({ id: checklistTask.id, checklistTask: checklistTaskWithSavedTag }));
    dispatch(setChecklistRowTask({ task: checklistTaskWithSavedTag }));
  }
  return response;
});

export const deleteTaskTagAsync = createAsyncThunk("task/tag/delete", async (tag: ITaskTag, { getState, dispatch }) => {
  const state: RootState = getState() as RootState;
  const response = await deleteTaskTag(tag);
  const formTask = state.tasks.openedTaskInfo && state.tasks.openedTaskInfo.formTask;
  if (formTask) {
    const formTaskWithoutRemovedTag = { ...formTask, tags: formTask.tags.filter((t) => t.id !== tag.id) };
    checkAndSaveFormTask(formTask, formTaskWithoutRemovedTag, dispatch);
  }
  const checklistTask = state.tasks.openedTaskInfo && state.tasks.openedTaskInfo.checklistTask;
  if (checklistTask) {
    const checklistTaskWithoutRemovedTag = { ...checklistTask, tags: checklistTask.tags.filter((t) => t.id !== tag.id) };
    dispatch(setOpenedTaskInfo({ id: checklistTask.id, checklistTask: checklistTaskWithoutRemovedTag }));
    dispatch(setChecklistRowTask({ task: checklistTaskWithoutRemovedTag }));
  }
  return response;
});

const tasksSlice = createSlice({
  name: "tasks",
  initialState: tasksInitialState,
  reducers: {
    removeAllTasks: tasksAdapter.removeAll,
    taskAdded: tasksAdapter.addOne,
    tasksAdded: tasksAdapter.upsertMany,
    taskRemoved: tasksAdapter.removeOne,
    addOrUpdateTasks: tasksAdapter.upsertMany,
    tasksUpdatedAndAdded: (state, action: PayloadAction<Array<ITask>>) => {
      const newAndUpdatedTasks = action.payload.map((task) => {
        const alreadySavedTask = state.entities[task.id];
        return alreadySavedTask ? { ...alreadySavedTask, ...task } : task;
      });
      tasksAdapter.upsertMany(state, newAndUpdatedTasks);
    },
    setOpenedTaskInfo: (state, action: PayloadAction<IOpenedTaskInfo>) => {
      state.openedTaskInfo = action.payload;
    },
    resetOpenedTaskInfo: (state) => {
      delete state.openedTaskInfo;
    },
    setTasksFilter: (state, action: PayloadAction<ITasksFilter>) => {
      state.filter = action.payload;
    },
    tasksSortSet: (state, action: PayloadAction<ITasksSort>) => {
      state.sort = action.payload;
    },
    taskCategoriesUpdatedAndAdded: (state, action: PayloadAction<Array<IProjectTaskCategory>>) => {
      action.payload.forEach((category) => {
        const existingCategory = state.projectTaskCategories.find((c) => c.id === category.id);
        !existingCategory && state.projectTaskCategories.push(category);
      });
    },
    categoryToggledInTaskBoardFilter: (state, action: PayloadAction<IProjectTaskCategory>) => {
      const isCategoryAlreadyFiltered = state.filter.categories.findIndex((c) => c.name === action.payload.name);
      if (isCategoryAlreadyFiltered > -1) {
        state.filter.categories.splice(isCategoryAlreadyFiltered, 1);
      } else {
        state.filter.categories.push({ name: action.payload.name } as IProjectTaskCategory);
      }
    },
    categoryTaskBoardFilterCleared: (state) => {
      state.filter.categories = [];
    },
    taskTagsUpdatedAndAdded: (state, action: PayloadAction<Array<ITaskTag>>) => {
      action.payload.forEach((tag) => {
        const existingTag = state.projectTaskTags.find((t) => t.id === tag.id);
        !existingTag && state.projectTaskTags.push(tag);
      });
    },
    tagToggledInTaskBoardFilter: (state, action: PayloadAction<ITaskTag>) => {
      const isTagAlreadyFiltered = state.filter.tags.findIndex((t) => t.name === action.payload.name);
      if (isTagAlreadyFiltered > -1) {
        state.filter.tags.splice(isTagAlreadyFiltered, 1);
      } else {
        state.filter.tags.push({ name: action.payload.name } as ITaskTag);
      }
    },
    tagTaskBoardFilterCleared: (state) => {
      state.filter.tags = [];
      state.filter.tagSearch = "";
    },
    userRoleToggledInTaskBoardFilter: (state, action: PayloadAction<ITaskUserRoleFilter>) => {
      const isRoleAlreadyFiltered = state.filter.userRoles.findIndex((r) => r.name === action.payload.name);
      if (isRoleAlreadyFiltered > -1) {
        state.filter.userRoles.splice(isRoleAlreadyFiltered, 1);
      } else {
        state.filter.userRoles.push(action.payload);
      }
    },
    userRoleTaskBoardFilterCleared: (state) => {
      state.filter.userRoles = [];
    },
    userToggledInTaskBoardFilter: (state, action: PayloadAction<IUserAuthority>) => {
      const isUserAlreadyFiltered = state.filter.users.findIndex((u) => u.userEntityId === action.payload.userEntityId);
      if (isUserAlreadyFiltered > -1) {
        state.filter.users.splice(isUserAlreadyFiltered, 1);
      } else {
        state.filter.users.push(action.payload);
      }
    },
    userTaskBoardFilterCleared: (state) => {
      state.filter.users = [];
    },
    setTaskModalsOpen: (state, action: PayloadAction<{ modal: keyof ITaskModalsOpen }>) => {
      state.taskModals.open[action.payload.modal] = !state.taskModals.open[action.payload.modal];
    },
    removeTaskBoardTasks: (state, action: PayloadAction<EntityId>) => {
      const taskBoardTaskIds = Object.values(state.entities)
        .filter((task) => task && task.taskBoardId === action.payload)
        .map((task) => task && task.id);
      if (taskBoardTaskIds.length > 0) {
        tasksAdapter.removeMany(state, taskBoardTaskIds as Array<EntityId>);
      }
    },
    updateTaskBoardTaskIdentifiers: (state, action: PayloadAction<{ taskBoardId: EntityId; abbreviation: string }>) => {
      const taskBoardTasks = Object.values(state.entities).filter((task) => task && task.taskBoardId === action.payload.taskBoardId);
      if (taskBoardTasks.length > 0) {
        const updatedTasks = taskBoardTasks.slice().map((task) => {
          const newIdentifier = task && action.payload.abbreviation + "-" + task.taskIdentifier.split("-")[1];
          return { ...task, ...({ taskIdentifier: newIdentifier } as ITask) };
        });
        tasksAdapter.upsertMany(state, updatedTasks);
      }
    },
    setNewTask: (state, action: PayloadAction<ITask>) => {
      state.newTask = action.payload;
    },
    resetNewTask: (state) => {
      delete state.newTask;
    },
    removeDeletedTasksFromTaskBoards: (state) => {
      const deletedTaskIds = Object.values(state.entities)
        .filter((task) => task && task.deleted)
        .map((task) => task && task.id) as Array<EntityId>;
      if (deletedTaskIds && deletedTaskIds.length > 0) {
        tasksAdapter.removeMany(state, deletedTaskIds);
      }
    },
    taskFileAnnotationRelationsRemoved: (state, action: PayloadAction<Array<ITaskToFileAnnotation>>) => {
      const taskToFileAnnotations = action.payload;
      taskToFileAnnotations.forEach((taskToFileAnnotation) => {
        const existingTask = state.entities[taskToFileAnnotation.taskId];
        if (existingTask) {
          existingTask.fileAnnotationRelations = existingTask.fileAnnotationRelations.filter((fileAnnotationRelation) => fileAnnotationRelation.id !== taskToFileAnnotation.id);
        }
      });
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaskAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchTaskAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const task = action.payload;
        tasksAdapter.upsertOne(state, task);
        if (task.fileAnnotationRelations && task.fileAnnotationRelations.length > 0 && state.openedTaskInfo) {
          if (state.openedTaskInfo.checklistTask) {
            state.openedTaskInfo.checklistTask.fileAnnotationRelations = task.fileAnnotationRelations;
          }
          if (state.openedTaskInfo.formTask) {
            state.openedTaskInfo.formTask.fileAnnotationRelations = task.fileAnnotationRelations;
          }
        }
      })
      .addCase(fetchPersonalTaskAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchPersonalTaskAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        tasksAdapter.upsertOne(state, action.payload);
      })
      .addCase(fetchChecklistPersonalTaskAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchChecklistPersonalTaskAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        tasksAdapter.upsertOne(state, action.payload);
      })
      .addCase(fetchPersonalTaskBoardTasksAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchPersonalTaskBoardTasksAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        tasksAdapter.upsertMany(state, action.payload);
      })
      .addCase(fetchAllTasksAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchAllTasksAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        tasksAdapter.upsertMany(state, action.payload);
      })
      .addCase(fetchTasksByIdsWithExtraFieldsAsync.fulfilled, (state, action) => {
        tasksAdapter.upsertMany(state, action.payload);
      })
      .addCase(fetchProjectTaskCategoriesAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchProjectTaskCategoriesAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.projectTaskCategories = action.payload;
      })
      .addCase(fetchProjectTaskTagsAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchProjectTaskTagsAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.projectTaskTags = action.payload;
      })
      .addCase(saveTaskBoardTaskAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveTaskBoardTaskAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        tasksAdapter.upsertOne(state, action.payload);
      })
      .addCase(savePersonalTaskAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(savePersonalTaskAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        tasksAdapter.upsertOne(state, action.payload);
      })
      .addCase(saveFormTaskAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveFormTaskAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        tasksAdapter.upsertOne(state, action.payload);
      })
      .addCase(savePersonalFormTaskAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(savePersonalFormTaskAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        tasksAdapter.upsertOne(state, action.payload);
      })
      .addCase(saveTaskStatusAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveTaskStatusAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const task = state.entities[action.payload.id];
        if (task) {
          task.status = action.payload.status;
          task.changeLog = action.payload.changeLog;
        }
      })
      .addCase(deleteTaskAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(deleteTaskAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        tasksAdapter.removeOne(state, action.payload.id);
      })
      .addCase(saveTaskBoardTaskCommentAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveTaskBoardTaskCommentAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const comment = action.payload;
        const task = state.entities[comment.taskId];
        if (task) {
          if (comment.id) {
            task.comments = task.comments.filter((c) => c.id !== comment.id);
          }
          task.comments = [...task.comments, comment];
        }
      })
      .addCase(savePersonalTaskCommentAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(savePersonalTaskCommentAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const comment = action.payload;
        const task = state.entities[comment.taskId];
        if (task) {
          if (comment.id) {
            task.comments = task.comments.filter((c) => c.id !== comment.id);
          }
          task.comments = [...task.comments, comment];
        }
      })
      .addCase(deleteTaskCommentAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(deleteTaskCommentAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        const comment = action.payload;
        const task = state.entities[comment.taskId];
        if (task) {
          task.comments = task.comments.filter((c) => c.id !== comment.id);
        }
      })
      .addCase(saveProjectTaskCategoryAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveProjectTaskCategoryAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        if (state.projectTaskCategories) {
          state.projectTaskCategories = state.projectTaskCategories.filter((category) => {
            return category.id !== action.payload.id;
          });
          if (action.payload.deleted) {
            const defaultCategory = state.projectTaskCategories.find((cat) => cat.code === CategoryDefaultCode.TASK);
            Object.values(state.entities).forEach((task) => {
              if (task && defaultCategory && task.projectCategoryId === action.payload.id) {
                task.projectCategoryId = defaultCategory.id;
                task.category = defaultCategory;
              }
            });
          } else {
            state.projectTaskCategories = [...state.projectTaskCategories, action.payload];
          }
        }
      })
      .addCase(fetchTaskBoardTasksWithExtraFieldsAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchTaskBoardTasksWithExtraFieldsAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        tasksAdapter.upsertMany(state, action.payload);
      })
      .addCase(copyTaskAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(copyTaskAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        tasksAdapter.addOne(state, action.payload);
      })
      .addCase(moveTaskAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(moveTaskAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        tasksAdapter.upsertOne(state, action.payload);
      })
      .addCase(saveTaskTagAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(saveTaskTagAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.projectTaskTags = [...state.projectTaskTags, action.payload];
        const tasks = Object.values(state.entities);
        const task = tasks.find((t) => t && t.id === action.payload.taskId);
        if (task) {
          task.tags = [...task.tags, action.payload];
        }
      })
      .addCase(deleteTaskTagAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(deleteTaskTagAsync.fulfilled, (state, action) => {
        state.status = BhStateStatusType.SUCCESS;
        state.projectTaskTags = state.projectTaskTags.filter((tag) => tag.id !== action.payload.id);
        const tasks = Object.values(state.entities);
        const task = tasks.find((t) => t && t.id === action.payload.taskId);
        if (task) {
          task.tags = task.tags.filter((tag) => tag.id !== action.payload.id);
        }
      });
  }
});

export const {
  removeAllTasks,
  taskAdded,
  tasksAdded,
  taskRemoved,
  addOrUpdateTasks,
  tasksUpdatedAndAdded,
  setOpenedTaskInfo,
  resetOpenedTaskInfo,
  setTasksFilter,
  tasksSortSet,
  taskCategoriesUpdatedAndAdded,
  categoryToggledInTaskBoardFilter,
  categoryTaskBoardFilterCleared,
  taskTagsUpdatedAndAdded,
  tagTaskBoardFilterCleared,
  tagToggledInTaskBoardFilter,
  userRoleToggledInTaskBoardFilter,
  userRoleTaskBoardFilterCleared,
  userTaskBoardFilterCleared,
  userToggledInTaskBoardFilter,
  setTaskModalsOpen,
  removeTaskBoardTasks,
  updateTaskBoardTaskIdentifiers,
  setNewTask,
  resetNewTask,
  removeDeletedTasksFromTaskBoards,
  taskFileAnnotationRelationsRemoved
} = tasksSlice.actions;

export const { selectIds: selectTaskIds, selectAll: selectAllTasks, selectById: selectTaskById } = tasksAdapter.getSelectors((state: RootState) => state.tasks);
const getColumnStatus = (_state: any, status: TaskStatus) => status;
export const selectTasksSort = (state: RootState) => state.tasks.sort;
export const selectTasksFilter = (state: RootState) => state.tasks.filter;
export const selectIsAnyFilterApplied = (state: RootState) =>
  state.tasks.filter.name ||
  (state.tasks.filter.tags && state.tasks.filter.tags.length > 0) ||
  (state.tasks.filter.users && state.tasks.filter.users.length > 0) ||
  (state.tasks.filter.categories && state.tasks.filter.categories.length > 0);
export const selectOpenedTaskInfo = (state: RootState) => state.tasks.openedTaskInfo;
export const selectOpenedTaskDisabled = (state: RootState) => state.tasks.openedTaskInfo && state.tasks.openedTaskInfo.disabled;
export const selectOpenedFormTask = (state: RootState) => state.tasks.openedTaskInfo && state.tasks.openedTaskInfo.formTask;
export const selectOpenedChecklistTask = (state: RootState) => state.tasks.openedTaskInfo && state.tasks.openedTaskInfo.checklistTask;
export const selectShouldModalCloseOnNewTaskSave = (state: RootState) => state.tasks.openedTaskInfo && state.tasks.openedTaskInfo.closeModalOnNewTaskSave;
export const selectNewTask = (state: RootState) => state.tasks.newTask;
export const selectShowArchivedFilter = (state: RootState) => state.tasks.filter.showArchived;
export const selectTaskTagSearchFilter = (state: RootState) => state.tasks.filter.tagSearch;
export const selectTaskCopyModalOpen = (state: RootState) => state.tasks.taskModals.open.taskCopyModal;
export const selectTaskMoveModalOpen = (state: RootState) => state.tasks.taskModals.open.taskMoveModal;
export const selectProjectTaskTags = (state: RootState) => state.tasks.projectTaskTags;
export const selectProjectTaskCategories = (state: RootState) => {
  // TODO: Memoize ja ära kasuta let
  if (state.tasks.projectTaskCategories) {
    let categories = [...state.tasks.projectTaskCategories];
    const defaultCategory = categories.find((cat) => cat.code === CategoryDefaultCode.TASK);
    if (defaultCategory) {
      categories = categories.filter((cat) => cat.code !== CategoryDefaultCode.TASK).sort((a, b) => (a.id > b.id ? 1 : -1));
      categories.unshift(defaultCategory);
      return categories;
    }
  }
};
export const selectDefaultProjectTaskCategory = (state: RootState) => {
  return state.tasks.projectTaskCategories.find((cat) => cat.code === CategoryDefaultCode.TASK);
};

export const selectSafetyProjectTaskCategoryIfAvailable = (state: RootState) => {
  const safetyCategory = state.tasks.projectTaskCategories.find((cat) => cat.code === CategoryDefaultCode.SAFETY);
  if (safetyCategory) {
    return safetyCategory;
  } else {
    return state.tasks.projectTaskCategories.find((cat) => cat.code === CategoryDefaultCode.TASK);
  }
};

const selectFilteredTagsForTasks = (tasks: Array<ITask>, searchString: string, projectId: EntityId, taskBoardId?: EntityId, isPersonalTaskBoardOpen?: boolean, isAllTasksTaskBoardOpen?: boolean) => {
  const taskTags = tasks
    .filter((task) => {
      if (isPersonalTaskBoardOpen) {
        return task.projectId === projectId && !task.taskBoardId;
      }
      if (isAllTasksTaskBoardOpen) {
        return task.projectId === projectId;
      }
      return task.projectId === projectId && task.taskBoardId === taskBoardId;
    })
    .flatMap((task) => task.tags);

  let uniqueTags = getUniqueValues(taskTags, "name");
  if (searchString && searchString.length > 0) {
    uniqueTags = uniqueTags.filter((tag) => {
      return tag.name.toLowerCase().includes(searchString.toLowerCase());
    });
  }
  return uniqueTags.sort((a, b) => (a.name > b.name ? 1 : -1));
};

export const selectFilteredTaskTags = createSelector(
  [selectAllTasks, selectTaskTagSearchFilter, selectCurrentProjectId, selectOpenedTaskBoardId, selectIsPersonalTaskBoardOpen, selectIsAllTasksTaskBoardOpen],
  (tasks, searchString, projectId, taskBoardId, isPersonalTaskBoard, isAllTasksTaskBoard) => {
    return selectFilteredTagsForTasks(tasks, searchString, projectId, taskBoardId, isPersonalTaskBoard, isAllTasksTaskBoard);
  }
);

export const selectTaskHistorySorted = createSelector([selectAllTasks, (state: any, taskId: EntityId) => taskId], (tasks, taskId) => {
  const task = tasks.find((t) => t.id === taskId);
  if (task && task.changeLog) {
    return task.changeLog.slice().sort((a, b) => (new Date(a.created) < new Date(b.created) ? 1 : -1));
  }
});

export const filterTasks = (tasks: Array<ITask>, filter: ITasksFilter, isPersonalTaskBoard: boolean | undefined) => {
  return tasks.filter((task: ITask) => {
    const nameFilter = filter.name && filter.name.length > 0 ? task.name.toLowerCase().includes(filter.name.toLowerCase()) : true;
    const categoriesFilter = filter.categories && filter.categories.length > 0 ? filter.categories.some((category) => category.name === task.category.name) : true;
    const tagsFilter = filter.tags && filter.tags.length > 0 ? task.tags.some((tag) => filter.tags.some((t) => tag.name === t.name)) : true;
    const usersFilter = !isPersonalTaskBoard
      ? filter.users && filter.users.length > 0
        ? filter.userRoles && filter.userRoles.length > 0
          ? filter.userRoles.some((role) => role.name === TaskUserRoleFilterEnum.REPORTER && filter.users.some((user) => user.userEntityId === task.reporter)) ||
            filter.userRoles.some((role) => role.name === TaskUserRoleFilterEnum.ASSIGNEE && filter.users.some((user) => user.userEntityId === task.assignee)) ||
            filter.userRoles.some(
              (role) => role.name === TaskUserRoleFilterEnum.PARTICIPANT && filter.users.some((user) => task.participants.some((participant) => participant.id === user.userEntityId))
            )
          : filter.users.some((user) => task.assignee === user.userEntityId || task.reporter === user.userEntityId || task.participants.some((p) => p.id === user.userEntityId))
        : true
      : true;
    return nameFilter && categoriesFilter && tagsFilter && usersFilter;
  });
};

export const sortTasks = (tasks: Array<ITask>, sort: ITasksSort) => {
  return tasks.sort((a, b) => naturalSortByField(a, b, sort.property, sort.reversed));
};

const getOpenedTaskBoardTasks = (
  tasks: Array<ITask>,
  openedTaskBoardId: EntityId | undefined,
  isPersonal: boolean | undefined,
  isAllTasks: boolean | undefined,
  currentUser: IUser,
  projectId: EntityId,
  status?: TaskStatus
) => {
  return tasks.filter((task) => {
    if (openedTaskBoardId && !isPersonal && !isAllTasks) {
      return task.taskBoardId === openedTaskBoardId && (status ? task.status === status : true);
    }
    if (isPersonal && !openedTaskBoardId && !isAllTasks) {
      return isTaskPersonal(task, currentUser) && (status ? task.status === status : true) && task.projectId === projectId;
    }
    if (isAllTasks && !openedTaskBoardId && !isPersonal) {
      return (status ? task.status === status : true) && task.projectId === projectId;
    }
    return false;
  });
};

const selectFilteredAndSortedTaskIdsByColumnStatus = createSelector(
  [
    selectAllTasks,
    selectTasksSort,
    selectTasksFilter,
    selectOpenedTaskBoardId,
    selectIsPersonalTaskBoardOpen,
    selectIsAllTasksTaskBoardOpen,
    selectCurrentUser,
    getColumnStatus,
    selectCurrentProjectId
  ],
  (tasks, sort, filter, openedTaskBoardId, isPersonal, isAllTasks, currentUser, status, projectId) => {
    let taskBoardTasks = getOpenedTaskBoardTasks(tasks, openedTaskBoardId, isPersonal, isAllTasks, currentUser, projectId, status);
    const filteredTasks = filterTasks(taskBoardTasks, filter, isPersonal);
    const sortedTasks = sortTasks(filteredTasks, sort);
    return sortedTasks.map((task) => {
      return task.id;
    });
  }
);

export const makeSelectFilteredAndSortedTaskIdsByColumnStatus = () => {
  return selectFilteredAndSortedTaskIdsByColumnStatus;
};

export const selectOpenedTaskBoardTasks = createSelector(
  [selectAllTasks, selectTasksSort, selectOpenedTaskBoardId, selectIsPersonalTaskBoardOpen, selectIsAllTasksTaskBoardOpen, selectCurrentUser, selectCurrentProjectId],
  (tasks, sort, openedTaskBoardId, isPersonal, isAllTasks, currentUser, projectId) => {
    const taskBoardTasks = getOpenedTaskBoardTasks(tasks, openedTaskBoardId, isPersonal, isAllTasks, currentUser, projectId);
    return sortTasks(taskBoardTasks, sort);
  }
);

export const selectFilteredAndSortedOpenedTaskBoardTasks = createSelector(
  [selectAllTasks, selectTasksSort, selectTasksFilter, selectOpenedTaskBoardId, selectIsPersonalTaskBoardOpen, selectIsAllTasksTaskBoardOpen, selectCurrentUser, selectCurrentProjectId],
  (tasks, sort, filter, openedTaskBoardId, isPersonal, isAllTasks, currentUser, projectId) => {
    let taskBoardTasks = getOpenedTaskBoardTasks(tasks, openedTaskBoardId, isPersonal, isAllTasks, currentUser, projectId);
    const filteredTasks = filterTasks(taskBoardTasks, filter, isPersonal);
    return sortTasks(filteredTasks, sort);
  }
);

export const selectFileAnnotationRelations = createSelector(selectAllTasks, (tasks) => {
  const fileAnnotationRelations = tasks.filter((task) => task.fileAnnotationRelations?.length > 0).map((task) => task.fileAnnotationRelations);
  const flatMap = fileAnnotationRelations.reduce((accumulator, value) => accumulator.concat(value), []);
  return flatMap;
});
export const selectFileEntityIdForFileAnnotationRelation = (state: RootState, fileEntityId: EntityId) => fileEntityId;
export const selectFileAnnotationRelationsForFileEntityId = createSelector([selectFileAnnotationRelations, selectFileEntityIdForFileAnnotationRelation], (allFileAnnotationRelations, fileEntityId) => {
  return allFileAnnotationRelations.filter((fileAnnotationRelation) => fileAnnotationRelation.fileEntityId === fileEntityId);
});

export const selectTaskIdsForTasks = (state: RootState, taskIds: Array<EntityId>) => taskIds;
export const selectTasksById = createSelector([selectAllTasks, selectTaskIdsForTasks], (tasks, ids) => {
  return tasks.filter((task) => ids.includes(task.id));
});

export const selectTaskIdentifier = (state: RootState, taskIdentifier: string) => taskIdentifier;
export const selectTaskByIdentifier = createSelector([selectAllTasks, selectTaskIdentifier], (tasks, taskIdentifier) => {
  const task = tasks.find((t) => t.taskIdentifier === taskIdentifier);
  if (task) {
    return task;
  }
});

export default tasksSlice.reducer;
