import React, { FC } from "react";
import BhSideBySideInput from "@components/input/BhSideBySideInput";
import BhCheckboxWithText from "@components/checkboxes/BhCheckboxWithText";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@/app/hooks";
import { selectValueByPropertyFromCurrentFormData } from "@/app/store/formSlice";

interface Props {
  saveCallback?: Function;
  disabled: boolean;
}

const FormWeatherField: FC<Props> = ({ saveCallback, disabled }) => {
  const { t } = useTranslation();
  const isStrongWind = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, "isStrongWind"));
  const isDry = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, "isDry"));
  const isRain = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, "isRain"));
  const isSleet = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, "isSleet"));
  const isSnow = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, "isSnow"));

  return (
    <BhSideBySideInput>
      <div className="flex w-full flex-row items-center justify-between">
        <div className="flex flex-row items-center">
          <div className="-ml-4">
            <BhCheckboxWithText text={t("FORMS.BDW.STRONG_WIND")} isChecked={isStrongWind} onChange={saveCallback} property={"isStrongWind"} disabled={disabled} />
          </div>
          <BhCheckboxWithText text={t("FORMS.BDW.DRY")} isChecked={isDry} onChange={saveCallback} property={"isDry"} disabled={disabled} />
          <BhCheckboxWithText text={t("FORMS.BDW.RAIN")} isChecked={isRain} onChange={saveCallback} property={"isRain"} disabled={disabled} />
          <BhCheckboxWithText text={t("FORMS.BDW.SLEET")} isChecked={isSleet} onChange={saveCallback} property={"isSleet"} disabled={disabled} />
          <BhCheckboxWithText text={t("FORMS.BDW.SNOW")} isChecked={isSnow} onChange={saveCallback} property={"isSnow"} disabled={disabled} />
        </div>
        <div className="text-14px bh-text-deep-ocean cursor-pointer leading-5 hover:underline">
          <a href="http://www.ilmateenistus.ee/ilm/ilmavaatlused/vaatlusandmed/tunniandmed/" target={"_blank"} rel={"noreferrer"}>
            {t("FORMS.SCM.WEAHTER_LINK")}
          </a>
        </div>
      </div>
    </BhSideBySideInput>
  );
};

export default FormWeatherField;
