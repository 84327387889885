import React, { FC } from "react";
import BhFormInput from "@components/input/BhFormInput";
import BhCheckbox from "@components/checkboxes/BhCheckbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faPaintBrush } from "@fortawesome/pro-regular-svg-icons";
import { faArrowUp } from "@fortawesome/pro-regular-svg-icons/faArrowUp";
import { faArrowDown } from "@fortawesome/pro-regular-svg-icons/faArrowDown";
import { classNames } from "@/utilities/jsUtilities";
import { useTranslation } from "react-i18next";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import { useModal } from "react-modal-hook";

interface Props {
  work: any;
  workIndex: number;
  saveCallback: Function;
  removeWorkCallback: Function;
  changeWorkOrderCallback: Function;
  disabled: boolean;
  lastFocusedElement: any;
  setLastFocusedElement: Function;
  isLastFocusedElementInput: boolean;
}

const TooplaanWorkRow: FC<Props> = ({ work, saveCallback, workIndex, removeWorkCallback, changeWorkOrderCallback, disabled, lastFocusedElement, setLastFocusedElement, isLastFocusedElementInput }) => {
  const { t } = useTranslation();

  const saveWork = (changedObject: any) => {
    const workToSave = { ...work, ...changedObject };
    saveCallback(workToSave);
  };

  const handleBlur = (changedObject: any) => {
    saveWork(changedObject);
  };

  const saveDay = (changedObject: any) => {
    if (lastFocusedElement?.name && ["name", "notes"].includes(lastFocusedElement.name)) {
      setLastFocusedElement(undefined);
      return;
    }
    const daysToSave = { days: { ...work.days, ...changedObject } };
    saveWork(daysToSave);
  };

  const toggleWork = () => {
    const workToSave = { isObjectRow: !work.isObjectRow };
    saveWork(workToSave);
  };

  const changeWorkOrder = (moveHigher: boolean) => {
    const newIndex = moveHigher ? workIndex - 1 : workIndex + 1;
    changeWorkOrderCallback(work, workIndex, newIndex);
  };

  const [showWorkDeleteConfirmationModal, hideWorkDeleteConfirmationModal] = useModal(
    () => (
      <BhDeleteConfirmationModal
        setIsOpen={hideWorkDeleteConfirmationModal}
        header={<h2>{t("FORMS.CONFIRMATION.DELETE")}</h2>}
        body={<div>{t("FORMS.CONFIRMATION.DELETE_BODY")}</div>}
        confirmationButtonText={t("GLOBAL.DELETE")}
        handleDelete={() => {
          removeWorkCallback(work);
          hideWorkDeleteConfirmationModal();
        }}
      />
    ),
    [removeWorkCallback, work]
  );

  return (
    <tr className="group align-top">
      <td colSpan={work.isObjectRow ? 9 : 1}>
        <BhFormInput initialValue={work.name} property={"name"} saveCallback={handleBlur} inputClasses={classNames(work.isObjectRow && "font-bold")} disabled={disabled} />
      </td>
      {!work.isObjectRow && (
        <>
          <td>
            <div className="flex h-full w-full flex-row items-center justify-center">
              <BhCheckbox isChecked={work.days.m} property={"m"} onChange={saveDay} disabled={disabled || isLastFocusedElementInput} />
            </div>
          </td>
          <td>
            <div className="flex h-full w-full flex-row items-center justify-center">
              <BhCheckbox isChecked={work.days.tu} property={"tu"} onChange={saveDay} disabled={disabled || isLastFocusedElementInput} />
            </div>
          </td>
          <td>
            <div className="flex h-full w-full flex-row items-center justify-center">
              <BhCheckbox isChecked={work.days.w} property={"w"} onChange={saveDay} disabled={disabled || isLastFocusedElementInput} />
            </div>
          </td>
          <td>
            <div className="flex h-full w-full flex-row items-center justify-center">
              <BhCheckbox isChecked={work.days.th} property={"th"} onChange={saveDay} disabled={disabled || isLastFocusedElementInput} />
            </div>
          </td>
          <td>
            <div className="flex h-full w-full flex-row items-center justify-center">
              <BhCheckbox isChecked={work.days.f} property={"f"} onChange={saveDay} disabled={disabled || isLastFocusedElementInput} />
            </div>
          </td>
          <td>
            <div className="flex h-full w-full flex-row items-center justify-center">
              <BhCheckbox isChecked={work.days.sa} property={"sa"} onChange={saveDay} disabled={disabled || isLastFocusedElementInput} />
            </div>
          </td>
          <td>
            <div className="flex h-full w-full flex-row items-center justify-center">
              <BhCheckbox isChecked={work.days.su} property={"su"} onChange={saveDay} disabled={disabled || isLastFocusedElementInput} />
            </div>
          </td>
          <td>
            <BhFormInput initialValue={work.notes} property={"notes"} saveCallback={saveWork} disabled={disabled} />
          </td>
        </>
      )}
      <td className="w-24">
        {!disabled && !isLastFocusedElementInput && (
          <div className="flex h-full flex-row items-center justify-center opacity-0 group-hover:opacity-100">
            <div className="hover:bh-bg-pigeon-20 flex h-5 w-5 cursor-pointer flex-row items-center justify-center rounded p-1" onClick={toggleWork}>
              <FontAwesomeIcon icon={faPaintBrush} size={"xs"} />
            </div>
            <div className="hover:bh-bg-pigeon-20 flex h-5 w-5 cursor-pointer flex-row items-center justify-center rounded p-1" onClick={() => changeWorkOrder(true)}>
              <FontAwesomeIcon icon={faArrowUp} size={"xs"} />
            </div>
            <div className="hover:bh-bg-pigeon-20 flex h-5 w-5 cursor-pointer flex-row items-center justify-center rounded p-1" onClick={() => changeWorkOrder(false)}>
              <FontAwesomeIcon icon={faArrowDown} size={"xs"} />
            </div>
            <div className="hover:bh-bg-pigeon-20 flex h-5 w-5 cursor-pointer flex-row items-center justify-center rounded p-1" onClick={() => showWorkDeleteConfirmationModal()}>
              <FontAwesomeIcon icon={faTrash} size={"xs"} />
            </div>
          </div>
        )}
      </td>
    </tr>
  );
};

export default TooplaanWorkRow;
