import React, { FC, ReactNode } from "react";
import { BauhubBannerType } from "@/model/IProject";
import BhBannerLeftSide from "./BhBannerLeftSide";
import { classNames } from "@/utilities/jsUtilities";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface Props {
  type: BauhubBannerType;
  children: ReactNode;
  fullWidth?: boolean;
  textClasses?: string;
  shadow?: boolean;
  customIcon?: IconProp;
}

const BhBanner: FC<Props> = ({ type, children, fullWidth, textClasses, shadow, customIcon }) => {
  let hasLeftSide: boolean = true;
  const widthClass = fullWidth ? "w-full" : "w-[400px]";
  let classes = "flex items-center rounded min-h-14 px-5 flex-row relative " + widthClass;
  if (type === BauhubBannerType.SUCCESS) {
    classes += " bh-bg-success-green-10";
  }
  if (type === BauhubBannerType.ERROR) {
    classes += " bh-bg-error-red-10";
  }
  if (type === BauhubBannerType.WARNING) {
    classes += " bh-bg-warning-yellow-10";
  }
  if (type === BauhubBannerType.ANNOUNCEMENT) {
    classes += " bh-bg-deep-ocean text-white justify-center";
    hasLeftSide = false;
  }
  if (type === BauhubBannerType.ANNOUNCEMENT_LIGHT) {
    classes += " bh-bg-pigeon text-white";
    hasLeftSide = false;
  }
  if (type === BauhubBannerType.ANNOUNCEMENT_BLUE) {
    classes += " bh-bg-royal-blue-light bh-text-deep-ocean bh-border-royal-blue border ";
    hasLeftSide = false;
  }
  if (shadow) {
    classes += " bh-shadow";
  }

  return (
    <div className={classes}>
      {hasLeftSide && <BhBannerLeftSide type={type} customIcon={customIcon} />}
      <div className={classNames(textClasses, "flex-1 break-words py-2")}>{children}</div>
    </div>
  );
};

export default BhBanner;
