import React, { FC, useEffect, useRef, useState } from "react";
import WebViewer, { Core, WebViewerInstance } from "@pdftron/webviewer";
import { fetchUrlForFile } from "@/api/fileAPI";
import { shouldUsePdftronServer } from "@/utilities/fileEntity/fileEntityUtilities";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { fetchFileEntityAsync, selectFileById } from "@/app/store/filesSlice";
import { fetchFileAnnotationAsync, selectFileAnnotationForFileEntityId } from "@/app/store/fileAnnotationsSlice";
import { selectCurrentUser } from "@/app/store/userSlice";
import { FileEntityType, IFileEntity } from "@/model/files/IFileEntity";
import { fetchProjectTaskCategoriesAsync, selectProjectTaskCategories } from "@/app/store/tasksSlice";
import { IFileAnnotation, ITaskToFileAnnotation } from "@/model/files/IFileAnnotation";
import { ITask } from "@/model/taskBoard/ITask";
import { createBauhubTaskTools, getStampPng, pdftronAnnotationChangedCallback, pdftronAnnotationChangedCallbackForTaskPreview } from "@/views/home/project/detail/pdftron/pdftronFunctions";
import { EntityId } from "@reduxjs/toolkit";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

interface Props {
  fileAnnotationRelation?: ITaskToFileAnnotation;
  task: ITask;
  // Used to save unsaved task fileAnnotation after task save
  saveTaskCallback?: Function;
  selectedFileEntityIdForInitialMarking?: EntityId;
  deleteAnnotation?: boolean;
  setDeleteAnnotation?: Function;
  setSelectedDrawing?: Function;
}

const PdftronContainerForTaskPreview: FC<Props> = ({
  fileAnnotationRelation,
  task,
  saveTaskCallback,
  selectedFileEntityIdForInitialMarking,
  deleteAnnotation,
  setDeleteAnnotation,
  setSelectedDrawing
}) => {
  const fileEntityId = fileAnnotationRelation?.fileEntityId || selectedFileEntityIdForInitialMarking;
  const currentUser = useAppSelector(selectCurrentUser);
  const fileEntity = useAppSelector((state) => fileEntityId && selectFileById(state, fileEntityId)) as IFileEntity;
  const [webviewerInitializing, setWebviewerInitializing] = useState(false);
  const fileAnnotation = useAppSelector((state) => fileEntityId && selectFileAnnotationForFileEntityId(state, fileEntityId)) as IFileAnnotation | undefined;
  const [instance, setInstance] = useState<WebViewerInstance>();
  const [annotationsDrawnFirstTime, setAnnotationsDrawnFirstTime] = useState(false);
  const projectTaskCategories = useAppSelector(selectProjectTaskCategories);
  const viewer = useRef(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (fileEntity?.projectId) {
      dispatch(fetchProjectTaskCategoriesAsync(fileEntity.projectId));
    }
  }, [fileEntity]);

  useEffect(() => {
    if (!fileEntity && fileEntityId) {
      dispatch(fetchFileEntityAsync(fileEntityId));
    }
  }, []);

  useEffect(() => {
    if (fileEntity && !webviewerInitializing) {
      setWebviewerInitializing(true);
      dispatch(fetchFileAnnotationAsync(fileEntity));
      fetchFileUrlAndInitPdftron();
    }
  }, [fileEntity]);

  useEffect(() => {
    if (!fileAnnotationRelation) return;
    // Change annotation color when task category changes
    const changeAnnotationColor = async (annotationManager: Core.AnnotationManager) => {
      let stampPNG: any;
      await getStampPng(task.category.color).then((val) => (stampPNG = val));
      await correspondingAnnotation.setImageData(stampPNG);

      annotationManager.deselectAllAnnotations();
      annotationManager.trigger("annotationChanged", [[correspondingAnnotation], "modify", {}]);
      annotationManager.redrawAnnotation(correspondingAnnotation);
    };

    const annotationManager = instance?.Core.annotationManager;
    let correspondingAnnotation = annotationManager?.getAnnotationsList().find((annotation) => annotation.Id === fileAnnotationRelation.annotationId) as Core.Annotations.StampAnnotation;
    if (correspondingAnnotation && annotationManager) {
      changeAnnotationColor(annotationManager);
    }

    if (selectedFileEntityIdForInitialMarking) {
      instance?.UI.setToolMode("CustomTaskStampTool" + task.category.id);
    }
  }, [task.projectCategoryId]);

  useEffect(() => {
    if (selectedFileEntityIdForInitialMarking) {
      const changeAnnotationColor = async (annotationManager: Core.AnnotationManager) => {
        let stampPNG: any;
        await getStampPng(task.category.color).then((val) => (stampPNG = val));
        await correspondingAnnotation.setImageData(stampPNG);

        annotationManager.deselectAllAnnotations();
        annotationManager.trigger("annotationChanged", [[correspondingAnnotation], "modify", {}]);
        annotationManager.redrawAnnotation(correspondingAnnotation);
      };

      const annotationManager = instance?.Core.annotationManager;
      let correspondingAnnotation = annotationManager
        ?.getAnnotationsList()
        .find((annotation) => annotation.Id === fileAnnotation?.relatedTaskAnnotation?.annotationId) as Core.Annotations.StampAnnotation;
      if (correspondingAnnotation && annotationManager) {
        changeAnnotationColor(annotationManager);
      }

      instance?.UI.setToolMode("CustomTaskStampTool" + task.category.id);
    }
  }, [task.category.id]);

  useEffect(() => {
    if (deleteAnnotation) {
      const annotationManager = instance?.Core.annotationManager;
      if (!annotationManager) return;

      let annotationToDelete;
      if (fileAnnotationRelation) {
        annotationToDelete = annotationManager?.getAnnotationById(fileAnnotationRelation.annotationId);
      } else {
        const markedTaskAnnotationId = fileAnnotation?.relatedTaskAnnotation?.annotationId;
        if (markedTaskAnnotationId) {
          annotationToDelete = annotationManager?.getAnnotationById(markedTaskAnnotationId);
        } else {
          setSelectedDrawing && setSelectedDrawing(undefined);
        }
      }
      annotationToDelete && annotationManager.deleteAnnotation(annotationToDelete, { force: true });
      setDeleteAnnotation && setDeleteAnnotation(false);
    }
  }, [deleteAnnotation]);

  const drawAnnotations = () => {
    if (fileAnnotation && fileAnnotation.xfdf) {
      instance?.Core.documentViewer.addEventListener("documentLoaded", function () {
        instance?.Core.annotationManager.importAnnotations(fileAnnotation.xfdf);
        instance?.Core.annotationManager.showAnnotations(instance?.Core.annotationManager.getAnnotationsList());
        const annotations = instance.Core.annotationManager.getAnnotationsList();
        annotations.forEach((annotation) => {
          if (selectedFileEntityIdForInitialMarking) {
            instance.Core.annotationManager.hideAnnotation(annotation);
          } else {
            if (annotation.Id === fileAnnotationRelation?.annotationId) {
              // Juhul kui sa teed taski siis ei ole viisi kuidas taskId CustomData salvestada annotatsiooni külge ning seda tuleb teha siis kui avatakse uuesti
              if (annotation.getCustomData("shouldSaveTaskIdOnNextOpen")) {
                annotation.setCustomData("shouldSaveTaskIdOnNextOpen", "false");
                annotation.setCustomData("taskId", fileAnnotationRelation.taskId.toString());
                instance.Core.annotationManager.trigger("annotationChanged", [[annotation], "add", {}]);
              }
              if (annotation.getWidth() < 700 && annotation.getHeight() < 200) {
                instance.Core.documentViewer.zoomTo(1.5);
              }
              instance.Core.annotationManager.jumpToAnnotation(annotation);
              instance.Core.annotationManager.selectAnnotation(annotation);
            }
          }
        });
      });
    }
  };

  useEffect(() => {
    // Add first time annotation drawing for one time only
    if (instance && fileAnnotation && !annotationsDrawnFirstTime) {
      drawAnnotations();
      setAnnotationsDrawnFirstTime(true);
    }

    const annoChangedCB = function (annotations: Array<Core.Annotations.Annotation>, action: string, info: Core.AnnotationManager.AnnotationChangedInfoObject) {
      if (selectedFileEntityIdForInitialMarking) {
        return pdftronAnnotationChangedCallbackForTaskPreview(fileAnnotation, fileEntity, task, saveTaskCallback, setSelectedDrawing, instance, dispatch, annotations, action, info);
      } else {
        return pdftronAnnotationChangedCallback(fileAnnotation, fileEntity, instance, dispatch, annotations, action, info);
      }
    };
    instance?.Core.annotationManager.addEventListener("annotationChanged", annoChangedCB);

    return () => instance?.Core.annotationManager.removeEventListener("annotationChanged", annoChangedCB);
  }, [fileAnnotation, instance, task]);

  const fetchFileUrlAndInitPdftron = () => {
    async function fetchFileUrl() {
      if (!fileEntityId) return;
      return await fetchUrlForFile(fileEntityId, false, false, fileEntity?.uuid);
    }

    fetchFileUrl()
      .then((presignedUrl) => {
        if (!viewer.current || instance || !presignedUrl) return;

        const formExtension = fileEntity?.type === FileEntityType.FORM ? ".pdf" : "";
        const fileName = fileEntity?.name + "" + formExtension;

        WebViewer(
          {
            licenseKey: "Bauhub OU:OEM:Bauhub::B+:AMS(20260305):4A5F85823C6F78B942CE606F4F3D959CDE1FC65BA4AF8F58BE54B2B6F5C7",
            filename: fileName,
            annotationUser: currentUser.username,
            path: ConfigSingleton.getInstance().getConfig().PUBLIC_URL + "/pdftron10.9.0",
            useDownloader: fileEntity && fileEntity.size > 40000000,
            initialDoc: presignedUrl.value,
            extension: fileName.split(".").pop(),
            webviewerServerURL: fileEntity && shouldUsePdftronServer(fileEntity) ? "https://pdf3.bauhub.ee" : undefined,
            disabledElements: ["header", "leftPanel", "scaleOverlayContainer", "pageNavOverlay", "annotationCommentButton"],
            isReadOnly: true
          },
          viewer.current
        ).then((instance) => {
          setInstance(instance);

          instance.UI.hotkeys.off();

          if (selectedFileEntityIdForInitialMarking) {
            instance.Core.documentViewer.enableAnnotations();

            createBauhubTaskTools(
              instance,
              false,
              () => {},
              () => {},
              projectTaskCategories,
              task.id
            );

            instance?.UI.setToolMode("CustomTaskStampTool" + task.category.id);
          }
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    return function cleanup() {
      instance?.UI.dispose();
    };
  }, []);

  if (!fileEntity) {
    return null;
  }

  return (
    <div className="h-full w-full">
      <div className="webviewer" ref={viewer} style={{ height: "100%", width: "100%" }} />
    </div>
  );
};

export default PdftronContainerForTaskPreview;
