import React, { FC } from "react";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { useAppSelector } from "@/app/hooks";
import { ITask } from "@/model/taskBoard/ITask";
import BhTagWithDropdown from "@components/tags/BhTagWithDropdown";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import { selectOpenedTaskInfo, selectProjectTaskCategories } from "@/app/store/tasksSlice";
import { CategoryDefaultCode, IProjectTaskCategory } from "@/model/taskBoard/IProjectTaskCategory";
import { useTranslation } from "react-i18next";

interface Props {
  taskCategory: IProjectTaskCategory;
  saveTaskCallback: Function;
}

const TaskModalCategorySelection: FC<Props> = ({ taskCategory, saveTaskCallback }) => {
  const { t } = useTranslation();
  const categories = useAppSelector(selectProjectTaskCategories);
  const openedTaskInfo = useAppSelector(selectOpenedTaskInfo);
  const isTaskDisabled = openedTaskInfo && openedTaskInfo.disabled;

  if (!taskCategory || !taskCategory.name) return null;

  const translateTaskCategory = Object.keys(CategoryDefaultCode).includes(taskCategory?.name.toUpperCase());
  const dropdownCategories =
    categories &&
    categories.map((category) => {
      if (Object.keys(CategoryDefaultCode).includes(category?.name.toUpperCase())) {
        return { name: t("TASK.CATEGORY." + category?.name.toUpperCase()), id: category.id };
      }
      return category;
    });

  const saveTaskCategory = (categoryObj: any) => {
    if (categories) {
      const category = categories.find((cat) => cat.id === categoryObj.id);
      if (category) {
        const changedObject = { projectCategoryId: category.id, category: category } as ITask;
        saveTaskCallback(changedObject);
      }
    }
  };

  return (
    <BhTagWithDropdown
      icon={faCaretDown}
      tagText={translateTaskCategory ? t("TASK.CATEGORY." + taskCategory?.name.toUpperCase()) : taskCategory?.name}
      values={dropdownCategories}
      textProperty={"name"}
      onSelect={saveTaskCategory}
      dropdownPosition={BhDropdownPositionEnum.BOTTOM_RIGHT}
      disabled={isTaskDisabled}
    />
  );
};

export default TaskModalCategorySelection;
