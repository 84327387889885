import React, { FC, ReactElement, useCallback, useState } from "react";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  value: any;
  textarea?: boolean;
  className?: string;
  children: ReactElement;
}

const BhFakeInput: FC<Props> = ({ value, textarea, className, children }) => {
  const [isActive, setIsActive] = useState(false);

  const onClick = useCallback(() => {
    setIsActive(true);
  }, []);

  if (!isActive) {
    return (
      <div
        onClick={onClick}
        className={classNames(
          className,
          textarea ? "mb-[4.5px] whitespace-pre-line" : "",
          "fake-input bh-border-pigeon-40 l-h-20 hover:bh-border-pigeon-70 disabled:bh-text-deep-ocean-40 disabled:bh-bg-smoke disabled:bh-border-smoke break-word py-7px min-h-[36px] w-full cursor-text overflow-hidden rounded border px-3"
        )}
      >
        {value}
      </div>
    );
  }
  return children;
};

export default React.memo(BhFakeInput);
