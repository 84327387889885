import React, { FC, useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import { EntityId } from "@reduxjs/toolkit";
import { Trans, useTranslation } from "react-i18next";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhDropdownButton from "@components/dropdown/BhDropdownButton";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import { useLocalStorage } from "@/utilities/hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";
import i18n from "i18next";
import TimeAgo from "javascript-time-ago";
import { setDefaultLocale } from "react-datepicker";
import et from "javascript-time-ago/locale/et.json";
import en from "javascript-time-ago/locale/en.json";
import ru from "javascript-time-ago/locale/ru.json";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import BhBauhubLogo from "@components/logo/BhBauhubLogo";

interface Props {
  companyId?: EntityId;
  withoutSignInButton?: boolean;
}

const PublicNavbar: FC<Props> = ({ companyId, withoutSignInButton }) => {
  const { t } = useTranslation();
  const [publicLanguage, setPublicLanguage] = useLocalStorage<string>("publicLanguage", "ET");
  const navigate = useNavigate();

  const linkMap: any = {
    ET: { homepage: "https://www.bauhub.ee/et", support: "https://www.bauhub.ee/tugi" },
    EN: { homepage: "https://www.bauhub.ee/en", support: "https://www.bauhub.ee/support" },
    RU: { homepage: "https://www.bauhub.ee/en", support: "https://www.bauhub.ee/support" }
  };

  useEffect(() => {
    const map: any = { ET: "et_EE", EN: "en_EN", RU: "ru_RU" };
    const language = map[publicLanguage];
    if (language && language.length > 0) {
      const lang = language.split("_")[0];
      if (lang !== i18n.language) {
        lang && i18n.changeLanguage(lang);
        language === "et_EE" && TimeAgo.addLocale(et);
        language === "en_EN" && TimeAgo.addLocale(en);
        language === "ru_RU" && TimeAgo.addLocale(ru);
        TimeAgo.setDefaultLocale(lang);
        setDefaultLocale(lang);
      }
    }
  }, [publicLanguage]);

  return (
    <Disclosure as="nav" className="bh-border-pigeon-20 z-30 flex h-14 flex-row border-b">
      <div className="flex px-8">
        <div className="flex flex-shrink-0 items-center">
          <a className="h-font" href={linkMap[publicLanguage]?.homepage} target="_blank" rel="noreferrer">
            <BhBauhubLogo />
          </a>
        </div>
      </div>
      <div className="flex items-center">
        <a className="h-font" href={linkMap[publicLanguage]?.support} target="_blank" rel="noreferrer">
          <Trans>PROJECT.NAVBAR.HELP</Trans>
        </a>
      </div>
      <div className="flex w-full items-center justify-end space-x-2 px-8">
        <BhDropdown
          button={<BhDropdownButton title="" value={publicLanguage} reversed={true} />}
          menu={<BhDropdownMenu values={["ET", "EN", "RU"]} type={BhDropdownTypeEnum.STRING} onSelect={(value: string) => setPublicLanguage(value)} />}
          position={BhDropdownPositionEnum.BOTTOM_LEFT}
        />
        {!withoutSignInButton && (
          <BhSecondaryButton buttonProps={{ onClick: () => navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/`) }}>
            <Trans>LOGIN.SIGNIN</Trans>
          </BhSecondaryButton>
        )}
      </div>
    </Disclosure>
  );
};

export default PublicNavbar;
