import React, { FC } from "react";
import BhFormInput from "@components/input/BhFormInput";
import BhCheckbox from "@components/checkboxes/BhCheckbox";
import { IFormDataSaveRequest } from "@/model/IForm";

interface Props {
  row: any;
  saveCallback: Function;
  disabled: boolean;
}

const BetoneerimiseProtokollWeatherTableRow: FC<Props> = ({ row, saveCallback, disabled }) => {
  const saveRow = (changedObject: any, changedProperty: any) => {
    const saveRequest = {
      path: "weather",
      changedProperty: changedProperty,
      changedValue: changedObject[changedProperty],
      changedObjectId: row._id
    } as IFormDataSaveRequest;
    saveCallback(saveRequest);
  };

  return (
    <tr>
      <td>
        <BhFormInput initialValue={row.time} property={"time"} saveCallback={(value: any) => saveRow(value, "time")} disabled={disabled} />
      </td>
      <td className="w-24">
        <BhFormInput initialValue={row.c} property={"c"} saveCallback={(value: any) => saveRow(value, "c")} disabled={disabled} />
      </td>
      <td className="w-24">
        <div className="flex flex-row justify-center">
          <BhCheckbox property={"dry"} isChecked={row.dry} onChange={(value: any) => saveRow(value, "dry")} large={true} disabled={disabled} />
        </div>
      </td>
      <td className="w-24">
        <div className="flex flex-row justify-center">
          <BhCheckbox property={"cloudy"} isChecked={row.cloudy} onChange={(value: any) => saveRow(value, "cloudy")} large={true} disabled={disabled} />
        </div>
      </td>
      <td className="w-24">
        <div className="flex flex-row justify-center">
          <BhCheckbox property={"rain"} isChecked={row.rain} onChange={(value: any) => saveRow(value, "rain")} large={true} disabled={disabled} />
        </div>
      </td>
      <td className="w-24">
        <div className="flex flex-row justify-center">
          <BhCheckbox property={"heavyRain"} isChecked={row.heavyRain} onChange={(value: any) => saveRow(value, "heavyRain")} large={true} disabled={disabled} />
        </div>
      </td>
      <td className="w-24">
        <div className="flex flex-row justify-center">
          <BhCheckbox property={"snow"} isChecked={row.snow} onChange={(value: any) => saveRow(value, "snow")} large={true} disabled={disabled} />
        </div>
      </td>
      <td>
        <BhFormInput initialValue={row.wind} property={"wind"} saveCallback={(value: any) => saveRow(value, "wind")} disabled={disabled} />
      </td>
    </tr>
  );
};

export default BetoneerimiseProtokollWeatherTableRow;
