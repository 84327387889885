import React, { FC, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  addNestedFormDataAsync,
  fetchFormDTOAsync,
  removeNestedFormDataAsync,
  saveFormDataAsync,
  saveFormNameAsync,
  saveNestedFormDataAsync,
  selectCanCurrentUserEditForm,
  selectCurrentFormFileEntityId,
  selectCurrentFormId,
  selectCurrentFormName
} from "@/app/store/formSlice";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import BhLightSeparator from "@components/separator/BhLightSeparator";
import BhSideBySideInput from "@components/input/BhSideBySideInput";
import BhInputSimple from "@components/input/BhInputSimple";
import { IFormDataSaveRequest } from "@/model/IForm";
import FormModals from "@/views/home/project/detail/form/modals/FormModals";
import FormContent from "@/views/home/project/detail/form/FormContent";
import { EntityId } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import FormContainerNewFillFormFromPrevious from "@/views/home/project/detail/form/FormContainerNewFillFormFromPrevious";

interface Props {
  fileEntityId: EntityId;
  dirId: EntityId;
}

const FormContainerNew: FC<Props> = ({ fileEntityId, dirId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectCurrentProjectId);
  const formId = useAppSelector(selectCurrentFormId);
  const formFileEntityId = useAppSelector(selectCurrentFormFileEntityId);
  const formName = useAppSelector(selectCurrentFormName);
  const formNameInputRef = useRef<HTMLInputElement>(null);
  const canCurrentUserEditForm = useAppSelector((state) => selectCanCurrentUserEditForm(state, projectId));

  useEffect(() => {
    dispatch(fetchFormDTOAsync({ dirId: dirId, fileEntityId: fileEntityId, projectId: projectId }));
  }, [fileEntityId]);

  useEffect(() => {
    if (formNameInputRef && formNameInputRef.current) {
      formNameInputRef.current.value = formName;
    }
  }, [formName]);

  const formDataSave = (changedObject: any, path?: string) => {
    const dataSaveRequest = { formId: formId, fileEntityId: fileEntityId, projectId: projectId, changes: changedObject, path: path } as IFormDataSaveRequest;
    return dispatch(saveFormDataAsync(dataSaveRequest));
  };

  const formNestedDataSave = (formDataSaveRequest: IFormDataSaveRequest) => {
    const dataSaveRequest = {
      formId: formId,
      fileEntityId: fileEntityId,
      projectId: projectId,
      path: formDataSaveRequest.path,
      changedProperty: formDataSaveRequest.changedProperty,
      changedValue: formDataSaveRequest.changedValue,
      changedObjectId: formDataSaveRequest.changedObjectId,
      changes: formDataSaveRequest.changes
    } as IFormDataSaveRequest;
    dispatch(saveNestedFormDataAsync(dataSaveRequest));
  };

  const formNestedDataAdd = (changedObject: any, path: string, index?: number) => {
    const dataSaveRequest = { formId: formId, fileEntityId: fileEntityId, projectId: projectId, changes: changedObject, path: path, index: index } as IFormDataSaveRequest;
    dispatch(addNestedFormDataAsync(dataSaveRequest));
  };

  const formNestedDataRemove = (changedObject: any, path: string) => {
    const dataSaveRequest = { formId: formId, fileEntityId: fileEntityId, projectId: projectId, changes: changedObject, path: path } as IFormDataSaveRequest;
    dispatch(removeNestedFormDataAsync(dataSaveRequest));
  };

  const saveFormName = (nameObj: any) => {
    dispatch(saveFormNameAsync({ projectId: projectId, formFileEntityId: formFileEntityId, formName: nameObj.name }));
  };

  if (!formId) return null;

  return (
    <>
      <div className="px-6 pb-24 pt-4">
        <BhSideBySideInput label={t("FILE.NAME")}>
          <div className="flex w-full flex-row gap-x-6">
            <div className="flex flex-1">
              <BhInputSimple initialValue={formName} property={"name"} saveCallback={saveFormName} inputRef={formNameInputRef} disabled={!canCurrentUserEditForm} />
            </div>
            {canCurrentUserEditForm && <FormContainerNewFillFormFromPrevious formId={formId} dirId={dirId} />}
          </div>
        </BhSideBySideInput>
        <div className="py-4">
          <BhLightSeparator />
        </div>
        <FormContent
          dataSaveCallback={formDataSave}
          nestedDataSaveCallback={formNestedDataSave}
          nestedDataAddCallback={formNestedDataAdd}
          nestedDataRemoveCallback={formNestedDataRemove}
          disabled={!canCurrentUserEditForm}
        />
      </div>
      <FormModals dirId={dirId} saveCallback={formDataSave} />
    </>
  );
};

export default FormContainerNew;
