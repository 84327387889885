import React from "react";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentProject } from "@/app/store/project/projectSlice";
import { selectCompanyById } from "@/app/store/companiesSlice";
import { BauhubPlan, ProjectStatus } from "@/model/IProject";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";

const ProjectSettingsGetProPlanBanner = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const project = useAppSelector(selectCurrentProject);
  const company = useAppSelector((state) => selectCompanyById(state, project.companyId));

  if (project.status === ProjectStatus.ARCHIVED || company?.plan === BauhubPlan.PRO) {
    return <></>;
  }
  return (
    <div className="bh-bg-deep-ocean h-22 my-4 flex w-full items-center rounded px-8 sm:hidden">
      <div className="bh-text-white flex w-full flex-row items-center justify-between">
        <div className="flex flex-row items-center">
          <span className="mr-12 whitespace-nowrap text-3xl font-extrabold">{t("PLANS.PRO")}</span>
          <div>
            <span className="sm:hidden" dangerouslySetInnerHTML={{ __html: t("PROJECT.SETTINGS.GET_PRO_BANNER_1") as any }} />
            <span className="bh-text-bauhub-green font-bold sm:hidden">Bauhub Pro</span>
            <span className="sm:hidden" dangerouslySetInnerHTML={{ __html: t("PROJECT.SETTINGS.GET_PRO_BANNER_2") as any }} />
          </div>
        </div>
        <div className="whitespace-nowrap pl-4">
          <BhSecondaryButton buttonProps={{ onClick: () => navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/company/${company?.id}/billing-plan`) }}>
            <span className="px-2">{t("SYNC.COMPARE_PLANS")}</span>
          </BhSecondaryButton>
        </div>
      </div>
    </div>
  );
};

export default ProjectSettingsGetProPlanBanner;
