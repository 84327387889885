import React, { FC, useCallback } from "react";
import { classNames } from "@/utilities/jsUtilities";

interface Props {
  isChecked: boolean;
  onChange: Function;
  disabled?: boolean;
  large?: boolean;
  property: string;
}

const BhCheckboxFC: FC<Props> = ({ isChecked, onChange, disabled, large, property }) => {
  const containerClasses = large ? "h-7 w-7 p-1.25" : "h-4.5 w-4.5 p-0.75";
  const inputClasses = isChecked
    ? "bh-border-bauhub-green-120 bh-bg-bauhub-green-120 disabled:bh-border-pigeon-60 disabled:bh-bg-pigeon-60"
    : "bh-border-pigeon-60 group-hover:bh-bg-mint-50-group disabled:bh-border-pigeon-40 disabled:bh-bg-smoke disabled:cursor-default";

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const changedValue = { [property]: e.target.checked };
      onChange(changedValue);
    },
    [onChange]
  );

  return (
    <div className={classNames(containerClasses, "group flex items-center rounded", !disabled && "hover:bh-bg-mint-50")}>
      <input
        className={classNames(inputClasses, "hover:bh-border-deep-ocean h-full w-full rounded focus:ring-0 focus:ring-offset-0")}
        type="checkbox"
        checked={isChecked !== undefined && isChecked}
        onChange={(e) => handleChange(e)}
        disabled={disabled}
      />
      <div className="bh-border-bauhub-green"></div>
    </div>
  );
};

const BhCheckbox = React.memo(BhCheckboxFC);
export default BhCheckbox;
