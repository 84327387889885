import { EntityId } from "@reduxjs/toolkit";
import React, { FC, useState } from "react";
import CompanySettingsChecklistTable from "@components/checklists/settings/companySettings/CompanySettingsChecklistTable";
import { useTranslation } from "react-i18next";
import BhIconButton from "@components/buttons/BhIconButton";
import { faAngleDown } from "@fortawesome/pro-solid-svg-icons/faAngleDown";
import CompanyChecklistBasesHeader from "@components/checklists/settings/companySettings/CompanyChecklistBasesHeader";
import { faAngleRight } from "@fortawesome/pro-regular-svg-icons/faAngleRight";

interface Props {
  companyId: EntityId;
}

const CompanySettingsChecklistBasesTab: FC<Props> = ({ companyId }) => {
  const { t } = useTranslation();
  const [companyBasesFilter, setCompanyBasesFilter] = useState({ searchString: "", username: "", orderBy: "type", reversed: false, category: null });
  const [projectBasesFilter, setProjectBasesFilter] = useState({ searchString: "", username: "", projectId: null, orderBy: "type", reversed: false, category: null });
  const [companyChecklistTableVisible, setCompanyChecklistTableVisible] = useState(true);
  const [projectChecklistTableVisible, setProjectChecklistTableVisible] = useState(true);

  return (
    <div className="flex flex-col px-16 pt-6 lg:px-24 md:px-8">
      <div>
        <div className="flex flex-row items-center gap-x-1">
          <BhIconButton icon={companyChecklistTableVisible ? faAngleDown : faAngleRight} buttonProps={{ onClick: () => setCompanyChecklistTableVisible(!companyChecklistTableVisible) }} />
          <h2 className="m-0">{t("COMPANY.SETTINGS.COMPANY_CHECKLIST_BASES")}</h2>
        </div>
        {companyChecklistTableVisible && (
          <div className="mx-auto flex h-full w-full flex-col">
            <CompanyChecklistBasesHeader filter={companyBasesFilter} setFilter={setCompanyBasesFilter} companyId={companyId} isProjectChecklistTableHeader={false} />
            <CompanySettingsChecklistTable filter={companyBasesFilter} setFilter={setCompanyBasesFilter} companyId={companyId} isProjectChecklistTable={false} />
          </div>
        )}
      </div>
      <div className="mb-32 mt-10">
        <div className="flex flex-row items-center gap-x-1">
          <BhIconButton icon={projectChecklistTableVisible ? faAngleDown : faAngleRight} buttonProps={{ onClick: () => setProjectChecklistTableVisible(!projectChecklistTableVisible) }} />
          <h2 className="m-0">{t("COMPANY.SETTINGS.PROJECT_CHECKLIST_BASES")}</h2>
        </div>
        <div className="bh-text-deep-ocean-80 my-3 w-3/5 text-justify" dangerouslySetInnerHTML={{ __html: t("COMPANY.SETTINGS.PROJECT_CHECKLIST_BASES.TEXT") as any }} />
        {projectChecklistTableVisible && (
          <div className="mx-auto flex h-full w-full flex-col">
            <CompanyChecklistBasesHeader filter={projectBasesFilter} setFilter={setProjectBasesFilter} companyId={companyId} isProjectChecklistTableHeader={true} />
            <CompanySettingsChecklistTable filter={projectBasesFilter} setFilter={setProjectBasesFilter} companyId={companyId} isProjectChecklistTable={true} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanySettingsChecklistBasesTab;
