import { createAsyncThunk, createSelector, createSlice, EntityId, PayloadAction } from "@reduxjs/toolkit";
import { fetchSearchItems } from "@/api/searchAPI";
import { RootState } from "@/app/store";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import { filterTasks, selectProjectTaskCategories, selectProjectTaskTags, sortTasks, taskCategoriesUpdatedAndAdded, tasksUpdatedAndAdded, taskTagsUpdatedAndAdded } from "@/app/store/tasksSlice";
import { IFilesFilter } from "@/model/files/IFilesFilter";
import { IContactsCompanyDropdownFilter, IContactsCompanyFilter, IContactsFilter } from "@/model/contacts/IContactsFilter";
import { IContactsSort } from "@/model/contacts/IContactsSort";
import { ITasksFilter, ITaskUserRoleFilter } from "@/model/taskBoard/ITasksFilter";
import { initialTasksSort, ITasksSort } from "@/model/taskBoard/ITasksSort";
import { IShareboxesFilter, IShareboxReceiversFilter } from "@/model/shareboxes/IShareboxesFilter";
import { filesUpdatedAndAdded } from "@/app/store/filesSlice";
import { IFileExtensionFilter } from "@/model/files/IFileExtensionFilter";
import { IFileEntityTag } from "@/model/files/IFileEntityTag";
import { FileEntityType, IFileEntity } from "@/model/files/IFileEntity";
import { contactsUpdatedAndAdded } from "@/app/store/project/projectContactsSlice";
import { IContact } from "@/model/IContact";
import { getKeyValue, getUniqueValues, removeDuplicates } from "@/utilities/jsUtilities";
import { IWorkGroup, WorkGroupType } from "@/model/IWorkGroup";
import { IProjectTaskCategory } from "@/model/taskBoard/IProjectTaskCategory";
import { ITaskTag } from "@/model/taskBoard/ITaskTag";
import { IUserAuthority } from "@/model/IUserAuthority";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { shareboxesUpdatedAndAdded } from "@/app/store/shareboxesSlice";
import { IShareboxesSort } from "@/model/shareboxes/IShareboxesSort";
import { ITask } from "@/model/taskBoard/ITask";
import { ITaskBoard } from "@/model/taskBoard/ITaskBoard";
import { ISharebox } from "@/model/shareboxes/ISharebox";
import { naturalSortByField, naturalSortFilesByField } from "@/utilities/sortUtilities";

export interface SearchState {
  status: BhStateStatusType;
  fileResults: Array<EntityId>;
  fileTagResults: Array<IFileEntityTag>;
  shareboxResults: Array<EntityId>;
  taskResults: Array<EntityId>;
  userResults: Array<EntityId>;
  searchString: string;
  filesFilter: IFilesFilter;
  usersFilterAndSort: {
    usersFilter: IContactsFilter;
    usersSort: IContactsSort;
  };
  tasksFilterAndSort: {
    tasksFilter: ITasksFilter;
    tasksSort: ITasksSort;
  };
  shareboxesFilterAndSort: {
    shareboxesFilter: IShareboxesFilter;
    shareboxesSort: IShareboxesSort;
  };
  isSearchInputFocused: boolean;
}

const initialState: SearchState = {
  status: BhStateStatusType.INITIAL,
  fileResults: [],
  fileTagResults: [],
  shareboxResults: [],
  taskResults: [],
  userResults: [],
  searchString: "",
  filesFilter: {
    extensions: [
      { id: 1, name: "pdf" },
      { id: 2, name: "dwg" }
    ],
    tags: [] as Array<IFileEntityTag>
  } as IFilesFilter,
  usersFilterAndSort: {
    usersFilter: {
      company: { selectedCompaniesInDropdown: [] as Array<IContactsCompanyFilter>, companySearchInput: "" },
      workGroup: { workGroupSearchInput: "", selectedWorkGroupIds: [] as Array<EntityId> },
      workGroupType: [] as Array<WorkGroupType>
    } as IContactsFilter,
    usersSort: { property: "firstName", reversed: false }
  },
  tasksFilterAndSort: {
    tasksFilter: {
      categories: [] as Array<IProjectTaskCategory>,
      tags: [] as Array<ITaskTag>,
      users: [] as Array<IUserAuthority>,
      userRoles: [] as Array<ITaskUserRoleFilter>
    } as ITasksFilter,
    tasksSort: initialTasksSort
  },
  shareboxesFilterAndSort: {
    shareboxesFilter: {
      shareboxReceivers: {
        receiversSearchKey: "",
        receiversDropdown: [] as Array<string>
      }
    } as IShareboxesFilter,
    shareboxesSort: { property: "name", reversed: false }
  },
  isSearchInputFocused: false
};

export const fetchSearchForKeywordAsync = createAsyncThunk("searchForKeyword", async (dto: { projectId: EntityId; query: string }, { dispatch }) => {
  const fetchResults = await fetchSearchItems(dto.projectId, dto.query);
  const fileIds = fetchResults.fileResults?.map((f) => f.id) || [];
  const userIds = fetchResults.userResults?.map((u) => u.id) || [];
  const taskIds = fetchResults.taskResults?.map((t) => t.id) || [];
  const taskCategories = fetchResults.taskResults?.map((t) => t.category) || [];
  const taskTags = fetchResults.taskResults?.flatMap((t) => t.tags) || [];
  const shareboxIds = fetchResults.shareboxResults?.map((s) => s.id) || [];
  dispatch(searchResultFileIdsAdded(fileIds));
  dispatch(filesUpdatedAndAdded(fetchResults.fileResults));
  dispatch(searchResultFileTagsAdded(fetchResults.fileResults || []));
  dispatch(searchResultUserIdsAdded(userIds));
  dispatch(contactsUpdatedAndAdded(fetchResults.userResults));
  dispatch(searchResultTaskIdsAdded(taskIds));
  dispatch(tasksUpdatedAndAdded(fetchResults.taskResults));
  dispatch(taskCategoriesUpdatedAndAdded(taskCategories));
  dispatch(taskTagsUpdatedAndAdded(taskTags));
  dispatch(searchResultShareboxIdsAdded(shareboxIds));
  dispatch(shareboxesUpdatedAndAdded(fetchResults.shareboxResults));
  return fetchResults;
});

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    resetSearchState: () => initialState,
    searchStringSet: (state, action: PayloadAction<string>) => {
      state.searchString = action.payload;
    },
    searchStateCleared: (state) => {
      return { ...initialState, isSearchInputFocused: state.isSearchInputFocused };
    },
    searchResultFileIdsAdded: (state, action: PayloadAction<Array<EntityId>>) => {
      state.fileResults = action.payload;
    },
    searchResultFileTagsAdded: (state, action: PayloadAction<Array<IFileEntity>>) => {
      state.fileTagResults = action.payload.flatMap((file) => {
        return file.tags;
      });
    },
    searchResultUserIdsAdded: (state, action: PayloadAction<Array<EntityId>>) => {
      state.userResults = action.payload;
    },
    searchResultTaskIdsAdded: (state, action: PayloadAction<Array<EntityId>>) => {
      state.taskResults = action.payload;
    },
    searchResultShareboxIdsAdded: (state, action: PayloadAction<Array<EntityId>>) => {
      state.shareboxResults = action.payload;
    },
    fileExtensionToggledInSearch: (state, action: PayloadAction<IFileExtensionFilter>) => {
      const extensionFilter = state.filesFilter.extensions.find((ext) => ext.id === action.payload.id);
      if (extensionFilter) extensionFilter.selected = !extensionFilter.selected;
    },
    fileExtensionFilterClearedInSearch: (state) => {
      state.filesFilter.extensions.forEach((ext) => (ext.selected = false));
    },
    fileTagToggledInSearch: (state, action: PayloadAction<IFileEntityTag>) => {
      const alreadySelected = state.filesFilter.tags.some((tag) => tag.text === action.payload.text);
      if (alreadySelected) state.filesFilter.tags.filter((t) => t.text !== action.payload.text);
      if (!alreadySelected) state.filesFilter.tags = [...state.filesFilter.tags, action.payload];
    },
    fileTagFilterClearedInSearch: (state) => {
      state.filesFilter.tags = [];
    },
    usersSortBySetInSearch: (state, action: PayloadAction<keyof IContact>) => {
      state.usersFilterAndSort.usersSort.property = action.payload;
    },
    usersSortToggledInSearch: (state) => {
      state.usersFilterAndSort.usersSort.reversed = !state.usersFilterAndSort.usersSort.reversed;
    },
    userCompanyFilterToggledInSearch: (state, action: PayloadAction<IContactsCompanyFilter>) => {
      const companyAlreadySelected = state.usersFilterAndSort.usersFilter.company.selectedCompaniesInDropdown.some((company) => company.companyName === action.payload.companyName);
      if (companyAlreadySelected) {
        state.usersFilterAndSort.usersFilter.company.selectedCompaniesInDropdown = state.usersFilterAndSort.usersFilter.company.selectedCompaniesInDropdown.filter(
          (company) => company.companyName !== action.payload.companyName
        );
      } else {
        state.usersFilterAndSort.usersFilter.company.selectedCompaniesInDropdown = [...state.usersFilterAndSort.usersFilter.company.selectedCompaniesInDropdown, action.payload];
      }
    },
    companyFilterSearchStringSetInSearch: (state, action: PayloadAction<IContactsCompanyDropdownFilter>) => {
      state.usersFilterAndSort.usersFilter.company.companySearchInput = action.payload.companySearchInput;
    },
    companyClearedInUsersFilterInSearch: (state) => {
      state.usersFilterAndSort.usersFilter.company.selectedCompaniesInDropdown = [];
      state.usersFilterAndSort.usersFilter.company.companySearchInput = "";
    },
    tasksSortBySetInSearch: (state, action: PayloadAction<ITasksSort>) => {
      state.tasksFilterAndSort.tasksSort = action.payload;
    },
    taskCategoryFilterToggledInSearch: (state, action: PayloadAction<IProjectTaskCategory>) => {
      const categoryAlreadySelected = state.tasksFilterAndSort.tasksFilter.categories.some((c) => c.name === action.payload.name);
      if (categoryAlreadySelected) {
        state.tasksFilterAndSort.tasksFilter.categories = state.tasksFilterAndSort.tasksFilter.categories.filter((category) => category.name !== action.payload.name);
      } else {
        state.tasksFilterAndSort.tasksFilter.categories = [...state.tasksFilterAndSort.tasksFilter.categories, action.payload];
      }
    },
    taskCategoryFilterClearedInSearch: (state) => {
      state.tasksFilterAndSort.tasksFilter.categories = [];
    },
    taskFilterInSearchSet: (state, action: PayloadAction<ITasksFilter>) => {
      const prevState = state.tasksFilterAndSort.tasksFilter;
      state.tasksFilterAndSort.tasksFilter = { ...prevState, ...action.payload };
    },
    taskTagFilterToggledInSearch: (state, action: PayloadAction<ITaskTag>) => {
      const tagAlreadySelected = state.tasksFilterAndSort.tasksFilter.tags.some((t) => t.name === action.payload.name);
      if (tagAlreadySelected) {
        state.tasksFilterAndSort.tasksFilter.tags = state.tasksFilterAndSort.tasksFilter.tags.filter((t) => t.name !== action.payload.name);
      } else {
        state.tasksFilterAndSort.tasksFilter.tags = [...state.tasksFilterAndSort.tasksFilter.tags, action.payload];
      }
    },
    taskTagFilterClearedInSearch: (state) => {
      state.tasksFilterAndSort.tasksFilter.tags = [];
      state.tasksFilterAndSort.tasksFilter.tagSearch = "";
    },
    taskUserRoleFilterToggledInSearch: (state, action: PayloadAction<ITaskUserRoleFilter>) => {
      const roleAlreadySelected = state.tasksFilterAndSort.tasksFilter.userRoles.some((r) => r.name === action.payload.name);
      if (roleAlreadySelected) {
        state.tasksFilterAndSort.tasksFilter.userRoles = state.tasksFilterAndSort.tasksFilter.userRoles.filter((r) => r.name !== action.payload.name);
      } else {
        state.tasksFilterAndSort.tasksFilter.userRoles.push(action.payload);
      }
    },
    taskUserRoleFilterClearedInSearch: (state) => {
      state.tasksFilterAndSort.tasksFilter.userRoles = [];
    },
    taskUserFilterToggledInSearch: (state, action: PayloadAction<IUserAuthority>) => {
      const userAlreadyFiltered = state.tasksFilterAndSort.tasksFilter.users.some((u) => u.userEntityId === action.payload.userEntityId);
      if (userAlreadyFiltered) {
        state.tasksFilterAndSort.tasksFilter.users = state.tasksFilterAndSort.tasksFilter.users.filter((u) => u.userEntityId !== action.payload.userEntityId);
      } else {
        state.tasksFilterAndSort.tasksFilter.users.push(action.payload);
      }
    },
    taskUserFilterClearedInSearch: (state) => {
      state.tasksFilterAndSort.tasksFilter.users = [];
    },
    shareboxSortBySetInSearch: (state, action: PayloadAction<IShareboxesSort>) => {
      if (state.shareboxesFilterAndSort.shareboxesSort.property === action.payload.property) {
        state.shareboxesFilterAndSort.shareboxesSort.reversed = !state.shareboxesFilterAndSort.shareboxesSort.reversed;
      } else {
        state.shareboxesFilterAndSort.shareboxesSort = action.payload;
      }
    },
    shareboxesReceiversFilterChangedInSearch: (state, action: PayloadAction<string>) => {
      state.shareboxesFilterAndSort.shareboxesFilter.shareboxReceivers.receiversSearchKey = action.payload.toLowerCase();
    },
    shareboxReceiversFilterToggledInSearch: (state, action: PayloadAction<string>) => {
      const receiversDropdown = state.shareboxesFilterAndSort.shareboxesFilter.shareboxReceivers.receiversDropdown;
      const receiverAlreadyFiltered = receiversDropdown.some((receiver) => receiver === action.payload);
      if (receiverAlreadyFiltered) {
        state.shareboxesFilterAndSort.shareboxesFilter.shareboxReceivers.receiversDropdown = receiversDropdown.filter((r) => r !== action.payload);
      } else {
        state.shareboxesFilterAndSort.shareboxesFilter.shareboxReceivers.receiversDropdown = [...receiversDropdown, action.payload];
      }
    },
    shareboxesReceiversFilterClearedInSearch: (state) => {
      state.shareboxesFilterAndSort.shareboxesFilter.shareboxReceivers = {
        receiversSearchKey: "",
        receiversDropdown: []
      } as IShareboxReceiversFilter;
    },
    shareboxCreatedFilterSetInSearch: (state, action: PayloadAction<IShareboxesFilter>) => {
      state.shareboxesFilterAndSort.shareboxesFilter = { ...state.shareboxesFilterAndSort.shareboxesFilter, ...action.payload };
    },
    searchInputFocusSet: (state, action) => {
      state.isSearchInputFocused = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSearchForKeywordAsync.pending, (state) => {
        state.status = BhStateStatusType.PENDING;
      })
      .addCase(fetchSearchForKeywordAsync.fulfilled, (state) => {
        state.status = BhStateStatusType.SUCCESS;
      });
  }
});

export const {
  resetSearchState,
  searchStringSet,
  searchStateCleared,
  searchResultFileIdsAdded,
  searchResultFileTagsAdded,
  searchResultUserIdsAdded,
  searchResultTaskIdsAdded,
  searchResultShareboxIdsAdded,
  fileExtensionToggledInSearch,
  fileTagToggledInSearch,
  fileTagFilterClearedInSearch,
  fileExtensionFilterClearedInSearch,
  usersSortBySetInSearch,
  usersSortToggledInSearch,
  companyFilterSearchStringSetInSearch,
  userCompanyFilterToggledInSearch,
  companyClearedInUsersFilterInSearch,
  tasksSortBySetInSearch,
  taskCategoryFilterToggledInSearch,
  taskCategoryFilterClearedInSearch,
  taskFilterInSearchSet,
  taskTagFilterToggledInSearch,
  taskTagFilterClearedInSearch,
  taskUserRoleFilterToggledInSearch,
  taskUserRoleFilterClearedInSearch,
  taskUserFilterToggledInSearch,
  taskUserFilterClearedInSearch,
  shareboxSortBySetInSearch,
  shareboxesReceiversFilterChangedInSearch,
  shareboxReceiversFilterToggledInSearch,
  shareboxesReceiversFilterClearedInSearch,
  shareboxCreatedFilterSetInSearch,
  searchInputFocusSet
} = searchSlice.actions;

export const selectSearchString = (state: RootState) => state.search.searchString || "";
export const selectSearchStateStatus = (state: RootState) => state.search.status;

//-----FILES----
export const selectFileEntityIdsInSearch = (state: RootState) => state.search.fileResults;
export const selectFileTagsInSearch = (state: RootState) => state.search.fileTagResults;
export const selectFilesFilterInSearch = (state: RootState) => state.search.filesFilter;
export const selectFilesSortInSearch = (state: RootState) => state.files.sort;

export const selectHasAnyFileResultsInSearch = (state: RootState) => state.search?.fileResults?.length > 0;
export const selectFileResultsCountInSearch = createSelector(
  (state: RootState) => state.search?.fileResults,
  (fileResults) => {
    return fileResults?.length || 0;
  }
);

export const selectAllFileEntitiesForSearch = (state: RootState) => Object.values(state.files.entities) as Array<IFileEntity>;

export const selectFilteredAndSortedFileEntityIdsInSearch = createSelector(
  [selectAllFileEntitiesForSearch, selectFileEntityIdsInSearch, selectFilesFilterInSearch, selectFilesSortInSearch],
  (files, searchResultIds, filesFilter, sort) => {
    const searchResultFiles = files.filter((fileEntity) => searchResultIds.includes(fileEntity.id));
    return searchResultFiles
      .filter((fileEntity) => {
        const tagsFiltersSelected = filesFilter.tags;
        const tagsFilter = tagsFiltersSelected.length > 0 ? fileEntity.tags.some((tag) => tagsFiltersSelected.flatMap((t) => t.text).includes(tag.text)) : true;
        const extensionsFiltersSelected = filesFilter.extensions.filter((ext) => ext.selected);
        const extensionsFilter =
          extensionsFiltersSelected.length > 0 ? extensionsFiltersSelected.filter((e) => fileEntity.type === FileEntityType.FILE && e.name === fileEntity.name.split(".").pop()).length > 0 : true;
        return tagsFilter && extensionsFilter;
      })
      .sort((a, b) => naturalSortFilesByField(a, b, sort.property, sort.reversed))
      .sort((a, b) => (a.type === FileEntityType.DIR && b.type === FileEntityType.DIR ? 0 : a.type === FileEntityType.DIR ? -1 : 1))
      .map((f) => f.id);
  }
);
export const selectAllFilesSelectedInSearch = createSelector([selectAllFileEntitiesForSearch, selectFilteredAndSortedFileEntityIdsInSearch], (files, searchResultIds) => {
  const searchResultFiles = files.filter((fileEntity) => searchResultIds.includes(fileEntity.id));
  return searchResultFiles.every((fileEntity) => fileEntity.selected);
});

export const selectUniqueFileTagsInSearch = createSelector([selectFileTagsInSearch], (tags) => {
  const tagTexts = tags.map((tag) => tag.text);
  return removeDuplicates(tagTexts).map((tagText) => {
    return { text: tagText };
  });
});

//-----USERS-----
export const selectUserIdsInSearch = (state: RootState) => state.search.userResults;
export const selectUsersFiltersAndSortInSearch = (state: RootState) => state.search.usersFilterAndSort;
export const selectUsersFilterInSearch = (state: RootState) => state.search.usersFilterAndSort.usersFilter || ({} as IContactsFilter);
export const selectUsersSortInSearch = (state: RootState) => state.search.usersFilterAndSort.usersSort || {};
export const selectHasAnyUserResultsInSearch = (state: RootState) => state.search?.userResults?.length > 0;
export const selectUserResultsCountInSearch = (state: RootState) => state.search.userResults.length || 0;

export const selectAllProjectContactsForSearch = (state: RootState) => Object.values(state.project.contacts.entities) as Array<IContact>;
export const selectAllProjectWorkGroupsForSearch = (state: RootState) => Object.values(state.project.workGroups.entities) as Array<IWorkGroup>;

export const selectUsersInSearch = createSelector([selectAllProjectContactsForSearch, selectUserIdsInSearch], (users, searchResultIds) => {
  return users.filter((user) => searchResultIds.includes(user.id));
});

export const selectFilteredAndSortedUsersInSearch = createSelector([selectUsersInSearch, selectUsersFiltersAndSortInSearch], (users, filterAndSort) => {
  const { usersFilter, usersSort } = filterAndSort;
  const { property, reversed } = usersSort;
  const sorted = users.sort((a, b) => {
    let aValue = getKeyValue<keyof IContact, IContact>(property)(a) || "";
    let bValue = getKeyValue<keyof IContact, IContact>(property)(b) || "";
    if (typeof aValue === "string" && typeof bValue === "string") {
      return aValue.toLowerCase() < bValue.toLowerCase() ? -1 : 1;
    }
    return aValue < bValue ? -1 : 1;
  });
  if (reversed) sorted.reverse();
  return sorted.filter((user) => {
    const companiesFilterSelected = usersFilter.company.selectedCompaniesInDropdown.map((comp) => comp.companyName);
    return companiesFilterSelected.length === 0
      ? user
      : companiesFilterSelected.some((company) => {
          const companyUndefined = user.company === undefined;
          const companyEmptyString = user.company === "";
          return companyUndefined ? company === null : companyEmptyString ? company === null : user.company === company;
        });
  });
});

export const selectContactsFilterCompanyDropdownValuesInSearch = createSelector([selectUsersInSearch, selectUsersFilterInSearch], (users, filter) => {
  const companies = users.map((user) => {
    const companyUndefined = user.company === undefined;
    const companyEmptyString = user.company === "";
    return companyUndefined ? null : companyEmptyString ? null : user.company;
  });
  const uniqueCompanyNames = removeDuplicates(companies).map((name) => {
    return { companyName: name };
  });
  const companyDropdownFilterValue = filter.company.companySearchInput || "";
  if (companyDropdownFilterValue.length > 0) {
    return uniqueCompanyNames.filter((company) => {
      const nameFilter = company.companyName?.toLowerCase().includes(companyDropdownFilterValue.toLowerCase());
      return nameFilter;
    });
  }
  return uniqueCompanyNames.sort((a, b) => naturalSortByField(a, b, "companyName", false));
});

//-----TASKS-----
export const selectTaskIdsInSearch = (state: RootState) => state.search.taskResults;
export const selectTasksFiltersAndSortInSearch = (state: RootState) => state.search.tasksFilterAndSort;
export const selectTasksFilterInSearch = (state: RootState) => state.search.tasksFilterAndSort.tasksFilter;
export const selectTasksSortInSearch = (state: RootState) => state.search.tasksFilterAndSort.tasksSort;
export const selectHasAnyTaskResultsInSearch = (state: RootState) => state.search.taskResults.length > 0;
export const selectTaskResultsCountInSearch = (state: RootState) => state.search.taskResults.length || 0;

export const selectAllTasksForSearch = (state: RootState) => Object.values(state.tasks.entities) as Array<ITask>;
export const selectAllTaskBoardsForSearch = (state: RootState) => Object.values(state.taskBoards.entities) as Array<ITaskBoard>;

export const selectTasksInSearch = createSelector([selectTaskIdsInSearch, selectAllTasksForSearch], (searchResultIds, tasks) => {
  return tasks.filter((task) => searchResultIds.includes(task.id));
});

export const selectFilteredAndSortedTaskIdsInSearch = createSelector([selectTasksInSearch, selectTasksFiltersAndSortInSearch], (tasks, filterAndSort) => {
  const { tasksFilter, tasksSort } = filterAndSort;
  const filtered = filterTasks(tasks, tasksFilter, undefined);
  const sorted = sortTasks(filtered, tasksSort);
  return sorted.map((task) => task.id);
});

export const selectTaskCategoriesInSearch = createSelector([selectProjectTaskCategories, selectTasksInSearch], (projectTaskCategories, tasks) => {
  const categoryIdsInFilter = tasks.map((task) => task.category.id);
  const uniqueIds = removeDuplicates(categoryIdsInFilter);
  return projectTaskCategories?.filter((cat) => uniqueIds.includes(cat.id)) || [];
});

export const selectTaskTagsInSearch = createSelector([selectProjectTaskTags, selectTasksInSearch], (tags, tasks) => {
  const tagsInSearch = tasks.flatMap((task) => task.tags);
  const uniqueIds = removeDuplicates(tagsInSearch.map((t) => t.id));
  return tags?.filter((tag) => uniqueIds.includes(tag.id)) || [];
});

export const selectFilteredTaskTagsInSearch = createSelector([selectTaskTagsInSearch, selectTasksFilterInSearch], (tags, filter) => {
  return tags.filter((tag) => tag.name.toLowerCase().includes(filter.tagSearch?.toLowerCase() || ""));
});

export const selectTaskUsersInSearch = createSelector([selectAllTaskBoardsForSearch, selectTasksInSearch], (taskBoards, tasks) => {
  let userIds: Array<EntityId> = [];
  tasks.forEach((task) => {
    task.assignee && userIds.push(task.assignee);
    task.reporter && userIds.push(task.reporter);
    task.participants.length > 0 && task.participants.forEach((p) => userIds.push(p.id));
  });
  let users: Array<ISimpleValidUserAuthority> = [];
  taskBoards.forEach((tb) => {
    users = [...users, ...tb.users];
  });
  return getUniqueValues(users, "userEntityId");
});

//-----SHAREBOXES-----
export const selectHasAnyShareboxResultsInSearch = (state: RootState) => state.search?.shareboxResults?.length > 0;
export const selectShareboxIdsInSearch = (state: RootState) => state.search.shareboxResults || [];
export const selectShareboxResultsCountInSearch = (state: RootState) => state.search.shareboxResults.length || 0;
export const selectShareboxesFilterAndSortInSearch = (state: RootState) => state.search.shareboxesFilterAndSort;
export const selectShareboxesFilterInSearch = (state: RootState) => state.search.shareboxesFilterAndSort.shareboxesFilter;

export const selectAllShareboxesForSearch = (state: RootState) => Object.values(state.shareboxes.entities) as Array<ISharebox>;

const selectShareboxesInSearch = createSelector([selectAllShareboxesForSearch, selectShareboxIdsInSearch], (shareboxes, searchResultIds) => {
  return shareboxes.filter((sharebox) => searchResultIds.includes(sharebox.id));
});

export const selectFilteredAndSortedShareboxIdsInSearch = createSelector([selectShareboxesInSearch, selectShareboxesFilterAndSortInSearch], (shareboxes, filterAndSort) => {
  const { shareboxesFilter, shareboxesSort } = filterAndSort;
  const usersSelectedInFilter = shareboxesFilter.shareboxReceivers.receiversDropdown;
  const createdFromRangeInFilter = shareboxesFilter.createdFrom;
  const createdToRangeInFilter = shareboxesFilter.createdTo;
  return shareboxes
    .filter((sharebox) => {
      const usersFilter =
        usersSelectedInFilter.length > 0
          ? sharebox.receiverEmails?.some((receiver) => receiver && usersSelectedInFilter.length > 0 && usersSelectedInFilter.some((value) => value === receiver))
          : true;
      const createdFromFilter = createdFromRangeInFilter ? sharebox.created >= createdFromRangeInFilter : true;
      const createdToFilter = createdToRangeInFilter ? sharebox.created <= createdToRangeInFilter : true;
      return usersFilter && createdFromFilter && createdToFilter;
    })
    .sort((a, b) => naturalSortByField(a, b, shareboxesSort.property, shareboxesSort.reversed))
    .map((sharebox) => sharebox.id);
});

export const selectShareboxesReceiversFilterValuesInSearch = createSelector([selectShareboxesInSearch, selectShareboxesFilterInSearch], (shareboxes, filter) => {
  const dropdownValues = shareboxes.flatMap((sharebox) => sharebox.receiverEmails);
  const uniqueValues = Array.from(new Set(dropdownValues));
  return uniqueValues.filter((v) => v?.includes(filter.shareboxReceivers.receiversSearchKey));
});

//-----UTILITIES-----

export const selectFirstTabWithResults = createSelector(
  [selectHasAnyFileResultsInSearch, selectHasAnyUserResultsInSearch, selectHasAnyTaskResultsInSearch, selectHasAnyShareboxResultsInSearch],
  (hasFiles, hasUsers, hasTasks, hasShareboxes) => {
    if (hasFiles) {
      return "files";
    } else if (hasUsers) {
      return "users";
    } else if (hasTasks) {
      return "tasks";
    } else if (hasShareboxes) {
      return "shareboxes";
    } else {
      return null;
    }
  }
);

export const selectIsSearchInputFocused = (state: RootState) => state.search.isSearchInputFocused;

export default searchSlice.reducer;
