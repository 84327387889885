import React, { FC } from "react";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import { faXmark } from "@fortawesome/pro-solid-svg-icons/faXmark";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { classNames } from "@/utilities/jsUtilities";
import { useTranslation } from "react-i18next";

interface Props {
  isProPlan: boolean;
  disablePlanChange: boolean;
}
const PlanFeaturesList: FC<Props> = ({ isProPlan, disablePlanChange }) => {
  const { t } = useTranslation();

  const planFeaturesInfoList: Array<{ descriptionKey: string; icon: IconDefinition }> = [
    { descriptionKey: isProPlan ? "PLANS.PRICING.TABLE.PRO.UNLIMITED_CHECKLISTS" : "PLANS.PRICING.TABLE.LIGHT.LIMITED_CHECKLISTS", icon: faCheck },
    { descriptionKey: isProPlan ? "PLANS.PRICING.TABLE.PRO.ARCHIVING" : "PLANS.PRICING.TABLE.LIGHT.ARCHIVING", icon: faCheck },
    { descriptionKey: "PLANS.PRICING.TABLE.PRO.EMAIL_SUPPORT", icon: faCheck },
    { descriptionKey: isProPlan ? "PLANS.PRICING.TABLE.PRO.PHONE_SUPPORT" : "PLANS.PRICING.TABLE.LIGHT.PHONE_SUPPORT", icon: isProPlan ? faCheck : faXmark },
    { descriptionKey: "PLANS.PRICING.TABLE.PRO.BAUDRIVE", icon: isProPlan ? faCheck : faXmark },
    { descriptionKey: "PLANS.PRICING.TABLE.PRO.PRESETS", icon: isProPlan ? faCheck : faXmark },
    { descriptionKey: "PLANS.PRICING.TABLE.PRO.CUSTOM_DOCUMENTS", icon: isProPlan ? faCheck : faXmark },
    { descriptionKey: "PLANS.PRICING.TABLE.PRO.INTEGRATIONS", icon: isProPlan ? faCheck : faXmark }
  ];

  return (
    <div>
      {planFeaturesInfoList.map((row) => (
        
        <div key={row.descriptionKey} className={classNames(disablePlanChange && "bh-text-deep-ocean-40", "l-h-28px py-2")}>
          <FontAwesomeIcon icon={row.icon} className={classNames(!disablePlanChange && row.icon === faCheck ? "bh-text-bauhub-green-120" : "bh-text-pigeon-50", "mr-2 w-3")} />
          <span dangerouslySetInnerHTML={{ __html: t(row.descriptionKey) as any }} />
        </div>
      ))}
    </div>
  );
};

export default PlanFeaturesList;
