import React, { FC } from "react";
import BhSideBySideInput from "@components/input/BhSideBySideInput";
import BetoneerimiseProtokollWeatherTableRow from "@components/form/fields/customFields/BetoneerimiseProtokollWeatherTableRow";
import { useAppSelector } from "@/app/hooks";
import { selectValueByPropertyFromCurrentFormData } from "@/app/store/formSlice";

interface Props {
  saveCallback: Function;
  disabled: boolean;
}

const FormBetoneerimiseProtokollWeatherTableField: FC<Props> = ({ saveCallback, disabled }) => {
  const weatherData = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, "weather"));

  return (
    <BhSideBySideInput>
      <table className="form-table">
        <thead>
          <tr className="betoneerimise-protokoll-weather-header-row">
            <th>Kellaaeg</th>
            <th>&deg;C</th>
            <th>kuiv</th>
            <th>pilvine</th>
            <th>vihm</th>
            <th>tugev vihm</th>
            <th>lumi</th>
            <th>tuul (m/s)</th>
          </tr>
        </thead>
        <tbody>
          {weatherData &&
            weatherData.map((row: any, index: number) => {
              return <BetoneerimiseProtokollWeatherTableRow row={row} saveCallback={saveCallback} key={index} disabled={disabled} />;
            })}
        </tbody>
      </table>
    </BhSideBySideInput>
  );
};

export default FormBetoneerimiseProtokollWeatherTableField;
