import React, { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhTableRow from "@components/table/BhTableRow";
import { useTranslation } from "react-i18next";
import { ChecklistBaseCategory, IChecklistBase } from "@/model/checklist/IChecklistBase";
import { ChecklistBaseVersionStatus, IChecklistBaseVersion } from "@/model/checklist/IChecklistBaseVersion";
import { classNames } from "@/utilities/jsUtilities";
import BhFileCardIcon, { FileCardSize } from "@components/cards/BhFileCardIcon";
import { FileEntityType, IFileEntity } from "@/model/files/IFileEntity";
import { Link, useNavigate } from "react-router-dom";
import { formatDateTime } from "@/utilities/dateUtility";
import { getUserFullName } from "@/model/IUser";
import BhTableRowVersion from "@components/table/BhTableRowVersion";
import BhTag from "@components/tags/BhTag";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faGear } from "@fortawesome/pro-regular-svg-icons/faGear";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import BhIconButton from "@components/buttons/BhIconButton";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons/faEllipsisVertical";
import BhCheckbox from "@components/checkboxes/BhCheckbox";
import { selectAllCompanyActiveProjectIds, selectCurrentCompanyProjectNameById } from "@/app/store/companiesSlice";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons/faCirclePlus";
import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons/faPlusCircle";
import { faPen } from "@fortawesome/pro-regular-svg-icons/faPen";
import { useModal } from "react-modal-hook";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import {
  addChecklistBasesToProjectsAsync,
  changeChecklistBaseCategoryAsync,
  copyChecklistBaseToCompanyAsync,
  deleteChecklistBaseAsync,
  deleteChecklistBaseVersionAsync,
  removeChecklistBasesFromProjectsAsync,
  saveNewChecklistBaseVersionAsync,
  selectActivatedBasesInCompanyActiveProjects,
  selectIsChecklistBaseActivatedInProjectByBaseAndProjectId
} from "@/app/store/checklist/checklistsSlice";
import { EntityId } from "@reduxjs/toolkit";
import ActivateBaseInProjectsModal from "@components/checklists/settings/companySettings/ActivateBaseInProjectsModal";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import BhToggleSwitch from "@components/toggle/BhToggleSwitch";
import { IBasesToProjectsDTO } from "@/model/checklist/IBasesToProjectsDTO";
import { faCopy } from "@fortawesome/pro-regular-svg-icons/faCopy";
import BhTooltip from "@components/BhTooltip";
import ChecklistBaseCategoryTagDropdown from "@components/checklists/category/ChecklistBaseCategoryTagDropdown";

interface Props {
  companyId: EntityId;
  checklistBase: IChecklistBase;
  checklistBaseVersion: IChecklistBaseVersion;
  isLastVersion: boolean;
  toggleShowAllVersions: Function;
  allVersionsVisible: boolean;
  selectRow: Function;
  isRowSelected: boolean;
  isProjectChecklistBase: boolean;
}

const CompanySettingsChecklistBaseListRow: FC<Props> = ({
  companyId,
  checklistBase,
  checklistBaseVersion,
  isLastVersion,
  toggleShowAllVersions,
  allVersionsVisible,
  selectRow,
  isRowSelected,
  isProjectChecklistBase
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const activatedBasesInCompanyProjects = useAppSelector(selectActivatedBasesInCompanyActiveProjects);
  const checklistActivatedInProjectsCount = activatedBasesInCompanyProjects.filter((pcb) => pcb.checklistBaseId === checklistBase.id).length;
  const isDraft = checklistBaseVersion.status === ChecklistBaseVersionStatus.DRAFT;
  const anyBaseInDraft = checklistBase.checklistBaseVersions.some((cb) => cb.status === ChecklistBaseVersionStatus.DRAFT);
  const companyActiveProjectsCount = useAppSelector((state) => selectAllCompanyActiveProjectIds(state, companyId)).length;
  const checklistBaseCreatedInProjectName = useAppSelector((state) => selectCurrentCompanyProjectNameById(state, checklistBase.createdInProjectId));
  const isChecklistBaseActivatedInProject = useAppSelector((state) => selectIsChecklistBaseActivatedInProjectByBaseAndProjectId(state, checklistBase.id, checklistBase.createdInProjectId));
  const isBauhubChecklistTemplate = checklistBase.bauhubChecklistBase;
  const [activateInProjectsModalOpen, setActivateInProjectsModalOpen] = useState(false);

  const [showDeleteChecklistBaseConfirmationModal, hideDeleteChecklistBaseConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideDeleteChecklistBaseConfirmationModal}
      header={<h2>{t("CHECKLIST.DELETE.MODAL.HEADER")}</h2>}
      body={<div>{t("CHECKLIST.DELETE.MODAL.BODY")}</div>}
      confirmationButtonText={t("GLOBAL.DELETE")}
      handleDelete={() => {
        deleteChecklistBase();
        hideDeleteChecklistBaseConfirmationModal();
      }}
    />
  ));

  const [showDeleteChecklistBaseVersionConfirmationModal, hideDeleteChecklistBaseVersionConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideDeleteChecklistBaseVersionConfirmationModal}
      header={<h2>{t("CHECKLIST.VERSION.DELETE.MODAL.HEADER")}</h2>}
      body={<div>{t("CHECKLIST.VERSION.DELETE.MODAL.BODY")}</div>}
      confirmationButtonText={t("GLOBAL.DELETE")}
      handleDelete={() => {
        deleteChecklistBaseVersion();
        hideDeleteChecklistBaseVersionConfirmationModal();
      }}
    />
  ));

  const [showChecklistBaseCopyConfirmationModal, hideChecklistBaseCopyConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideChecklistBaseCopyConfirmationModal}
      header={<h2>{t("CHECKLIST.BASE.COPY.MODAL.HEADER")}</h2>}
      body={<div>{t("CHECKLIST.BASE.COPY.MODAL.BODY")}</div>}
      confirmationButtonText={t("CHECKLIST.BASE.COPY.MODAL.CONFIRM")}
      handleDelete={() => {
        copyChecklistBaseToCompany();
        hideChecklistBaseCopyConfirmationModal();
      }}
    />
  ));

  if (!checklistBase || !checklistBaseVersion) return null;

  const addNewVersion = () => {
    dispatch(saveNewChecklistBaseVersionAsync({ companyId: companyId, checklistBaseId: checklistBase.id })).then((action) => {
      const newChecklistBaseVersion = action.payload as IChecklistBaseVersion;
      editVersion(newChecklistBaseVersion.id);
    });
  };

  const editVersion = (checklistBaseVersionId: EntityId) => {
    navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/company/${companyId}/checklist/base/${checklistBase.id}/version/${checklistBaseVersionId}`);
  };

  const deleteChecklistBase = () => {
    dispatch(deleteChecklistBaseAsync({ companyId: companyId, checklistBaseId: checklistBase.id }));
  };

  const deleteChecklistBaseVersion = () => {
    dispatch(deleteChecklistBaseVersionAsync({ companyId: companyId, checklistBaseId: checklistBase.id, checklistBaseVersionId: checklistBaseVersion.id }));
  };

  const toggleBaseInProject = (projectId: any) => {
    if (isChecklistBaseActivatedInProject) {
      removeChecklistBaseFromProjects([projectId]);
    } else {
      addChecklistBaseToProjects([projectId]);
    }
  };

  const addChecklistBaseToProjects = (projectIds: Array<EntityId>) => {
    const dto = { checklistBaseIds: [checklistBase.id], projectIds: projectIds } as IBasesToProjectsDTO;
    dispatch(addChecklistBasesToProjectsAsync({ companyId: companyId, basesToProjectsDTO: dto }));
  };

  const removeChecklistBaseFromProjects = (projectIds: Array<EntityId>) => {
    const dto = { checklistBaseIds: [checklistBase.id], projectIds: projectIds } as IBasesToProjectsDTO;
    dispatch(removeChecklistBasesFromProjectsAsync({ companyId: companyId, basesToProjectsDTO: dto }));
  };

  const copyChecklistBaseToCompany = () => {
    dispatch(copyChecklistBaseToCompanyAsync({ companyId: companyId, checklistBaseId: checklistBase.id }));
  };

  const changeChecklistBaseCategory = (category: ChecklistBaseCategory) => {
    const checklistBaseToSave = { ...checklistBase, category: category };
    dispatch(changeChecklistBaseCategoryAsync({ companyId: companyId, checklistBase: checklistBaseToSave }));
  };

  const ellipsisDropdownValues: Array<any> = [
    isLastVersion &&
      !anyBaseInDraft && {
        text: t("CHECKLIST.BASE.NEW_VERSION"),
        function: addNewVersion,
        icon: faPlusCircle
      },
    isDraft && {
      text: t("CHECKLIST.TEMPLATE_EDIT"),
      function: () => editVersion(checklistBaseVersion.id),
      icon: faPen
    },
    isLastVersion &&
      !isDraft &&
      !isProjectChecklistBase && {
        text: t("CHECKLIST.BASE.MANAGE_IN_PROJECTS"),
        function: () => setActivateInProjectsModalOpen(true),
        icon: faGear
      },
    isLastVersion &&
      !isDraft && {
        text: isProjectChecklistBase ? t("CHECKLIST.BASE.COPY_FROM_PROJECT") : t("CHECKLIST.BASE.COPY_TO_COMPANY"),
        function: showChecklistBaseCopyConfirmationModal,
        icon: faCopy
      },
    isLastVersion &&
      !isBauhubChecklistTemplate && {
        text: t("CHECKLIST.BASE.DELETE"),
        function: showDeleteChecklistBaseConfirmationModal,
        icon: faTrash
      },
    !isLastVersion &&
      isDraft && {
        text: t("CHECKLIST.BASE.VERSION.DELETE"),
        function: showDeleteChecklistBaseVersionConfirmationModal,
        icon: faTrash
      }
  ].filter(Boolean);

  return (
    <BhTableRow classes={classNames(!isLastVersion && !isDraft && "bh-bg-smoke-50", isDraft && "bh-bg-warning-yellow-10", "border-b bh-border-pigeon-40 group hover:bh-bg-mint-30")}>
      {!isProjectChecklistBase && (
        <td>
          {isLastVersion && (
            <BhCheckbox onChange={selectRow} property={"selected"} isChecked={isRowSelected} large={true} disabled={checklistBaseVersion.status === ChecklistBaseVersionStatus.DRAFT} />
          )}
        </td>
      )}
      <td>
        <div className={classNames("group flex w-full flex-row items-center", !isLastVersion && "pl-7")}>
          <BhTooltip body={isBauhubChecklistTemplate ? t("CHECKLIST_BASE.BAUHUB_TEMPLATE") : ""}>
            <BhFileCardIcon
              fileEntity={
                isBauhubChecklistTemplate
                  ? ({ id: checklistBaseVersion.id, name: checklistBase.type, type: FileEntityType.FORM } as IFileEntity)
                  : ({
                      id: checklistBaseVersion.id,
                      name: checklistBase.type,
                      type: FileEntityType.CHECKLIST
                    } as IFileEntity)
              }
              fileCardSize={FileCardSize.LARGE}
            />
          </BhTooltip>
          <div className="ml-2 flex w-full flex-row items-center">
            <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/company/${companyId}/checklist/base/${checklistBase.id}/version/${checklistBaseVersion.id}`}>
              <div className="text-14px bh-text-deep-ocean leading-5 hover:underline">{checklistBase.type}</div>
            </Link>
            {isDraft && <BhTag type={BhTagType.MUSTAND} children={t("CHECKLIST.STATUS.DRAFT")} classes={"ml-3 h-4.5"} />}
          </div>
        </div>
      </td>
      <td>{isLastVersion && <ChecklistBaseCategoryTagDropdown category={checklistBase.category} saveCallback={changeChecklistBaseCategory} />}</td>
      <td className="bh-text-deep-ocean text-center font-bold">
        <BhTableRowVersion
          versionNumber={checklistBaseVersion.versionNumber}
          disabled={!isLastVersion || (isLastVersion && anyBaseInDraft)}
          onClick={toggleShowAllVersions}
          otherVersionsExist={checklistBaseVersion.versionNumber > 1 && isLastVersion}
          allVersionsVisible={allVersionsVisible}
          isActive={allVersionsVisible && isLastVersion}
          icon={faCirclePlus}
          addNewCallback={addNewVersion}
        />
      </td>
      <td>
        <div className="flex flex-col">
          <div className="text-14px bh-text-deep-ocean leading-5">{formatDateTime(checklistBaseVersion.created)}</div>
          <div className="text-12px bh-text-deep-ocean-80">{checklistBaseVersion.createdByUserEntity && getUserFullName(checklistBaseVersion.createdByUserEntity)}</div>
        </div>
      </td>
      <td>
        {!isProjectChecklistBase && isLastVersion && !isDraft && (
          <div className="flex flex-row items-center justify-between">
            <BhSecondaryButton buttonProps={{ onClick: () => setActivateInProjectsModalOpen(true) }}>{checklistActivatedInProjectsCount + "/" + companyActiveProjectsCount}</BhSecondaryButton>
          </div>
        )}
        {isProjectChecklistBase && <div>{checklistBaseCreatedInProjectName}</div>}
      </td>
      {isProjectChecklistBase && (
        <td> {isLastVersion && !isDraft && <BhToggleSwitch value={isChecklistBaseActivatedInProject} onClickAction={() => toggleBaseInProject(checklistBase.createdInProjectId)} />}</td>
      )}
      <td>
        {ellipsisDropdownValues.length > 0 && (
          <BhDropdown
            button={<BhIconButton icon={faEllipsisVertical} />}
            menu={<BhDropdownMenu values={ellipsisDropdownValues} type={BhDropdownTypeEnum.STRING} textProperty="text" widthClass={isProjectChecklistBase ? "w-64" : ""} />}
            position={BhDropdownPositionEnum.BOTTOM_LEFT}
          />
        )}
        {activateInProjectsModalOpen && !isProjectChecklistBase && (
          <ActivateBaseInProjectsModal
            setIsShown={setActivateInProjectsModalOpen}
            checklistBase={checklistBase}
            companyId={companyId}
            addChecklistBaseToProjects={addChecklistBaseToProjects}
            removeChecklistBaseFromProjects={removeChecklistBaseFromProjects}
          />
        )}
      </td>
    </BhTableRow>
  );
};

export default CompanySettingsChecklistBaseListRow;
