import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhModal from "@components/modal/BhModal";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import BhTabs from "@components/tabs/BhTabs";
import BhInputStackedLabel from "@components/input/BhInputStackedLabel";
import {
  fetchContainerHash,
  fetchMobileSigningStatus,
  fetchPublicContainerHash,
  fetchPublicMobileSigningStatus,
  fetchPublicSmartSigningStatus,
  fetchSmartSigningStatus,
  initializeMobileSign,
  initializePublicMobileSign,
  initializePublicSmartSign,
  initializeSmartSign,
  registerContainerSigning,
  registerPublicContainerSigning
} from "@/api/signatureAPI";
import { IMobileSignDTO } from "@/model/signing/IMobileSignDTO";
import useHandleFormSubmit from "@/utilities/hooks/useHandleFormSubmit";
import { ISignResult } from "@/model/signing/ISignResult";
import BhInputWithPrefixDropdown from "@components/input/BhInputWithPrefixDropdown";
import BhSidebarNavDivider from "@components/nav/BhSidebarNavDivider";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { faXmark } from "@fortawesome/pro-regular-svg-icons/faXmark";
import { toastFlagAdded } from "@/app/store/globalSlice";
import { v4 as uuidv4 } from "uuid";
import { BauhubBannerType } from "@/model/IProject";
import { getSigningCertificate, sign } from "@web-eid/web-eid-library/dist/es/web-eid";
import { Trans, useTranslation } from "react-i18next";
import { base64ToBase16 } from "@/utilities/signatureUtil";
import { ISignatureHolder, ISignRequest } from "@/model/ISignatureHolder";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import BhSpinner from "@components/spinner/BhSpinner";
import BhLargeText from "@components/text/BhLargeText";
import BhLightSeparator from "@components/separator/BhLightSeparator";
import { EntityId } from "@reduxjs/toolkit";
import parse from "html-react-parser";
import { BhSectionMessageError } from "@/components/sectionMessage/BhSectionMessages";
import { selectCurrentUser } from "@/app/store/userSlice";

interface Props {
  containerUuid: string;
  signInviteUuid?: string;
  username: string;
  projectId: EntityId;
  initialNumber: { prefix: string; number: string };
  initialPersonalCode: string;
  setContainerSignModalShown: Dispatch<SetStateAction<boolean>>;
  onSignatureSuccess: Function;
  isPublicSigning?: boolean;
}

const ContainerSignModalBody: FC<Props> = ({
  containerUuid,
  signInviteUuid,
  username,
  setContainerSignModalShown,
  projectId,
  initialNumber,
  initialPersonalCode,
  onSignatureSuccess,
  isPublicSigning
}) => {
  const [tab, setTab] = useState(0);
  const [challengeInfo, setChallengeInfo] = useState({ show: false, challenge: "" });
  const handleMobileFormSubmit = useHandleFormSubmit<IMobileSignDTO>();
  const handleSmartFormSubmit = useHandleFormSubmit<IMobileSignDTO>();
  const handleIdCardFormSubmit = useHandleFormSubmit<IMobileSignDTO>();
  const [timerId, setTimerId] = useState<number | undefined>();
  const [expanded, setExpanded] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [number, setNumber] = useState(initialNumber);

  const [personalCodePrefix, setPersonalCodePrefix] = useState("EE");
  const [personalCode, setPersonalCode] = useState(initialPersonalCode);

  const [idCardError, setIdCardError] = useState<string>("GLOBAL.ERROR");
  const [showIdCardError, setShowIdCardError] = useState(false);

  const { t } = useTranslation();
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const currentUser = useAppSelector(selectCurrentUser);
  const userLang = currentUser.language === "et_EE" ? "et" : currentUser.language === "ru_RU" ? "ru" : "en";

  useEffect(() => {
    return () => {
      clearInterval(timerId);
    };
  });

  const removeAdditionalFields = () => {
    setExpanded(false);
  };

  const displayUserRefusedToast = () => {
    dispatch(
      toastFlagAdded({
        id: uuidv4(),
        type: BauhubBannerType.ERROR,
        disappear: true,
        children: t("SMART_ID.USER_REFUSED")
      })
    );
  };

  const completeSigning = (result: ISignResult) => {
    setChallengeInfo({ show: false, challenge: "" });
    setContainerSignModalShown(false);
    onSignatureSuccess(result.signature);
  };

  const pollMobileSignStatus = (dto: IMobileSignDTO) => {
    const fetchMobileStatus = !isPublicSigning ? fetchMobileSigningStatus : fetchPublicMobileSigningStatus;
    fetchMobileStatus(dto)
      .then((result) => {
        if (result.state === "COMPLETE" && result.result === "OK") {
          return completeSigning(result);
        }
        if (result.state === "COMPLETE" && result.result === "USER_CANCELLED") {
          displayUserRefusedToast();
          setContainerSignModalShown(false);
          return;
        }
        const timerId = window.setTimeout(() => pollMobileSignStatus(dto), 5000);
        setTimerId(timerId);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const pollSmartSignStatus = (dto: IMobileSignDTO) => {
    const fetchSmartStatus = !isPublicSigning ? fetchSmartSigningStatus : fetchPublicSmartSigningStatus;
    fetchSmartStatus(dto)
      .then((result) => {
        if (result.status === "COMPLETE" && result.endResult === "OK") {
          return completeSigning(result);
        } else if (result.status === "COMPLETE" && result.endResult === "USER_REFUSED") {
          // User clicked Cancel on second page of Smart ID UI
          displayUserRefusedToast();
          setContainerSignModalShown(false);
          return;
        }
        const timerId = window.setTimeout(() => pollSmartSignStatus(dto), 5000);
        setTimerId(timerId);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const startMobileSign = (formValues: IMobileSignDTO) => {
    const telephoneNumberWithoutSpaces = (number.number + "").replace(/\s/g, "");
    const combined = number.prefix + telephoneNumberWithoutSpaces;
    const validNumber = telephoneNumberWithoutSpaces && /\+\d{9,}/.test(combined);
    const personalCodeWithoutSpaces = (formValues.personalCode + "").replace(/\s/g, "");

    if (!formValues.personalCode || personalCodeWithoutSpaces.length === 0 || !validNumber) {
      dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.ERROR,
          disappear: true,
          translateCode: "ERROR_INCORRECT_INPUT_PARAMETERS"
        })
      );
      return;
    }

    setSubmitButtonDisabled(true);
    const dto = {
      projectId: projectId,
      containerUuid: containerUuid,
      phoneNumber: combined,
      personalCode: personalCodeWithoutSpaces,
      signerEmail: username,
      signInviteUuid,
      role: formValues.role,
      resolution: formValues.resolution,
      country: formValues.country,
      state: formValues.state,
      city: formValues.city,
      zip: formValues.zip
    } as IMobileSignDTO;

    const initializeMobile = !isPublicSigning ? initializeMobileSign : initializePublicMobileSign;
    initializeMobile(dto)
      .then((result) => {
        setChallengeInfo({ show: true, challenge: result.challenge });
        const timerId = window.setTimeout(() => pollMobileSignStatus(dto), 5000);
        setTimerId(timerId);
      })
      .catch((reason) => {
        console.log(reason);
      })
      .finally(() => {
        setSubmitButtonDisabled(false);
      });
  };

  const startSmartSign = (formValues: IMobileSignDTO) => {
    const personalCodeWithoutSpaces = (personalCode + "").replace(/\s/g, "");
    if (!personalCode || personalCodeWithoutSpaces.length === 0) {
      dispatch(
        toastFlagAdded({
          id: uuidv4(),
          type: BauhubBannerType.ERROR,
          disappear: true,
          translateCode: "ERROR_CHECK_PERSONAL_CODE"
        })
      );
      return;
    }

    setSubmitButtonDisabled(true);
    const dto = {
      projectId: projectId,
      containerUuid: containerUuid,
      personalCode: personalCodeWithoutSpaces,
      personalCountry: personalCodePrefix,
      signerEmail: username,
      signInviteUuid,
      role: formValues.role,
      resolution: formValues.resolution,
      country: formValues.country,
      state: formValues.state,
      city: formValues.city,
      zip: formValues.zip
    } as IMobileSignDTO;

    const initializeSmart = !isPublicSigning ? initializeSmartSign : initializePublicSmartSign;
    initializeSmart(dto)
      .then((result) => {
        setChallengeInfo({ show: true, challenge: result.verificationCode });
        const timerId = window.setTimeout(() => pollSmartSignStatus(dto), 5000);
        setTimerId(timerId);
      })
      .catch((reason) => {
        // TODO: Currently unused because backend returns 400 and bauhubPost catches it
        // Reproduce by clicking "Cancel" on _first_ page of Smart ID UI
        if (reason.endResult && reason.endResult === "End result: USER_REFUSED") {
          displayUserRefusedToast();
          setContainerSignModalShown(false);
          return;
        }
      })
      .finally(() => {
        setSubmitButtonDisabled(false);
      });
  };

  const handleIdCardError = (err: any) => {
    let errCode = err.code ? err.code : "GLOBAL.ERROR";
    if (errCode === "ERR_WEBEID_VERSION_MISMATCH") {
      if (err.requiresUpdate?.extension) {
        // Web eID browser extension needs to be updated
        errCode = "ERR_WEBEID_VERSION_MISMATCH_BROWSER";
      }

      if (err.requiresUpdate?.nativeApp) {
        // Web eID native application needs to be updated
        errCode = "ERR_WEBEID_VERSION_MISMATCH_NATIVE";
      }
    }

    setIdCardError(errCode);
    setShowIdCardError(true);
  };

  const startIdCardSign = (formValues: IMobileSignDTO) => {
    setIdCardError("");
    setShowIdCardError(false);

    setSubmitButtonDisabled(true);
    const formValuesCopy = { ...formValues };
    getSigningCertificate({ lang: userLang }).then(
      (certificateResponse: any) => {
        const certificateResponseCopy = certificateResponse;
        const certInHex = base64ToBase16(certificateResponseCopy.certificate);
        return fetchHash(certInHex, containerUuid, formValuesCopy)
          .then((hashResponse: any) => {
            sign(certificateResponseCopy.certificate, hashResponse.dataToSign, "SHA-256", { lang: userLang })
              .then((signature: any) => {
                const baseDTO = {
                  certificateHex: certInHex,
                  signatureHex: base64ToBase16(signature.signature),
                  uuid: containerUuid
                } as ISignRequest;
                const dto = !isPublicSigning ? baseDTO : ({ ...baseDTO, signerEmail: username, signInviteUuid } as ISignRequest);
                const registerContainer = !isPublicSigning ? registerContainerSigning : registerPublicContainerSigning;
                registerContainer(projectId, dto)
                  .then((signatureHolder: ISignatureHolder) => {
                    setContainerSignModalShown(false);
                    onSignatureSuccess(signatureHolder);
                  })
                  .finally(() => {
                    setSubmitButtonDisabled(false);
                  });
              })
              .catch(() => {
                setSubmitButtonDisabled(false);
              });
          })
          .catch(() => {
            setSubmitButtonDisabled(false);
          });
      },
      function (err: any) {
        handleIdCardError(err);
        setSubmitButtonDisabled(false);
      }
    );
  };

  const fetchHash = (hex: string, uuid: string, formValues: IMobileSignDTO) => {
    const baseDTO = {
      hex: hex,
      uuid: uuid,
      role: formValues.role,
      resolution: formValues.resolution,
      country: formValues.country,
      state: formValues.state,
      city: formValues.city,
      zip: formValues.zip
    };
    const dto = !isPublicSigning ? baseDTO : { ...baseDTO, signerEmail: username };
    const fetchHashFunction = !isPublicSigning ? fetchContainerHash : fetchPublicContainerHash;
    return fetchHashFunction(projectId, dto);
  };

  const submitCorrectForm = () => {
    if (tab === 0) {
      return "idCardSignForm";
    } else if (tab === 1) {
      return "mobileSignForm";
    } else if (tab === 2) {
      return "smartSignForm";
    } else {
      return "";
    }
  };

  const additionalFields = (
    <>
      <div className="mt-1">
        <div className="flex items-center justify-between">
          <div>
            <h3 className="bh-text-deep-ocean-80 inline-block">{t("GLOBAL.FILL_EXTRA_FIELDS")}</h3>
            <span className="text-13px l-h-18px bh-text-deep-ocean-80 ml-2 font-normal italic">{t("GLOBAL.OPTIONAL")}</span>
          </div>
          <div>
            {!expanded && <BhSecondaryButton buttonProps={{ onClick: () => setExpanded(true) }}>{t("CONTAINER.FILL_EXTRA_FIELDS")}</BhSecondaryButton>}
            {expanded && (
              <BhTextOnlyButton icon={faXmark} buttonProps={{ onClick: () => removeAdditionalFields(), classes: " " }}>
                {t("CONTAINER.REMOVE_EXTRA_FIELDS")}
              </BhTextOnlyButton>
            )}
          </div>
        </div>
        {expanded && (
          <>
            <div id="additional-fields-1" className="mb-8">
              <BhInputStackedLabel initialValue="" property="role" label={t("GLOBAL.ROLE")} />
              <div className="mt-18px">
                <BhInputStackedLabel initialValue="" property="resolution" label={t("GLOBAL.RESOLUTION")} />
              </div>
            </div>
            <BhSidebarNavDivider />
            <div id="additional-fields-2" className="mt-18px">
              <div className="mt-4 flex flex-row">
                <div className="mr-8 flex-1 basis-1/2">
                  <BhInputStackedLabel initialValue={""} property="country" label={t("GLOBAL.COUNTRY")} />
                </div>
                <div className="flex-1 basis-1/2">
                  <BhInputStackedLabel initialValue={""} property="state" label={t("GLOBAL.STATE")} />
                </div>
              </div>
              <div className="mt-6 flex flex-row">
                <div className="mr-8 flex-1 basis-1/2">
                  <BhInputStackedLabel initialValue={""} property="city" label={t("GLOBAL.CITY")} />
                </div>
                <div className="flex-1 basis-1/2">
                  <BhInputStackedLabel initialValue={""} property="zip" label={t("GLOBAL.ZIP")} />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );

  const idCardTab = (
    <div>
      <form id="idCardSignForm" onSubmit={handleIdCardFormSubmit((dto) => startIdCardSign(dto))}>
        <div className="px-6 pt-12 pb-6 text-center">
          <BhLargeText>
            <Trans>SIGN.ID_CARD</Trans>
          </BhLargeText>
        </div>
        {showIdCardError && (
          <div className="px-12">
            <BhSectionMessageError>
              <div className="text-18px l-h-24px font-bold">{t("ID_CARD.ERROR")}</div>
              <div>{parse(t(idCardError))}</div>
            </BhSectionMessageError>
          </div>
        )}
        <div className="py-6">
          <BhLightSeparator />
        </div>
        {additionalFields}
      </form>
    </div>
  );
  const mobileIdTab = (
    <div className="pt-7">
      <form id="mobileSignForm" className="flex flex-col" onSubmit={handleMobileFormSubmit((dto) => startMobileSign(dto))}>
        <BhInputStackedLabel initialValue={personalCode} property="personalCode" label={t("GLOBAL.PERSONAL_CODE")} />
        <div className="my-18px">
          <BhInputWithPrefixDropdown
            initialPrefix={number.prefix}
            initialValue={number.number}
            property="number"
            type="tel"
            dropdownElements={["+", "+372", "+371", "+370", "+358", "+381", "+39", "+82"]}
            onChangeCallback={(value: { prefix: string; number: string }) => setNumber({ ...number, ...value })}
            label={t("GLOBAL.PHONE_NUMBER") || ""}
          />
        </div>
        <div className="py-6">
          <BhLightSeparator />
        </div>
        {additionalFields}
      </form>
    </div>
  );
  const smartIdTab = (
    <div>
      <form id="smartSignForm" className="flex flex-col" onSubmit={handleSmartFormSubmit((dto) => startSmartSign(dto))}>
        <div className="mt-6">
          <BhInputWithPrefixDropdown
            initialPrefix={personalCodePrefix}
            initialValue={personalCode}
            dropdownElements={["EE", "LV", "LT"]}
            property="code"
            onChangeCallback={(value: { prefix: string; code: string }) => {
              if (value.prefix !== undefined) setPersonalCodePrefix(value.prefix);
              if (value.code !== undefined) setPersonalCode(value.code);
            }}
            label={`${t("USER.PROFILE.SSN")}`}
          />
        </div>
        <div className="py-6">
          <BhLightSeparator />
        </div>
        {additionalFields}
      </form>
    </div>
  );

  const tabs = [
    { id: 0, header: <h2 className="bh-text-deep-ocean-80">{t("GLOBAL.ID_CARD")}</h2>, content: idCardTab },
    { id: 1, header: <h2 className="bh-text-deep-ocean-80">{t("GLOBAL.M_ID")}</h2>, content: mobileIdTab },
    { id: 2, header: <h2 className="bh-text-deep-ocean-80">{t("GLOBAL.S_ID")}</h2>, content: smartIdTab }
  ];

  return (
    <BhModal
      size="2xl"
      isShown={true}
      setIsShown={setContainerSignModalShown}
      header={
        <div className="flex flex-row items-center">
          <h2 className="bh-text-deep-ocean-80">{t("CONTAINER.MODAL.SIGN.TITLE")}</h2>
        </div>
      }
      children={
        <BhScrollableBody>
          <div className="max-w-480px my-6 mb-8 flex flex-col px-20">
            {!challengeInfo.show && (
              <>
                <h3 className="bh-text-deep-ocean-80">{t("CONTAINER.MODAL.SIGN.CHOOSE_SIGNING_TOOL")}</h3>
                <div>
                  <BhTabs tabs={tabs} tabClasses="w-1/3" callback={setTab} initialActiveTab={tab} tabsWidthFull={true} />
                </div>
              </>
            )}
            {challengeInfo.show && (
              <div className="flex flex-col gap-y-6 pt-6">
                <h3>
                  {tab === 1 && <>{t("CONTAINER.SIGNING.M_ID.HEADER")}</>}
                  {tab === 2 && <>{t("CONTAINER.SIGNING.S_ID.HEADER")}</>}
                </h3>
                <div className="flex flex-col">
                  <span>{t("USER.PROFILE.PERSONALIZATION_MODAL.CONFIRM_CODE")}</span>
                  <h1>{challengeInfo.challenge}</h1>
                </div>
                <div>
                  {tab === 1 && <>{t("CONTAINER.SIGNING.M_ID.INFO")}</>}
                  {tab === 2 && <>{t("CONTAINER.SIGNING.S_ID.INFO")}</>}
                </div>
              </div>
            )}
          </div>
        </BhScrollableBody>
      }
      footer={
        <div>
          <BhTextOnlyButton buttonProps={{ onClick: () => setContainerSignModalShown(false) }}>{t("GLOBAL.CANCEL")}</BhTextOnlyButton>
          {!challengeInfo.show && (
            <BhPrimaryButton buttonProps={{ submitForm: submitCorrectForm(), disabled: submitButtonDisabled }}>
              {submitButtonDisabled && (
                <span className="pr-2 pb-0.5">
                  <BhSpinner />
                </span>
              )}
              <span>{t("GLOBAL.SIGN")}</span>
            </BhPrimaryButton>
          )}
        </div>
      }
    />
  );
};

export default ContainerSignModalBody;
