import React, { FC, useRef, useState } from "react";
import { getUserFullName } from "@/model/IUser";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { fetchSearchedUsersInProject } from "@/api/userAPI";
import BhInputWithUserDropdown from "@components/input/BhInputWithUserDropdown";

interface Props {
  label?: string;
  initialValue: ISimpleValidUserAuthority | string;
  property: string;
  saveCallback?: Function;
  returnOnlyString?: boolean;
  disabled?: boolean;
}

const BhInputWithFetchedUsers: FC<Props> = ({ label, saveCallback, property, initialValue, returnOnlyString, disabled }) => {
  const [fetchedUsers, setFetchedUsers] = useState([] as Array<ISimpleValidUserAuthority>);
  const inputRef = useRef<HTMLInputElement>(null);
  const projectId = useAppSelector(selectCurrentProjectId);
  const value = isISimpleValidUserAuthority(initialValue) ? getUserFullName(initialValue) : initialValue;

  function isISimpleValidUserAuthority(object: any): object is ISimpleValidUserAuthority {
    return object && object.userEntityId !== undefined;
  }

  const fetchUsers = (query: string) => {
    return fetchSearchedUsersInProject(projectId, query).then((users) => {
      return users;
    });
  };

  return (
    <BhInputWithUserDropdown
      label={label}
      initialValue={value}
      property={property}
      saveCallback={saveCallback}
      dropdownValues={fetchedUsers}
      inputRef={inputRef}
      fetchValues={fetchUsers}
      returnOnlyString={returnOnlyString}
      disabled={disabled}
    />
  );
};

export default React.memo(BhInputWithFetchedUsers);
