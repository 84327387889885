import { FormType, IFormTemplate } from "@/model/IForm";

export function initializeWithNullValues(formTemplate: IFormTemplate) {
  // @ts-ignore
  const propertyMap: Record<FormType, boolean> = {
    [FormType.EHITUSPAEVIK_UUS]: formTemplate.property === "wasteDate",
    [FormType.EHITUSOBJEKTI_TOOOHUTUSE_PLAAN_MARU]: formTemplate.property === "startDate" || formTemplate.property === "endDate" || formTemplate.property === "eelteade"
  };

  return Object.keys(propertyMap).includes(formTemplate.formType) && propertyMap[formTemplate.formType];
}
