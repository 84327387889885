import React, { FC } from "react";
import BhSideBySideInput from "@components/input/BhSideBySideInput";
import { IFormTemplate } from "@/model/IForm";
import AttachmentsContainerLarge from "@components/attachments/AttachmentsContainerLarge";
import { useTranslation } from "react-i18next";
import { EntityId } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { selectFileIdToRenameInForm, selectValueByPropertyFromCurrentFormData, setFileIdToRenameInForm } from "@/app/store/formSlice";
import { uploadFileTypesAllowed } from "@components/form/helper/FormUploadFileTypesAllowed";
import { allowOnlyFileUpload } from "@components/form/helper/FormAllowOnlyFileUpload";
import AttachmentRenameModal from "@components/form/modal/AttachmentRenameModal";
import { IFileEntity } from "@/model/files/IFileEntity";
import { enableFileRename } from "@components/form/helper/FormEnableFileRename";
import BhTextOnlyButton from "@components/buttons/BhTextOnlyButton";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { useModal } from "react-modal-hook";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";

interface Props {
  formTemplate: IFormTemplate;
  saveCallback: Function;
  disabled: boolean;
}

const FormAttachmentsField: FC<Props> = ({ formTemplate, saveCallback, disabled }) => {
  const { t } = useTranslation();
  const fieldValue = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, formTemplate.property));
  const allowedFileTypesForUpload = uploadFileTypesAllowed(formTemplate);
  const disableFileChoosingFromProject = allowOnlyFileUpload(formTemplate);
  const fileRenameEnabled = enableFileRename(formTemplate);
  const fileIdToRename = useAppSelector(selectFileIdToRenameInForm);
  const fileToRename = fileIdToRename && fieldValue.find((f: IFileEntity) => f.id === fileIdToRename);
  const dispatch = useAppDispatch();

  const deleteAllAttachments = () => {
    const objToSave = { [formTemplate.property]: [] };
    saveCallback && saveCallback(objToSave);
  };

  const [showDeleteAllConfirmationModal, hideDeleteAllConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideDeleteAllConfirmationModal}
      header={<h2>{t("FORMS.GLOBAL.ADDONS_DELETE_MODAL.HEADER")}</h2>}
      body={<div>{t("FORMS.GLOBAL.ADDONS_DELETE_MODAL.BODY")}</div>}
      confirmationButtonText={t("GLOBAL.DELETE")}
      handleDelete={() => {
        deleteAllAttachments();
        hideDeleteAllConfirmationModal();
      }}
    />
  ));

  if (!fieldValue || fieldValue.length === 0) return null;

  const removeFile = (fileEntityId: EntityId) => {
    const attachmentsToSave = fieldValue.filter((att: any) => fileEntityId !== att.id);
    const objToSave = { [formTemplate.property]: attachmentsToSave };
    saveCallback && saveCallback(objToSave);
  };

  const openRenameModal = (fileId: EntityId) => {
    const file = fieldValue.find((f: IFileEntity) => f.id === fileId);
    if (file) {
      dispatch(setFileIdToRenameInForm(file.id));
    }
  };

  const renameFile = (fileId: EntityId, fileName: string) => {
    const attachmentsToSave = fieldValue.map((file: IFileEntity) => {
      if (file.id === fileId) {
        return { ...file, name: fileName };
      }
      return file;
    });
    const objToSave = { [formTemplate.property]: attachmentsToSave };
    saveCallback && saveCallback(objToSave);
    dispatch(setFileIdToRenameInForm(undefined));
  };

  const saveUploadedFiles = (changedValue: any) => {
    changedValue[formTemplate.property] = changedValue[formTemplate.property].map((val: any) => ({ ...val, selected: false }));
    saveCallback(changedValue);
  };

  return (
    <BhSideBySideInput
      customLabelPosition={true}
      label={
        <div className="flex flex-col">
          <div>{formTemplate.value && t(formTemplate.value)}</div>
          {fieldValue.length > 1 && (
            <BhTextOnlyButton icon={faTrash} buttonProps={{ onClick: showDeleteAllConfirmationModal }}>
              {t("FORMS.GLOBAL.ADDONS_DELETE")}
            </BhTextOnlyButton>
          )}
        </div>
      }
    >
      <div className="bh-bg-smoke bh-border-pigeon-50 rounded border border-dashed px-4 pt-4 pb-2">
        <AttachmentsContainerLarge
          attachments={fieldValue}
          property={formTemplate.property}
          saveCallback={saveUploadedFiles}
          removeCallback={!disabled ? (fileEntityId: EntityId) => removeFile(fileEntityId) : undefined}
          addingDisabled={disabled}
          fileTypesAllowedForUpload={allowedFileTypesForUpload}
          chooseFromProjectAllowed={!disableFileChoosingFromProject}
          renameCallback={fileRenameEnabled && !disabled ? openRenameModal : undefined}
          multiple={true}
        />
      </div>
      {fileToRename && <AttachmentRenameModal fileEntity={fileToRename} closeModal={() => dispatch(setFileIdToRenameInForm(undefined))} renameCallback={renameFile} />}
    </BhSideBySideInput>
  );
};

export default FormAttachmentsField;
