import React from "react";
import BOCompanyActs from "@components/backoffice/company/modulesAndTemplates/BOCompanyActs";
import { EnabledFormType } from "@/model/ICompany";
import { useAppDispatch } from "@/app/hooks";
import { boSaveFormAsync } from "@/app/store/backofficeSlice";

const BOCompanyActsSection = () => {
  const dispatch = useAppDispatch();
  const onSave = (dto: any) => {
    dispatch(boSaveFormAsync(dto as any));
  };

  return (
    <>
      <div id="subcontractor-acts" className="w-full">
        <h2>Subcontractor acts</h2>
        <BOCompanyActs formType={EnabledFormType.SUBCONTRACTOR_ACT} onSaveCallback={onSave} />
      </div>
      <div id="client-acts" className="w-full">
        <h2>Client acts</h2>
        <BOCompanyActs formType={EnabledFormType.CLIENT_ACT} onSaveCallback={onSave} />
      </div>
    </>
  );
};

export default BOCompanyActsSection;
