import React, { FC } from "react";
import { setFormModalsField, toggleFormModalsOpen } from "@/app/store/formSlice";
import { useAppDispatch } from "@/app/hooks";
import { useTranslation } from "react-i18next";

interface Props {
  label: string;
  property: string;
  disabled: boolean;
}

const FormLabelWithImport: FC<Props> = ({ label, property, disabled }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const openImportModal = (field: string) => {
    dispatch(toggleFormModalsOpen({ modal: "importFieldInfoModal" }));
    dispatch(setFormModalsField(field));
  };

  return (
    <div className="flex flex-col">
      <div>
        <span>{label}</span>
      </div>
      {!disabled && (
        <div className="text-12px flex flex-col items-end">
          <a onClick={(e) => openImportModal(property)} className="cursor-pointer">
            <span>{t("FORMS.EHITUSPAEVIK_UUS.IMPORT")}</span>
          </a>
        </div>
      )}
    </div>
  );
};

export default FormLabelWithImport;
