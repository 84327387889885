import React, { FC } from "react";
import BhSideBySideInput from "@components/input/BhSideBySideInput";
import { FormType, IFormTemplate } from "@/model/IForm";
import BhAttachmentsUploadContainer from "@components/upload/BhAttachmentsUploadContainer";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { faPaperclip } from "@fortawesome/pro-regular-svg-icons/faPaperclip";
import { fetchFormsOfTypeSince } from "@/api/formAPI";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import { formatDateTimeInternationalFormat } from "@/utilities/dateUtility";
import { useTranslation } from "react-i18next";
import { selectValueByPropertyFromCurrentFormData } from "@/app/store/formSlice";
import { uploadFileTypesAllowed } from "@components/form/helper/FormUploadFileTypesAllowed";
import { allowOnlyFileUpload } from "@components/form/helper/FormAllowOnlyFileUpload";
import { setDatesForAddedFiles } from "@components/form/helper/FormSetAttachmentsDates";
import { IFileEntity } from "@/model/files/IFileEntity";

interface Props {
  formTemplate: IFormTemplate;
  saveCallback: Function;
  disabled: boolean;
}

const FormUploadField: FC<Props> = ({ formTemplate, saveCallback, disabled }) => {
  const { t } = useTranslation();
  const fieldValue = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, formTemplate.property));
  const docDate = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, "docDate"));
  const projectId = useAppSelector(selectCurrentProjectId);
  const allowedFileTypesForUpload = uploadFileTypesAllowed(formTemplate);
  const disableFileChoosingFromProject = allowOnlyFileUpload(formTemplate);
  const addDatesWhenAddingFiles = setDatesForAddedFiles(formTemplate);

  const fetchForms = () => {
    const since = formatDateTimeInternationalFormat(docDate, false, true);
    const formTypes = [FormType.KTA_UUS, FormType.VCW];
    return fetchFormsOfTypeSince(projectId, since, formTypes).then((result) => {
      const formsNotAlreadyExisting = result.filter((formFileEntity) => {
        return !fieldValue.some((f: IFileEntity) => {
          return f.id === formFileEntity.id;
        });
      });
      const formsObject = { documentsFileArray: [...fieldValue, ...formsNotAlreadyExisting] };
      saveCallback(formsObject);
    });
  };

  const saveUploadedFiles = (changedValue: any) => {
    changedValue[formTemplate.property] = changedValue[formTemplate.property].map((val: any) => ({ ...val, selected: false }));
    if (addDatesWhenAddingFiles) {
      saveUploadedFilesWithDates(changedValue);
      return;
    }
    saveCallback(changedValue);
  };

  const saveUploadedFilesWithDates = (changedValue: any) => {
    const filesWithDates = changedValue[formTemplate.property].map((file: any) => {
      const isNewFile = !fieldValue.some((f: any) => f.id === file.id);
      if (isNewFile) {
        return { ...file, date: file.created };
      }
      return file;
    });
    saveCallback({ [formTemplate.property]: filesWithDates });
  };

  return (
    <BhSideBySideInput
      label={
        <div>
          {formTemplate.value && t(formTemplate.value)}
          {!formTemplate.value && (formTemplate.formType === FormType.EHITUSPAEVIK_UUS || formTemplate.formType === FormType.BWD) && (
            <div className="flex flex-col">
              <div className="flex flex-col">
                <a onClick={fetchForms} className="cursor-pointer hover:underline">
                  <span>{t("FORMS.BDW.IMPORT_DOCS")}</span>
                </a>
              </div>
            </div>
          )}
        </div>
      }
    >
      <div className="mt-2.5 flex h-full flex-row items-center">
        <BhAttachmentsUploadContainer
          saveCallback={saveUploadedFiles}
          existingAttachments={fieldValue}
          button={
            <BhSecondaryButton icon={faPaperclip} buttonProps={{ disabled: disabled }}>
              {t("TASK.ADD_ATTACHMENT")}
            </BhSecondaryButton>
          }
          property={formTemplate.property}
          multiple={true}
          disabled={disabled}
          fileTypesAllowedForUpload={allowedFileTypesForUpload}
          fileUploadAllowed={true}
          chooseFromProjectAllowed={!disableFileChoosingFromProject}
        />
      </div>
    </BhSideBySideInput>
  );
};

export default FormUploadField;
