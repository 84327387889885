import { IFormTemplate } from "@/model/IForm";

export function enableDateRemove(formTemplate: IFormTemplate) {
  const propertyMap: Record<string, boolean> = {
    EHITUSPAEVIK_UUS: formTemplate.property === "wasteDate",
    BETONEERIMISE_PROTOKOLL:
      formTemplate.property === "kastmine" || formTemplate.property === "katmine" || formTemplate.property === "loikamine" || formTemplate.property === "konstruktsiooniKoormamine",
    BETONEERIMISE_PROTOKOLL_KAKSKEELNE:
      formTemplate.property === "kastmine" || formTemplate.property === "katmine" || formTemplate.property === "loikamine" || formTemplate.property === "konstruktsiooniKoormamine",
    EHITUSOBJEKTI_TOOOHUTUSE_PLAAN_MARU: formTemplate.property === "startDate" || formTemplate.property === "endDate" || formTemplate.property === "eelteade"
  };

  return Object.keys(propertyMap).includes(formTemplate.formType) && propertyMap[formTemplate.formType];
}
