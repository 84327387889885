import React, { FC, startTransition, useCallback, useEffect, useMemo, useRef } from "react";
import { classNames } from "@/utilities/jsUtilities";
import { debounce } from "lodash";

interface Props {
  initialValue: any;
  property: string;
  saveCallback?: Function;
  required?: boolean;
  type?: string;
  placeholder?: string;
  inputClasses?: string;
  disabled?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
  onChangeCallback?: Function;
  onEnterCallback?: Function;
  autoFocus?: boolean;
  onFocus?: Function;
  onBlurAction?: Function;
  withoutBorder?: boolean;
  maxLength?: number;
  onPasteCallback?: Function;
  readOnly?: boolean;
  debounceLength?: number;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  onChangeCallbackOnEveryValue?: boolean;
}

const BhInput: FC<Props> = ({
  property,
  initialValue,
  required,
  saveCallback,
  type,
  placeholder,
  inputClasses,
  disabled,
  inputRef,
  onChangeCallback,
  onEnterCallback,
  autoFocus,
  onFocus,
  onBlurAction,
  withoutBorder,
  maxLength,
  onPasteCallback,
  readOnly,
  debounceLength,
  inputProps,
  onChangeCallbackOnEveryValue
}) => {
  let containedInputRef = useRef<HTMLInputElement>(null);

  const onBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      e.preventDefault();
      startTransition(() => {
        const inputValue = e.target.value;
        const initialValueString = initialValue || "";
        if (inputValue === initialValueString) return;
        const changedValue = { [property]: e.target.value };
        onBlurAction && onBlurAction(changedValue);
        saveCallback && saveCallback(changedValue);
        // If you have the same onChange and onBlur actions then disable onChange after blur
        if (onChangeCallback === onBlurAction) {
          debouncedOnChangeHandler.cancel();
        }
      });
    },
    [saveCallback, onChangeCallback, onBlurAction, initialValue]
  );

  const onChange = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      e.preventDefault();
      startTransition(() => {
        const inputValue = e.target.value;
        if (inputValue === initialValue && !onChangeCallbackOnEveryValue) return;
        const changedValue = { [property]: e.target.value };
        onChangeCallback && onChangeCallback(changedValue);
      });
    },
    [onChangeCallback]
  );

  const debouncedOnChangeHandler = useMemo(() => debounce(onChange, debounceLength || 300), [initialValue, onChangeCallback]);

  useEffect(() => {
    let inputRefPropOrContained = inputRef ? inputRef : containedInputRef;
    if (inputRefPropOrContained.current) {
      if (initialValue !== undefined) inputRefPropOrContained.current.value = initialValue;
      if (initialValue === undefined && inputRefPropOrContained.current.value) inputRefPropOrContained.current.value = "";
      // Eemaldasin selle, et kui FakeInputi peale avades input kohe fokuseeritud oleks.
      // if (autoFocus) inputRefPropOrContained.current.focus();
    }

    return () => {
      debouncedOnChangeHandler.cancel();
    };
  }, [initialValue]);

  useEffect(() => {
    const inputRefPropOrContained = inputRef ? inputRef : containedInputRef;
    if (autoFocus) inputRefPropOrContained.current?.focus();
  }, []);

  const onKeyDown = (event: any) => {
    if (event.key === "Enter" && onEnterCallback) {
      event.preventDefault();
      const inputRefPropOrContained = inputRef ? inputRef : containedInputRef;
      const changedValue = { [property]: inputRefPropOrContained.current?.value };
      inputRefPropOrContained.current?.blur();
      onEnterCallback(changedValue);
    }
  };

  const handleOnFocus = () => {
    onFocus && onFocus(containedInputRef.current?.value);
  };

  const onPaste = (event: any) => {
    if (onPasteCallback) {
      event.preventDefault();
      onPasteCallback && onPasteCallback(event);
    }
  };

  return (
    <input
      ref={inputRef || containedInputRef}
      name={property}
      onPaste={onPaste}
      onChange={debouncedOnChangeHandler}
      className={classNames(
        !withoutBorder && "bh-border-pigeon-40",
        "l-h-20 placeholder:bh-text-deep-ocean-60 hover:bh-border-pigeon-70 focus:bh-border-pigeon-70 py-7px w-full rounded " +
          "disabled:bh-text-deep-ocean-40 disabled:bh-bg-smoke disabled:bh-border-smoke",
        inputClasses
      )}
      type={type ? type.toString() : "text"}
      placeholder={placeholder || ""}
      disabled={disabled}
      autoComplete="off"
      onBlur={onBlur}
      onKeyDown={(e) => onKeyDown(e)}
      onFocus={handleOnFocus}
      maxLength={maxLength}
      required={required}
      readOnly={readOnly}
      aria-autocomplete={"none"}
      {...inputProps}
    />
  );
};

export default React.memo(BhInput);
