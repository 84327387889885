import React, { FC } from "react";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import BhDropdown from "@components/dropdown/BhDropdown";
import { useAppDispatch } from "@/app/hooks";
import { deleteFormTemplateAsync, saveFormTemplateAsync, saveNewFormTemplateAsync } from "@/app/store/formSlice";
import { FormFieldType, IFormTemplate } from "@/model/IForm";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons/faPlusCircle";
import BhIconButton from "@components/buttons/BhIconButton";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import BhInputSimple from "@components/input/BhInputSimple";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";

interface Props {
  formTemplate: IFormTemplate;
}

const FormEditRow: FC<Props> = ({ formTemplate }) => {
  const dispatch = useAppDispatch();
  const rowTypes = Object.keys(FormFieldType).sort((a, b) => (a > b ? 1 : -1));

  const addRow = (rowType: any) => {
    const newRow = { ...{ formType: formTemplate.formType, orderNumber: formTemplate.orderNumber + 1, rowType: rowType } } as IFormTemplate;
    dispatch(saveNewFormTemplateAsync(newRow));
  };

  const deleteRow = () => {
    dispatch(deleteFormTemplateAsync(formTemplate));
  };

  const saveRow = (changedObject: any) => {
    const rowToSave = { ...formTemplate, ...changedObject };
    dispatch(saveFormTemplateAsync(rowToSave));
  };

  return (
    <tr>
      <td>{formTemplate.orderNumber}</td>
      <td>{formTemplate.rowType}</td>
      <td>
        <BhInputSimple initialValue={formTemplate.property} property={"property"} saveCallback={saveRow} />
      </td>
      <td>
        <BhInputSimple initialValue={formTemplate.value} property={"value"} saveCallback={saveRow} />
      </td>
      <td>
        <div className="flex flex-row items-center">
          <BhDropdown
            button={<BhIconButton icon={faPlusCircle} />}
            position={BhDropdownPositionEnum.BOTTOM_LEFT}
            menu={<BhDropdownMenu values={rowTypes} type={BhDropdownTypeEnum.STRING} onSelect={addRow} widthClass={"w-fit"} />}
          />
          <BhIconButton icon={faTrash} buttonProps={{ onClick: () => deleteRow() }} />
        </div>
      </td>
    </tr>
  );
};

export default FormEditRow;
