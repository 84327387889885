import { BauhubPlan, IProject, Module } from "@/model/IProject";
import { ICompanyInfo } from "@/model/iCompanyInfo";
import { ICompanyUser } from "@/model/companyUsers/ICompanyUser";
import { ICompanyInvitedUser } from "@/model/companyUsers/ICompanyInvitedUser";
import { IFileTreeTemplates } from "@/model/files/IFileTreeTemplate";
import { IPredefinedWorkGroup } from "@/model/IPredefinedWorkGroup";
import { EntityId } from "@reduxjs/toolkit";
import { ISimpleValidUserAuthority } from "@/model/ISimpleValidUserAuthority";
import { FormType } from "@/model/IForm";
import { IChecklistBase } from "@/model/checklist/IChecklistBase";

export interface ICompany {
  id: EntityId;
  name: string;
  uuid: string;
  projects: Array<IProject>;
  createdByFullName: string;
  updatedByFullName: string;
  created: Date;
  updated: Date;
  users: Array<ISimpleValidUserAuthority>;
  companyInfo: ICompanyInfo;
  billingSetupDone: boolean;
  realUsers: Array<ICompanyUser>;
  invitedUsers: Array<ICompanyInvitedUser>;
  fileTreeTemplates: IFileTreeTemplates;
  predefinedWorkGroups?: Array<IPredefinedWorkGroup>;
  plan: BauhubPlan;
  enabledForms: Array<IEnabledForm>;
  modules: Array<ICompanyModule>;
  trialStart: Date;
  trialEnd: Date;
  status: CompanyStatus;
  comment: ICompanyComment;
  companyChecklistBases: Array<IChecklistBase>;
}

export interface INewCompanyDTO {
  name: string;
  tier: BauhubPlan;
  users: Array<ISimpleValidUserAuthority>;
  companyInfo: ICompanyInfo;
}

export interface ICompanyModule {
  id: EntityId;
  companyId: EntityId;
  projectId: EntityId;
  module: Module;
  settings: any;
  adminSettings: any;
  created: Date;
  createdBy: string;
  updated: Date;
  updatedBy: string;
}

export interface IEnabledForm {
  id: EntityId;
  nameLabel: string;
  code: FormType;
  companyId: EntityId;
  type: EnabledFormType;
}

export enum EnabledFormType {
  CLIENT_ACT = "CLIENT_ACT",
  SUBCONTRACTOR_ACT = "SUBCONTRACTOR_ACT",
  FILE_CONFIRMATION = "FILE_CONFIRMATION",
  SUBCONTRACTOR_CONTRACT = "SUBCONTRACTOR_CONTRACT",
  CLIENT_CONTRACT = "CLIENT_CONTRACT"
}

export enum CompanyStatus {
  TRIAL = "TRIAL",
  TRIAL_EXPIRED = "TRIAL_EXPIRED",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE"
}

export interface ICompanyComment {
  id: EntityId;
  comment: string;
  companyId: EntityId;
}
