import React, { FC, useCallback, useEffect, useState } from "react";
import CopyAndMoveDirectorySelectionModal from "@/views/home/project/detail/directory/directoryModals/copyAndMoveModals/CopyAndMoveDirectorySelectionModal";
import { IFileCopyOrMoveDTO, IFileMoveOrCopyRequestDTO } from "@/model/files/IFileEntity";
import DuplicateFolderNameWarningModal from "@/views/home/project/detail/directory/directoryModals/copyAndMoveModals/DuplicateFolderNameWarningModal";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { resetFoldersSelected } from "@/app/store/foldersSlice";
import { allFileEntitiesUnSelected, filesAdded, setDirectoryModalsOpen, setFileEntityParentIdsAfterMove } from "@/app/store/filesSlice";
import { copyFiles, moveFiles } from "@/api/fileAPI";
import { EntityId } from "@reduxjs/toolkit";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import DuplicateFileNameWarningModals from "@/views/home/project/detail/directory/directoryModals/copyAndMoveModals/DuplicateFileNameWarningModals";
import SimilarFileNamesWarningModals from "@/views/home/project/detail/directory/directoryModals/copyAndMoveModals/SimilarFileNamesWarningModals";

interface Props {
  isCopyModal: boolean;
  isMoveModal: boolean;
  currentDirectoryId: EntityId;
}

const CopyAndMoveModals: FC<Props> = ({ isCopyModal, isMoveModal, currentDirectoryId }) => {
  const dispatch = useAppDispatch();
  const projectId = useAppSelector(selectCurrentProjectId);

  // Modals
  const [directorySelectionModalOpen, setDirectorySelectionModalOpen] = useState(true);
  const [showDuplicateFileNameWarningModal, setShowDuplicateFileNameWarningModal] = useState(false);
  const [showDuplicateFolderNameWarningModal, setShowDuplicateFolderNameWarningModal] = useState(false);
  const [showSimilarFileNamesWarningModal, setShowSimilarFileNamesWarningModal] = useState(false);

  // Files
  const [filesWithRevisionsAndMatchingName, setFilesWithRevisionsAndMatchingName] = useState([] as Array<IFileCopyOrMoveDTO>);
  const [duplicateDirectories, setDuplicateDirectories] = useState([] as Array<IFileCopyOrMoveDTO>);
  const [similarFiles, setSimilarFiles] = useState([] as Array<IFileCopyOrMoveDTO>);
  const [remainingFiles, setRemainingFiles] = useState([] as Array<IFileCopyOrMoveDTO>);

  // DTOs
  const [DTOsForCopyOrMove, setDTOsForCopyOrMove] = useState([] as Array<IFileMoveOrCopyRequestDTO>);

  // CopyRevisions boolean
  const [copyRevisions, setCopyRevisions] = useState(true);

  // If directory selection is done open next modal if needed
  // Also: After each special case modal is closed, this will check if another modal should be opened
  useEffect(() => {
    if (!directorySelectionModalOpen) {
      handleModals();
    }
  }, [directorySelectionModalOpen, filesWithRevisionsAndMatchingName, duplicateDirectories, similarFiles, remainingFiles]);

  // Closing modals, adding request DTOs to state and removing handled file from corresponding list
  const handleModalClose = (dtos: Array<IFileMoveOrCopyRequestDTO>) => {
    const isModalSubmitted = dtos.every((dto) => dto.destinationId && dto.fromId);
    if (isModalSubmitted) {
      setDTOsForCopyOrMove([...DTOsForCopyOrMove, ...dtos]);
    }
    if (showDuplicateFileNameWarningModal) {
      setShowDuplicateFileNameWarningModal(false);
      const filteredFiles = filesWithRevisionsAndMatchingName.filter((file) => !dtos.flatMap((dto) => dto.fileIds).includes(file.fileToCopyOrMove.id));
      setFilesWithRevisionsAndMatchingName(filteredFiles);
    }
    if (showDuplicateFolderNameWarningModal) {
      setShowDuplicateFolderNameWarningModal(false);
      const filteredDirectories = duplicateDirectories.filter((dir) => !dtos.flatMap((dto) => dto.fileIds).includes(dir.fileToCopyOrMove.id));
      setDuplicateDirectories(filteredDirectories);
    }
    if (showSimilarFileNamesWarningModal) {
      setShowSimilarFileNamesWarningModal(false);
      const filteredFiles = similarFiles.filter((file) => !dtos.flatMap((dto) => dto.fileIds).includes(file.fileToCopyOrMove.id));
      setSimilarFiles(filteredFiles);
    }
  };

  const handleModals = useCallback(() => {
    if (filesWithRevisionsAndMatchingName.length > 0) {
      setShowDuplicateFileNameWarningModal(true);
    } else if (duplicateDirectories.length > 0) {
      setShowDuplicateFolderNameWarningModal(true);
    } else if (similarFiles.length > 0) {
      setShowSimilarFileNamesWarningModal(true);
    } else if (remainingFiles.length > 0) {
      handleRemainingFiles();
    } else if (DTOsForCopyOrMove.length > 0 && !directorySelectionModalOpen) {
      if (isCopyModal) {
        copy();
      } else if (isMoveModal) {
        move();
      }
      resetState();
    } else {
      resetState();
    }
  }, [filesWithRevisionsAndMatchingName, duplicateDirectories, similarFiles, remainingFiles, directorySelectionModalOpen, DTOsForCopyOrMove]);

  // This will create DTOs from remainingFiles for each source directory separately
  const handleRemainingFiles = () => {
    let fromDirToDestinationsFileMap: Record<EntityId, Record<EntityId, Array<IFileCopyOrMoveDTO>>> = {};
    remainingFiles.forEach((file) => {
      if (!fromDirToDestinationsFileMap[file.fileToCopyOrMove.parentFileEntityId]) {
        fromDirToDestinationsFileMap[file.fileToCopyOrMove.parentFileEntityId] = {};
      }
      if (!fromDirToDestinationsFileMap[file.fileToCopyOrMove.parentFileEntityId][file.destinationDirectory.id]) {
        fromDirToDestinationsFileMap[file.fileToCopyOrMove.parentFileEntityId][file.destinationDirectory.id] = [];
      }
      fromDirToDestinationsFileMap[file.fileToCopyOrMove.parentFileEntityId][file.destinationDirectory.id].push(file);
    });
    let dtos = [] as Array<IFileMoveOrCopyRequestDTO>;
    Object.keys(fromDirToDestinationsFileMap).forEach((fromDirKey) => {
      Object.keys(fromDirToDestinationsFileMap[fromDirKey]).forEach((destinationDirKey) => {
        const files = fromDirToDestinationsFileMap[fromDirKey][destinationDirKey];
        const fileIds = files.map((file) => file.fileToCopyOrMove.id);
        const dto = {
          fileIds: fileIds,
          destinationId: destinationDirKey,
          fromId: fromDirKey,
          projectId: projectId,
          copyRevisions: copyRevisions,
          mergeFolders: false,
          keepSameName: false
        } as IFileMoveOrCopyRequestDTO;
        dtos.push(dto);
      });
    });
    setDTOsForCopyOrMove([...DTOsForCopyOrMove, ...dtos]);
    setRemainingFiles([]);
  };

  async function copy() {
    for (const dto of DTOsForCopyOrMove) {
      await copyFiles(dto).then((copiedFiles) => {
        if (dto.destinationId === currentDirectoryId) {
          dispatch(filesAdded(copiedFiles));
        }
      });
    }
  }

  async function move() {
    for (const dto of DTOsForCopyOrMove) {
      delete dto.copyRevisions;
      await moveFiles(dto);
    }
    const movedFileEntityIds = DTOsForCopyOrMove.flatMap((dto) => dto.fileIds);
    const destinationId = parseInt(DTOsForCopyOrMove[0].destinationId.toString());
    dispatch(setFileEntityParentIdsAfterMove({ ids: movedFileEntityIds, newParentFileEntity: destinationId }));
  }

  const resetState = () => {
    dispatch(resetFoldersSelected());
    dispatch(allFileEntitiesUnSelected());
    const modalToClose = isCopyModal ? "copyModal" : "moveModal";
    dispatch(setDirectoryModalsOpen({ modal: modalToClose, value: false }));
  };

  return (
    <>
      {directorySelectionModalOpen && (
        <CopyAndMoveDirectorySelectionModal
          setIsShown={setDirectorySelectionModalOpen}
          setFilesWithRevisionsAndMatchingName={setFilesWithRevisionsAndMatchingName}
          setDuplicateDirectories={setDuplicateDirectories}
          setSimilarFiles={setSimilarFiles}
          setRemainingFiles={setRemainingFiles}
          onClose={resetState}
          isMoveModal={isMoveModal}
          currentDirectoryId={currentDirectoryId}
          setCopyRevisions={setCopyRevisions}
        />
      )}
      {showDuplicateFileNameWarningModal && (
        <DuplicateFileNameWarningModals dto={filesWithRevisionsAndMatchingName[0]} onClose={handleModalClose} projectId={projectId} isMoveModal={isMoveModal} copyRevisions={copyRevisions} />
      )}
      {showDuplicateFolderNameWarningModal && <DuplicateFolderNameWarningModal dto={duplicateDirectories[0]} onClose={handleModalClose} projectId={projectId} copyRevisions={copyRevisions} />}
      {showSimilarFileNamesWarningModal && <SimilarFileNamesWarningModals dtos={similarFiles} onClose={handleModalClose} projectId={projectId} copyRevisions={copyRevisions} />}
    </>
  );
};

export default CopyAndMoveModals;
