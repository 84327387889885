import React, { FC, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import BhTableRow from "@components/table/BhTableRow";
import BhIconButton from "@components/buttons/BhIconButton";
import { faCircleMinus } from "@fortawesome/pro-regular-svg-icons/faCircleMinus";
import {
  changeCompanyAdministratorAsync,
  changeCompanyAdminUserInviteAsync,
  removeCompanyAdminInviteAsync,
  removeCompanyAdministratorAsync,
  selectCompanyUserOrInviteeByUsername
} from "@/app/store/companiesSlice";
import BhToggleSwitch from "@components/toggle/BhToggleSwitch";
import { selectCurrentUser } from "@/app/store/userSlice";
import { ICompanyInvitedUser } from "@/model/companyUsers/ICompanyInvitedUser";
import { ICompanyUser } from "@/model/companyUsers/ICompanyUser";
import BhTag from "@components/tags/BhTag";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import { EntityId } from "@reduxjs/toolkit";
import { formatDateTime } from "@/utilities/dateUtility";
import BhTagListContainer from "@components/tags/BhTagListContainer";
import BhTooltip from "@components/BhTooltip";
import { useTranslation } from "react-i18next";
import { useModal } from "react-modal-hook";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import BhUserIconWithName from "@components/user/BhUserIconWithName";
import { IUser } from "@/model/IUser";

interface Props {
  companyId: EntityId;
  username: string;
  userExists: boolean;
}

const CompanyAdminRow: FC<Props> = React.memo(({ companyId, username, userExists }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const admin = useAppSelector((state) => selectCompanyUserOrInviteeByUsername(state, companyId, username, userExists));
  const currentUser = useAppSelector(selectCurrentUser);
  const notOwnUser = username !== currentUser.username;
  const inviteDateExists = admin && !admin.userExists && (admin as ICompanyInvitedUser).inviteSentAt !== undefined;
  const inviteDate = inviteDateExists ? (admin as ICompanyInvitedUser).inviteSentAt : undefined;
  const [showDeleteConfirmationModal, hideDeleteConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideDeleteConfirmationModal}
      header={<h2>{t("COMPANY.SETTINGS.USERS.DELETE.COMPANY_ADMIN.HEADER")}</h2>}
      body={<div dangerouslySetInnerHTML={{ __html: t("COMPANY.SETTINGS.USERS.DELETE.COMPANY_ADMIN.BODY") as any }} />}
      confirmationButtonText={t("GLOBAL.DELETE")}
      handleDelete={() => {
        if (admin?.userExists) {
          dispatch(removeCompanyAdministratorAsync({ companyId: companyId, userId: (admin as ICompanyUser).userEntityId }));
        } else {
          dispatch(removeCompanyAdminInviteAsync(admin as ICompanyInvitedUser));
        }
        hideDeleteConfirmationModal();
      }}
    />
  ));

  const onAdminChangeSubmit = useCallback(
    (changedValue: any) => {
      const accountManagerToggledOn = !admin?.accountManager && changedValue.accountManager;
      if (accountManagerToggledOn) {
        changedValue.checklistsManager = true;
      }
      const changedObject = { ...admin, ...changedValue };
      if (admin?.userExists) {
        dispatch(changeCompanyAdministratorAsync({ companyId: companyId, changedUser: changedObject }));
      } else {
        const request = {
          username: changedObject.username,
          accountManager: changedObject.accountManager,
          newProjectDefaultAdmin: changedObject.newProjectDefaultAdmin,
          checklistsManager: changedObject.checklistsManager
        };
        dispatch(changeCompanyAdminUserInviteAsync({ companyId: companyId, request: request }));
      }
    },
    [admin]
  );

  if (!admin) return null;

  return (
    <BhTableRow classes="w-full border-b bh-border-pigeon-40">
      <td className="w-2/5">
        {admin.userExists && <BhUserIconWithName user={admin as unknown as IUser} />}
        {inviteDate && (
          <div>
            <BhTagListContainer>
              <BhTag type={BhTagType.TAG}>{t("GLOBAL.INVITE.PENDING")}</BhTag>
            </BhTagListContainer>
            <div>
              {t("INVITE.SENT")} {formatDateTime(inviteDate)}
            </div>
          </div>
        )}
      </td>
      <td className="w-72 flex-grow">
        <a className="bh-underline-link" href={"mailto:" + admin.username}>
          {admin.username}
        </a>
      </td>
      <td>{notOwnUser && <BhToggleSwitch value={admin.accountManager} property={"accountManager"} onClickAction={onAdminChangeSubmit} />}</td>
      <td>
        <BhToggleSwitch value={admin.checklistsManager} property={"checklistsManager"} onClickAction={onAdminChangeSubmit} />
      </td>
      <td>
        <BhToggleSwitch value={admin.newProjectDefaultAdmin} property={"newProjectDefaultAdmin"} onClickAction={onAdminChangeSubmit} />
      </td>
      <td className="w-5 flex-grow-0 text-right">
        {notOwnUser && (
          <BhTooltip body={t("COMPANY.SETTINGS.REMOVE_ADMINISTRATOR")}>
            <BhIconButton icon={faCircleMinus} buttonProps={{ onClick: showDeleteConfirmationModal }} />
          </BhTooltip>
        )}
      </td>
    </BhTableRow>
  );
});

export default CompanyAdminRow;
