import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons/faInfoCircle";
import BhTooltip from "@components/BhTooltip";
import { useTranslation } from "react-i18next";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import MntMpTableRow from "@components/form/fields/customFields/mntTables/MntMpTableRow";
import { useAppSelector } from "@/app/hooks";
import { selectValueByPropertyFromCurrentFormData } from "@/app/store/formSlice";

interface Props {
  dataTableRows: Array<any>;
  dataTableColumns: Array<string>;
  saveRowCallback: Function;
  addRowCallback: Function;
  removeRowCallback: Function;
  onPasteCallback: Function;
  disabled: boolean;
}

const MntMpKraavidTable: FC<Props> = ({ dataTableRows, dataTableColumns, saveRowCallback, addRowCallback, removeRowCallback, onPasteCallback, disabled }) => {
  const { t } = useTranslation();
  const sum = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, "sumN")) || 0;

  return (
    <table className="form-table">
      <thead>
        <tr>
          <th colSpan={2}>Asukoht PK +</th>
          <th colSpan={6}>Külgkraavide mõõtmed ja kaevetööde mahud</th>
          <th colSpan={2}>Puhastamine(-50cm)</th>
          <th colSpan={4}>Külgkraavide põhja kõrgus ja pikikalle %</th>
          <th rowSpan={3} className="w-[45px] min-w-[45px]"></th>
        </tr>
        <tr>
          <th>Vasakul</th>
          <th>Paremal</th>
          <th>Pikkus</th>
          <th>Maapinna</th>
          <th>Kraavi</th>
          <th>Kr. pealt</th>
          <th>Maht</th>
          <th>Äravedu</th>
          <th>Vasak k.</th>
          <th>Parem k.</th>
          <th colSpan={2}>Vasak kraav</th>
          <th colSpan={2}>Parem kraav</th>
        </tr>
        <tr className="mnt-kraavid-table-row">
          <th>
            <BhTooltip body={t("FORMS.MNT_MP.PASTE_TOOLTIP")}>
              <FontAwesomeIcon icon={faInfoCircle} />
            </BhTooltip>
          </th>
          <th></th>
          <th>m</th>
          <th>kõrgus m</th>
          <th>sügav. m</th>
          <th>laius m</th>
          <th>m3</th>
          <th>m3</th>
          <th>m</th>
          <th>m</th>
          <th>Kõrgus</th>
          <th>%</th>
          <th>Kõrgus</th>
          <th>%</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>2</td>
          <td>3</td>
          <td>4</td>
          <td>5</td>
          <td>6</td>
          <td>7</td>
          <td>8</td>
          <td>9</td>
          <td>10</td>
          <td>11</td>
          <td>12</td>
          <td>13</td>
          <td>14</td>
          <td>
            {dataTableRows.length === 0 && !disabled && (
              <div className="flex flex-row items-center justify-center">
                <div className="hover:bh-bg-pigeon-20 flex h-5 w-5 cursor-pointer flex-row items-center justify-center rounded p-1" onClick={() => addRowCallback()}>
                  <FontAwesomeIcon icon={faPlus} size={"xs"} />
                </div>
              </div>
            )}
          </td>
        </tr>
        {dataTableRows &&
          dataTableRows.length > 0 &&
          dataTableRows.map((row: any, index) => {
            return (
              <MntMpTableRow
                row={row}
                columns={dataTableColumns}
                saveRowCallback={saveRowCallback}
                onPasteCallback={onPasteCallback}
                addRowCallback={addRowCallback}
                removeRowCallback={removeRowCallback}
                key={index}
                disabled={disabled}
                rowIndex={index}
              />
            );
          })}
        <tr>
          <td>Kokku:</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>{sum}</td>
          <td></td>
        </tr>
      </tbody>
    </table>
  );
};

export default MntMpKraavidTable;
