import React, { FC, useState } from "react";
import BhSideBySideInput from "@components/input/BhSideBySideInput";
import { IFormTemplate } from "@/model/IForm";
import { useTranslation } from "react-i18next";
import BhCheckbox from "@components/checkboxes/BhCheckbox";
import { getVisibleDatepickerProperties } from "@components/form/helper/FormVisibleFieldPropertyMap";
import BhDatePicker from "@components/input/BhDatePicker";
import { enableDateRemove } from "@components/form/helper/FormEnableDateRemove";
import { useAppSelector } from "@/app/hooks";
import { selectValueByPropertyFromCurrentFormData } from "@/app/store/formSlice";

interface Props {
  formTemplate: IFormTemplate;
  saveCallback: Function;
  disabled: boolean;
}

const FormToggleVisibilityDatepickerField: FC<Props> = ({ formTemplate, saveCallback, disabled }) => {
  const { t } = useTranslation();
  const fieldValue = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, formTemplate.property));
  const visibleFieldProperties = getVisibleDatepickerProperties(formTemplate);
  const startDateProperty = visibleFieldProperties && visibleFieldProperties.startDate;
  const endDateProperty = visibleFieldProperties && visibleFieldProperties.endDate;
  const startDate = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, startDateProperty));
  const endDate = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, endDateProperty));
  const [datepickersVisible, setDatepickersVisible] = useState<boolean>(fieldValue);
  const enableDateRemoveOnDatepicker = enableDateRemove(formTemplate);

  const toggleInputVisible = (changedObj: any) => {
    setDatepickersVisible(!datepickersVisible);
    saveCallback(changedObj);
  };

  const resetStartDatefield = () => {
    const dateToSave = { [startDateProperty]: "" };
    saveCallback && saveCallback(dateToSave);
  };

  const resetEndDatefield = () => {
    const dateToSave = { [endDateProperty]: "" };
    saveCallback && saveCallback(dateToSave);
  };

  return (
    <BhSideBySideInput
      label={
        <div className="flex flex-row items-center gap-x-2">
          <div>{t(formTemplate.value)}</div>
          <BhCheckbox property={formTemplate.property} isChecked={fieldValue} large={true} onChange={toggleInputVisible} disabled={disabled} />
        </div>
      }
    >
      {datepickersVisible && visibleFieldProperties && (
        <div className="flex flex-row items-center gap-x-3">
          <BhDatePicker
            property={startDateProperty}
            initialValue={new Date(startDate)}
            returnISOString={true}
            onChangeCallback={saveCallback}
            disabled={disabled}
            onResetCallback={enableDateRemoveOnDatepicker ? resetStartDatefield : undefined}
          />
          <BhDatePicker
            property={endDateProperty}
            initialValue={new Date(endDate)}
            returnISOString={true}
            onChangeCallback={saveCallback}
            disabled={disabled}
            onResetCallback={enableDateRemoveOnDatepicker ? resetEndDatefield : undefined}
          />
        </div>
      )}
    </BhSideBySideInput>
  );
};

export default FormToggleVisibilityDatepickerField;
