import React, { FC } from "react";
import BhInputSimple from "@components/input/BhInputSimple";
import BhSideBySideInput from "@components/input/BhSideBySideInput";
import { FormType } from "@/model/IForm";
import BhDatePicker from "@components/input/BhDatePicker";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentFormType, selectValueByPropertyFromCurrentFormData } from "@/app/store/formSlice";

interface Props {
  saveCallback: Function;
  disabled: boolean;
}

const FormMntHeaderField: FC<Props> = ({ saveCallback, disabled }) => {
  const formType = useAppSelector(selectCurrentFormType);
  const koostamiseDate = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, "koostamiseDate"));
  const name = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, "name"));
  const worker = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, "worker"));
  const roadName = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, "roadName"));
  const km = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, "km"));
  const PK = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, "PK"));
  const constructionInfo = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, "constructionInfo"));
  const formNameFieldValueMap: Record<string, string> = {
    MNT_MP_ALUSED: "... aluse paksuse, laiuste, põikkallete, tasasuse ja loodimise protokoll",
    MNT_MP_KATEND: "Katte ... kihi laiuste, põikkallete, tasasuse ja loodimise protokoll",
    MNT_MP_PINNAS: "... pinnase mahtude mõõtmisprotokoll"
  };
  const initialCreatedValue = koostamiseDate ? new Date(koostamiseDate) : new Date();

  return (
    <div>
      {Object.keys(formNameFieldValueMap).includes(formType) && (
        <BhSideBySideInput
          label={
            <div className="flex flex-row items-center">
              <div>{formNameFieldValueMap[formType]}</div>
            </div>
          }
        >
          <BhInputSimple initialValue={name} property={"name"} saveCallback={saveCallback} disabled={disabled} />
        </BhSideBySideInput>
      )}
      <BhSideBySideInput
        label={
          <div className="flex flex-row items-center">
            <div>Töö tegija</div>
          </div>
        }
      >
        <BhInputSimple initialValue={worker} property={"worker"} saveCallback={saveCallback} disabled={disabled} />
      </BhSideBySideInput>
      <BhSideBySideInput
        label={
          <div className="flex flex-row items-center">
            <div>Tee nr ja nimetus</div>
          </div>
        }
      >
        <BhInputSimple initialValue={roadName} property={"roadName"} saveCallback={saveCallback} disabled={disabled} />
      </BhSideBySideInput>
      {formType !== FormType.MNT_MP_KANDEVOIME && (
        <BhSideBySideInput>
          <BhSideBySideInput label={"km"}>
            <BhInputSimple initialValue={km} property={"km"} saveCallback={saveCallback} disabled={disabled} />
          </BhSideBySideInput>
          <BhSideBySideInput label={"PK"}>
            <BhInputSimple initialValue={PK} property={"PK"} saveCallback={saveCallback} disabled={disabled} />
          </BhSideBySideInput>
        </BhSideBySideInput>
      )}
      {formType === FormType.MNT_MP_KANDEVOIME && (
        <>
          <BhSideBySideInput label={"km"}>
            <BhInputSimple initialValue={km} property={"km"} saveCallback={saveCallback} inputClasses={"max-w-[140px]"} disabled={disabled} />
          </BhSideBySideInput>
          <BhSideBySideInput label={"Aruande koostamise kuupäev"}>
            <BhDatePicker property={"koostamiseDate"} initialValue={initialCreatedValue} onChangeCallback={saveCallback} returnISOString={true} disabled={disabled} />
          </BhSideBySideInput>
          <BhSideBySideInput label={"Mõõdetud konstruktsiooni nimetus, seadme tüüp ja nr., testimistunnistuse nr."}>
            <BhInputSimple initialValue={constructionInfo} property={"constructionInfo"} saveCallback={saveCallback} disabled={disabled} />
          </BhSideBySideInput>
        </>
      )}
    </div>
  );
};

export default FormMntHeaderField;
