import React from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { boRemoveFormAsync, boSaveFormAsync, boSelectCompany } from "@/app/store/backofficeSlice";
import BhCombobox from "@components/input/BhCombobox";
import BOCompanyTemplateRow from "@components/backoffice/company/modulesAndTemplates/BOCompanyTemplateRow";
import { IEnabledForm } from "@/model/ICompany";
import { FormType } from "@/model/IForm";
import { useTranslation } from "react-i18next";
import { naturalSortString } from "@/utilities/sortUtilities";

const BOCompanyForms = () => {
  const { t } = useTranslation();
  const company = useAppSelector(boSelectCompany);
  const dispatch = useAppDispatch();
  const companyForms = company.enabledForms?.filter((form) => form.type === null);
  const forms = Object.values(FormType);
  const filteredForms = forms.filter((form) => !company.enabledForms?.some((enabledForm) => form === enabledForm.code));

  const formTranslationMap: Record<string, string> = {};

  const translatedForms = filteredForms
    .map((form) => {
      const translatedForm = t("FORMS." + form);
      formTranslationMap[translatedForm] = form;
      return translatedForm;
    })
    .sort((a, b) => naturalSortString(a, b));

  const removeForm = (form: IEnabledForm) => {
    dispatch(boRemoveFormAsync(form));
  };

  const saveForm = (selectedValue: Record<string, FormType>) => {
    const dto = { code: formTranslationMap[selectedValue.code], nameLabel: formTranslationMap[selectedValue.code], companyId: company.id };
    dispatch(boSaveFormAsync(dto as IEnabledForm));
  };

  return (
    <div id="forms" className="w-full">
      <h2>Forms</h2>
      {companyForms?.map((form) => (
        <BOCompanyTemplateRow key={form.id} template={form as IEnabledForm} nameProperty="code" removeCallback={removeForm} label={t("FORMS." + form.code)} />
      ))}
      <div className="mt-2 w-full">
        <BhCombobox initialValue={""} values={translatedForms} property="code" placeholder="+ Add module" onSelect={saveForm} enableNewValue={true} />
      </div>
    </div>
  );
};

export default BOCompanyForms;
