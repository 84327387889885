import React, { FC } from "react";
import BhSideBySideInput from "@components/input/BhSideBySideInput";
import { IFormTemplate } from "@/model/IForm";
import { useTranslation } from "react-i18next";
import BhFileCardMedium from "@components/cards/BhFileCardMedium";
import BhDatePicker from "@components/input/BhDatePicker";
import { useAppSelector } from "@/app/hooks";
import { selectValueByPropertyFromCurrentFormData } from "@/app/store/formSlice";

interface Props {
  formTemplate: IFormTemplate;
  saveCallback: Function;
  disabled: boolean;
}

const FormAttachmentsWithDatesField: FC<Props> = ({ formTemplate, saveCallback, disabled }) => {
  const { t } = useTranslation();
  const attachments = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, formTemplate.property));

  if (!attachments || attachments.length === 0) return null;

  const saveAttachmentDate = (value: any, attachmentToSave: any) => {
    const attachmentsToSave = attachments.map((attachment: any) => {
      if (attachment.id === attachmentToSave.id) {
        return { ...attachment, ...value };
      }
      return attachment;
    });
    saveCallback({ [formTemplate.property]: attachmentsToSave });
  };

  const resetAttachmentDate = (attachmentToSave: any) => {
    saveAttachmentDate({ date: null }, attachmentToSave);
  };

  const removeAttachment = (attachmentToRemove: any) => {
    const filteredAttachments = attachments.filter((attachment: any) => attachment.id !== attachmentToRemove.id);
    saveCallback({ [formTemplate.property]: filteredAttachments });
  };

  return (
    <BhSideBySideInput label={<div className="flex flex-row items-start pt-2">{formTemplate.value && t(formTemplate.value)}</div>} customLabelPosition={true}>
      <div className="flex flex-col">
        {attachments.map((attachment: any) => {
          return (
            <div className="flex flex-row items-center pb-2" key={attachment.id}>
              <div className="w-80">
                <BhFileCardMedium fileEntity={attachment} deleteCallback={!disabled ? (attachmentToRemove: any) => removeAttachment(attachmentToRemove) : undefined} />
              </div>
              <div className="ml-10">
                <BhDatePicker
                  property={"date"}
                  initialValue={attachment.date && new Date(attachment.date)}
                  onChangeCallback={(value: any) => saveAttachmentDate(value, attachment)}
                  returnISOString={true}
                  onResetCallback={() => resetAttachmentDate(attachment)}
                  disabled={disabled}
                />
              </div>
            </div>
          );
        })}
      </div>
    </BhSideBySideInput>
  );
};

export default FormAttachmentsWithDatesField;
