import React, { FC, useCallback } from "react";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons/faPlus";
import { useTranslation } from "react-i18next";
import { IFormDataSaveRequest } from "@/model/IForm";
import { useAppSelector } from "@/app/hooks";
import { selectBwdRowIdsByPropertyFromCurrentFormData } from "@/app/store/formSlice";
import FormBwdWorkerTableRow from "@components/form/fields/customFields/bwd/FormBwdWorkerTableRow";
import { shallowEqual } from "react-redux";

interface Props {
  saveRowCallback: Function;
  addRowCallback: Function;
  removeRowCallback: Function;
  disabled: boolean;
  property: string;
}

const FormBwdWorkersTable: FC<Props> = ({ property, saveRowCallback, addRowCallback, removeRowCallback, disabled }) => {
  const { t } = useTranslation();
  const rowIds = useAppSelector((state) => selectBwdRowIdsByPropertyFromCurrentFormData(state, property), shallowEqual);

  const addRow = useCallback((index?: number) => {
    if (index || index === 0) {
      addRowCallback({}, "workers", index + 1);
    } else {
      addRowCallback({}, "workers");
    }
  }, []);

  const removeRow = useCallback((row: any) => {
    removeRowCallback(row, "workers");
  }, []);

  const saveRow = useCallback((changedObject: any, changedProperty: any, row: any) => {
    const saveRequest = {
      path: "workers",
      changedProperty: changedProperty,
      changedValue: changedObject[changedProperty],
      changedObjectId: row._id
    } as IFormDataSaveRequest;
    saveRowCallback(saveRequest);
  }, []);

  return (
    <div className="px-28">
      <h3>{t("FORMS.BWD.WORKFORCE")}</h3>
      <div className="mt-4 flex flex-row items-center">
        <div className="w-1/2 pr-8">
          <div className="mb-1.5">{t("FORMS.BWD.WORKFORCE.NAME")}</div>
        </div>
        <div className="w-1/2 pr-8">
          <div className="mb-1.5">{t("FORMS.BWD.WORKFORCE.COUNT")}</div>
        </div>
      </div>
      {rowIds &&
        rowIds.map((id: any) => {
          return <FormBwdWorkerTableRow rowId={id} saveCallback={saveRow} addCallback={addRow} removeCallback={removeRow} disabled={disabled} key={id} />;
        })}
      <div className="mt-3.5 flex w-full flex-row items-center justify-between pr-7">
        <BhSecondaryButton icon={faPlus} buttonProps={{ onClick: () => addRow(), classes: "ml-0", disabled: disabled }}>
          {t("FORMS.SCM.ADD_WORKER")}
        </BhSecondaryButton>
      </div>
    </div>
  );
};

export default FormBwdWorkersTable;
