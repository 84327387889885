import React, { FC } from "react";
import { IFormTemplate } from "@/model/IForm";
import BhSideBySideInput from "@components/input/BhSideBySideInput";
import { useTranslation } from "react-i18next";

interface Props {
  formTemplate: IFormTemplate;
}

const FormSubheaderField: FC<Props> = ({ formTemplate }) => {
  const { t } = useTranslation();
  return (
    <BhSideBySideInput>
      <div className="py-1 font-bold">{t(formTemplate.value)}</div>
    </BhSideBySideInput>
  );
};

export default FormSubheaderField;
