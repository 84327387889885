import React, { FC, useEffect, useState } from "react";
import { EntityId } from "@reduxjs/toolkit";
import FormContainerNew from "@/views/home/project/detail/form/FormContainerNew";
import { resetCurrentForm } from "@/app/store/formSlice";
import { useAppDispatch } from "@/app/hooks";
import { BauhubFormTypes, BauhubMntFormTypes, ClientFormTypes, FormType } from "@/model/IForm";
import FormContainerForOldFE from "@/views/home/project/detail/form/FormContainerForOldFE";
import BhDetailContainer from "@components/detailContainer/BhDetailContainer";
import BhDetailContainerBody from "@components/detailContainer/BhDetailContainerBody";
import { setCurrentSidebarMenuItem, setSidebarCollapsed } from "@/app/store/sidebarSlice";
import { fetchFormWithTypeOnly } from "@/api/formAPI";
import { fetchFileEntityAsync } from "@/app/store/filesSlice";
import CommentsSidebar from "@/views/home/project/detail/pdftron/CommentsSidebar";
import BhLightSeparator from "@components/separator/BhLightSeparator";
import FormContainerHeader from "@/views/home/project/detail/form/FormContainerHeader";
import { SidebarItemType } from "@/model/ISidebarItem";

interface Props {
  fileEntityId: EntityId;
  dirId: EntityId;
}

const FormContainer: FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const [formType, setFormType] = useState<FormType | null>(null);
  const [commentsOpen, setCommentsOpen] = useState(false);

  useEffect(() => {
    dispatch(setSidebarCollapsed(true));
    dispatch(setCurrentSidebarMenuItem(SidebarItemType.DOCUMENTS));
    dispatch(resetCurrentForm());
    dispatch(fetchFileEntityAsync(props.fileEntityId));
    fetchFormWithTypeOnly(props.fileEntityId).then((res) => {
      setFormType(res.formType);
    });
  }, [props]);

  if (!formType) {
    return null;
  }

  const isNewUiForm = [...BauhubFormTypes, ...BauhubMntFormTypes, ...ClientFormTypes].includes(formType);

  return (
    <BhDetailContainer>
      <div className="flex h-full w-full flex-row">
        <div className="flex flex-1 flex-col">
          <FormContainerHeader fileEntityId={props.fileEntityId} dirId={props.dirId} formType={formType} commentsOpen={commentsOpen} setCommentsOpen={setCommentsOpen} />
          <BhLightSeparator />
          <div className="h-full w-full overflow-hidden">
            <BhDetailContainerBody>{isNewUiForm ? <FormContainerNew {...props} /> : <FormContainerForOldFE {...props} />}</BhDetailContainerBody>
          </div>
        </div>
        {commentsOpen && <CommentsSidebar fileEntityId={props.fileEntityId} hideSidebar={() => setCommentsOpen(false)} />}
      </div>
    </BhDetailContainer>
  );
};

export default FormContainer;
