import React, { FC, useState } from "react";
import BhInputSimple from "@components/input/BhInputSimple";
import BhSideBySideInput from "@components/input/BhSideBySideInput";
import { IFormTemplate } from "@/model/IForm";
import { useTranslation } from "react-i18next";
import BhCheckbox from "@components/checkboxes/BhCheckbox";
import { getVisibleFieldProperty } from "@components/form/helper/FormVisibleFieldPropertyMap";
import { useAppSelector } from "@/app/hooks";
import { selectValueByPropertyFromCurrentFormData } from "@/app/store/formSlice";

interface Props {
  formTemplate: IFormTemplate;
  saveCallback: Function;
  disabled: boolean;
}

const FormToggleVisibilityInputField: FC<Props> = ({ formTemplate, saveCallback, disabled }) => {
  const { t } = useTranslation();
  const fieldValue = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, formTemplate.property));
  const visibleFieldProperty = getVisibleFieldProperty(formTemplate);
  const visibleFieldValue = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, visibleFieldProperty));
  const [inputVisible, setInputVisible] = useState<boolean>(fieldValue);

  const toggleInputVisible = (changedObj: any) => {
    setInputVisible(!inputVisible);
    saveCallback(changedObj);
  };

  return (
    <BhSideBySideInput
      label={
        <div className="flex flex-row items-center gap-x-2">
          <div>{t(formTemplate.value)}</div>
          <BhCheckbox property={formTemplate.property} isChecked={fieldValue} large={true} onChange={toggleInputVisible} disabled={disabled} />
        </div>
      }
    >
      {inputVisible && visibleFieldProperty && <BhInputSimple initialValue={visibleFieldValue} property={visibleFieldProperty} saveCallback={saveCallback} disabled={disabled} />}
    </BhSideBySideInput>
  );
};

export default FormToggleVisibilityInputField;
