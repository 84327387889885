import React, { FC, useRef } from "react";
import ContentContainer from "@/views/home/contentContainer/ContentContainer";
import BhPrimaryButton from "@components/buttons/BhPrimaryButton";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import BhDropdown from "@components/dropdown/BhDropdown";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { fetchFormTemplatesAsync, saveNewFormTemplateAsync, selectFormTemplatesForEdit } from "@/app/store/formSlice";
import FormEditRow from "@/views/home/formEdit/FormEditRow";
import { FormType, IFormTemplate } from "@/model/IForm";
import BhInputSimple from "@components/input/BhInputSimple";
import { useTranslation } from "react-i18next";

const FormEditContainer: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const formTemplates = useAppSelector(selectFormTemplatesForEdit);
  const currentFormType = formTemplates && formTemplates.length > 0 && formTemplates[0].formType;
  const currentFormName = currentFormType && t("FORMS." + currentFormType);
  let inputRef = useRef<HTMLInputElement>(null);

  const fetchFormTemplates = (formType: string) => {
    dispatch(fetchFormTemplatesAsync(formType));
  };

  const createNew = () => {
    const newRow = { ...{ formType: inputRef?.current?.value, orderNumber: 1, rowType: "INPUT" } } as IFormTemplate;
    dispatch(saveNewFormTemplateAsync(newRow));
  };

  const availableForms = Object.keys(FormType).map((formType) => {
    return { text: t("FORMS." + formType) + " (" + formType + ")", function: () => fetchFormTemplates(formType) };
  });

  return (
    <ContentContainer>
      <div className="max-w-screen-2xl w-full p-8 pb-96">
        <div className="flex w-full flex-row items-center justify-between">
          <BhDropdown
            button={<BhPrimaryButton>Forms</BhPrimaryButton>}
            menu={<BhDropdownMenu values={availableForms} type={BhDropdownTypeEnum.STRING} textProperty={"text"} widthClass={"w-fit whitespace-nowrap"} />}
          />
          {currentFormType && (
            <div>
              <h3>{currentFormName + " (" + currentFormType + ")"}</h3>
            </div>
          )}
          <div className="flex flex-row items-center">
            <div className="ml-4">
              <BhInputSimple initialValue={""} inputRef={inputRef} property={"text"} />
            </div>
            <BhPrimaryButton buttonProps={{ onClick: createNew }}>Create new</BhPrimaryButton>
          </div>
        </div>
        <div className="mt-10">
          <table className="w-full">
            <thead>
              <tr>
                <th className="w-32">Order number</th>
                <th className="w-52">Row type</th>
                <th>Property</th>
                <th>Value</th>
                <th className="w-24"></th>
              </tr>
            </thead>
            <tbody>
              {formTemplates &&
                formTemplates.length > 0 &&
                formTemplates.map((template) => {
                  return <FormEditRow formTemplate={template} key={template.id} />;
                })}
            </tbody>
          </table>
        </div>
      </div>
    </ContentContainer>
  );
};

export default FormEditContainer;
