import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  archiveOrActivateChecklistsAsync,
  fetchChecklistsForUserAsync,
  isLastChecklistRound,
  saveChecklistAsync,
  selectCanCurrentUserEditChecklist,
  selectChecklistById,
  selectChecklistFilter,
  selectChecklistRoundById,
  selectIsChecklistRoundSelected,
  startNewChecklistRoundFromLatestRoundAsync,
  toggleChecklistRoundSelection,
  toggleChecklistRoundsVisible
} from "@/app/store/checklist/checklistsSlice";
import BhTableRow from "@components/table/BhTableRow";
import BhFileCardIcon, { FileCardSize } from "@components/cards/BhFileCardIcon";
import { FileEntityType, IFileEntity, IFolderFileEntity } from "@/model/files/IFileEntity";
import { classNames } from "@/utilities/jsUtilities";
import { getUserFullName } from "@/model/IUser";
import { IDropdownItem } from "@/model/IDropdownItem";
import BhDropdown from "@components/dropdown/BhDropdown";
import BhIconButton from "@components/buttons/BhIconButton";
import { faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons/faEllipsisVertical";
import BhDropdownMenu from "@components/dropdown/BhDropdownMenu";
import { BhDropdownTypeEnum } from "@components/dropdown/BhDropdownTypeEnum";
import { BhDropdownPositionEnum } from "@components/dropdown/BhDropdownPositionEnum";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faFile } from "@fortawesome/pro-regular-svg-icons/faFile";
import { faFileSignature } from "@fortawesome/pro-regular-svg-icons/faFileSignature";
import { ChecklistRoundStatus, IChecklistRound } from "@/model/checklist/IChecklistRound";
import BhDeleteConfirmationModal from "@components/modal/BhDeleteConfirmationModal";
import { Link, useNavigate } from "react-router-dom";
import BhCheckbox from "@components/checkboxes/BhCheckbox";
import BhSignatureContainerStatus from "@components/container/BhSignatureContainerStatus";
import BhTableRowVersion from "@components/table/BhTableRowVersion";
import { faUnlockKeyhole } from "@fortawesome/pro-regular-svg-icons";
import { faLockKeyhole } from "@fortawesome/pro-solid-svg-icons/faLockKeyhole";
import { faBoxArchive } from "@fortawesome/pro-regular-svg-icons/faBoxArchive";
import { faArrowRotateLeft } from "@fortawesome/pro-regular-svg-icons/faArrowRotateLeft";
import { copyChecklistRoundPdfToDirectories, generatePdfForChecklistRound } from "@/api/checklist/checklistAPI";
import { selectCurrentProjectId } from "@/app/store/project/projectSlice";
import BhTags from "@components/tags/BhTags";
import { BhTagType } from "@components/tags/BhTagTypeEnum";
import ChecklistRoundProgressBar from "@components/checklists/ChecklistRoundProgressBar";
import { EntityId } from "@reduxjs/toolkit";
import { formatDateTime } from "@/utilities/dateUtility";
import { useTranslation } from "react-i18next";
import { useModal } from "react-modal-hook";
import { ConfigSingleton } from "@/model/utilities/IBauhubConfiguration";
import { ChecklistBaseCategory } from "@/model/checklist/IChecklistBase";
import ChecklistRoundTomPercentage from "@components/checklists/list/ChecklistRoundTomPercentage";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons/faFileExport";
import DirectorySelectionModal from "@/views/home/project/detail/directory/directoryModals/DirectorySelectionModal";
import { fullPageLoadingSet } from "@/app/store/globalSlice";

interface Props {
  checklistRoundId: EntityId;
  directorySelectionModalIds: Array<EntityId>;
}

const ChecklistListRow: FC<Props> = ({ checklistRoundId, directorySelectionModalIds }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const checklistRound = useAppSelector((state) => selectChecklistRoundById(state, checklistRoundId));
  const checklist = useAppSelector((state) => checklistRound && selectChecklistById(state, checklistRound.checklistId));
  const isLastRound = useAppSelector((state) => isLastChecklistRound(state, checklistRoundId));
  const filter = useAppSelector(selectChecklistFilter);
  const projectId = useAppSelector(selectCurrentProjectId);
  const canUserDeleteChecklist = useAppSelector((state) => checklist && selectCanCurrentUserEditChecklist(state, projectId, checklist.id));
  const isSelected = useAppSelector((state) => selectIsChecklistRoundSelected(state, checklistRoundId));

  const [showDeleteConfirmationModal, hideDeleteConfirmationModal] = useModal(() => (
    <BhDeleteConfirmationModal
      setIsOpen={hideDeleteConfirmationModal}
      header={<h2>{t("CHECKLIST.DELETE_CHECKLIST.HEADER")}</h2>}
      body={<div>{t("CHECKLIST.DELETE_CHECKLIST.BODY")}</div>}
      confirmationButtonText={t("GLOBAL.DELETE")}
      handleDelete={() => {
        deleteChecklist();
        hideDeleteConfirmationModal();
      }}
    />
  ));

  const [showCopyPdfFileToDirectoryModal, hideCopyPdfFileToDirectoryModal] = useModal(() => (
    <DirectorySelectionModal
      directoryIds={directorySelectionModalIds}
      modalHeader={t("MODAL.CHANGE_DIR.CHOOSE_DIR")}
      onModalSubmit={(selectedFolderFileEntities: Array<IFolderFileEntity>) => {
        const selectedFolderIds = selectedFolderFileEntities.map((file) => file.id);
        if (selectedFolderIds.length > 0) {
          dispatch(fullPageLoadingSet(true));
          copyChecklistRoundPdfToDirectories(projectId, checklistRoundId, selectedFolderIds).then((files) => {
            dispatch(fullPageLoadingSet(false));
          });
          hideCopyPdfFileToDirectoryModal();
        }
      }}
      onModalClose={hideCopyPdfFileToDirectoryModal}
      selectOnlyOne={false}
    />
  ));

  if (!checklist || !checklistRound) return null;

  const openPdf = () => {
    generatePdfForChecklistRound(projectId, checklistRound.id).then((response) => {
      navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/view/${response.id}/${response.uuid}`);
    });
  };

  const openContainer = () => {
    navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/container/${checklistRound.digiDocContainer.id}`);
  };

  const toggleArchiveChecklist = () => {
    dispatch(archiveOrActivateChecklistsAsync([checklist.id])).then(() => {
      const newFilter = { ...filter, archived: checklist.archived };
      dispatch(fetchChecklistsForUserAsync({ projectId: projectId, filter: newFilter }));
    });
  };

  const ellipsisDropdownValues: Array<IDropdownItem> = [
    {
      text: t("GLOBAL.AS_PDF"),
      function: openPdf,
      icon: faFile
    },
    checklistRound.digiDocContainer && {
      text: t("GLOBAL.OPEN_DIGIDOC"),
      function: openContainer,
      icon: faFileSignature
    },
    {
      text: t("CHECKLIST.COPY_TO_FILETREE"),
      function: showCopyPdfFileToDirectoryModal,
      icon: faFileExport
    },
    isLastRound && {
      text: checklist.archived ? t("CHECKLIST.RESTORE") : t("CHECKLIST.ARCHIVE"),
      function: toggleArchiveChecklist,
      icon: checklist.archived ? faArrowRotateLeft : faBoxArchive
    },
    canUserDeleteChecklist && {
      text: t("GLOBAL.DELETE"),
      function: () => {
        showDeleteConfirmationModal();
      },
      icon: faTrash
    }
  ].filter(Boolean) as Array<IDropdownItem>;

  const deleteChecklist = () => {
    const checklistToDelete = { ...checklist, ...{ deleted: true } };
    const newFilter = checklist.archived ? { ...filter, ...{ archived: true } } : filter;
    dispatch(saveChecklistAsync(checklistToDelete)).then(() => {
      dispatch(fetchChecklistsForUserAsync({ projectId: projectId, filter: newFilter }));
    });
  };

  const toggleChecklistRound = () => {
    dispatch(toggleChecklistRoundSelection({ checklistId: checklist.id, checklistRoundId: checklistRound.id }));
  };

  const toggleShowChecklistRounds = () => {
    dispatch(toggleChecklistRoundsVisible({ checklistId: checklist.id }));
  };

  const addNewVersionToChecklist = () => {
    const lastRoundId = checklist.checklistRounds && checklist.checklistRounds.reduce((max, round) => (max.roundNumber > round.roundNumber ? max : round)).id;
    if (lastRoundId) {
      dispatch(startNewChecklistRoundFromLatestRoundAsync(lastRoundId)).then((action) => {
        const newRound = action.payload as IChecklistRound;
        navigate(ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/checklists/${newRound.checklistId}/round/${newRound.id}`);
      });
    }
  };

  const showCheckpointProgressBar = filter.category !== ChecklistBaseCategory.SAFETY && checklistRound.checkpointValue.total > 0;
  const showTomCalculation = filter.category === ChecklistBaseCategory.SAFETY && checklistRound.checkpointValue.tomOk >= 0 && checklistRound.checkpointValue.tomNotOk >= 0;

  return (
    <BhTableRow classes={classNames(!isLastRound && "bh-bg-smoke-50", isSelected && "bh-bg-mint-110", !isSelected && "hover:bh-bg-mint-30", "border-b bh-border-pigeon-40 group")}>
      <td className={classNames(isSelected && "bh-border-bauhub-green-120", !isSelected && "bh-border-white", "border-l-2")}>
        {isLastRound && (
          <div className="flex items-center">
            <BhCheckbox isChecked={isSelected} property={"selected"} large={true} onChange={toggleChecklistRound} />
          </div>
        )}
      </td>
      <td>
        <div className="group flex w-full flex-row items-center">
          {!isLastRound && (
            <div className="mr-1 flex items-center">
              <BhCheckbox isChecked={isSelected} property={"selected"} large={true} onChange={toggleChecklistRound} />
            </div>
          )}
          <BhFileCardIcon fileEntity={{ id: checklist.id, name: checklist.name, type: FileEntityType.CHECKLIST } as IFileEntity} fileCardSize={FileCardSize.LARGE} />
          <div className="ml-2 flex w-full flex-col">
            <Link to={ConfigSingleton.getInstance().getConfig().REACT_APP_HOME + `/project/${projectId}/checklists/${checklist.id}/round/${checklistRound.id}`}>
              <div className="text-14px bh-text-deep-ocean leading-5 hover:underline">{checklist.name}</div>
            </Link>
            <div className="flex flex-row items-center gap-x-1">
              <div className="text-12px bh-text-deep-ocean-80">{checklist.type}</div>
              {checklist.checklistTags && checklist.checklistTags.length > 0 && <BhTags tags={checklist.checklistTags} type={BhTagType.TAG} property={"name"} sortByProperty={true} />}
            </div>
          </div>
        </div>
      </td>
      <td className="flex h-full flex-row items-center justify-center">{checklistRound.digiDocContainer && <BhSignatureContainerStatus fileEntity={checklistRound.digiDocContainer} />}</td>
      <td>
        <div className="flex flex-col">
          <div className="text-14px bh-text-deep-ocean leading-5">
            {filter.toggleFieldValue === "created" ? formatDateTime(checklistRound.created) : getUserFullName(checklistRound.createdByUserEntity)}
          </div>
          <div className="text-12px bh-text-deep-ocean-80">{filter.toggleFieldValue === "created" ? getUserFullName(checklistRound.createdByUserEntity) : formatDateTime(checklistRound.created)}</div>
        </div>
      </td>
      <td className="bh-text-deep-ocean text-center font-bold">
        <BhTableRowVersion
          versionNumber={checklistRound.roundNumber}
          disabled={!isLastRound || checklistRound.status !== ChecklistRoundStatus.COMPLETE}
          onClick={toggleShowChecklistRounds}
          icon={checklistRound.status === ChecklistRoundStatus.IN_PROGRESS ? faUnlockKeyhole : faLockKeyhole}
          otherVersionsExist={isLastRound && checklistRound.roundNumber > 1}
          allVersionsVisible={checklist.showAllRounds}
          isActive={checklist.showAllRounds && isLastRound}
          addNewCallback={addNewVersionToChecklist}
        />
      </td>
      <td>
        {showCheckpointProgressBar && <ChecklistRoundProgressBar checkpointValue={checklistRound.checkpointValue} classes={"rounded-full"} />}
        {showTomCalculation && <ChecklistRoundTomPercentage checkpointValue={checklistRound.checkpointValue} />}
      </td>
      <td>
        {ellipsisDropdownValues.length > 0 && (
          <BhDropdown
            button={<BhIconButton icon={faEllipsisVertical} />}
            menu={<BhDropdownMenu values={ellipsisDropdownValues} type={BhDropdownTypeEnum.STRING} textProperty="text" />}
            position={BhDropdownPositionEnum.BOTTOM_LEFT}
          />
        )}
      </td>
    </BhTableRow>
  );
};

export default ChecklistListRow;
