import React, { FC } from "react";
import BhSideBySideInput from "@components/input/BhSideBySideInput";
import { FormType, IFormTemplate } from "@/model/IForm";
import BhTextareaSimple from "@components/input/BhTextareaSimple";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons/faCircleInfo";
import { getFormFieldTooltip } from "@components/form/helper/FormTooltipMap";
import { useTranslation } from "react-i18next";
import BhTooltip from "@components/BhTooltip";
import { classNames } from "@/utilities/jsUtilities";
import { useAppSelector } from "@/app/hooks";
import { selectValueByPropertyFromCurrentFormData } from "@/app/store/formSlice";

interface Props {
  formTemplate: IFormTemplate;
  saveCallback?: Function;
  disabled: boolean;
}

const FormTextareaField: FC<Props> = ({ formTemplate, saveCallback, disabled }) => {
  const { t } = useTranslation();
  const fieldValue = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, formTemplate.property));
  const tooltip = getFormFieldTooltip(formTemplate);

  return (
    <BhSideBySideInput
      label={
        <div className="flex flex-row items-start pt-2">
          {tooltip && (
            <BhTooltip body={t(tooltip)}>
              <FontAwesomeIcon icon={faCircleInfo} className="bh-text-deep-ocean mr-3 mt-0.5" />
            </BhTooltip>
          )}
          <div>{t(formTemplate.value)}</div>
        </div>
      }
      customLabelPosition={true}
    >
      <BhTextareaSimple
        initialValue={fieldValue}
        property={formTemplate.property}
        onBlurCallback={saveCallback}
        disabled={disabled}
        inputClasses={classNames([FormType.KOOSOLEK, FormType.KOOSOLEK_LV].includes(formTemplate.formType) && formTemplate.property === "nextDate" && "font-bold")}
      />
    </BhSideBySideInput>
  );
};

export default FormTextareaField;
