import React, { FC } from "react";
import BhSideBySideInput from "@components/input/BhSideBySideInput";
import { FormType, IFormTemplate } from "@/model/IForm";
import BhInputWithFetchedUsers from "@components/input/BhInputWithFetchedUsers";
import { useTranslation } from "react-i18next";
import { getUserFullName } from "@/model/IUser";
import { useAppSelector } from "@/app/hooks";
import { selectValueByPropertyFromCurrentFormData } from "@/app/store/formSlice";

interface Props {
  formTemplate: IFormTemplate;
  saveCallback?: Function;
  disabled: boolean;
}

const FormInputUserField: FC<Props> = ({ formTemplate, saveCallback, disabled }) => {
  const { t } = useTranslation();
  const fieldValue = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, formTemplate.property));

  const saveUser = (chosenObject: any) => {
    const userObject = chosenObject[formTemplate.property];
    const objectToSave = { [formTemplate.property]: userObject.id ? getUserFullName(userObject) : userObject };
    saveCallback && saveCallback(objectToSave);
    if (formTemplate.formType === FormType.VCW) {
      if (formTemplate.property === "contractorRepresentative" && userObject.simpleUserInfo && userObject.simpleUserInfo.companyName) {
        const companyNameToSave = { contractorRepresentativeCompany: userObject.simpleUserInfo.companyName };
        saveCallback && saveCallback(companyNameToSave);
      }
      if (formTemplate.property === "subcontractorRepresentative" && userObject.simpleUserInfo && userObject.simpleUserInfo.companyName) {
        const companyNameToSave = { subcontractorRepresentativeCompany: userObject.simpleUserInfo.companyName };
        saveCallback && saveCallback(companyNameToSave);
      }
    }
    if (formTemplate.formType === FormType.SCM) {
      if (formTemplate.property === "subcontractorRepresentative" && userObject.simpleUserInfo && userObject.simpleUserInfo.companyName) {
        const companyNameToSave = { subcontractorRepresentativeCompany: userObject.simpleUserInfo.companyName };
        saveCallback && saveCallback(companyNameToSave);
      }
    }
  };

  return (
    <BhSideBySideInput label={t(formTemplate.value)}>
      <BhInputWithFetchedUsers initialValue={fieldValue} property={formTemplate.property} saveCallback={saveUser} disabled={disabled} />
    </BhSideBySideInput>
  );
};

export default FormInputUserField;
