import { ISignatureHolder, ISignRequest } from "@/model/ISignatureHolder";
import { IMobileSignDTO } from "@/model/signing/IMobileSignDTO";
import { ISignResult } from "@/model/signing/ISignResult";
import { bauhubGet, bauhubPost, bauhubPublicPost } from "@/api/bauhubAPI";
import { EntityId } from "@reduxjs/toolkit";

export function fetchContainerSignatures(projectId: EntityId, containerUuid: string, containerId: EntityId): Promise<Array<ISignatureHolder>> {
  return bauhubGet("/project/" + projectId + "/container/" + containerUuid + "/signatures", { containerId: containerId });
}

export function initializeMobileSign(mobileSignDTO: IMobileSignDTO): Promise<ISignResult> {
  return bauhubPost("/mobile/sign?st=false", mobileSignDTO);
}

export function initializePublicMobileSign(mobileSignDTO: IMobileSignDTO): Promise<ISignResult> {
  return bauhubPublicPost("/mobile/sign?st=false", mobileSignDTO);
}

export function initializeSmartSign(mobileSignDTO: IMobileSignDTO): Promise<ISignResult> {
  return bauhubPost("/smart/sign?st=false", mobileSignDTO);
}

export function initializePublicSmartSign(mobileSignDTO: IMobileSignDTO): Promise<ISignResult> {
  return bauhubPublicPost("/smart/sign?st=false", mobileSignDTO);
}

export function fetchMobileSigningStatus(mobileSignDTO: IMobileSignDTO): Promise<ISignResult> {
  return bauhubPost("/mobile/status?st=false", mobileSignDTO);
}

export function fetchPublicMobileSigningStatus(mobileSignDTO: IMobileSignDTO): Promise<ISignResult> {
  return bauhubPublicPost("/mobile/status?st=false", mobileSignDTO);
}

export function fetchSmartSigningStatus(mobileSignDTO: IMobileSignDTO): Promise<ISignResult> {
  return bauhubPost("/smart/status?st=false", mobileSignDTO);
}

export function fetchPublicSmartSigningStatus(mobileSignDTO: IMobileSignDTO): Promise<ISignResult> {
  return bauhubPublicPost("/smart/status?st=false", mobileSignDTO);
}

export function declineSignature(signatureHolder: ISignatureHolder): Promise<ISignatureHolder> {
  return bauhubPost("/project/container/" + signatureHolder.containerUuid + "/invite/decline", signatureHolder);
}

export function declinePublicSignature(signatureHolder: ISignatureHolder): Promise<ISignatureHolder> {
  return bauhubPublicPost("/project/container/" + signatureHolder.containerUuid + "/invite/decline", signatureHolder);
}

export function resendSignInvite(signature: ISignatureHolder): Promise<ISignatureHolder> {
  return bauhubPost("/project/container/invite/resend", signature);
}

// export function fetchUserUnsignedInvites(): Promise<Array<ISignatureHolder>> {
//   return bauhubGet("/v2/user/sign/invites/unsigned");
// }

export function fetchContainerHash(projectId: EntityId, req: any): Promise<{ dataToSign: String; dataToSignInHex: string }> {
  return bauhubPost("/project/" + projectId + "/container/hash?st=false", req);
}

export function fetchPublicContainerHash(projectId: EntityId, req: any): Promise<{ dataToSign: String; dataToSignInHex: string }> {
  return bauhubPublicPost("/project/" + projectId + "/container/hash?st=false", req);
}

export function registerContainerSigning(projectId: EntityId, req: ISignRequest): Promise<ISignatureHolder> {
  return bauhubPost("/project/" + projectId + "/container/sign", req);
}

export function registerPublicContainerSigning(projectId: EntityId, req: ISignRequest): Promise<ISignatureHolder> {
  return bauhubPublicPost("/project/" + projectId + "/container/sign", req);
}
