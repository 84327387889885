import React, { FC } from "react";
import BhSideBySideInput from "@components/input/BhSideBySideInput";
import { FormType } from "@/model/IForm";
import BhInputWithFetchedUsers from "@components/input/BhInputWithFetchedUsers";
import BhDatePicker from "@components/input/BhDatePicker";
import { useAppSelector } from "@/app/hooks";
import { selectCurrentFormType, selectValueByPropertyFromCurrentFormData } from "@/app/store/formSlice";

interface Props {
  saveCallback: Function;
  disabled: boolean;
}

const FormMntFooterField: FC<Props> = ({ saveCallback, disabled }) => {
  const formType = useAppSelector(selectCurrentFormType);
  const andisDate = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, "andisDate"));
  const vottisDate = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, "vottisDate"));
  const geodeetDate = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, "geodeetDate"));
  const andis = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, "andis"));
  const vottis = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, "vottis"));
  const geodeet = useAppSelector((state) => selectValueByPropertyFromCurrentFormData(state, "geodeet"));

  const initialAndisDateValue = andisDate ? new Date(andisDate) : new Date();
  const initialVottisDateValue = vottisDate ? new Date(vottisDate) : new Date();
  const initialGeodeetDateValue = geodeetDate ? new Date(geodeetDate) : new Date();

  return (
    <div className="mb-3">
      {formType !== FormType.MNT_MP_TRUUP && (
        <>
          <BhSideBySideInput label={"Andis üle: Töövõtja projektijuht või tema volitatud isik"}>
            <div className="flex w-1/2 flex-row gap-x-3">
              <BhInputWithFetchedUsers initialValue={andis} property={"andis"} saveCallback={saveCallback} returnOnlyString={true} disabled={disabled} />
              <BhDatePicker initialValue={initialAndisDateValue} property={"andisDate"} onChangeCallback={saveCallback} returnISOString={true} disabled={disabled} />
            </div>
          </BhSideBySideInput>
          <BhSideBySideInput label={"Võttis vastu: Insener või tema volitatud isik"}>
            <div className="flex w-1/2 flex-row gap-x-3">
              <BhInputWithFetchedUsers initialValue={vottis} property={"vottis"} saveCallback={saveCallback} returnOnlyString={true} disabled={disabled} />
              <BhDatePicker initialValue={initialVottisDateValue} property={"vottisDate"} onChangeCallback={saveCallback} returnISOString={true} disabled={disabled} />
            </div>
          </BhSideBySideInput>
        </>
      )}
      {formType !== FormType.MNT_MP_KANDEVOIME && (
        <BhSideBySideInput label={"Geodeet:"}>
          <div className="flex w-1/2 flex-row gap-x-3">
            <BhInputWithFetchedUsers initialValue={geodeet} property={"geodeet"} saveCallback={saveCallback} returnOnlyString={true} disabled={disabled} />
            <BhDatePicker initialValue={initialGeodeetDateValue} property={"geodeetDate"} onChangeCallback={saveCallback} returnISOString={true} disabled={disabled} />
          </div>
        </BhSideBySideInput>
      )}
    </div>
  );
};

export default FormMntFooterField;
