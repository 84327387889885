import React, { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  addCompanyAdministratorAsync,
  fetchCompanyInvitedUsersAsync,
  fetchCompanyUsersAsync,
  selectAddCompanyAdministratorModalOpen,
  selectCompanyAdminUsers,
  selectCompanyInvitedAdmins,
  selectCompanyInvitedUsers,
  selectCompanyUsers,
  selectCompanyUsersStatus,
  selectDeletableUser,
  setAddCompanyAdministratorModalOpen
} from "@/app/store/companiesSlice";
import CompanyAdminRow from "@/views/home/company/detail/companySettingsUsers/CompanyAdminRow";
import BhTableHeader from "@components/table/BhTableHeader";
import { IBhTableHeader } from "@/model/bhModels/IBhTableHeader";
import { ICompanyUser } from "@/model/companyUsers/ICompanyUser";
import CompanyProjectUserRow from "@/views/home/company/detail/companySettingsUsers/CompanyProjectUserRow";
import { faPlus } from "@fortawesome/pro-solid-svg-icons/faPlus";
import BhSecondaryButton from "@components/buttons/BhSecondaryButton";
import CompanyUsersFilter from "@/views/home/company/detail/companySettingsUsers/CompanyUsersFilter";
import { ICompanyInvitedUser } from "@/model/companyUsers/ICompanyInvitedUser";
import AddCompanyAdminModal from "@/views/home/company/detail/companySettingsUsers/AddCompanyAdminModal";
import DeleteCompanyUserModal from "@/views/home/company/detail/companySettingsUsers/DeleteCompanyUserModal";
import { EntityId } from "@reduxjs/toolkit";
import BhScrollableBody from "@components/detailContainer/BhScrollableBody";
import { Trans, useTranslation } from "react-i18next";
import { CompanySettingsAdminTableHeaderFC } from "@/views/home/company/detail/companySettingsUsers/CompanySettingsAdminTableHeaderFC";
import BhLargeText from "@components/text/BhLargeText";
import { BhStateStatusType } from "@/model/utilities/BhStateStatusType";
import CompanySettingsUsersAdminSkeleton from "@/views/home/company/detail/CompanySettingsUsersAdminSkeleton";
import CompanySettingsUsersAllUserSkeleton from "@/views/home/company/detail/CompanySettingsUsersAllUserSkeleton";
import { ICompanyAdministratorChangeRequest } from "@/model/ICompanyAdministratorChangeRequest";

interface Props {
  companyId: EntityId;
}

const CompanySettingsUsersTab: FC<Props> = ({ companyId }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const companyAdmins: Array<ICompanyUser> = useAppSelector((state) => selectCompanyAdminUsers(state, companyId));
  const allCompanyUsers: Array<ICompanyUser> = useAppSelector((state) => selectCompanyUsers(state, companyId));
  const invitedUsers: Array<ICompanyInvitedUser> = useAppSelector((state) => selectCompanyInvitedUsers(state, companyId));
  const invitedAdmins: Array<ICompanyInvitedUser> = useAppSelector((state) => selectCompanyInvitedAdmins(state, companyId));
  const addModalOpen = useAppSelector(selectAddCompanyAdministratorModalOpen);
  const deletableUser = useAppSelector(selectDeletableUser);
  const companyUsersStatus = useAppSelector(selectCompanyUsersStatus);

  const adminTableHeaders: Array<IBhTableHeader<ICompanyUser>> = [
    { id: 1, content: t("GLOBAL.NAME"), field: "firstName", classes: "w-2/5 ", sortable: false },
    { id: 2, content: t("GLOBAL.EMAIL"), field: "username", classes: "w-72", sortable: false },
    {
      id: 3,
      content: <CompanySettingsAdminTableHeaderFC header={t("COMPANY.SETTINGS.TABLE.ACCOUNT_MANAGER")} tooltip={t("COMPANY.SETTINGS.COMPANY_ADMINISTRATORS.ACCOUNT_MANAGER.INFO")} />,
      field: "accountManager",
      classes: "w-52",
      sortable: false
    },
    {
      id: 4,
      content: t("COMPANY.SETTINGS.TABLE.CHECKLISTS_MANAGER"),
      classes: "w-52"
    },
    {
      id: 5,
      content: <CompanySettingsAdminTableHeaderFC header={t("COMPANY.SETTINGS.USERS.DEFAULT_ADMIN")} tooltip={t("COMPANY.SETTINGS.COMPANY_ADMINISTRATORS.DEFAULT_PROJECT_ADMIN.INFO")} />,
      colSpan: 2,
      field: "newProjectDefaultAdmin",
      classes: "w-52",
      sortable: false
    }
  ];

  const projectUsersTableHeaders: Array<IBhTableHeader<ICompanyUser>> = [
    { id: 1, content: t("GLOBAL.NAME"), field: "firstName", classes: " w-2/5 ", sortable: false },
    { id: 2, content: t("GLOBAL.EMAIL"), field: "username", classes: " flex-grow ", sortable: false },
    { id: 3, content: t("COMPANY.SETTINGS.PROJECTS"), field: "projects", classes: " w-60 ", sortable: false }
  ];

  useEffect(() => {
    dispatch(fetchCompanyUsersAsync(companyId));
    dispatch(fetchCompanyInvitedUsersAsync(companyId));
  }, []);

  const openAdministratorModal = () => {
    dispatch(setAddCompanyAdministratorModalOpen(true));
  };

  const deleteUserModalIsOpen = deletableUser.username?.length > 0;
  const noAdmins = companyAdmins.length === 0 && invitedAdmins.length === 0;
  const noUsers = allCompanyUsers.length === 0 && invitedUsers.length === 0;
  const adminsAndUsersSuccess = companyUsersStatus === BhStateStatusType.SUCCESS;
  const companyAdminsAndUsersLoading = companyUsersStatus === BhStateStatusType.PENDING;
  const showCompanyAdmins = adminsAndUsersSuccess && !noAdmins && companyAdmins;
  const showCompanyInvitedAdmins = adminsAndUsersSuccess && invitedAdmins;
  const showCompanyUsers = adminsAndUsersSuccess && !noUsers && allCompanyUsers;
  const showCompanyInvitedUsers = adminsAndUsersSuccess && invitedUsers;
  const noAdminsResult = noAdmins && adminsAndUsersSuccess;
  const noUsersResult = noUsers && adminsAndUsersSuccess;

  const handleAdd = (request: ICompanyAdministratorChangeRequest) => {
    request.username = request.username?.toLowerCase();
    dispatch(addCompanyAdministratorAsync({ companyId: companyId, request: request }));
  };

  const handleCancel = () => {
    dispatch(setAddCompanyAdministratorModalOpen(false));
  };

  return (
    <BhScrollableBody>
      <div className="px-14">
        <div className="flex flex-row pt-12">
          <h2 className="bh-text-deep-ocean flex-grow">
            <Trans>COMPANY.SETTINGS.COMPANY_ADMINISTRATORS</Trans>
          </h2>
          <div className="flex-none">
            <BhSecondaryButton icon={faPlus} buttonProps={{ onClick: openAdministratorModal }}>
              {t("COMPANY.SETTINGS.ADD_ADMINISTRATOR")}
            </BhSecondaryButton>
          </div>
        </div>

        {noAdminsResult && <BhLargeText classes="w-full text-center mt-10">{t("COMPANY.SETTINGS.USERS.NO_ADMINS")}</BhLargeText>}

        <table className="w-full">
          <BhTableHeader key="adminsTable" columns={adminTableHeaders} />
          <tbody>
            {companyAdminsAndUsersLoading && <CompanySettingsUsersAdminSkeleton numberOfDisplayedItems={3} />}
            {showCompanyAdmins && companyAdmins.map((admin) => <CompanyAdminRow key={admin.userEntityId} companyId={companyId} username={admin.username} userExists={true} />)}
            {showCompanyInvitedAdmins && invitedAdmins.map((admin) => <CompanyAdminRow key={admin.username} companyId={companyId} username={admin.username} userExists={false} />)}
          </tbody>
        </table>

        <h2 className="bh-text-deep-ocean mt-12">
          <Trans>COMPANY.SETTINGS.PROJECT_USERS</Trans>
        </h2>
        <CompanyUsersFilter companyId={companyId} />

        {noUsersResult && <BhLargeText classes="w-full text-center mt-10">{t("GLOBAL.NO_RESULTS")}</BhLargeText>}

        <table className="w-full">
          <BhTableHeader key="allCompanyUsersTable" columns={projectUsersTableHeaders} />
          <tbody>
            {companyAdminsAndUsersLoading && <CompanySettingsUsersAllUserSkeleton numberOfDisplayedItems={3} />}
            {showCompanyUsers && allCompanyUsers.map((companyUser) => <CompanyProjectUserRow key={companyUser.userEntityId} companyId={companyId} username={companyUser.username} userExists={true} />)}
            {showCompanyInvitedUsers &&
              invitedUsers.map((invitedUser) => <CompanyProjectUserRow key={invitedUser.username} companyId={companyId} username={invitedUser.username} userExists={false} />)}
          </tbody>
        </table>

        {addModalOpen && <AddCompanyAdminModal companyId={companyId} onSave={handleAdd} onCancel={handleCancel} />}
        {deleteUserModalIsOpen && <DeleteCompanyUserModal />}
      </div>
    </BhScrollableBody>
  );
};

export default CompanySettingsUsersTab;
